import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const styles = (theme) => ({
    heading: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        textAlign: 'left',
        display: 'inline',
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.current.invertedBtn.primaryColor,
    },
});
class Commentsadd extends React.PureComponent {
    render() {
        const { classes } = this.props;
        return (
            <>
                <Grid container>
                    <Grid className={classes.commentss}>
                        <h1 className={classes.heading}> Comments</h1>
                    </Grid>
                </Grid>
            </>
        );
    }
}
Commentsadd.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Commentsadd);
