import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Loginpopup from '../../pages/loginpopup/index';
import * as actionTypes from '../../../reducers/auth/action.js';

const styles = (theme) => ({
    button: {
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        margin: '10px auto',
        width: '95%',
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '10px 30px',
        fontWeight: 600,
        borderRadius: 8,
    },
    InvertedButton: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.invertedBtn.fontColor,

        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.invertedBtn.fontColor,
        },
        marginLeft: 0,
        textTransform: 'capitalize',
        padding: '10px 43px',
        width: '95%',
        fontWeight: 600,
        borderRadius: 8,
        '& a': {
            textDecoration: 'none',
            color: theme.palette.current.invertedBtn.fontColor,
        },
    },
    linkPoem: {
        textDecoration: 'none',
        color: theme.palette.current.invertedBtn.primaryColor,
    },
    InvertedmyaccountButton: {
        margin: '0px auto 10px auto',
    },
});
class UserMenu extends React.PureComponent {
    handleDrawerToggleAdd = () => {
        this.props.showLoginDrawer();
        return (
            <Link to="/addpoem" />
        );
    }

    render() {
        const {
            classes, core, userLogout, loginPopup, showLoginDrawer, hideLoginDrawer,
        } = this.props;
        const corelogin = core;
        return (
            <>
                {(corelogin.auth_token()) ? (
                    <>
                        <Button className={classes.button}>
                            <Link to="/addpoem" className={classes.linkPoem}>
                                Add Poem
                            </Link>
                        </Button>
                        <Button className={`${classes.InvertedmyaccountButton} ${classes.InvertedButton}`}>
                            <Link to="/user">
                                My Account
                            </Link>
                        </Button>
                        <Button className={classes.InvertedButton} onClick={() => userLogout()}>
                            <Link to="/">
                                Log Out
                            </Link>
                        </Button>
                    </>
                )
                    : (
                        <>
                            <Button className={classes.button} onClick={this.handleDrawerToggleAdd}>
                                Add Poem
                            </Button>
                            <Button className={classes.InvertedButton} onClick={showLoginDrawer}>
                                Login
                            </Button>
                            <Dialog
                                open={loginPopup.value}
                                onClose={hideLoginDrawer}
                                className={classes.alertboxx}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                maxWidth="lg"
                            >
                                <span className={classes.popupheight}>
                                    <DialogContent className={classes.innerheight}>
                                        <Loginpopup handleDrawerToggle={this.handleDrawerToggle} />
                                    </DialogContent>
                                </span>
                            </Dialog>
                        </>
                    )}
            </>
        );
    }
}
UserMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    core: PropTypes.object.isRequired,
    userLogout: PropTypes.func.isRequired,
    loginPopup: PropTypes.bool.isRequired,
    showLoginDrawer: PropTypes.func.isRequired,
    hideLoginDrawer: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    Core: state.Core,
    Header: state.Header,
    loginPopup: state.LoginPopup,
});

const mapDispatchToProps = (dispatch) => ({
    userLogout: () => dispatch({ type: actionTypes.USER_LOGOUT }),
    showLoginDrawer: () => dispatch({ type: actionTypes.LOGIN_DRAWER_TOGGLE, value: true }),
    hideLoginDrawer: () => dispatch({ type: actionTypes.LOGIN_DRAWER_TOGGLE, value: false }),
    dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserMenu));
