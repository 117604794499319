import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
// import Badges from '../../../../../../images/books.png';
import * as poemActionTypes from '../../../../../../reducers/poempage/action.js';


const styles = (theme) => ({
    heading: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        fontSize: 23,
    },
    poemabout: {
        background: theme.palette.current.footerBrandColor,
        padding: 10,
        borderRadius: 10,
        marginTop: 10,
        display: 'flex',
    },
    chip: {
        margin: 10,
        padding: 20,
        borderRadius: '10px !important',
    },
    poembadgess: {
        textAlign: 'left',
        paddingLeft: 20,
        '& h1': {
            marginBottom: 0,
        },
    },
});
class Poembadges extends React.Component {
    loadSkeleton(size = 1) {
        const { classes } = this.props;
        const skeletonSize = Array(size).fill('');
        let index = 1;
        return skeletonSize.map(() => {
            index += 1;
            return (
                <Grid key={`skeleton_ ${index}`} item xs={12}>
                    <Grid container>
                        <Grid className={index % 2 ? classes.rightAlign : classes.leftAlign} item xs={12}>
                            <Skeleton height={80} />
                        </Grid>
                    </Grid>
                </Grid>
            );
        });
    }


    render() {
        const { classes, poetBadges } = this.props;
        return (
            <>
                        <Grid className={classes.poemabout} item xs={12}>
                            <Grid className={classes.poembadgess}>
                              
                                <h1 className={classes.heading}> This Poet's Badges</h1>
                                { poetBadges && Object.keys(poetBadges).map((key, i) => (
                                    (
                                        <img
                                            className={classes.imgUserBadges}
                                            src={poetBadges[key].image}
                                            alt="user badges"
                                        />
                                    )
                                ))}
                                
                            </Grid>  
                        </Grid>
            </>
        );
    }
}
const mapStateTopProps = (state) => ({
    poetBadges: state.Poempage.find((st) => st.type === poemActionTypes.POEMPAGE_POETDETAIL_BADGES).value,


});

export default connect(mapStateTopProps, null)(withStyles(styles)(Poembadges));
