import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from 'react-loading-skeleton';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import * as actionTypes from '../../../reducers/header/action.js';
import Logo from '../../common/logo/logo';
import Mobilemenu from './menu';
import UserMenu from './userMenu';

const drawerWidth = 240;
const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
        alignItems: 'center',
    },
    mobileheader: {
        borderColor: theme.palette.current.primaryColor,
        borderTop: '5px solid',
        paddingTop: 0,
        height: 'auto',
        minHeight: 130,
        zIndex: 11111,
    },
    linkMain: {
        background: theme.palette.current.primaryColor,
        position: 'fixed',
        width: '100%',
        top: 0,
        height: 15,
        textAlign: 'center',
        fontSize: 5,
        zIndex: 10010,
        '& a': {
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
    redirectionWeb: {
        background: 'none',
        border: 'none',
        outline: 'none !important',
    },
    icon: {
        textAlign: 'right',
    },
    mobilelogo: {
        marginTop: 10,
        '& img': {
            [theme.breakpoints.down('sm')]: {
                height: 100,
            },
        },
    },
    appBar: {
        color: theme.palette.current.btn.primaryColor,
        background: theme.palette.current.slider.dotActive,
        zIndex: 11111,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            position: 'unset',
            boxShadow: 'none',
        },
        '& >div': {
            [theme.breakpoints.down('sm')]: {
                padding: '0px 15px',
            },
        },
    },
    lineBorder: {
        borderBottom: `${'1px solid'} ${theme.palette.current.borderBottomColor}`,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
    },
    mobusrmenu: {
        textAlign: 'center',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    header: {
        paddingTop: 15,
        paddingBottom: 15,
        borderBottom: '1px solid',
    },
    minHeight: {
        minHeight: 120,
    },
    right: {
        textAlign: 'right',
    },
    marginLeftAuto: {
        marginLeft: 'auto',
    },
    mobiledrawer: {
        zIndex: '11111 !important',
    },
    headerinnerdiv: {
        [theme.breakpoints.down('sm')]: {
            margin: '10px auto !important',
        },
    },
});
class Mobileheader extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            mobileOpen: false,
        };
    }

  handleDrawerToggle = () => {
      this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  handleCookie =() => {
      const cookies = new Cookies();
      cookies.set('destination', 'main', { domain: '.powerpoetry.org', path: '/' });
  }


  render() {
      const {
          classes, Core, Header, dispatch, LoginPopup,
      } = this.props;
      const { mobileOpen } = this.state;
      const drawer = (
          <div>
              <div className={classes.toolbar} />
              <Divider />
              <div>
                  {Core.getStateValueByType(Header, actionTypes.HEADER_MENU).length > 0
                      ? (
                          <Mobilemenu
                              menu={Core.getStateValueByType(Header, actionTypes.HEADER_MENU)}
                          />
                      )
                      : <Skeleton height={50} />}
              </div>
              <Divider />
              <div className={classes.mobusrmenu}>
                  {Core.getStateValueByType(Header, actionTypes.HEADER_USER_MENU).length > 0
                      ? (
                          <UserMenu
                              menu={Core.getStateValueByType(Header, actionTypes.HEADER_USER_MENU)}
                              core={Core}
                              dispatch={dispatch}
                              LoginPopup={LoginPopup}
                          />
                      )
                      : <Skeleton height={50} />}
              </div>
          </div>
      );
      return (
          <header className={classes.mobileheader}>
              <div className={classes.appBar}>
                  <Grid className={classes.linkMain}>
                      <button
                          type="button"
                          className={classes.redirectionWeb}
                          onClick={this.handleCookie}
                      > Click
                          <a href="https://powerpoetry.org/">
                           &nbsp;here&nbsp;
                          </a>
                          to get back on our main web portal
                      </button>
                  </Grid>
                  <Grid
                      className={`${classes.container}  ${Core.getCurrentPath() === '/' ? '' : classes.lineBorder}`}
                      container
                      justify="center"
                  >
                      <Grid xs={4} item className={classes.mobilelogo}>
                          {Core.getStateValueByType(Header, actionTypes.HEADER_LOGO)
                              ? (
                                  <Logo
                                      src={Core.getStateValueByType(Header, actionTypes.HEADER_LOGO)}
                                  />
                              )
                              : <Skeleton height={150} />}
                      </Grid>
                      <Grid xs={8} item className={classes.icon}>
                          <IconButton
                              color="inherit"
                              aria-label="open drawer"
                              edge="start"
                              onClick={this.handleDrawerToggle}
                              className={classes.menuButton}
                          >
                              <MenuIcon />
                          </IconButton>
                      </Grid>
                  </Grid>
              </div>
              <Hidden mdUp>
                  <Drawer
                      variant="temporary"
                      open={mobileOpen}
                      onClose={this.handleDrawerToggle}
                      className={classes.mobiledrawer}
                      classes={{ paper: classes.drawerPaper }}
                      ModalProps={{ keepMounted: true }}
                  >
                      {drawer}
                  </Drawer>
              </Hidden>
              <Hidden smDown implementation="css">
                  <Drawer
                      variant="permanent"
                      open
                      anchor="right"
                      classes={{ paper: classes.drawerPaper }}
                  >
                      {drawer}
                  </Drawer>
              </Hidden>
          </header>
      );
  }
}
Mobileheader.propTypes = {
    classes: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    Header: PropTypes.array.isRequired,
    LoginPopup: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
    Core: state.Core,
    Header: state.Header,
    LoginPopup: state.LoginPopup,
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Mobileheader));
