import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import * as poemActionTypes from '../../../../../../reducers/poempage/action'

const styles = theme => ({
  heading: {
    fontFamily: 'GT-Walsheim-Pro-Regular',
    display: 'inline',
    fontWeight: 500,
    textAlign: 'left'
  },
  headingShort: {
    fontFamily: 'GT-Walsheim-Pro-Regular',
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: 15,
    textAlign: 'justify'
  },
  headingShortInfo: {
    background: theme.palette.current.topics.background,
    marginBottom: 20,
    borderRadius: 8,
    padding: 20,
    fontFamily: 'GT-Walsheim-Pro-Regular',
    margin: '0 auto',
    paddingTop: 20,
    paddingBottom: 20
  },
  poemabout: {
    background: theme.palette.current.footerBrandColor,
    padding: 20,
    borderRadius: 10
  },
  poetSectionn: {
    display: 'inline-flex'
  },
  dummyImage: {
    width: '100%',
    borderRadius: '50%',
    height: 150,
    display: 'block',
    margin: 'auto'
  },
  otherpoem: {
    fontFamily: 'GT-Walsheim-Pro-Bold'
  },
  menuLink: {
    color: theme.palette.current.invertedBtn.primaryColor,
    textDecoration: 'none !important'
  },
  poetInfo: {
    overflow: 'hidden',
  }
})

// const data = (d) => {
//     if (d) {
//         if (d.und) {
//             return d.und[0].value;
//         }
//         return null;
//     }
// };
class Poetinfo extends React.Component {
  loadSkeleton (size = 1) {
    const { classes } = this.props
    const skeletonSize = Array(size).fill('')
    let index = 1
    return skeletonSize.map(() => {
      index += 1
      return (
        <Grid key={`skeleton_ ${index}`} item xs={12}>
          <Grid container>
            <Grid
              className={index % 2 ? classes.rightAlign : classes.leftAlign}
              item
              xs={12}
            >
              <Skeleton height={120} />
            </Grid>
          </Grid>
        </Grid>
      )
    })
  }

  render () {
    const { classes, poetInfo, poetName, poetImage } = this.props
    return (
      <>
        <Grid className={classes.headingShortInfo} item xs={9}>
          {poetName ? (
            <Grid className={classes.poetSectionn} item xs={12}>
              <Grid item xs={6}>
                <img
                  className={classes.dummyImage}
                  src={poetImage}
                  alt='PoetImage'
                />
              </Grid>

              <Grid item xs={8}>
                <h1 className={classes.heading}> {poetName}</h1>
                {poetInfo.und ? (
                  <Grid
                    className={`${classes.headingShortInfo} ${classes.poetInfo}`}
                  >
                    {poetInfo.und[0].value}
                  </Grid>
                ) : null}
                <Grid className={classes.otherpoem}>
                  <Link
                    to={`/userpoems/${poetName}`}
                    className={classes.menuLink}
                  >
                    Other Poem By This Poet
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            this.loadSkeleton()
          )}
        </Grid>
      </>
    )
  }
}
Poetinfo.propTypes = {
  classes: PropTypes.object.isRequired,
  poetInfo: PropTypes.string.isRequired,
  poetName: PropTypes.string.isRequired,
  poetImage: PropTypes.string.isRequired
}
const mapStateTopProps = state => ({
  poetName: state.Poempage.find(
    st => st.type === poemActionTypes.POEMPAGE_POETNAME
  ).value,
  poetImage: state.Poempage.find(
    st => st.type === poemActionTypes.POEMPAGE_POETIMAGE
  ).value,
  poetInfo: state.Poempage.find(
    st => st.type === poemActionTypes.POEMPAGE_POETDETAIL
  ).value
})
export default connect(mapStateTopProps, null)(withStyles(styles)(Poetinfo))
