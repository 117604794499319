import * as actionTypes from './action.js';

const initialState = [
    {
        type: actionTypes.HOMEPAGE_SLIDER,
        value: [],
    },
    {
        type: actionTypes.HOMEPAGE_POEMS,
        value: [],
    },
    {
        type: actionTypes.HOMEPAGE_TOPICS,
        value: null,
    },
    {
        type: actionTypes.HOMEPAGE_WIDGET_RIGHT,
        value: null,
    },
    {
        type: actionTypes.HOMEPAGE_RECEIVED_DATA,
        value: false,
    },
];
const Homepage = (state = initialState, action) => {
    let OtherStates = [];
    switch (action.type) {
        case actionTypes.HOMEPAGE_SLIDER:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.HOMEPAGE_POEMS:
            OtherStates = state.filter((st) => st.type !== action.type);
            const previousState = state.find((st) => st.type === action.type);
            if (previousState.value.length > 0) {
                return [
                    ...OtherStates,
                    {
                        type: action.type,
                        value: [...previousState.value, ...action.value],
                    },
                ];
            }
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];

        case actionTypes.HOMEPAGE_TOPICS:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.HOMEPAGE_WIDGET_RIGHT:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        default:
            return state;
    }
};

export default Homepage;
