import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';


const styles = (theme) => ({
    sidebar: {
        borderRadius: 8,
        marginTop: '-8%',
        textAlign: 'center',
        '& h1': {
            fontsize: 25,
        },
    },
    headingText: {
        paddingBottom: 15,
        marginTop: 15,
    },
    sidebarSkelton: {
        marginTop: 5,
    },
    circularLoader: {
        marginTop: 200,
        color: theme.palette.current.primaryColor,
    },
    contentStyle: {
        fontFamily: 'GT-Walsheim-Pro-light',
    },
    subrow: {
        padding: 0.5,
        textAlign: 'justify',
        justifyContent: 'center',
        borderRadius: 3,
        backgroundColor: theme.palette.current.commentSupporter.listBackground,
        '&:nth-child(even)': {
            textAlign: 'justify',
            width: '100%',
            backgroundColor: theme.palette.current.editProfilePage.listPrimaryColor,
            borderRadius: 3,
        },
    },
});
class CommentSupporter extends PureComponent {
    constructor() {
        super();
        this.state = {
            data: [],
        };
    }


    componentDidMount() {
        const {  Core } = this.props;
        const  ApiRoutes  = new Core.ApiRoutes();
        const Http = new Core.Http();
        Http.post(ApiRoutes.getRoute('getPageViews'), { path: 'api/top-poetry-supporters-sidebar' })
            // .then((res) => res.json)
            .then((res) => this.setState({
                data: res.data.nodes,
            }));
    }


    render() {
        const { classes  } = this.props;
        const { data } = this.state;
        return (
            <>

                {    data.length > 0
                    ?                                                 (
                        <Grid className={classes.sidebar} item xs={4}>
                            <h1 className={classes.headingText}> Top Poetry Supporters </h1>

                            <div> {data.map((content, i) => (
                                <div key={i} className={classes.subrow}>
                                    {content
                                        ? (<ul className={classes.contentStyle}> {content.node.Name} <br /> {content.node.Uid}</ul>)
                                        : <CircularProgress />}
                                </div>
                            ))}
                            </div>

                        </Grid>
                    )
                    :  (
                        <Grid className={classes.sidebarSkelton} item xs={4}>
                            <Skeleton height={870} width={390}  />
                        </Grid>
                    )}
            </>
        );
    }
}
const Core = (state) => state.Core;

const mapStateToProps = (state) => ({
    Core: Core(state),
});
export default  connect(mapStateToProps)(withStyles(styles)(CommentSupporter));
