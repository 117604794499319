import * as actionTypes from './action.js';

const initialState = [
    {
        type: actionTypes.BASICPAGE_TITLE,
        value: null,
    },
    {
        type: actionTypes.BASICPAGE_LOADING,
        value: true,
    },
    {
        type: actionTypes.BASICPAGE_BODY,
        value: null,
    },
    {
        type: actionTypes.BASICPAGE_ALL_CONTENT,
        value: null,
    },
    {
        type: actionTypes.BASICPAGE_CURRENT_LINK,
        value: null,
    },
];
const BasicPage = (state = initialState, action) => {
    let OtherStates = [];
    switch (action.type) {
        case actionTypes.BASICPAGE_TITLE:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.BASICPAGE_ALL_CONTENT:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.BASICPAGE_BODY:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.BASICPAGE_CURRENT_LINK:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.BASICPAGE_LOADING:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        default:
            return state;
    }
};

export default BasicPage;
