/* eslint-disable import/prefer-default-export */
import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../reducers/poemactionpage/action.js';
import Http from '../services/http.js';
import ApiRoutes from '../api/Routes.js';

function* getPoemByUrl(action) {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    const data = yield call([http, http.post], apiRoutes.getRoute('getPoemByUrl'), { path: action.url });
    yield put({ type: actionTypes.ACTIONPAGE_TITLE, value: data.data.data.title });
    yield put({ type: actionTypes.ACTIONPAGE_IMAGE, value: data.data.data.field_image });
    yield put({ type: actionTypes.ACTIONPAGE_BODY,  value: data.data.data.body });
}

function* getsSearchAction() {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    const data = yield call([http, http.post], apiRoutes.getRoute('getsearchactions'), {});
    yield put({ type: actionTypes.ACTIONPAGE_RIGHTWIDGET, value: data.data.data });
}

function* unMountPoemActionPage() {
    yield put({ type: actionTypes.ACTIONPAGE_TITLE });
    yield put({ type: actionTypes.ACTIONPAGE_IMAGE });
    yield put({ type: actionTypes.ACTIONPAGE_BODY });
    yield put({ type: actionTypes.ACTIONPAGE_RIGHTWIDGET });
}
export function* watcherPoemActionPage() {
    yield takeEvery(actionTypes.LOAD_POEM_ACTION_BY_URL, getPoemByUrl);
    yield takeEvery(actionTypes.LOAD_SEARCH_ACTION, getsSearchAction);
    yield takeEvery(actionTypes.UNMOUNT_POEM_ACTION_PAGE, unMountPoemActionPage);
}
