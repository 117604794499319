import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from 'react-loading-skeleton';
import Registration from '../../../../common/registration';
import IntroBlock from '../../../../common/introBlock/IntroBlock';
import Topics from '../../../../common/topics/topics';
import ProfileInfo from '../../../../common/registration/ProfileInfo';


const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    clear: {
        clear: 'both',
    },
});
class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    getRegistration = () => {
        const { homepageData, core } = this.props;
        if (!localStorage.authentication_token) {
            if (homepageData.length > 0) {
                return <Registration registerdata={core} />;
            }
            return <Skeleton height={450} />;
        }
        return <ProfileInfo Core={core} />;
    }

    render() {
        const {
            rigthIntroWidget, classes, topics,
        } = this.props;
        return (
            <>
                <div className={classes.clear}>
                    {this.getRegistration()}
                </div>
                <div className={classes.clear}>
                    {rigthIntroWidget
                        ? <IntroBlock data={rigthIntroWidget} />
                        : <Skeleton height={450} />}
                </div>
                <div className={classes.clear}>
                    {topics ? <Topics data={topics} /> : <Skeleton height={650} />}
                </div>
            </>
        );
    }
}
export default withStyles(styles)(Sidebar);
