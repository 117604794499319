/* eslint-disable consistent-return */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'react-html-parser';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({
    poemheader: {
        margin: 'auto',
        paddingBottom: 25,
        '& p': {
            fontFamily: 'GT-Walsheim-Pro-Regular',
            fontSize: '18px',
            lineHeight: 2,
            textAlign: 'justify',
        },
        '& span': {
            fontFamily: 'GT-Walsheim-Pro-Regular !important',
            fontSize: '18px !important',
            lineHeight: '2px !important',
            fontStyle: 'unset !important',
        },
    },
    date: {
        fontFamily: 'GT-Walsheim-Pro-Light',
        paddingBottom: 20,
        color: theme.palette.current.darkgreycolor,
        display: 'inline-block',
        paddingRight: 20,
        verticalAlign: 'middle',
    },
    heading: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.current.invertedBtn.primaryColor,
        display: 'inline',
    },
    headingGroup: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        display: 'inline',
    },
    poetNameNew: {
        fontFamily: 'GT-Walsheim-Pro-Regular !important',
        paddingLeft: '10px !important',
        display: 'inline',
    },
    poemthumbnail: {
        width: '100%',
        display: 'block',
        margin: '10px auto',
    },
    mainimage: {
        width: '100%',
        display: 'block',
    },
    buttonWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    buttonGroup: {
        width: '60%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    invertedButton: {
        height: 40,
        marginTop: 20,
        '& a': {
            color: theme.palette.current.invertedBtn.fontColor,
            textDecoration: 'none !important',
        },
        [theme.breakpoints.down('lg')]: {
            padding: '10px 13px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '10px 20px',
        },
        textTransform: 'capitalize',
        fontWeight: 600,
        borderRadius: 8,
        marginLeft: 85,
    },
    editButton: {
        background: theme.palette.current.invertedBtn.fontColor,
        color: theme.palette.current.invertedBtn.primaryColor,
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.invertedBtn.fontColor,
        },
    },
    deleteButton: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.invertedBtn.fontColor,
        '&:hover': {
            background: theme.palette.current.invertedBtn.fontColor,
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
});
const datapoem = (data) => {
    if (data) {
        if (data.und) {
            return data.und[0].value;
        }
        return ('');
    }
};
const poemDataImage = (dataimage) => {
    if (dataimage) {
        if (dataimage.und) {
            return dataimage.und[0].uri;
        }
        return ('');
    }
};
const poemDataImageAlt = (dataimage) => {
    if (dataimage) {
        if (dataimage.und) {
            return dataimage.und[0].alt;
        }
        return ('');
    }
};
class Poembody extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            open: false,
        };
    }


handleClickOpen=() => {
    this.setState({
        open: true,
    });
}

handleClose=() => {
    this.setState({
        open: false,
    });
}

handleUnpublishPoem = () => {
    const { Core, poemid, history } = this.props;
    const ApiRoutes = new Core.ApiRoutes();
    const Http = new Core.Http();
    Http.post(ApiRoutes.getRoute('unpublishPoem'), {
        nid: poemid,
    })
        .then((response) => {
            this.setState({
                open: false,
            });
            if (response.status === 1) {
                this.setState({
                    open: false,
                });
                history.push('/user');
            }
        });
}

loadSkeleton(size = 1) {
    const { classes } = this.props;
    const skeletonSize = Array(size).fill('');
    let index = 1;
    return skeletonSize.map(() => {
        index += 1;
        return (
            <Grid key={`skeleton_ ${index}`} item xs={12}>
                <Grid container>
                    <Grid className={index % 2 ? classes.rightAlign : classes.leftAlign} item xs={12}>
                        <Skeleton height={300}  />
                    </Grid>
                </Grid>
            </Grid>
        );
    });
}

render() {
    const {
        classes, data, dataTitle, dataCreated, poetName, dataImage, Core, url, poetscope,
    } = this.props;
    const { open } = this.state;
    const reqdata = JSON.stringify(datapoem(data));
    const stringData = reqdata.replace(new RegExp('\\\\r', 'g'), '<br />');
    const poemData = stringData.replace(new RegExp('\\\\n', 'g'), '<br />');
    const html = poemData;
    const newData = html.toString().replace(/"/g, '');
    const neww = newData.replace(/&nbsp;/gi, '');
    const nameUser =  localStorage.getItem('loggein_username');
    const newDate = dataCreated.toString().replace(/,/g, '/');
    const path = Core.getCurrentPath();
    const newPath = path.substr(1);
    if (url && url.includes('/groups/')) {
        return (
            <>
                <Grid container className={classes.poemheader} item xs={9}>
                    <Grid container className={classes.poemss}>
                        { poetscope && dataTitle !== null
                            ? (
                                <>
                                    <div className={classes.buttonWrapper}>
                                        <h1 className={classes.heading}>{dataTitle}</h1>
                                    </div>
                                    <Grid className={classes.date} item xs={12}>
                                        <h4 className={classes.headingGroup}> Group Visibility:&nbsp;
                                        </h4>
                                        {poetscope ? poetscope.group_access : ''}
                                        <br />
                                        {poetscope ? poetscope.field_school_or_independent !== [] || null : ''
                                            ? (
                                                <>
                                                    <h4 className={classes.headingGroup}>
                                                        Is this a Teacher-led school group or Independent group?:
                                                    </h4>
                                                    {poetscope ? poetscope.field_school_or_independent : ''}
                                                </>
                                            ) : ''}
                                    </Grid>
                                    <Grid className={classes.date} item xs={12}>
                                        {newDate}<span className={classes.poetNameNew}>{poetName}</span>
                                    </Grid>
                                </>
                            )
                            : this.loadSkeleton()}
                    </Grid>
                </Grid>
            </>
        );
    }
    return (
        <>
            <Grid container className={classes.poemheader} item xs={9}>
                <Grid container className={classes.poemss}>
                    <div className={classes.buttonWrapper}>
                        <h1 className={classes.heading}>{dataTitle || null}</h1>
                        {nameUser === poetName && nameUser !== null  ?  (
                            <div className={classes.buttonGroup}>
                                <Link to={`/editpoem/${newPath}`}>
                                    <Button
                                        className={`${classes.invertedButton} ${classes.editButton}`}
                                        size="small"
                                        color="primary"
                                    >
                                        <CreateIcon />
                                    </Button>
                                </Link>
                                <Button
                                    className={`${classes.invertedButton} ${classes.deleteButton}`}
                                    size="small"
                                    color="primary"
                                    onClick={this.handleClickOpen}
                                >
                                    <DeleteOutlineIcon />
                                </Button>
                                <Dialog
                                    open={open}
                                    onClose={this.handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">Unpublish poem </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Are you sure you want to unpublish this poem?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={this.handleUnpublishPoem}
                                            color="primary"
                                        >
                                            yes
                                        </Button>
                                        <Button
                                            onClick={this.handleClose}
                                            color="primary"
                                            autoFocus
                                        >
                                            No
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                            </div>
                        ) : '' }
                    </div>
                    <div className={classes.mainimage}>
                        {dataImage
                            ?      <img className={classes.poemthumbnail} src={poemDataImage(dataImage)} alt={poemDataImageAlt(dataImage)} />
                            : this.loadSkeleton()}
                    </div>
                    <Grid className={classes.date} item xs={12}>
                        {newDate || null }<span className={classes.poetNameNew}>{poetName || null}</span>
                    </Grid>
                    <div>{ReactHtmlParser(neww)}</div>
                </Grid>
            </Grid>
        </>
    );
}
}
Poembody.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
    dataCreated: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    dataImage: PropTypes.object,
    poetName: PropTypes.string,
    dataTitle: PropTypes.string,
    poemid: PropTypes.bool,
};
Poembody.defaultProps = PropTypes.shape({
    dataImage: PropTypes.object,
    poetName: PropTypes.string,
    dataTitle: PropTypes.string,
    poemid: PropTypes.bool,
});
const Core = (state) => state.Core;
const mapStateToProps = (state) => ({
    Core: Core(state),
});
export default connect(mapStateToProps)(withRouter(withStyles(styles)(Poembody)));
