/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import {  CircularProgress } from '@material-ui/core';
import fallback_url from '../../../../images/newPP-placeholder-min.jpg';


const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    mainContainer: {
        maxHeight: 'auto',
        marginBottom: 50,
        margin: 'auto',
    },
    poetPoemContainer: {
        minHeight: 1000,
        ' & h3': {
            textAlign: 'center',
        },
    },
    poemDiv: {
        padding: '10px 0px 10px 0px',
        justifyContent: 'center',
    },
    imgUserBadges: {
        marginLeft: 10,
    },
    badgeName: {
        marginLeft: 10,
        fontFamily: 'GT-Walsheim-Pro-Bold',
    },
    cards: {
        maxWidth: '80%',
        // maxHeight: '100%',
        background: theme.palette.current.poem.block.normalBackground,
        '&:hover': {
            background: theme.palette.current.poem.block.hoverBackground,
        },
    },
    rightAlign: {
        marginLeft: 0,
        marginBottom: 25,
    },
    leftAlign: {
        marginBottom: 25,
    },
    bodyHeight: {
        height: 100,
        lineHeight: 1.7,
        overflow: 'hidden',
        color: theme.palette.current.poem.block.fontColor,
        fontFamily: ' GT-Walsheim-Pro-Light',
    },
    badgeBack: {
        height: 'auto',
        background: theme.palette.current.user.fieldBorder,
        maxWidth: 600,
        marginTop: 20,
        marginBottom: 100,
        borderRadius: 5,
        '& h4': {
            textAlign: 'center',
        },
    },
    InvertedButton: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.invertedBtn.fontColor,
        '&:hover': {
            color: theme.palette.current.invertedBtn.primaryColor,
            background: theme.palette.current.poem.block.hoverButtonBackground,
        },
        marginLeft: 10,
        textTransform: 'capitalize',
        padding: '10px 25px',
        fontWeight: 600,
        fontSize: '0.8125rem',
        lineHeight: 1.75,
        letterSpacing: '0.02857em',
        textDecoration: 'none',
        borderRadius: 8,
        fontFamily: 'GT-Walsheim-Pro-Light',
    },
    loadingCircle: {
        color: theme.palette.current.primaryColor,
        marginLeft: '40%',
        marginTop: '10%',
    },
    poetBadgeContainer: {
        justifyContent: 'center',
        margin: 'auto',
    },
});

export class UserPoems extends PureComponent {
    constructor() {
        super();
        this.state = {
            data: [],
            load: [],

        };
    }

    componentDidMount() {
        const {  coredata } = this.props;
        const name = this.props.match.params.name;
        const  core  = coredata;
        const ApiRoutes = new core.ApiRoutes();
        const Http = new core.Http();
        Http.post(ApiRoutes.getRoute('getPoemsWithImageAndBody'), {
            poet: name,
        })
            .then((res) => this.setState({
                data: res.data,
            }));
        Http.post(ApiRoutes.getRoute('fetchUser'), {
            username: name,
        })
            .then((response) => this.setState({
                load: response.data.data,
            }));
    }

    loader=() => (
        <CircularProgress
            size={100}
            thickness={2.5}
            style={{
                // color: theme.palette.current.primaryColor,
                marginBottom: 200,
                marginLeft: 500,
                marginTop: 200,
            }}
        />
    )

    render() {
        const { classes } = this.props;
        const { data, load } = this.state;
        const { badges_all } = load;

        const  name  = this.props.match.params.name;
        return (
            <div className={classes.container}>
                {data.length !== 0 && data.status === true
                    ? (
                        <>
                            <Grid item xs={12} className={classes.mainContainer}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.poetPoemContainer}>
                                        <h1> Poems from {name} </h1>
                                        <Grid container>
                                            { Object.keys(data.data).map((key, i) => (

                                                <Grid key={`poem_ ${key}`} item xs={12}>
                                                    <Grid container className={classes.poemDiv}>
                                                        {/* <Grid className={key % 2 ? classes.rightAlign : classes.leftAlign}> */}
                                                        <Card className={classes.cards}>
                                                            <CardActionArea>
                                                                <CardMedia
                                                                    className={classes.media}
                                                                    component="img"
                                                                    src={data.data[i].image}
                                                                    onError={(e) => { e.target.src = fallback_url; }}
                                                                    title={data.data[i].title}
                                                                />
                                                                <CardContent>
                                                                    <Typography gutterBottom variant="h5" component="h2">
                                                                        {data.data[i].title}
                                                                    </Typography>
                                                                    <Typography variant="body2" className={classes.bodyHeight} color="textSecondary" component="p">
                                                                        {ReactHtmlParser(data.data[i].body_value)}
                                                                    </Typography>
                                                                </CardContent>
                                                            </CardActionArea>
                                                            <CardActions>
                                                                <Link
                                                                    className={classes.InvertedButton}
                                                                    color="primary"

                                                                    to={data.data[i].url}
                                                                >
                                                                    Learn More
                                                                </Link>
                                                            </CardActions>
                                                        </Card>
                                                        {/* </Grid> */}
                                                    </Grid>
                                                </Grid>


                                            ))}
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Grid>
                            <Grid item xs={10} className={classes.poetBadgeContainer}>

                                <div className={classes.badgeBack}>

                                    { badges_all && Object.keys(badges_all).map((key, i) => (

                                        <div key={i}>
                                            {badges_all !== []
                                                ? (
                                                    <>
                                                        <h4> Badges by {name} </h4>
                                                        <img
                                                            className={classes.imgUserBadges}
                                                            src={badges_all[key].image}
                                                            alt="userBadges"

                                                        />
                                                        <div className={classes.badgeName}> {badges_all[key].name} </div>
                                                    </>
                                                )
                                                : ''}
                                        </div>
                                    ))}
                                </div>
                            </Grid>
                        </>
                    ) : (data.length === 0 && data.status === true) ? (<h1> No Data found</h1>)
                        :   (
                            <CircularProgress
                                size={100}
                                thickness={2.5}
                                className={classes.loadingCircle}
                            />
                        ) }
            </div>
        );
    }
}

export default withStyles(styles)(UserPoems);
