export const LOAD_PAGE_CONTENT_VIEW = 'LOAD_PAGE_CONTENT_VIEW';

export const BASICPAGE_TITLE = 'BASICPAGE_TITLE';
export const BASICPAGE_LOADING = 'BASICPAGE_LOADING';
export const BASICPAGE_BODY = 'BASICPAGE_BODY';
export const BASICPAGE_ALL_CONTENT = 'BASICPAGE_ALL_CONTENT';
export const BASICPAGE_CURRENT_LINK = 'BASICPAGE_CURRENT_LINK';


export const LOGIN_DRAWER_TOGGLE = 'LOGIN_DRAWER_TOGGLE';

export const MULTINODEPAGE_TITLE = 'MULTINODEPAGE_TITLE';
export const MULTINODEPAGE_LOADING = 'MULTINODEPAGE_LOADING';
export const MULTINODEPAGE_BODY = 'MULTINODEPAGE_BODY';
export const MULTINODEPAGE_ALL_CONTENT = 'MULTINODEPAGE_ALL_CONTENT';
export const MULTINODEPAGE_CURRENT_LINK = 'MULTINODEPAGE_CURRENT_LINK';
export const MULTINODEPAGE_CURRENT_PAGE = 'MULTINODEPAGE_CURRENT_PAGE';

export const SITE_CURRENT_URL = 'SITE_CURRENT_URL';

export const LOAD_COMMENTS = 'LOAD_COMMENTS';
export const EDIT_COMMENTS = 'EDIT_COMMENTS';

export const SHOW_ALERT = 'SHOW_ALERT';
export const HOMEPAGE_TOPICS = 'HOMEPAGE_TOPICS';
export const LOAD_HOMEPAGE_TOPICS = 'LOAD_HOMEPAGE_TOPICS';
