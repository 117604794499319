import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Slam from './slam/slam';
import Poemtags from './tags/tags';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    geomeheader: {
        background: theme.palette.current.topics.background,
        marginBottom: 20,
        borderRadius: 8,
        padding: 20,
        margin: '0 auto',
    },
    contentArea: {
        marginBottom: 25,
    },
    sidebar: {
        marginLeft: 'auto',
    },
});
class Geome extends React.PureComponent {
    render() {
        const {
            classes, poetdata, rappersdata, poemtags, poetscope,
        } = this.props;
        return (
            <>
                <Grid className={`${classes.container}`} container>
                    <Grid item xs={12}>
                        <Grid container justify="center" className={classes.geomeheader} item xs={11}>
                            <Slam
                                poetdata={poetdata}
                                rappersdata={rappersdata}
                                poetscope={poetscope}
                                poemtags={poemtags}
                            />
                            <Poemtags poemtags={poemtags} />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}
Geome.propTypes = {
    classes: PropTypes.object.isRequired,
    poetdata: PropTypes.object.isRequired,
    rappersdata: PropTypes.object.isRequired,
    poemtags: PropTypes.object.isRequired,
    poetscope: PropTypes.object.isRequired,
};
export default withStyles(styles)(Geome);
