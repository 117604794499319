import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    brandMessage: {
        marginTop: 15,
        alignItems: 'center',
    },
    copyrightYear: {
        alignItems: 'center',
    },
    brandName: {
        textTransform: 'uppercase',
        color: theme.palette.current.footerBrandColor,
    },
    title: {
        color: theme.palette.current.footerMenuItemColor,
        paddingTop: 25,
    },
    anchor: {
        textDecoration: 'none',
    },
});
class Copyright extends React.PureComponent {
    render() {
        const { classes, message } = this.props;
        return (
            <div className={classes.title}>
                <span className={classes.copyrightYear}> &copy; {message.year} </span>
                <Link to="/" className={classes.anchor}><b className={classes.brandName}> {message.brand} </b></Link>
                <span className={classes.brandMessage}> {message.message} </span>
            </div>
        );
    }
}
Copyright.propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
};
export default withStyles(styles)(Copyright);
