import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PoemActionBody from './body/body';
import * as actionTypes from '../../../../reducers/poemactionpage/action';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    contentArea: {
        marginBottom: 25,
        display: 'inline-flex',
    },
    sidebar: {
        marginLeft: 'auto',
    },
});
class PoemAction extends React.PureComponent {
    componentDidMount() {
        const { url, loadPoemByUrl, loadSearchActions } = this.props;
        const currenturl = url;
        const newurl = currenturl.replace('/', '');
        loadPoemByUrl(newurl);
        loadSearchActions();
    }

    componentDidUpdate(prevProps) {
        const { url } = this.props;
        if (prevProps.url !== url) {
            this.componentWillUnmount();
            this.componentDidMount();
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({
            type: 'ACTIONPAGE.TITLE',
            value: null,
        });
        dispatch({
            type: 'ACTIONPAGE.IMAGE',
            value: null,
        });
        dispatch({
            type: 'ACTIONPAGE.BODY',
            value: null,
        });
        dispatch({
            type: 'ACTIONPAGE.RIGHTWIDGET',
            value: null,
        });
    }

    loadAction() {
        const { dispatch, Core } = this.props;
        const ApiRoutes = new Core.ApiRoutes();
        const Http = new Core.Http();
        const { url } = this.props;
        const currenturl = url;
        const newurl = currenturl.replace('/', '');

        Http.post(ApiRoutes.getRoute('getPoemByUrl'), {
            path: newurl,
        }).then((res) => {
            dispatch({
                type: 'ACTIONPAGE.TITLE',
                value: res.data.data.title,
            });
            dispatch({
                type: 'ACTIONPAGE.IMAGE',
                value: res.data.data.field_image,
            });
            dispatch({
                type: 'ACTIONPAGE.BODY',
                value: res.data.data.body,
            });
        });

        Http.post(ApiRoutes.getRoute('getsearchactions'), {}).then((response) => {
            dispatch({
                type: 'ACTIONPAGE_RIGHTWIDGET',
                value: response.data.data,
            });
        });
    }

    render() {
        const {
            classes, Core, Poemactionpage,
        } = this.props;
        return (
            <>
                <Grid className={`${classes.container}`} container>
                    <Grid className={classes.contentArea} key="content-area" item xs={12}>
                        <Grid item xs={1} />
                        <Grid className={classes.content} item xs={10}>
                            <PoemActionBody
                                datatitle={Core.getStateValueByType(Poemactionpage, actionTypes.ACTIONPAGE_TITLE)}
                                dataimage={Core.getStateValueByType(Poemactionpage, actionTypes.ACTIONPAGE_IMAGE)}
                                data={Core.getStateValueByType(Poemactionpage, actionTypes.ACTIONPAGE_BODY)}
                            />
                        </Grid>
                    </Grid>
                </Grid>

            </>
        );
    }
}
PoemAction.propTypes = {
    classes: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
    Poemactionpage: PropTypes.array.isRequired,
    url: PropTypes.string.isRequired,
    loadPoemByUrl: PropTypes.func.isRequired,
    loadSearchActions: PropTypes.func.isRequired,
    unMountComponent: PropTypes.func.isRequired,
};
PoemAction.defaultProps = {
    others: 'others',
};

const mapDispatchToProps = (dispatch) => ({
    loadPoemByUrl: (url) => dispatch({ type: actionTypes.LOAD_POEM_ACTION_BY_URL, url }),
    loadSearchActions: () => dispatch({ type: actionTypes.LOAD_SEARCH_ACTION }),
    unMountComponent: () => dispatch({ type: actionTypes.UNMOUNT_POEM_ACTION_PAGE }),
    dispatch,
});
export default connect(null, mapDispatchToProps)(withStyles(styles)(PoemAction));
