import * as actionTypes from './action.js';

const initialState = [
    {
        type: actionTypes.ACTIONPAGE_TITLE,
        value: null,
    },
    {
        type: actionTypes.ACTIONPAGE_IMAGE,
        value: null,
    },
    {
        type: actionTypes.ACTIONPAGE_BODY,
        value: [],
    },
    {
        type: actionTypes.ACTIONPAGE_RIGHTWIDGET,
        value: [],
    },
];
const Poemactionpage = (state = initialState, action) => {
    let OtherStates = [];
    switch (action.type) {
        case actionTypes.ACTIONPAGE_TITLE:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.ACTIONPAGE_IMAGE:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];

        case actionTypes.ACTIONPAGE_BODY:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.ACTIONPAGE_RIGHTWIDGET:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        default:
            return state;
    }
};
export default Poemactionpage;
