import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Commentsadd from './add/add';
import Commentsbody from './list/body/body';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    geomeheader: {

        textAlign: 'center',
        borderRadius: 8,
        margin: '0 auto',
    },
    contentArea: {
        marginBottom: 25,
    },
    sidebar: {
        marginLeft: 'auto',
    },
});
class Comments extends React.PureComponent {
    render() {
        const { classes, comments } = this.props;
        return (
            <>
                <Grid className={`${classes.container}`} container>
                    <Grid item xs={12}>
                        <Grid className={classes.geomeheader} item xs={11}>
                            <Commentsadd />
                            <Commentsbody comments={comments} />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}
Comments.propTypes = {
    classes: PropTypes.object.isRequired,
    comments: PropTypes.array,
};
Comments.defaultProps = {
    comments: [],
};
export default withStyles(styles)(Comments);
