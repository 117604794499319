import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
const styles = (theme) => ({
	heading: {
		fontFamily: 'GT-Walsheim-Pro-Bold',
		display: 'inline',
		borderBottom: '2px solid',
		borderBottomColor: theme.palette.current.invertedBtn.primaryColor,
		textAlign: 'left'
	},
	bioinfo: {
		fontFamily: 'GT-Walsheim-Pro-Light',
		textAlign: 'left',
		paddingBottom: 20
	},
	poemabout: {
		background: theme.palette.current.footerBrandColor,
		padding: 20,
		borderRadius: 10
	}
});
const data = (poetscope) => {
	if (poetscope) {
		const poetscopedata = poetscope.field_poem_scope.und;
		const taxonomydata = poetscope.taxonomyResult;

		const poetsname = (scopeid) => {
			taxonomydata.map((taxoid) => {
				if (taxoid.tid === scopeid.tid) {
					return taxoid.description;
				}
				return null;
			});
		};

		if (poetscopedata) {
			poetscopedata.map((scopeid) => {
				poetsname(scopeid);
				return null;
			});
		}
	}
};
class Poetbio extends React.Component {
	render() {
		const { classes, poetscope } = this.props;
		return (
			<React.Fragment>
				<Grid container>
					<Grid item xs={12}>
						<h1 className={classes.heading}> About Poet</h1>
						<Grid className={classes.bioinfo}>
							<p>{data(poetscope)}</p>
						</Grid>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}
export default withStyles(styles)(Poetbio);
