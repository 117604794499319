import * as actionTypes from './action.js';

const initialState = {
    username: '',
    password: '',
    isLoading: false,
    error: '',
};

const Auth = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NAME_CHANGE_HANDLER:
            return { ...state, [action.payloads.name]: action.payloads.value, error: '' };
        case actionTypes.LOADING:
            return { ...state, isLoading: true };
        case actionTypes.USER_LOGIN_SUCCESS:
            return {
                ...state, error: action.message,  isLoading: false, username: '', password: '',
            };
        case actionTypes.USER_LOGIN_FAILED:
            return { ...state, error: action.message,  isLoading: false };
        default:
            return state;
    }
};

export default Auth;
