/* eslint-disable import/prefer-default-export */
import {
    call, put,  takeEvery, delay,
} from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import Http from '../services/http.js';
import ApiRoutes from '../api/Routes.js';
import * as actionTypes from '../reducers/auth/action.js';

function* loginAction(action) {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    yield put({ type: actionTypes.LOADING });
    const guestTokenData = yield call([http, http.post], apiRoutes.getRoute('guestToken'));
    const guestToken = guestTokenData.data.token;
    yield window.localStorage.setItem('guests_token', guestToken);
    const headers = {
        'X-CSRF-Token': guestToken,
    };
    const username = action.data.username;
    const password = action.data.password;
    const userData = yield call([http, http.post], apiRoutes.getRoute('userLogin'), { username, password, headers });
    if (userData.status === 1) {
        const authtoken = userData.data.token;
        yield  localStorage.setItem('authentication_token', authtoken);
        yield  sessionStorage.setItem('userId', userData.data.user.uid);
        yield localStorage.setItem('loggein_username', userData.data.user.name);
        const cookies = new Cookies();
        yield cookies.set('pp_token', userData.data.session_name += `=${userData.data.sessid}`, { path: '/' });
        yield put({ type: actionTypes.USER_LOGIN_SUCCESS, message: 'Login Success' });
        yield delay(1000);
        yield put({ type: actionTypes.LOGIN_DRAWER_TOGGLE, value: false });
    } else {
        yield put({ type: actionTypes.USER_LOGIN_FAILED, message: 'Invalid Username or Password.' });
    }
}

function* logoutAction() {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    yield call([http, http.post], apiRoutes.getRoute('userLogout'));
    yield window.localStorage.removeItem('authentication_token');
    yield window.localStorage.removeItem('loggein_username');
    yield window.localStorage.removeItem('userId');
    yield window.localStorage.removeItem('pp_load');
    yield window.localStorage.removeItem('guests_token');
    const cookies = yield new Cookies();
    yield cookies.remove('pp_token', { path: '/' });
    yield put({ type: actionTypes.USER_LOGOUT_SUCCESS, value: true });
    yield window.location = '/';
}

export function* watcherAuthActions() {
    yield takeEvery(actionTypes.USER_LOGIN, loginAction);
    yield takeEvery(actionTypes.USER_LOGOUT, logoutAction);
}
