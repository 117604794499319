import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    searchBodyMainImage: {
        textAlign: 'center',
        '& img': {
            width: '85%',
        },
    },
    sideBar: {
        backgroundColor: theme.palette.current.topics.background,
        marginLeft: 15,
        marginRight: 15,
        borderRadius: 8,
        textAlign: 'center',
        '& h1': {
            fontsize: 25,
            paddingTop: 20,
        },
    },
    searchActionTitle: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        textAlign: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        '& a': {
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
    searchBodyMainAction: {
        paddingBottom: 30,
    },
    heading: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
    },
});

class searchAction extends React.PureComponent {
    loadSkeleton(size = 1) {
        const { classes } = this.props;
        const skeletonSize = Array(size).fill('');
        let index = 1;
        return skeletonSize.map(() => {
            index += 1;
            return (
                <Grid key={`skeleton_ ${index}`} item xs={12}>
                    <Grid container>
                        <Grid className={index % 2 ? classes.rightAlign : classes.leftAlign} item xs={12}>
                            <Skeleton height={650} />
                        </Grid>
                    </Grid>
                </Grid>
            );
        });
    }

    renderSearchAction(searchAction) {
        if (searchAction !== null) {
            const { classes } = this.props;
            const searchActionNew = searchAction ? Object.values(searchAction) : null;
            return (
                <div>
                    <div><h1 className={classes.heading}>TAKE ACTION</h1></div>
                    {searchActionNew.map((actionNew) => (
                        <div key={actionNew.nid} className={classes.searchBodyMainAction}>
                            <div className={classes.searchBodyMainImage}>
                                {' '}
                                <img src={actionNew.image} alt="PoemImage" />
                            </div>

                            <div className={classes.searchActionTitle}>
                                <Link to={actionNew.url}>{actionNew.node_title}</Link>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }
        return null;
    }


    render() {
        const { classes, searchAction } = this.props;
        const actionNew = searchAction ? searchAction !== null && Object.values(searchAction) : null;
        return (
            <>
                <Grid className={`${classes.container}`} container />
                <Grid key="sidebar-area">
                    <Grid className={classes.sideBar} item xs={12}>
                        {actionNew  ? this.renderSearchAction(actionNew) : <Skeleton height={650} />}
                    </Grid>
                </Grid>
            </>
        );
    }
}

searchAction.propTypes = {
    classes: PropTypes.object.isRequired,
    searchAction: PropTypes.array.isRequired,
};

export default withStyles(styles)(searchAction);
