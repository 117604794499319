import React from 'react';
import useStyles from '../useStyles';

const Input = props => {
    const classes = useStyles();
    const { width, type, placeholder, value, onChange } = props;
    return (
        <div className={classes.formField}>
            <input
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                style={width ? { width: width } : null}
            />
        </div>
    );
};

export default Input;
