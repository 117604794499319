import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import CardHeader from '@material-ui/core/CardHeader';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Alert from '../../../../../../common/alert/alert.js';
import  * as reducerActionTypes from '../../../../../../../reducers/action.js';
import * as poemActionTypes from '../../../../../../../reducers/poempage/action.js';
import Femaledummy from '../../../../../../../images/Femaledummy.jpg';
import Searchicon from '../../../../../../../images/searchicon.png';
import * as actionTypes from '../../../../../../../reducers/auth/action.js';

const styles = (theme) => ({
    button: {
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '10px 30px',
        fontWeight: 600,
        borderRadius: 8,
    },
    InvertedButton: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.invertedBtn.fontColor,
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.invertedBtn.fontColor,
        },
        marginLeft: 10,
        textTransform: 'capitalize',
        padding: '10px 35px',
        fontWeight: 600,
        borderRadius: 8,
        '& a': {
            textDecoration: 'none',
            color: `${theme.palette.current.invertedBtn.fontColor} ${'!important'}`,
        },

    },
    searchBox: {
        padding: '15px 5px',
        outline: '0',
        width: '100%',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        border: '1px solid',
        borderColor: theme.palette.current.tablecolor,
        '&::placeholder': {
            fontFamily: 'GT-Walsheim-Pro-Light',
            fontSize: 20,
            paddingLeft: 12,
        },
    },
    searchbutton: {
        width: '100%',
        height: '98%',
        minWidth: '50%',
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '& img': {
            height: '2em',
        },
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '10px 20px',
        fontWeight: 600,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    commentss: {
        textAlign: 'left',
        paddingTop: '20px',
        paddingBottom: '20px',
        width: '100%',
    },
    commentname: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
        paddingRight: 12,
    },
    commentdetail: {
        padding: '0px 0px 39px 76px',
    //  paddingLeft: 76,
    //  paddingBottom: 39
    },
    deletecomments: {
        cursor: 'pointer',
    },
    editcomments: {
        cursor: 'pointer',
        paddingLeft: 7,
    },
    closeicon: {
        cursor: 'pointer',
        paddingRight: 20,
    },
    checkicon: {
        cursor: 'pointer',
        paddingRight: 10,
    },
    loadskeleton: {
        paddingBottom: 20,
    },
    newCommentTextarea: {
        width: '-webkit-fill-available',
        borderRadius: 9,
        borderWidth: 2,
        fontFamily: 'GT-Walsheim-Pro-Regular',
        paddingRight: '12px',
        paddingLeft: '11px',
        fontSize: 'unset',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    actioncomment: {
        paddingTop: 10,
    },
    actionnewcomment: {
        textAlign: 'end',
        paddingTop: 9,
    },
    deleteid: {
        display: 'none',
    },
    commentsection: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
        display: 'flex',
        marginBottom: 20,
    },
    buttonspace: {
        marginLeft: 10,
    },
    commentsdays: {
        borderLeft: '1px solid',
        borderLeftColor: theme.palette.current.tablecolor,
        paddingLeft: 12,
        color: theme.palette.current.tablecolor,
    },
    commentsimage: {
        '& img': {
            width: '50%',
        },
    },
    searchimage: {
        '& img': {
            width: '50%',
        },
    },
    commentsdataa: {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.current.tablecolor,
        display: 'flex',
    },
    innerheight: {
        padding: 0,
        width: 600,
    },
    alertboxx: {
        zIndex: '11111 !important',
    },

});

class Commentsbody extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            items: [],
            // edit: false,
            defaultValue: '',
            // open: false,
            setOpen: false,
            commentId: [],
            // newComment: '',
            commentSkeleton: true,
            mobileOpen: false,
        };
    }

    // componentDidMount() {
    //     const { loadUserData } = this.props;
    //     const user = localStorage.getItem('loggein_username');
    //     if (user) {
    //         loadUserData(user);
    //     }
    // }

    handleDrawerToggle = () => {
        const { mobileOpen } = this.state;
        this.setState({ mobileOpen: !mobileOpen });
    }

    onChange = (event) => {
        this.setState({ message: event.target.value });
    };

    onKeyPress = (event) => {
        if (event.which === 32 && !event.target.value) {
            event.preventDefault();
        }
    };

    handleChange = (event) => {
        this.setState({ defaultValue: event.target.value });
    };

    deleteUniqueComments = (id) => {
        const { deleteComment } = this.props;
        this.setState({
            commentSkeleton: true,
        });
        deleteComment(id).then(() => {
            this.setState({
                commentSkeleton: false,
            });
        });
    };

    editUniqueComments = (defaultValue, commentId) => {
        const { toggleEditComment } = this.props;

        this.setState({
            defaultValue,
            commentId,
        });
        toggleEditComment();
    };

    submitEditComment = (event) => {
        event.preventDefault();
        this.setState({
            commentSkeleton: true,
        });
        const { commentId, defaultValue } = this.state;
        const cid = commentId;
        const newCommnt = defaultValue;
        const { updateComment } = this.props;
        updateComment(cid, newCommnt).then(() => {
            this.setState({
                commentSkeleton: false,
            });
        });
        this.setState({
            setOpen: false,
        });
    };


    loadAllComment = () => {
        const {  Core } = this.props;
        const auth_token = Core.auth_token();
        let loggedin = false;
        if (auth_token === true) { loggedin = true; }
        if (loggedin) { return this.loadEditableComments(); }
        return this.loadComments();
    };

    loadEditableComments =() => {
        const {
            classes, comments, deletePoemComment, updatePoemComment, nid, editComments, toggleEditComment,
        } = this.props;
        const { commentId, defaultValue } = this.state;
        const uid = sessionStorage.getItem('userId');
        return comments
            ? comments
                .map((item, i) => (

                    <div key={i}>
                        <Grid container justify="center">
                            <Grid className={classes.commentsimage} item xs={1}>
                                <img
                                    className={classes.dummyimage}
                                    src={item.user_pic ? item.user_pic : Femaledummy}
                                    alt=""
                                />
                            </Grid>
                            <Grid item xs={11}>
                                <span className={classes.commentname}>{item.user_firstname} {item.user_lastname}</span>
                                <span className={classes.commentsdays}>{item.created}</span>
                            </Grid>
                        </Grid>
                        <div className={classes.commentdetail}>
                            {editComments && commentId === item.cid ? (
                                <textarea
                                    rows="5"
                                    cols="70"
                                    className={classes.newCommentTextarea}
                                    onChange={this.handleChange}
                                    defaultValue={item.comment_body.und[0].value}
                                    type="text"
                                />
                            ) : (
                                <span className={classes.commentname}>
                                    {ReactHtmlParser(item.comment_body.und[0].value)}
                                </span>
                            )}
                            <span className={classes.deleteid}>{item.cid}</span>
                            {editComments && commentId === item.cid ? (
                                <div className={classes.actionnewcomment}>
                                    <CloseIcon
                                        onClick={toggleEditComment}
                                        className={classes.closeicon}
                                    />
                                    <CheckIcon
                                        onClick={() => updatePoemComment(defaultValue, item.cid, nid, uid)}
                                        className={classes.checkicon}
                                    />
                                </div>
                            ) : (
                                <div className={classes.actioncomment}>
                                    <DeleteOutlineIcon
                                        className={classes.deletecomments}
                                        onClick={() => deletePoemComment(item.cid)}
                                    />
                                    <EditIcon
                                        className={classes.editcomments}
                                        onClick={() => this.editUniqueComments(
                                            item.comment_body.und[0].value,
                                            item.cid,
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                ))
                .reverse()
            : null;
    };

    loadComments =() => {
        const { classes, comments } = this.props;
        return comments
            ? comments
                .map((item, i) => (
                    <div key={i}>
                        <Grid container justify="center">
                            <Grid className={classes.commentsimage} item xs={1}>
                                <img
                                    className={classes.dummyimage}
                                    src={item.user_pic ? item.user_pic : Femaledummy}
                                    alt=""
                                />
                            </Grid>
                            <Grid item xs={11}>
                                <span className={classes.commentname}>{item.user_firstname} {item.user_lastname}</span>
                                <span className={classes.commentsdays}>{item.created}</span>
                            </Grid>
                        </Grid>
                        <div className={classes.commentdetail}>

                            <span className={classes.commentname}>
                                {ReactHtmlParser(item.comment_body.und[0].value)}
                            </span>

                            <span className={classes.deleteid}>ds{item.cid}</span>

                        </div>
                    </div>
                ))
                .reverse()
            : null;
    };

    handleClose = () => {
        this.setState({
            setOpen: false,
        });
    };

    onSubmit = (event) => {
        const { message, items } = this.state;
        const uid = sessionStorage.getItem('userId');
        const { createComment, nid } = this.props;
        const data = {
            nid,
            uid,
            body: message,
        };
        event.preventDefault();
        this.setState({
            message: '',
            items: [items, message],
            commentSkeleton: false,
        });
        // createComment(message).then(() => {
        //     this.setState({
        //         commentSkeleton: false,
        //     });
        // });
        createComment(data);
    };

    loadSkeleton =(size = 2) => {
        const skeletonSize = Array(size).fill('');
        return skeletonSize.map(() => (
            <CardHeader
                avatar={<Skeleton variant="circle" width={80} height={80} />}
                title={<Skeleton height={10} width="100%" />}
                subheader={<Skeleton height={6} width="40%" />}
            />
        ));
    }

    render() {
        const {
            classes, Core, loadComment, showLoginDrawer,
        } = this.props;
        const {  message } = this.state;
        const loggedin = Core.auth_token();
        return (
            <>
                <Grid container>
                    <Alert />
                    <Grid className={classes.commentss}>
                        {loggedin ? (
                            <form onSubmit={this.onSubmit}>
                                <Grid container justify="center">
                                    <Grid className={classes.commentss} />
                                    <Grid className={classes.searchimage} item xs={1}>
                                        <img
                                            className={classes.dummyimage}
                                            src={Femaledummy}
                                            alt=""
                                        />
                                    </Grid>
                                    <Grid key="searchBox" item xs={10}>
                                        <input
                                            value={message}
                                            onChange={this.onChange}
                                            onKeyPress={this.onKeyPress}
                                            className={classes.searchBox}
                                            placeholder="write a comment"
                                            type="text"
                                            name="message"
                                            required
                                        />
                                    </Grid>
                                    <Grid key="searchButton" item xs={1}>
                                        <Button className={classes.searchbutton} type="submit">
                                            <img
                                                className={classes.dummyimage}
                                                src={Searchicon}
                                                alt=""
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        ) : (
                            <>
                                <Button className={classes.InvertedButton} onClick={showLoginDrawer}>
                                    Log In
                                </Button>
                                <span className={classes.buttonspace}>or</span>
                                <Button className={classes.InvertedButton} onClick={showLoginDrawer}>
                                    Register
                                </Button>
                                <span className={classes.buttonspace}>to post a comment</span>
                            </>
                        )}
                        <Grid className={classes.commentss}>
                            {loadComment
                                ? this.loadSkeleton()
                                : this.loadAllComment()}
                        </Grid>

                        {/* <span className={classes.buttonspace}>to post a comment</span> */}
                    </Grid>
                    {/* </Grid> */}
                </Grid>

            </>
        );
    }
}
Commentsbody.defaultProps = {
    comments: [],
};
Commentsbody.propTypes = {
    classes: PropTypes.object.isRequired,
    createComment: PropTypes.func.isRequired,
    updatePoemComment: PropTypes.func.isRequired,
    deletePoemComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    loadComment: PropTypes.func.isRequired,
    comments: PropTypes.array,
    Core: PropTypes.object.isRequired,
    nid: PropTypes.number.isRequired,
    showLoginDrawer: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    Core: state.Core,
    editComments: state.LoginPopup.editComments,
    nid: state.Poempage.find((st) => st.type === poemActionTypes.POEMPAGE_NID).value,
    loadComment: state.LoginPopup.loadComments,
});
const mapDispatchToProps = (dispatch) => ({
    getPoemByurl: (url, page) => dispatch({ type: poemActionTypes.LOAD_POEM_BY_URL, url, page }),
    deletePoemComment: (id) => dispatch({ type: poemActionTypes.POEMPAGE_DELETE_COMMENTS, id }),
    createComment: (data) => dispatch({ type: poemActionTypes.POEMPAGE_CREATE_COMMENTS, data }),
    toggleEditComment: () => dispatch({ type: reducerActionTypes.EDIT_COMMENTS }),
    showLoginDrawer: () => dispatch({ type: actionTypes.LOGIN_DRAWER_TOGGLE, value: true }),
    updatePoemComment: (message, cid, nid, uid) => dispatch({
        type: poemActionTypes.POEMPAGE_UPDATE_COMMENTS,
        payloads: {
            body: message, cid, nid, uid,
        },
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Commentsbody));
