import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { withStyles } from '@material-ui/core/styles';
import {
    Map, GoogleApiWrapper, Marker, InfoWindow,
} from 'google-maps-react';

const styles = () => ({
    container: {
        height: 650,
        width: '100%',
    },
    stylemap: {
        height: '60vh',
        width: '60%',
        marginLeft: '20%',
        marginBottom: 50,
    },
});
const LoadingContainer = () => (
    <h1> loading </h1>
);
export class MapContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            activeMarker: {},
        };
    }


    componentDidMount() {
        const {  coredata } = this.props;
        const Http = new coredata.Http();
        const ApiRoutes = new coredata.ApiRoutes();
        Http.post(ApiRoutes.getRoute('getPageViews'), { path: 'api/map_resources' })
            .then((res) => this.setState({
                data: res.data.nodes,
            }));
    }


     onMarkerClick = (props, marker, e, content) => this.setState({
         selectedTitle: content.node.title,
         selectedBody: content.node.Body,
         selectedLink: content.node.Path,
         activeMarker: marker,
         showingInfoWindow: true,
     });


    displayMarkers = () => this.state.data.map((content, index) => (
        <Marker
            onClick={(props, marker, e) => this.onMarkerClick(props, marker, e, content)}
            key={index}
            id={index}
            position={{
                lat: content.node.Latitude,
                lng: content.node.Longitude,
            }}
        />

    ))


    render() {
        const mapStyles = {
            height: '60vh', width: '60%', marginBottom: 50,
        };
        const {
            activeMarker, selectedBody, selectedTitle, showingInfoWindow, selectedLink, data,
        } = this.state;
        const { classes, google } = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.stylemap}>
                    { data ? (
                        <Map
                            google={google}
                            zoom={2}
                            style={mapStyles}
                            initialCenter={{ lat: 47.444, lng: -122.176 }}
                        >
                            {this.displayMarkers()}
                            <InfoWindow
                                marker={activeMarker}
                                visible={showingInfoWindow}
                            >
                                <div>
                                    <h1>{selectedTitle}</h1>
                                    <span> {selectedBody}<a href={selectedLink} style={{ textDecoration: 'none' }}>Learn More</a>
                                    </span>
                                </div>
                            </InfoWindow>
                        </Map>
                    ) : <Skeleton height={600}   />  }
                </div>
            </div>

        );
    }
}
MapContainer.propTypes = {
    coredata: PropTypes.object.isRequired,
    google: PropTypes.object.isRequired,
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDAnmJO4OKMofUzKq6B9yV7H-9VfIbP8s4',
    LoadingContainer,
})(withStyles(styles)(MapContainer));
