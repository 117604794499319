import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
    marginTop: {
        marginTop: 15,
        alignItems: 'center',
        justify: 'center',
    },
    alignItemsCenter: {
        alignItems: 'center',
    },
    shareIcon: {
        borderRadius: '50%',
        width: 50,
        height: 50,
    },
    title: {
        color: theme.palette.current.footerMenuItemColor,
        padding: '10px 5px',
        fontSize: 17,
    },
    shareIcontitle: {
        color: theme.palette.current.footerMenuItemColor,
        textDecoration: 'none',
    },
    containerBottom: {
        justify: 'center',
    },
});
class ShareLinks extends PureComponent {
    render() {
        const { classes, links } = this.props;
        return (
            <>
                <Grid className={classes.marginTop} container>
                    {links.map((link) => (
                        <Grid key={link.name} item xs={6}>
                            <Grid container className={classes.containerBottom}>
                                <Grid className={classes.alignItemsCenter} item xs={4}>
                                    <a
                                        className={classes.shareIcontitle}
                                        rel="noopener noreferrer"
                                        href={link.link}
                                        target="_blank"
                                    >
                                        <img
                                            className={classes.shareIcon}
                                            alt={`${'Share on'} ${link.name}`}
                                            src={link.icon}
                                        />
                                    </a>
                                </Grid>
                                <Grid className={classes.title} item xs={8}>
                                    <a
                                        className={classes.shareIcontitle}
                                        rel="noopener noreferrer"
                                        href={link.link}
                                        target="_blank"
                                    >
                                        {link.name}
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </>
        );
    }
}
export default withStyles(styles)(ShareLinks);
