import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Skeleton from 'react-loading-skeleton';
import Cookies from 'universal-cookie';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import * as actionTypes from '../../../reducers/header/action.js';
import Menu from './menu';
import UserMenu from './userMenu';
import Logo from '../../common/logo/logo';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
        alignItems: 'center',
    },
    header: {
        borderColor: theme.palette.current.primaryColor,
        paddingTop: 15,
        paddingBottom: 15,
    },
    minHeight: {
        minHeight: 120,
    },
    linkMain: {
        background: theme.palette.current.primaryColor,
        position: 'fixed',
        width: '100%',
        top: 0,
        height: 20,
        textAlign: 'center',
        fontSize: 15,
        zIndex: 10010,
        '& a': {
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
    right: {
        textAlign: 'right',
    },
    marginLeftAuto: {
        marginLeft: 'auto',
        [theme.breakpoints.down('lg')]: {
            marginRight: 'auto',
        },
    },
    headerappBar: {
        background: theme.palette.current.slider.dotActive,
        zIndex: 11111,
    },
    paper: {
        paddingBottom: 50,
    },
    desktopmaxHeight: {
        maxHeight: 104,
        marginTop: 20,
        '& img': {
            maxHeight: 102,
        },
    },
    headerlogoDiv: {
        marginTop: 10,
        [theme.breakpoints.down('lg')]: {
            marginRight: 'auto',
            marginLeft: 'auto',
        },
    },
    redirectionWeb: {
        background: 'none',
        border: 'none',
        outline: 'none',
    },
    menu: {
        paddingLeft: 25,
    },
});
function ElevationScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    return (
        <Slide appear direction="down" in={trigger}>
            {children}
        </Slide>
    );
}
class Desktopheader extends React.PureComponent {
    handleCookie =() => {
        const cookies = new Cookies();
        cookies.set('destination', 'main', { domain: '.powerpoetry.org', path: '/' });
    }

    render() {
        const {
            classes, Core, Header, dispatch, LoginPopup,
        } = this.props;

        return (
            <header className={classes.header}>
                <Grid className={classes.linkMain}>
                    <button
                        type="button"
                        className={classes.redirectionWeb}
                        onClick={this.handleCookie}
                    >
                        Click
                        <a href="https://powerpoetry.org/">
                        &nbsp;here&nbsp;
                        </a>
                        to get back on our main web portal
                    </button>
                </Grid>
                <Grid className={classes.container} container justify="center">
                    <Grid className={classes.minHeight} item xs={2}>
                        <Grid item className={classes.headerlogoDiv} xs={11}>
                            {Core.getStateValueByType(Header, actionTypes.HEADER_LOGO)
                                ? (
                                    <Logo
                                        src={Core.getStateValueByType(Header, actionTypes.HEADER_LOGO)}
                                    />
                                )
                                : <Skeleton height={150} />}
                        </Grid>
                    </Grid>
                    <Grid className={classes.menu} item xs={6}>
                        {Core.getStateValueByType(Header, actionTypes.HEADER_MENU).length > 0
                            ? <Menu menu={Core.getStateValueByType(Header, actionTypes.HEADER_MENU)} />
                            : <Skeleton height={50} />}
                    </Grid>
                    <Grid className={classes.right} item xs={4}>
                        <Grid item className={classes.marginLeftAuto} xs={11}>
                            {Core.getStateValueByType(Header, actionTypes.HEADER_USER_MENU).length > 0
                                ? (
                                    <UserMenu
                                        menu={Core.getStateValueByType(Header, actionTypes.HEADER_USER_MENU)}
                                        core={Core}
                                        dispatch={dispatch}
                                        LoginPopup={LoginPopup}
                                    />
                                )
                                : <Skeleton height={50} />}
                        </Grid>
                    </Grid>
                </Grid>
                {Core.getCurrentPath() !== '/'
                    ? (
                        <ElevationScroll>
                            <AppBar className={classes.headerappBar}>
                                <Grid className={classes.linkMain}>
                                    <button
                                        type="button"
                                        className={classes.redirectionWeb}
                                        onClick={this.handleCookie}
                                    >
                                        Click
                                        <a href="https://powerpoetry.org/">
                                          &nbsp;here&nbsp;
                                        </a>
                                        to get back on our main web portal
                                    </button>
                                </Grid>
                                <Grid className={classes.container} container justify="center">

                                    <Grid className={classes.desktopmaxHeight} item xs={2}>
                                        <Grid item xs={11}>
                                            {Core.getStateValueByType(Header, actionTypes.HEADER_LOGO)
                                                ? (
                                                    <Logo
                                                        src={Core.getStateValueByType(Header, actionTypes.HEADER_LOGO)}
                                                    />
                                                )
                                                : <Skeleton height={150} />}
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.menu} item xs={6}>
                                        {Core.getStateValueByType(Header, actionTypes.HEADER_MENU).length > 0
                                            ? (
                                                <Menu
                                                    menu={Core.getStateValueByType(Header, actionTypes.HEADER_MENU)}
                                                />
                                            )
                                            : <Skeleton height={50} />}
                                    </Grid>
                                    <Grid className={classes.right} item xs={4}>
                                        <Grid item className={classes.marginLeftAuto} xs={11}>
                                            {Core.getStateValueByType(Header, actionTypes.HEADER_USER_MENU)
                                                .length > 0
                                                ? (
                                                    <UserMenu
                                                        menu={Core.getStateValueByType(
                                                            Header,
                                                            actionTypes.HEADER_USER_MENU,
                                                        )}
                                                        core={Core}
                                                        dispatch={dispatch}
                                                        LoginPopup={LoginPopup}
                                                    />
                                                )
                                                : <Skeleton height={50} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AppBar>
                        </ElevationScroll>
                    ) : ''}
            </header>
        );
    }
}

Desktopheader.propTypes = {
    classes: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    Header: PropTypes.array.isRequired,
    LoginPopup: PropTypes.array.isRequired,
};
ElevationScroll.propTypes = {
    children: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    Core: state.Core,
    Header: state.Header,
    LoginPopup: state.LoginPopup,
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Desktopheader));
