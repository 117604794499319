/* eslint-disable import/prefer-default-export */
export const LOAD_HOMEPAGE_POEMS = 'LOAD_HOMEPAGE_POEMS';
export const LOAD_HOMEPAGE_POEMS_MORE = 'LOAD_HOMEPAGE_POEMS_MORE';
export const LOAD_HOMEPAGE_WIDGET_RIGHT = 'LOAD_HOMEPAGE_WIDGET_RIGHT';
export const LOAD_HOMEPAGE_TOPICS = 'LOAD_HOMEPAGE_TOPICS';
export const HOMEPAGE_UNMOUNT = 'HOMEPAGE_UNMOUNT';
export const HOMEPAGE_SLIDER = 'HOMEPAGE_SLIDER';
export const HOMEPAGE_POEMS = 'HOMEPAGE_POEMS';
export const HOMEPAGE_WIDGET_RIGHT = 'HOMEPAGE_WIDGET_RIGHT';
export const HOMEPAGE_TOPICS = 'HOMEPAGE_TOPICS';
export const HOMEPAGE_RECEIVED_DATA = 'HOMEPAGE_RECEIVED_DATA';
