/* eslint-disable import/named */
import { all } from 'redux-saga/effects';
import { watcherHeaderData } from './header.js';
import { watcherhomepageData } from './homePage.js';
import { watcherGetFooterData } from './footer.js';
import { watcheraboutPageContent } from './about.js';
import { watchergetPageNodes } from './pageContentNodes.js';
import { watchUserData } from './user.js';
import { watcherPoemActionPage } from './poemActionPage.js';
import { watcherPoemPage } from './poemPage.js';
import { watcherAuthActions } from './auth.js';
import { watcherUpdatePoemPage } from './updatepoem.js';

export default function* rootSaga() {
    yield all([
        watchUserData(),
        watcherHeaderData(),
        watcherhomepageData(),
        watcherGetFooterData(),
        watcheraboutPageContent(),
        watchergetPageNodes(),
        watcherPoemActionPage(),
        watcherPoemPage(),
        watcherAuthActions(),
        watcherUpdatePoemPage(),
    ]);
}
