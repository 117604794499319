import APP_CONFIG from '../config';
import API_ROUTES_LIST from '../apiRoutesList';

class ApiRoutes {
    constructor(endPoint = `${APP_CONFIG.API_BASE_URL}${APP_CONFIG.API_ENDPOINT}`) {
        this.endPoint = endPoint;
    }

    getRoute(key) {
        if (typeof API_ROUTES_LIST[key] !== 'undefined') {
            return `${this.endPoint}${API_ROUTES_LIST[key]}`;
        }
        return false;
    }
}

export default ApiRoutes;
