import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from 'react-loading-skeleton';

const styles = (theme) => ({
    heading: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        textAlign: 'left',
        display: 'inline',
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.current.invertedBtn.primaryColor,
    },
    bioinfo: {
        fontFamily: 'GT-Walsheim-Pro-Light',
        textAlign: 'left',
    },
    poemabout: {
        background: theme.palette.current.footerBrandColor,
        padding: 20,
        borderRadius: 10,
    },
});
const data = (poetscope) => {
    if (poetscope) {
        const poetscopedata = poetscope.field_poem_scope ? poetscope.field_poem_scope.und : [];
        const taxonomydata = poetscope.taxonomyResult;
        const poetsname = (scopeid) => {
            taxonomydata.map((taxoid) => {
                if (taxoid.tid === scopeid.tid) {
                    return taxoid.description;
                }
                return null;
            });
        };
        if (poetscopedata) {
            poetscopedata.map((scopeid) => {
                poetsname(scopeid);
                return '';
            });
        }
    }
};
class Poetbio extends React.Component {
    loadSkeleton(size = 1) {
        const { classes } = this.props;
        const skeletonSize = Array(size).fill('');
        let index = 1;
        return skeletonSize.map(() => {
            index += 1;
            return (
                <Grid key={`skeleton_ ${index}`} item xs={12}>
                    <Grid container>
                        <Grid className={index % 2 ? classes.rightAlign : classes.leftAlign} item xs={12}>
                            <Skeleton height={80} />
                        </Grid>
                    </Grid>
                </Grid>
            );
        });
    }

    render() {
        const { classes, poetscope } = this.props;
        return (
            <>

                <Grid container>
                    {poetscope
                        ? (
                            <Grid item xs={12}>
                                <h1 className={classes.heading}> About Poet</h1>
                                <Grid className={classes.bioinfo}>
                                    <p>  {data(poetscope)} </p>
                                </Grid>
                            </Grid>
                        )
                        : this.loadSkeleton()}
                </Grid>


            </>
        );
    }
}
export default withStyles(styles)(Poetbio);
