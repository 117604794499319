import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = '100%';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        fontFamily: 'GT-Walsheim-Pro-Regular !important',
        width: '100%',
        minHeight: '100vh',
        maxHeight: '100vh',
        padding: '0 20px 0 40px',
    },

    radio: {
        '&$checked': {
            color: '#FECB45 !important',
        },
    },
    checked: {},
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        flexShrink: 0,
    },
    alert: {
        minWidth: 80,
        padding: 15,
        marginBottom: 20,
        border: '1px solid transparent',
        borderRadius: 3,
        '&  p': {
            padding: 0,
            margin: 0,
        },
        ' & button': {
            position: ' relative',
            float: 'right',
            padding: '0',
            border: 0,
            cursor: 'pointer',
            background: 0,
            fontSize: 21,
            lineHeight: 1,
            fontWeight: 'bold',
            opacity: '0.4',
        },
    },
    alertSuccess: {
        background: theme.palette.current.btn.primaryColor,
        color: 'black',
    },
    drawerPaper: {
        backgroundColor: 'rgb(230, 230, 230)',
        boxShadow: '2px 2px 20px 1.5px rgba(0,0,0,0.19)',
        borderRadius: '0 5px 5px 0',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'center',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `calc(-${drawerWidth})`,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    listItemRoot: {
        height: '50px',
        '&$listItemSelected': {
            backgroundColor: theme.palette.current.primaryColor,
            color: '#1f1f1f',
            fontFamily: 'GT-Walsheim-Pro-Regular',
            '&:hover': {
                backgroundColor: theme.palette.current.primaryColor,
                color: '#1f1f1f',
            },
        },
        '&:hover': {
            backgroundColor: '#f9f9f9',
            color: '#1f1f1f',
        },
    },
    msg: {
        fontSize: 20,
    },
    listItemSelected: {},
    formContainer: {
        width: '90%',
        margin: '10px auto 50px auto',
        display: 'grid',
        gridTemplateRows: 'auto auto',
    },
    poemContent: {
        width: '100%',
        marginTop: 20,
    },
    poemTitle: {
        boxSizing: 'border-box',
        width: '100%',
        border: '1px solid rgba(0,0,0,0.2)',
        fontSize: '1.5em',
        padding: '10px',
        margin: '10px auto',
    },
    poemBody: {
        borderRadius: '8px',
        '& .ql-container': {
            width: '100% !important',
            height: '350px',
            backgroundColor: 'white',
            borderRadius: '0 0 8px 8px',
        },
        '& .ql-toolbar': {
            borderRadius: '8px 8px 0 0',
            backgroundColor: '#f2f2f2',
        },
    },
    input: {
        display: 'none',
    },
    optionContainer: {
        display: 'grid',
        gridTemplateColumns: '150px auto',
        marginTop: '20px',
        clear: 'both',
        gap: '0 20px',
    },
    optionsList: {
        backgroundColor: 'rgb(250, 250, 250)',
        border: 'none',
        boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.1)',
    },
    optionContent: {
        padding: '20px',
        backgroundColor: 'white',
        border: 'none',
        boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.1)',
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    contentArea: {
        marginBottom: 25,
    },
    sidebar: {
        marginLeft: 'auto',
    },
    formField: {
        border: '1px solid rgb(204, 204, 204)',
        marginBottom: 10,
        borderRadius: 8,
        '& > input': {
            border: 0,
            padding: 15,
            width: '85%',
            outline: 'none !important',
            borderRadius: 8,
            fontFamily: 'GT-Walsheim-Pro-Light',
        },
    },
    formHheader: {
        color: '#000',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    circularLoader: {
        color: theme.palette.current.primaryColor,
        position: 'relative',
        left: '45%',
        marginTop: '50%',
    },
    bold: {
        fontWeight: '800',
    },
    selectFormControl: {
        display: 'block !important',
        marginBottom: '10px !important',
        marginTop: '20px !important',
        '& p ': {
            fontFamily: 'GT-Walsheim-Pro-Regular',
        },
        '& ul ': {
            fontFamily: 'GT-Walsheim-Pro-Regular',
        },
        '& div label ': {
            fontFamily: 'GT-Walsheim-Pro-Regular',

        },
    },


    buttonUpload: {
        width: '100px',
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.invertedBtn.fontColor,
        '& a': {
            color: theme.palette.current.invertedBtn.fontColor,
            textDecoration: 'none !important',
        },
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.invertedBtn.fontColor,
        },
        textTransform: 'capitalize',
        fontWeight: 600,
        borderRadius: 8,
    },
    selectStyle: {
        width: '70%',
        fontFamily: 'GT-Walsheim-Pro-Regular !important',
    },
    textFieldLast: {
        marginTop: '7px',
    },
    formControlbuttons: {
        '&>div>label>span': {
            marginTop: '0',
            fontFamily: 'GT-Walsheim-Pro-Regular ',
            '&>span>div>svg': {
                height: '15px !important',

            },
        },
    },
    buttonPrimary: {
        background: theme.palette.current.primaryColor,
        color: theme.palette.current.btn.fontColor,
        float: 'right',
        width: '100px',
        height: '40px',
        marginTop: '10px',
        fontWeight: '600',
        borderRadius: '8px',
        textTransform: 'capitalize',
        textDecoration: 'none !important',
        '& a': {
            color: theme.palette.current.btn.fontColor,
            textDecoration: 'none !important',
        },
        '&:hover': {
            backgroundColor: theme.palette.current.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
    },
    txtSelect: {
        fontFamily: 'GT-Walsheim-Pro-Regular ',

    },
    abtPoem: {
        '&>div': {
            height: '22px!important',
            borderBottom: '20px',
        },
    },
    loaderButton: {
        color: theme.palette.current.primaryColor,
        float: 'right',
        marginTop: '10px',
    },
}));

export default useStyles;
