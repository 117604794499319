let API_HOST = 'https://powerpoetry.org/';
let API_ENV = 'stage';
if (typeof window !== 'undefined') {
    if (window.location.hostname !== 'beta.powerpoetry.org' && window.location.hostname !== 'powerpoetry.org') {
        API_HOST = 'https://v-dev2-power-poetry.pantheonsite.io/';
        API_ENV = 'dev';
    }
}


const APP_CONFIG = {
    API_BASE_URL: API_HOST,
    API_ENDPOINT: 'pp',
    API_ENV,
};
export default APP_CONFIG;
