import React from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import Mobilefooter from './footer/mobile/footer';
import Desktopfooter from './footer/desktop/footer';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    containerBorderBottom: {
        borderBottom: `${'1px solid'} ${theme.palette.current.footerMenuItemColor}`,
    },
    footer: {
        borderColor: theme.palette.current.primaryColor,
        borderTop: '5px solid',
        paddingTop: 50,
        borderBottom: '1px solid',
        background: theme.palette.current.footerBackgroundColor,
    },
    minHeight: {
        minHeight: 250,
        paddingBottom: 70,
    },
    right: {
        textAlign: 'right',
    },
    marginLeftAuto: {
        marginLeft: 'auto',
        [theme.breakpoints.down('lg')]: {
            marginRight: 'auto',
        },
    },
    alignCenter: {
        textAlign: 'center',
    },
});
function Footer(props) {
    if (isWidthUp('md', props.width)) {
        return <Desktopfooter {...props} />;
    }
    return <Mobilefooter {...props} />;
}
const widthFooter = withWidth()(Footer);
export default withStyles(styles)(widthFooter);
