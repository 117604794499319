import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import fallback_url from '../../../../images/newPP-placeholder-min.jpg';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    parentContent: {
        minHeight: 170,
        width: '100%',
        display: 'flex',
    },
    poemBody: {
        fontSize: 10,
        overflow: 'hidden',
    },
    textTitle: {
        width: '80%',
        marginLeft: '1%',
        '& h2': {
            color: theme.palette.current.mainMenuItemColor,
        },
    },
    imgApi: {
        minHeight: '100%',
        width: '100%',
    },
    imgPoetParent: {
        maxHeight: 100,
        margin: 10,
        overflow: 'hidden',
    },
    dividerList: {
        listStyle: 'none',
    },
    subrow: {
        padding: '0px 10px 0px 10px ',
        textAlign: 'justify',
        justifyContent: 'center',
        width: '100%',
        background: theme.palette.current.editProfilePage.listColor,
    },
    bodyHeight: {
        height: 100,
        lineHeight: 1.7,
        overflow: 'hidden',
        color: theme.palette.current.poem.block.fontColor,
        fontFamily: ' GT-Walsheim-Pro-Light',
    },
    titleHeight: {
        height: 60,
        overflow: 'hidden',
        fontFamily: 'GT-Walsheim-Pro-Bold',
        display: 'inline-block',
    },
    PoemItem: {
        fontFamily: 'GT-Walsheim-Pro-Light',
    },
    InvertedButton: {
        color: theme.palette.current.invertedBtn.primaryColor,
        float: 'right',
        fontSize: 12,
    },
});

class PoemCard extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    poems = () => {
        const { classes, poem } = this.props;
        const title = poem ? poem.title : '';

        return (
            <div className={classes.subrow}>
                <div className={classes.parentContent}>
                    <div className={classes.imgPoetParent}>
                        <img
                            src={poem.image}
                            alt="PoetImage"
                            className={classes.imgApi}
                            onError={(e) => { e.target.src = fallback_url; }}
                        />
                    </div>
                    <div className={classes.textTitle}>
                        <h4> {title}</h4>
                        <p className={classes.poemBody}>
                            {poem.body_value}
                        </p>
                        <Link
                            className={classes.InvertedButton}
                            color="primary"
                            id={poem.nid}
                            to={poem.url}
                        >
                            Learn More
                        </Link>
                    </div>


                </div>
                <Divider variant="inset" component="li" className={classes.dividerList} />
            </div>
        );
    };


    render() {
        const { poem } = this.props;

        return (
            <>
                <Grid container> {poem ? this.poems() : null} </Grid>{' '}
            </>
        );
    }
}
export default withStyles(styles)(PoemCard);
