import React from 'react';
import PropTypes from 'prop-types';
import useStyles from '../useStyles';
import 'react-quill/dist/quill.snow.css';

const DrawerForm = ({
    children,
}) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.drawerHeader}>
                <h1 className={classes.formHheader}>Add Poem</h1>
            </div>
            <form className={classes.formContainer}>{children}</form>
        </>
    );
};

DrawerForm.propTypes = {
    children: PropTypes.func.isRequired,
};

export default DrawerForm;
