import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from 'react-loading-skeleton';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import Pagination from 'material-ui-flat-pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Searchstylemobile } from '../../../../common/search/search';


const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    contentArea: {
        marginBottom: 25,
    },
    circularLoaders: {
        color: theme.palette.current.primaryColor,
        position: 'relative',
        top: '61%',
        left: '40%',
    },
    searchBox: {
        border: '1px solid',
        borderColor: theme.palette.current.borderrighttable,
        padding: '15px 5px',
        width: '100%',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        outline: 'none !important',
        height: 12,
        '&::placeholder': {
            fontFamily: 'GT-Walsheim-Pro-Light',
            fontSize: 20,
            paddingLeft: 12,
        },
    },
    button: {
        width: '100%',
        height: '100%',
        minWidth: '50%',
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '10px 20px',
        fontWeight: 600,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    searchhead: {
        display: 'flex',
        marginBottom: 20,
        '&:input': {
            border: '1px solid !important',
            borderColor: theme.palette.current.borderrighttable,
            padding: '15px 5px',
            width: '100%',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
        },
    },
    searchtitle: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        '& a': {
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
    searchbody: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
    },
    searchauthor: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.current.invertedBtn.primaryColor,
        display: 'inline',
    },
    searchbodymain: {
        paddingBottom: 25,
        marginTop: 20,
    },
    searchbodymainimage: {
        textAlign: 'center',
        '& img': {
            width: '85%',
        },
    },
    sidebar: {
        backgroundColor: theme.palette.current.topics.background,
        borderRadius: 8,

        '& h1': {
            textAlign: 'center',
            fontsize: 25,
            paddingTop: 20,
        },
    },
    searchactiontitle: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        textAlign: 'center',
        '& a': {
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
    searchbodymainaction: {
        paddingBottom: 30,
        marginTop: 30,
    },
    heading: {
        fontFamily: 'GT-Walsheim-Pro-Bold',

        borderBottom: '2px solid',
        borderBottomColor: theme.palette.current.invertedBtn.primaryColor,
        display: 'inline',
    },
    comments: {
        paddingLeft: 10,
    },
    actionsidebar: {
        padding: 20,
    },
    paginationbutton: {
        paddingLeft: 20,
        '& button': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.invertedBtn.primaryColor,
            margin: 5,
        },
    },
    keywords: {
        paddingLeft: 20,
    },
    searchdataa: {
        paddingLeft: 20,
    },
    noresult: {
        marginTop: 30,
        fontFamily: ' GT-Walsheim-Pro-Regular',
    },
    poemmainsection: {
        display: 'flex',
    },
    imagesection: {
        '& img': {
            width: '90%',
            maxHeight: 90,
        },
    },
});

class Searchmobileview extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            offset: 0,
            poemsPerPage: 10,
            isLoaded: true,
        };
    }

    handleClickPagination(offset) {
        this.setState({ offset, isLoaded: false });
    }

    loadSkeleton(size = 1) {
        const { classes } = this.props;
        const skeletonSize = Array(size).fill('');
        let index = 1;
        return skeletonSize.map(() => {
            index += 1;
            return (
                <Grid key={`skeleton_ ${index}`} item xs={12}>
                    <Grid container>
                        <Grid className={index % 2 ? classes.rightAlign : classes.leftAlign} item xs={11}>
                            <Skeleton height={650} />
                        </Grid>
                    </Grid>
                </Grid>
            );
        });
    }

    rendersearch(data) {
        if (data) {
            const { classes } = this.props;
            const search = Object.values(data);

            return (
                <div>
                    {search.map((datanew, i) => {
                        if (datanew) {
                            const newcomment = typeof datanew.body.und !== 'undefined' && datanew.body.und[0].value;
                            const reqdata = JSON.stringify(newcomment);
                            const removebreak = reqdata.replace(/<[^>]+>/g, '');

                            const body = `${removebreak.substring(1, 80)}...`;
                            const created = datanew.created;
                            return (
                                <div key={i} className={classes.searchbodymain}>
                                    <Grid className={classes.poemmainsection} item xs={12}>
                                        <Grid className={classes.imagesection} item xs={2}>
                                            <img className={classes.dummyimage} src={datanew.field_image} alt="" />
                                        </Grid>
                                        <Grid className={classes.contentsection} item xs={10}>
                                            <div className={classes.searchtitle}>
                                                <Link to={datanew.url}>{datanew.title}</Link>
                                            </div>

                                            <div className={classes.searchbody}>{ReactHtmlParser(body)}</div>

                                            <div>
                                                <span className={classes.searchauthor}>
                                                    {typeof datanew.field_editorial_first_name !== 'undefined'
												&& datanew.field_editorial_first_name.und[0].value}{' '}
                                                </span>
                                                <span className={classes.comments}>
                                                    {' '}
											- {created} - {datanew.comment} comments
                                                </span>{' '}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>

                            );
                        }
                        return '';
                    })}
                </div>
            );
        }
    }

    render() {
        const {
            classes, data, searchaction, ...others
        } = this.props;

        const { offset, poemsPerPage, isLoaded } = this.state;
        const datavalue = Object.values(data);

        const indexOfLastTodo = parseInt(offset) + parseInt(poemsPerPage);
        const indexOfFirstTodo = parseInt(offset);

        const currentPoems = datavalue.slice(indexOfFirstTodo, indexOfLastTodo);

        return (
            <>
                <Grid className={`${classes.container}`} container>
                    <Grid className={classes.contentArea} key="content-area" item xs={12}>
                        <Grid className={classes.keywords}>
                            {' '}
                            <h1 className={classes.heading}>Enter your keywords</h1>{' '}
                        </Grid>
                        <Searchstylemobile {...others} />
                        <Grid className={classes.searchdataa}>
                            <h1 className={classes.heading}>SEARCH RESULTS</h1>
                            { data &&  datavalue.length > 0 ? isLoaded  ? (
                                this.rendersearch(Object.values(currentPoems))
                            ) : (
                                this.loadSkeleton()
                            ) : (
                                <div className={classes.noresult}>YOUR SEARCH YIELDED NO RESULTS </div>
                            )}
                            <CircularProgress classes={{ colorPrimary: classes.circularLoaders }} />
                        </Grid>
                        {data ? (
                            <div>
                                <Pagination
                                    limit={this.state.poemsPerPage}
                                    className={classes.paginationbutton}
                                    offset={this.state.offset}
                                    total={datavalue.length}
                                    onClick={(e, offset) => this.handleClickPagination(offset)}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                    </Grid>
                </Grid>
            </>
        );
    }
}
export default withStyles(styles)(Searchmobileview);
