/* eslint-disable import/prefer-default-export */
import {
    takeEvery, put, call,
} from 'redux-saga/effects';
import Http from '../services/http.js';
import ApiRoutes from '../api/Routes.js';
import * as actionTypes from '../reducers/header/action.js';
import * as homepageActionTypes from '../reducers/homepage/action.js';
import APP_CONFIG from '../config';

function* getConfig() {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    const data = yield http.post(apiRoutes.getRoute('getConfig'));
    yield put({
        type: actionTypes.HEADER_LOGO,
        value: data.data.data.logoImage,
    });
    yield put({
        type: homepageActionTypes.HOMEPAGE_SLIDER,
        value: data.data.data.homeSlider,
    });
}

function* getMenu() {
    const { API_ENV } = APP_CONFIG;
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    const data = yield call([http, http.post], apiRoutes.getRoute('getMenu'), { menuName: API_ENV !== 'stage' ? 'main-menu' : 'menu-main-menu-react' });
    yield put({
        type: actionTypes.HEADER_MENU,
        value: data.data.data,
    });
    yield put({
        type: actionTypes.HEADER_USER_MENU,
        value: [
            {
                link: '/login',
                if: 'userLogout',
                title: 'Log in',
            },
            {
                link: '/logout',
                if: 'userLogin',
                title: 'Log Out',
            },
            {
                link: '/add-poem',
                if: null,
                title: 'Add Poem',
            },
        ],
    });
}
export function* watcherHeaderData() {
    yield takeEvery(actionTypes.LOAD_HEADER, getConfig);
    yield takeEvery(actionTypes.LOAD_HEADER_MENU, getMenu);
}
