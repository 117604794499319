/* eslint-disable import/extensions */
/* eslint-disable import/prefer-default-export */
import {
    takeEvery, put, all,
} from 'redux-saga/effects';
import Http from '../services/http.js';
import ApiRoutes from '../api/Routes.js';
import * as actionTypes from '../reducers/homepage/action.js';

function* homepagePoems() {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    const data = yield http.post(apiRoutes.getRoute('getPoemsWithImageAndBody'), { page: 1 });
    yield put({
        type: actionTypes.HOMEPAGE_POEMS,
        value: data.data.data,
    });
}

function* homepagePoemsMore(actions) {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    const data = yield http.post(apiRoutes.getRoute('getPoemsWithImageAndBody'), { page: actions.i });
    yield put({
        type: actionTypes.HOMEPAGE_POEMS,
        value: data.data.data,
    });
}

function* homepageWidgetRight() {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    const data = yield http.post(apiRoutes.getRoute('getBlock'), { block_name: 'homepage sidebar note' });
    yield put({
        type: actionTypes.HOMEPAGE_WIDGET_RIGHT,
        value: data.data.data,
    });
}

function* homepageTopics() {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    const data = yield http.post(apiRoutes.getRoute('getBlock'), { block_name: 'homepage sidebar topics' });
    yield put({
        type: actionTypes.HOMEPAGE_TOPICS,
        value: data.data.data,
    });
}
function* homePageUnmount() {
    yield all([
        put({
            type: actionTypes.HOMEPAGE_TOPICS,
            value: [],
        }),
        put({
            type: actionTypes.HOMEPAGE_WIDGET_RIGHT,
            value: [],
        }),
        put({
            type: actionTypes.HOMEPAGE_POEMS,
            value: [],
        }),
        put({
            type: actionTypes.HOMEPAGE_TOPICS,
            value: [],
        }),
    ]);
}

export function* watcherhomepageData() {
    yield takeEvery(actionTypes.LOAD_HOMEPAGE_POEMS, homepagePoems);
    yield takeEvery(actionTypes.LOAD_HOMEPAGE_WIDGET_RIGHT, homepageWidgetRight);
    yield takeEvery(actionTypes.LOAD_HOMEPAGE_TOPICS, homepageTopics);
    yield takeEvery(actionTypes.LOAD_HOMEPAGE_POEMS_MORE, homepagePoemsMore);
    yield takeEvery(actionTypes.HOMEPAGE_UNMOUNT, homePageUnmount);
}
