import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';


const styles = (theme) => ({
    introBlock: {
        background: theme.palette.current.introBlock.background,
        marginTop: 20,
        padding: 25,
        color: theme.palette.current.invertedColor,
        borderRadius: 8,
        textAlign: 'center',
        fontFamily: 'GT-Walsheim-Pro-Regular',
    },
    button: {
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '10px 30px',
        fontWeight: 600,
        borderRadius: 8,
        fontFamily: 'GT-Walsheim-Pro-Medium',
    },
    text: {
        fontSize: 25,
    },
    butnlink: {
        color: theme.palette.current.btn.fontColor,
        textDecoration: 'none',
    },
});
class IntroBlock extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        const { classes, data } = this.props;
        const content =  data ? data.content : '';
        const widgetData = content ? JSON.parse(content) : <Skeleton height={450} />;
        return (
            <>
                {
                    widgetData.content
                        ? (
                            <div className={classes.introBlock}>
                                <p className={classes.text}>{widgetData.content}</p>
                                <Button className={classes.button}>
                                    <Link className={classes.butnlink} to={widgetData.btnLink}>
                                        {widgetData.btnText}
                                    </Link>
                                </Button>
                            </div>
                        )

                        : <Skeleton height={250} />
                }
            </>
        );
    }
}
export default withStyles(styles)(IntroBlock);
