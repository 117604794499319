import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Footericon from '../../../../../images/footerhome.png';
const styles = (theme) => ({
	heading: {
		fontFamily: 'GT-Walsheim-Pro-Bold',
		textAlign: 'left',
		display: 'inline',
		borderBottom: '2px solid',
		borderBottomColor: theme.palette.current.footerBrandColor
	},
	widgetinfo: {
		background: theme.palette.current.invertedBtn.primaryColor,
		color: theme.palette.current.footerBrandColor,
		marginBottom: 20,
		borderRadius: 8,
		padding: 20,
		margin: '0 auto'
	},
	poemabout: {
		background: theme.palette.current.footerBrandColor,
		padding: 20,
		borderRadius: 10
	},
	poemcode: {
		color: theme.palette.current.invertedBtn.fontColor,
		fontFamily: 'GT-Walsheim-Pro-Regular',
	},
	widgetimage: {
		'& img': {
			textAlign: 'center',
			marginLeft: 'auto',
			marginRight: 'auto',
			display: 'block'
		}
	},
	widgetin: {
		textAlign: 'center',
		'& h1': {
			textAlign: 'center'
		}
	},
	poemhome:{
		fontFamily: 'GT-Walsheim-Pro-Regular',
		fontWeight: 600,
	}
});
class Poetwidget extends React.Component {
	render() {
		const { classes } = this.props;
		return (
			<React.Fragment>
				<Grid container>
					<Grid item xs={12}>
						<Grid className={classes.widgetinfo} item xs={11}>
							<Grid className={classes.widgetsection} item xs={12}>
								<Grid className={classes.widgetimage} item xs={12}>
									<img src={Footericon} alt={''} />
								</Grid>
								<Grid className={classes.widgetin} item xs={12}>
									<h1 className={classes.heading}> Need To Talk?</h1>
									<p>
										If you ever need help or support,we trust CrisisTextline.org for people dealing
										with depression.Text<span className={classes.poemhome}> HOME </span>to <span className={classes.poemcode}>741741</span>
									</p>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}
export default withStyles(styles)(Poetwidget);
