import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../reducers/poempage/action.js';
import * as reducerActionTypes from '../reducers/action.js';
import Http from '../services/http.js';
import ApiRoutes from '../api/Routes.js';

function* getUpdatedPoem(action) {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    yield call([http, http.post], apiRoutes.getRoute('getUpdatePoem')+"/"+ action.data.nid, { node: action.data });
    yield put({
        type: reducerActionTypes.SHOW_ALERT,
        value: true,
        message: 'Peom Updated Successfully',
    });

}
export function* watcherUpdatePoemPage() {
    yield takeEvery(actionTypes.UPDATE_POEMPAGE, getUpdatedPoem);
}
