import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ReactRouterLink from "../../../helpers/ReactRouterLink";

const styles = () => ({
    logo: {
       maxWidth: '100%'
    }
});
class Logo extends React.Component {
    render() {
        const { classes, src } = this.props;
        return (
            <React.Fragment>
                <ReactRouterLink
                    title="Power Poetry"
                    label={
                        <img
                            className={classes.logo}
                            src={src}
                            alt="Power Poetry Logo"
                        />
                    }
                    to="/"
                ></ReactRouterLink>
            </React.Fragment>
        );
    }
}
export default withStyles(styles)(Logo);
