/* eslint-disable import/extensions */
import * as actionTypes from './action';

const initialState = [{
    type: actionTypes.HEADER_LOGO,
    value: '',
},
{
    type: actionTypes.HEADER_MENU,
    value: [],
},
{
    type: actionTypes.HEADER_MENU_MIN_LENGTH,
    value: Array(6).fill(''),
},
{
    type: actionTypes.HEADER_USER_MENU,
    value: [],
},


];
const Header = (state = initialState, action) => {
    let OtherStates = [];
    switch (action.type) {
        case actionTypes.HEADER_LOGO:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.HEADER_MENU:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.HEADER_USER_MENU:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        default:
            return state;
    }
};

export default Header;
