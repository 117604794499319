export const LOAD_POEM_BY_URL = 'LOAD_POEM_BY_URL';
export const UNMOUNT_POEM_PAGE = 'UNMOUNT_POEM_PAGE';
export const UPDATE_POEMPAGE = 'UPDATE_POEMPAGE';

export const POEMPAGE_TITLE = 'POEMPAGE_TITLE';
export const POEMPAGE_IMAGE = 'POEMPAGE_IMAGE';
export const POEMPAGE_DATE = 'POEMPAGE_DATE';
export const POEMPAGE_POEMS = 'POEMPAGE_POEMS';
export const POEMPAGE_POETS = 'POEMPAGE_POETS';
export const POEMPAGE_POETDETAIL_BADGES = 'POEMPAGE_POETDETAIL_BADGES';
export const POEMPAGE_GEOME = 'POEMPAGE_GEOME';
export const POEMPAGE_TOPICS = 'POEMPAGE_TOPICS';
export const POEMPAGE_RAPPERS = 'POEMPAGE_RAPPERS';
export const POEMPAGE_TAGS = 'POEMPAGE_TAGS';
export const POEMPAGE_POETDETAIL = 'POEMPAGE_POETDETAIL';
export const POEMPAGE_POETNAME = 'POEMPAGE_POETNAME';
export const POEMPAGE_POETIMAGE = 'POEMPAGE_POETIMAGE';
export const POEMPAGE_COMMENTS = 'POEMPAGE_COMMENTS';
export const POEMPAGE_SCOPE = 'POEMPAGE_SCOPE';
export const POEMPAGE_WIDGET_BOTTOM = 'POEMPAGE_WIDGET_BOTTOM';
export const POEMPAGE_NID = 'POEMPAGE_NID';

export const POEMPAGE_DELETE_COMMENTS = 'POEMPAGE_DELETE_COMMENTS';
export const DELETE_COMMENTS = 'DELETE_COMMENTS';

export const POEMPAGE_UPDATE_COMMENTS = 'POEMPAGE_UPDATE_COMMENTS';
export const UPDATE_COMMENTS = 'UPDATE_COMMENTS';


export const POEMPAGE_CREATE_COMMENTS = 'POEMPAGE_CREATE_COMMENTS';
export const CREATE_COMMENTS = 'CREATE_COMMENTS';
