import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actionTypes from '../../../reducers/footer/action.js';
import ReactRouterLink from '../../../helpers/ReactRouterLink';

const styles = (theme) => ({
    menu: {
        maxWidth: '100%',
        listStyle: 'none',
        marginTop: 0,
        padding: 0,
        position: 'relative',
    },
    horizontaly: {
        display: 'block!important',
    },
    item: {
        display: 'block',
        marginRight: 30,
        fontSize: 18,
        lineHeight: 1.9,
        color: theme.palette.current.footerMenuItemColor,
        cursor: 'pointer',
        '&:hover > ul': {
            display: 'block!important',
            borderColor: theme.palette.current.footerMenuItemColor,
            borderBottom: '1px solid',
        },
    },
    menulink: {
        color: theme.palette.current.mainMenuItemColor,
        textDecoration: 'none !important',
    },
});

class FooterMenuItems extends React.PureComponent {
    chunk(arr, chunkSize) {
        const reduces = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize) {
            reduces.push(arr.slice(i, i + chunkSize));
        }
        return reduces;
    }

    getMenuItem(item, horizontaly) {
        const { classes } = this.props;
        const urlString = item.href;
        if (
            urlString.indexOf('http://') === 0
      || urlString.indexOf('https://') === 0
        ) {
            return (
                <li className={classes.item} key={item.href}>
                    <a
                        className={classes.menulink}
                        rel="noopener noreferrer"
                        href={item.href}
                        target="_blank"
                    >
                        {item.title}
                    </a>
                </li>
            );
        }
        return (
            <li className={classes.item} key={`${'/'}${item.href}`}>
                <ReactRouterLink
                    css={classes.menulink}
                    to={`${'/'}${item.href}`}
                    label={item.title}
                />
            </li>
        );
    }

    getBaseMenu(menu = false, horizontaly = false) {
        const { classes } = this.props;
        return (
            <ul className={classes.menu}>
                {menu.map((item) => {
                    const key = Object.keys(item);
                    return this.getMenuItem(item[key[0]], horizontaly);
                })}
            </ul>
        );
    }

    render() {
        const { items } = this.props;
        const chunks = this.chunk(items, 5);
        return (
            <Grid container justify="center">
                {chunks.map((chunk) => (
                    <Grid key={JSON.stringify(chunk)} item xs={6}>
                        {this.getBaseMenu(chunk)}
                    </Grid>
                ))}
            </Grid>
        );
    }
}

const getMenus = (state) =>
// state.Footer.filter(st => st.type === 'FOOTER.MENU')[0].value;
    state.Footer.filter((st) => st.type === actionTypes.FOOTER_MENU)[0].value;
const mapStateToProps = (state) => ({
    items: getMenus(state),
});

export default connect(mapStateToProps)(withStyles(styles)(FooterMenuItems));
