/* eslint-disable import/prefer-default-export */
/* eslint-disable no-use-before-define */
import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../reducers/user/action.js';
import Http from '../services/http.js';
import * as  reducerActionTypes from '../reducers/action.js';
import ApiRoutes from '../api/Routes.js';

function* getUserData(action) {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    const data = yield call([http, http.post], apiRoutes.getRoute('fetchUser'), { username: action.userName });
    yield put({
        type: actionTypes.USER_FIRST_NAME,
        value: data.data.data.field_first_name,
    });
    yield put({
        type: actionTypes.USER_UID,
        value: data.data.data.uid,
    });
    yield put({
        type: actionTypes.USER_LAST_NAME,
        value: data.data.data.field_last_name,
    });
    yield put({
        type: actionTypes.USER_USERNAME,
        value: data.data.data.name,
    });
    yield put({
        type: actionTypes.USER_MAIL,
        value: data.data.data.mail,
    });
    yield put({
        type: actionTypes.USER_BIO,
        value: data.data.data.field_bio,
    });
    yield put({
        type: actionTypes.USER_POSTAL_CODE,
        value: data.data.data.field_user_location.postal_code,
    });
    yield put({
        type: actionTypes.USER_COUNTRY,
        value: data.data.data.field_user_location.country_name,
    });
    yield put({
        type: actionTypes.USER_BIRTHDAY,
        value: data.data.data.field_birthdate,
    });
    yield put({
        type: actionTypes.USER_PICTURE,
        value: data.data.data.picture ? data.data.data.picture.uri : null,
    });
    const countryData = yield call([http, http.post], apiRoutes.getRoute('getCountries'));
    yield put({
        type: actionTypes.USER_COUNTRY_DATA,
        value: countryData.data.data,
    });
}

function* updateUserData(action) {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    const userData = action.payload;
    const data = yield call([http, http.post], apiRoutes.getRoute('updateProfilePage'), { ...userData });
    yield put({
        type: reducerActionTypes.SHOW_ALERT,
        value: true,
        message: 'User Details Updated Successfully',
    });
    yield put({
        type: actionTypes.USER_FIRST_NAME,
        value: data.data.data.field_first_name,
    });
    yield put({
        type: actionTypes.USER_UID,
        value: data.data.data.uid,
    });
    yield put({
        type: actionTypes.USER_LAST_NAME,
        value: data.data.data.field_last_name,
    });
    yield put({
        type: actionTypes.USER_USERNAME,
        value: data.data.data.name,
    });
    yield put({
        type: actionTypes.USER_MAIL,
        value: data.data.data.mail,
    });
    yield put({
        type: actionTypes.USER_BIO,
        value: data.data.data.field_bio,
    });
    yield put({
        type: actionTypes.USER_POSTAL_CODE,
        value: data.data.data.field_user_location.postal_code,
    });
    yield put({
        type: actionTypes.USER_COUNTRY,
        value: data.data.data.field_user_location.country_name,
    });
    yield put({
        type: actionTypes.USER_BIRTHDAY,
        value: data.data.data.field_birthdate,
    });
    yield put({
        type: actionTypes.USER_PICTURE,
        value: data.data.data.picture ? data.data.data.picture.uri : null,
    });
}
export function* watchUserData() {
    yield takeEvery(actionTypes.LOAD_USER_DATA, getUserData);
    yield takeEvery(actionTypes.UPDATE_USER_DATA, updateUserData);
}
