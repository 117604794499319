/* eslint-disable import/prefer-default-export */
/* eslint-disable eol-last */
import {
    takeEvery, put,
} from 'redux-saga/effects';
import * as actionTypes from '../reducers/action.js';
import * as checkTypes from '../reducers/core.js';
import Http from '../services/http.js';
import ApiRoutes from '../api/Routes.js';

function* getPageNodes(action) {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    let data = yield http.post(apiRoutes.getRoute('getPageViews'), { path: `api/${action.url}` });
    yield put({
        type: actionTypes.BASICPAGE_LOADING,
        value: false,
    });
    if (data.data.nodes) {
        if (checkTypes.isSingleNode(data.data.nodes)) {
            data = checkTypes.getSingleNode(data.data.nodes);
            yield put({
                type: actionTypes.BASICPAGE_TITLE,
                value: data.title,
            });
            yield put({
                type: actionTypes.BASICPAGE_BODY,
                value: data.Body,
            });
            yield put({
                type: actionTypes.BASICPAGE_ALL_CONTENT,
                value: data,
            });
            yield put({
                type: actionTypes.SITE_CURRENT_URL,
                value: action.url,
            });
        } else if (checkTypes.isAllNode(data.data.nodes)) {
            const data1 = checkTypes.getAllNode(data.data.nodes);
            const pager = data.data.pager;
            yield put({
                type: actionTypes.MULTINODEPAGE_LOADING,
                value: false,
            });
            yield put({
                type: actionTypes.MULTINODEPAGE_ALL_CONTENT,
                value: data1,
            });
            yield put({
                type: actionTypes.MULTINODEPAGE_CURRENT_PAGE,
                value: pager,
            });
            yield put({
                type: actionTypes.SITE_CURRENT_URL,
                value: action.url,
            });
        }
    }
}

export function* watchergetPageNodes() {
    yield takeEvery(actionTypes.LOAD_PAGE_CONTENT_VIEW, getPageNodes);
}
