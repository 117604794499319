import React,  {
    useState, useEffect, useRef, useMemo,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Option from './Option.js';
import Input from '../desktop/View/Input.js';
import * as ADDPOEM from '../../../../reducers/addpoem/constants.js';
import Http_ from '../../../../services/http.js';
import ApiRoute from '../../../../api/Routes.js';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    message: {
        textAlign: 'center',
    },
    poemForm: {
        marginLeft: '10%',
    },
    optionContainerList: {
        marginTop: '20px',
        clear: 'both',
        gap: '0 20px',
    },
    poemBody: {
        borderRadius: '8px',
        '& .ql-container': {
            width: '100% !important',
            height: '350px',
            backgroundColor: 'white',
            borderRadius: '0 0 8px 8px',
        },
        '& .ql-toolbar': {
            borderRadius: '8px 8px 0 0',
            backgroundColor: '#f2f2f2',
        },
    },
    buttonPrimary: {
        background: theme.palette.current.primaryColor,
        color: theme.palette.current.btn.fontColor,
        margin: '10px 0px 10px 0px',
        '&:hover': {
            backgroundColor: theme.palette.current.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
    },
});
const AddPoem = (props) => {
    const { classes } = props;
    const { Core } = props;
    const [open, setOpen] = useState(false);
    const [myUid, setMyUid] = useState('');
    const {
        getPoemData, getApiState,
    } = props;
    const [poemData, setPoemData] = useState(getPoemData());
    const [mediaData, setMediaData] = useState({
        fid: '',
        filename: '',
    });

    useEffect(() => {
        const ApiRoutes = new ApiRoute();
        const Http = new Http_();
        const name =  localStorage.getItem('loggein_username');
        if (name) {
            Http.post(ApiRoutes.getRoute('fetchUser'), {
                username: name,
            })
                .then((response) => response.data)
                .then((response) => {
                    setMyUid(response.data.uid);
                });
        }
    }, []);
    const setPoemField = (field, data) => {
        setPoemData({
            ...poemData,
            [field]: data,
        });
    };

    const clearInitialState = () => {
        setPoemData(getPoemData());
        setMediaData({
            fid: '',
            filename: '',
        });
    };
    const  handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const submitInput = () => {
        const terms_tid = poemData.poetry_terms.tid || {};
        const calls_tid = poemData.causes.tid;
        const slam_nid = poemData.poetry_slam.nid;
        const guides_nid = poemData.guides.nid;
        const group_title =  poemData.groups.title;
        const ApiRoutes = new Core.ApiRoutes();
        const Http = new Core.Http();
        let tags = poemData.display_data.map((row) => (row.name));
        tags = tags.join(',');

        const poem_scope = poemData.tid;
        const bodytext = poemData.body;
        Http.post(ApiRoutes.getRoute('addPoem'), {
            title: poemData.title,
            type: 'poem',
            body: bodytext,
            uid: myUid,
            tags,
            causes: calls_tid,
            guides: guides_nid,
            poetry_terms: terms_tid,
            poem_scope,
            groups: group_title,
            content_access: poemData.content_access,
            poetry_slam: slam_nid,
            image_type: 'upload',
            image: {
                fid: mediaData.fid,
                title: mediaData.filename,
            },
            video_type: poemData.video_type,
            field_poetryvideo: poemData.video_code,
        })
            .then((response) => {
                if (response.status === 1) {
                    clearInitialState();
                    setOpen(true);
                }
            }).catch((error) => Promise.reject(error));
    };
    const InitialProps = useRef(props);
    useMemo(() => {
        (async () => {
            const Http = new Core.Http();
            const ApiRoutes = new Core.ApiRoutes();
            const {  isApiRecieved, setApiState } = InitialProps.current;
            Http.post(ApiRoutes.getRoute('getCountries'), {}).then((res) => setApiState({
                data: res.data.data,
                recieved: true,
            }, ADDPOEM.API_DATA.COUNTRIES));
            Http.post(ApiRoutes.getRoute('getAllPoetrySlam'), {}).then((res) => setApiState({
                data: res.data.data,
                recieved: true,
            }, ADDPOEM.API_DATA.SLAMS));
            Http.post(ApiRoutes.getRoute('getAllPoemActions'), {}).then((res) => setApiState({
                data: res.data.data,
                recieved: true,
            }, ADDPOEM.API_DATA.GUIDES));
            Http.post(ApiRoutes.getRoute('getAllGroups'), {}).then((res) => setApiState({
                data: res.data.data,
                recieved: true,
            }, ADDPOEM.API_DATA.GROUPS));
            Http.post(ApiRoutes.getRoute('getAllPoemScopes'), {}).then((res) => setApiState({
                data: res.data.data,
                recieved: true,
            }, ADDPOEM.API_DATA.ABOUT));
            Http.post(ApiRoutes.getRoute('getAllPoetryTerms'), {}).then((res) => setApiState({
                data: res.data.data,
                recieved: true,
            }, ADDPOEM.API_DATA.TERMS));
            Http.post(ApiRoutes.getRoute('getAllCauses'), {}).then((res) => {
                const filteredData = res.data.data.filter((cause, index) => (
                    index === res.data.data.findIndex(
                        (t) => t.uuid === cause.uuid && t.tid === cause.tid,
                    )
                ));
                setApiState({
                    data: filteredData,
                    recieved: true,
                }, ADDPOEM.API_DATA.CAUSES);
            });
            if (
                isApiRecieved(ADDPOEM.API_DATA.COUNTRIES)
                && isApiRecieved(ADDPOEM.API_DATA.SLAMS)
                && isApiRecieved(ADDPOEM.API_DATA.GUIDES)
                && isApiRecieved(ADDPOEM.API_DATA.GROUPS)
                && isApiRecieved(ADDPOEM.API_DATA.ABOUT)
                && isApiRecieved(ADDPOEM.API_DATA.TERMS)
                && isApiRecieved(ADDPOEM.API_DATA.CAUSES)
            ) {
                // setIsLoaded(true);
            }
        })();
    }, [Core, InitialProps]);
    const apiData = {
        countries: getApiState(ADDPOEM.API_DATA.COUNTRIES),
        slams: getApiState(ADDPOEM.API_DATA.SLAMS),
        causes: getApiState(ADDPOEM.API_DATA.CAUSES),
        guides: getApiState(ADDPOEM.API_DATA.GUIDES),
        terms: getApiState(ADDPOEM.API_DATA.TERMS),
        groups: getApiState(ADDPOEM.API_DATA.GROUPS),
        about: getApiState(ADDPOEM.API_DATA.ABOUT),
        scopes: '',
    };
    return (
        <Grid className={classes.container} xs={12}>
            <Grid xs={10} className={classes.poemForm}>
                <h3> Add Poem </h3>
                <Input
                    type="text"
                    placeholder="Title"
                    value={poemData.title}
                    onChange={(e) => setPoemField('title', e.target.value)}
                />
                <ReactQuill
                    defaultValue={poemData.body}
                    onChange={(e) => setPoemField('body', e)}
                    theme="snow"
                    placeholder="Enter poem"
                    className={classes.poemBody}
                />
                <Option
                    setPoemData={setPoemData}
                    poemData={poemData}
                    setPoemField={setPoemField}
                    setMediaData={setMediaData}
                    apiData={apiData}
                    className={classes.optionContainerList}
                />
                <Button
                    variant="contained"
                    className={classes.buttonPrimary}
                    onClick={submitInput}
                    disabled={poemData.title.replace(/\s*/g, '').length === 0}
                >
                    Submit
                </Button>
                <Snackbar
                    autoHideDuration={3000}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <span variant="outlined" className={`${classes.alert} ${classes.alertSuccess}`}>
                        Poem created succesfullly
                        <button type="button" className={classes.closeAlert} onClick={handleClose}>  &nbsp; x  </button>
                    </span>
                </Snackbar>
            </Grid>
        </Grid>
    );
};

AddPoem.propTypes = {
    Core: PropTypes.object.isRequired,
    getPoemData: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    getApiState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    Core: state.Core,
    getPoemData: () => state.Addpoem.find((st) => st.type === ADDPOEM.POEMDATA).value,
    isApiRecieved: (type) => state.Addpoem.find((st) => st.type === type).value.data,
    getApiState: (type) => state.Addpoem.find((st) => st.type === type).value.data,
});
const mapDispatchToProps = (dispatch) => ({
    setApiState: (data, type) => dispatch({
        type,
        value: data,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddPoem));
