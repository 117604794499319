/* eslint-disable import/prefer-default-export */
import { put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../reducers/footer/action.js';
import Http from '../services/http.js';
import ApiRoutes from '../api/Routes.js';
import APP_CONFIG from '../config';

function* getConfig() {
    const http = new Http();
    const apiRoutes = new ApiRoutes();

    const data = yield http.post(apiRoutes.getRoute('getConfig'));
    yield put({
        type: actionTypes.FOOTER_LOGO,
        value: data.data.data.footer.logo,
    });
    yield put({
        type: actionTypes.FOOTER_COPYRIGHT,
        value: data.data.data.footer.copyright,
    });
    yield put({
        type: actionTypes.FOOTER_SEARCH,
        value: true,
    });
    yield put({
        type: actionTypes.FOOTER_NEWSLETTER,
        value: true,
    });
    yield put({
        type: actionTypes.FOOTER_SHARE_LINK,
        value: data.data.data.footer.shareLinks,
    });
}

function* getMenu() {
    const { API_ENV } = APP_CONFIG;
    const http = new Http();
    const apiRoutes = new ApiRoutes();

    const data = yield http.post(apiRoutes.getRoute('getMenu'), { menuName: API_ENV !== 'stage' ? 'menu-footer' : 'menu-footer-menu-react' });
    yield put({
        type: actionTypes.FOOTER_MENU,
        value: data.data.data,
    });
}

export function* watcherGetFooterData() {
    yield takeEvery(actionTypes.LOAD_FOOTER_CONFIG, getConfig);
    yield takeEvery(actionTypes.LOAD_FOOTER_MENU, getMenu);
}
