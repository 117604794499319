import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from 'react-loading-skeleton';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import CircularProgress from '@material-ui/core/CircularProgress';
import PoemItem from './item';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    blocktitle: {
        textDecoration: 'underline',
        fontSize: 35,
        fontWeight: 500,
        fontFamily: 'GT-Walsheim-Bold',
        [theme.breakpoints.down('md')]: {
            paddingLeft: '18px',
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            textAlign: 'center',
        },
    },
    leftAlign: {
        marginBottom: 25,
    },
    rightAlign: {
        marginLeft: 0,
        marginBottom: 25,
    },
    loadmoreButton: {
        textAlign: 'center',
        width: '100%',
        textTransform: 'capitalize',
    },
    button: {
        textTransform: 'capitalize',
        background: theme.palette.current.poem.loadmore.background,
        fontFamily: 'GT-Walsheim-Pro-Medium',
    },
    poemDiv: {
        '&>div': {
            [theme.breakpoints.down('md')]: {
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
    },
    circularLoader: {
        color: theme.palette.current.primaryColor,
    },
});
class PoemList extends React.Component {
    loadMoreButton = () => {
        const { classes, loadMore, recieved } = this.props;
        return  recieved
            ? (
                <Button onClick={loadMore} className={classes.button}>
                    Load more
                </Button>
            )
            : <CircularProgress classes={{ colorPrimary: classes.circularLoader }} />;
    };

    loadSkeleton(size = 10) {
        const { classes } = this.props;
        const skeletonSize = Array(size).fill('');
        let index = 1;
        return skeletonSize.map(() => {
            index += 1;
            return (
                <Grid key={`skeleton_ ${index}`} item xs={6}>
                    <Grid container>
                        <Grid className={index % 2 ? classes.rightAlign : classes.leftAlign} item xs={11}>
                            <Skeleton height={250} />
                        </Grid>
                    </Grid>
                </Grid>
            );
        });
    }

    loadPoem() {
        const { classes, data, corenew } = this.props;

        let index = 1;
        return data.map((poem) => {
            index += 1;
            if (isWidthUp('md', this.props.width)) {
                return (
                    <Grid key={`poem_ ${index}`} item xs={6}>
                        <Grid container className={classes.poemDiv}>
                            <Grid
                                className={index % 2 ? classes.rightAlign : classes.leftAlign}
                                item
                                xs={11}
                            >
                                <PoemItem poem={poem} />
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }
            return (
                <Grid key={`poem_ ${index}`} item xs={12}>
                    <Grid container className={classes.poemDiv}>
                        <Grid className={index % 2 ? classes.rightAlign : classes.leftAlign} item xs={11}>
                            <PoemItem poem={poem} corenew={corenew} />
                        </Grid>
                    </Grid>
                </Grid>
            );
        });
    }

    render() {
        const { data, classes } = this.props;
        const newData = data && data[0];
        return (
            <>
                <h2 className={classes.blocktitle}>
                    { newData ? (
                        'Poems For A Purpose'
                    ) : (
                        <div style={{ width: '50%' }}>
                            <Skeleton height={50} />
                        </div>
                    )}
                </h2>
                <Grid container>
                    {newData ? this.loadPoem() : this.loadSkeleton()}
                    <div className={classes.loadmoreButton}> {newData ? this.loadMoreButton() : null} </div>
                </Grid>
            </>
        );
    }
}
const poemlist = withWidth()(PoemList);
export default withStyles(styles)(poemlist);
