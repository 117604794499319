import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actionTypes from '../reducers/action.js';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    message: {
        textAlign: 'center',
    },
});

class ReactRouterLink extends React.Component {
    dispacthToRedux(link) {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
        const { dispatch } = this.props;
        dispatch({
            type: actionTypes.SITE_CURRENT_URL,
            value: link,
        });
    }

    render() {
        const { to, label, css } = this.props;
        let cleanContent = to;
        if (cleanContent.indexOf('/content') >= 0) {
            cleanContent = cleanContent.replace('/content', '');
        }
        if (cleanContent.indexOf('/api') >= 0) {
            cleanContent = cleanContent.replace('/api', '');
        }

        return (
            <Link
                className={css}
                onClick={() => {
                    this.dispacthToRedux(cleanContent);
                }}
                to={cleanContent}
            >
                {label}
            </Link>
        );
    }
}

const Core = (state) => state.Core;

const mapStateToProps = (state) => ({
    Core: Core(state),
});

export default connect(mapStateToProps)(withStyles(styles)(ReactRouterLink));
