import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

const styles = (theme) => ({
    InvertedButton: {
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '10px 30px',
        fontWeight: 600,
        borderRadius: 8,
        width: '50%',
        border: 'none',
    },
    circularLoader: {
        color: theme.palette.current.btn.primaryColor,
    },
    formField: {
        textAlign: 'center',
        marginTop: '20px',
        '& > input': {
            border: '1px solid ',
            borderColor: theme.palette.current.btn.tabcolor,
            padding: 15,
            width: '92%',
            borderRadius: 8,
            marginBottom: 10,
            fontFamily: 'GT-Walsheim-Pro-Light',
            outline: 'none !important',
        },
    },
    formFieldbtn: {
        textAlign: 'center',
    },
    emailInvalid: {
        color: theme.palette.current.user.invalidColor,
    },
    valid: {
        color: theme.palette.current.user.validColor,
    },
});


class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            email: '',
            emailError: '',
            isLoading: false,

        };
    }

    validate = () => {
        const {
            email,
        } = this.state;
        let emailError = '';
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            emailError = 'Please Provide E-mail Address';
        }
        if (emailError) {
            this.setState({
                emailError,
                isLoading: false,
            });
            return false;
        }
        return true;
    };

    isValid=() => {
        const isValid = this.validate();
        if (isValid) {
            this.register();
            this.setState({
                emailError: '',
                isLoading: true,
            });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }

    register() {
        const { Core } = this.props;
        const ApiRoutes = new Core.ApiRoutes();
        const Http = new Core.Http();
        const { email } = this.state;
        Http.post(ApiRoutes.getRoute('forgetPassword'), {
            name: email,
        }).then((response) => {
            if (response.data[0] === true) {
                const emailError = 'Reset link sent successfully';
                this.setState({
                    emailError,
                    isLoading: false,
                });
            } else if (response.serverStatus === 500 || 406) {
                const emailError = response ? ReactHtmlParser(response.data.response.data) : '';
                this.setState({
                    emailError,
                    isLoading: false,
                });
            } else {
                const emailError = 'An error occurred while sending mail';
                this.setState({
                    emailError,
                    isLoading: false,
                });
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { email, emailError, isLoading } = this.state;
        return (
            <>
                <div>
                    <div className={classes.formField}>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(event) => this.handleInputChange(event)}
                            placeholder="E-mail address *"
                        />
                        <span className={emailError === 'Reset link sent successfully' ? classes.valid : classes.emailInvalid}> {emailError}</span>
                    </div>
                    <div className={classes.formField}>
                        { isLoading === true
                            ? <CircularProgress classes={{ colorPrimary: classes.circularLoader }} />
                            : (
                                <Button
                                    className={classes.InvertedButton}
                                    size="small"
                                    color="primary"
                                    type="submit"
                                    onClick={this.isValid}
                                >
                                    Submit
                                </Button>
                            )}
                    </div>
                </div>
            </>
        );
    }
}

ResetPassword.propTypes = {
    classes: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    Core: state.Core,
});


const mapDispatchToProp = (dispatch) => ({
    dispatch,
});


export default connect(mapStateToProps, mapDispatchToProp)(withStyles(styles)(ResetPassword));
