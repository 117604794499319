import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import PoemAction from './desktop/index';
import PoemActionMobile from './mobile/index';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    contentArea: {
        marginBottom: 25,
    },
    sidebar: {
        marginLeft: 'auto',
    },
});

function PoemsActionPage(props) {
    const { width } = props;
    if (isWidthUp('md', width)) {
        return <PoemAction {...props} />;
    }
    return <PoemActionMobile {...props} />;
}
PoemsActionPage.propTypes = {
    width: PropTypes.string.isRequired,
};
const widthFooter = withWidth()(PoemsActionPage);
export default withStyles(styles)(widthFooter);
