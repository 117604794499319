import axios from 'axios';
import Cookies from 'universal-cookie';
import ApiRoutes from '../api/Routes';

const headers = {
    'Content-Type': 'application/json',
};
const withCredentials = {};
const storage = window.localStorage;

class Http {
    clearCookies = () => {
        const routes = new ApiRoutes();

        const guestToken = storage.getItem('guest_token');
        if (guestToken) {
            // console.log('test');
        } else {
            this.generateGuestToken();
        }
        headers['X-CSRF-TOKEN'] = guestToken;
        return  axios.post(routes.getRoute('checkSession'), {}, { headers, withCredentials });
    }

    generateGuestToken = () => {
        // console.log('generating guest token');
        const routes = new ApiRoutes();
        axios.post(routes.getRoute('guestToken'), {}).then((res) => {
            const { token } = res.data;
            if (typeof window !== 'undefined') {
                storage.setItem('guest_token', token);
            }
        });
    }

    get(_url, _parameters = {}, _tokenRequired = true) {
        if (typeof window !== 'undefined') {
            const authToken = storage.getItem('auth_token');
            const guestToken = storage.getItem('guest_token');
            if (authToken) {
                headers['X-CSRF-TOKEN'] = authToken;
            } else if (guestToken && _tokenRequired) {
                headers['X-CSRF-TOKEN'] = guestToken;
            }
        }
        const sessiongetdata = this.sessionsgetCalls(_url, _parameters);
        return sessiongetdata;
    }

    axiosgetCall = (_url, _parameters) => axios
        .get(
            _url,
            {
                params: _parameters,
            },
            { headers },
        )
        .then((result) => ({ status: 1, data: result.data }))
        .catch((error) => ({
            serverStatus: error.response.status,
            status: 0,
            data: error.response.data,
        }))

    sessionsgetCalls = (_url, _parameters) => this.clearCookies().then((response) => {
        if (typeof response.data.token !== 'undefined') {
            const authtoken = response.data.token;
            // console.log(authtoken, 'Successfully Login');
            localStorage.setItem('auth_token', authtoken);
            const cookies = new Cookies();
            cookies.set('pp_token', response.data.session_name += `=${response.data.sessid}`, { path: '/' });

            headers['X-CSRF-TOKEN'] = authtoken;
            withCredentials.withCredentials = true;
            return this.axiosgetCall(_url, _parameters);
        }
        if (typeof response.data.token === 'undefined') {
            window.localStorage.removeItem('auth_token');
            window.localStorage.removeItem('pp_load');
            window.localStorage.removeItem('guest_token');
            const cookies = new Cookies();
            cookies.remove('pp_token', { path: '/' });
            return this.axiosgetCall(_url, _parameters);
        }
        return false;
    });

    post(_url, _parameters, _tokenRequired = true) {
        const authToken = storage.getItem('auth_token');
        const guestToken = storage.getItem('guest_token');

        if (typeof window !== 'undefined') {
            if (authToken) {
                headers['X-CSRF-TOKEN'] = authToken;
                withCredentials.withCredentials = true;
            } else if (guestToken && _tokenRequired) {
                headers['X-CSRF-TOKEN'] = guestToken;
                withCredentials.withCredentials = true;
            }
        }

        const sessionpostdata = this.sessionspostCalls(_url, _parameters);
        return sessionpostdata;
    }

    axiospostCall = (_url, _parameters) => axios
        .post(_url, _parameters, { headers, withCredentials })
        .then((result) => ({ status: 1, data: result.data }))
        .catch((error) => ({ serverStatus: 500, status: 0, data: error }))

    sessionspostCalls = (_url, _parameters) => this.clearCookies().then((response) => {
        if (typeof response.data.token !== 'undefined') {
            const authtoken = response.data.token;
            // console.log(authtoken, 'Successfully Login');
            localStorage.setItem('auth_token', authtoken);
            const cookies = new Cookies();
            cookies.set('pp_token', response.data.session_name += `=${response.data.sessid}`, { path: '/' });

            headers['X-CSRF-TOKEN'] = authtoken;
            withCredentials.withCredentials = true;
            return this.axiospostCall(_url, _parameters);
        }
        if (typeof response.data.token === 'undefined') {
            window.localStorage.removeItem('auth_token');
            window.localStorage.removeItem('pp_load');
            window.localStorage.removeItem('guest_token');
            const cookies = new Cookies();
            cookies.remove('pp_token', { path: '/' });
            return this.axiospostCall(_url, _parameters);
        }
        return false;
    })
}
export default Http;
