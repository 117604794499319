import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Slider from './slider/slider';
import * as actionTypes from '../../../../reducers/homepage/action.js';
import PoemList from '../../../common/poem/list';

import Sidebar from './sidebar/sidebar';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    contentArea: {
        marginBottom: 25,
    },
    sidebar: {
        marginLeft: 'auto',
        [theme.breakpoints.down('lg')]: {
            marginRight: 'auto',
        },
    },
    zIndex: {
        zIndex: 9999,
    },
});
class Homepage extends React.PureComponent {
    render() {
        const {
            classes, Core, Homepage, loadMore, recieved,
        } = this.props;
        const corenew = Core;
        return (
            <>
                {(Core.getStateValueByType(Homepage, actionTypes.HOMEPAGE_SLIDER).length > 0) ? <Slider data={Core.getStateValueByType(Homepage, actionTypes.HOMEPAGE_SLIDER)} /> : null}
                <Grid className={`${classes.container}`} container>
                    <Grid className={classes.contentArea} key="content-area" item xs={8}>
                        <PoemList loadMore={loadMore} recieved={recieved} data={Core.getStateValueByType(Homepage, actionTypes.HOMEPAGE_POEMS)} corenew={corenew} />
                    </Grid>
                    <Grid className={classes.zIndex} key="sidebar-area" item xs={4}>
                        <Grid className={classes.sidebar} item xs={11}>
                            <Sidebar core={Core} topics={Core.getStateValueByType(Homepage, actionTypes.HOMEPAGE_TOPICS)} rigthIntroWidget={Core.getStateValueByType(Homepage, actionTypes.HOMEPAGE_WIDGET_RIGHT)} homepageData={Core.getStateValueByType(Homepage, actionTypes.HOMEPAGE_SLIDER)} />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}
Homepage.propTypes = {
    classes: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
};
export default withStyles(styles)(Homepage);
