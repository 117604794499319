
import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import fallback_url from '../../../images/newPP-placeholder-min.jpg';


const styles = (theme) => ({
    sidebar: {
        backgroundColor: theme.palette.current.topics.background,
        borderRadius: 8,
        maxHeight: 900,
        marginTop: -70,
        textAlign: 'center',
        '& h1': {
            fontsize: 25,
        },
    },
    sidebarSkelton: {
        marginTop: -70,
    },
    circularLoader: {
        marginTop: 200,
        color: theme.palette.current.primaryColor,
    },
    contentStyle: {
        fontFamily: 'GT-Walsheim-Pro-light',
    },
    searchbodymainimage: {
        textAlign: 'center',
        '& img': {
            width: '90%',
            height: 200,
        },
    },
    searchactiontitle: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        textAlign: 'center',
        padding: 20,
        '& a': {
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
    subrow: {
        padding: 0.5,
        textAlign: 'justify',
        justifyContent: 'center',
        '&:nth-child(odd)': {
            textAlign: 'justify',
            width: '98%',
            marginLeft: '1%',
            background: 'white',
            borderRadius: '5px',
            boxShadow: '0px 4px 14px rgba(0,0,0,0.20)',
        },
    },
});

class WritingTips extends PureComponent {
    constructor() {
        super();
        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        const {  Core } = this.props;
        const  ApiRoutes  = new Core.ApiRoutes();
        const Http = new Core.Http();
        Http.post(ApiRoutes.getRoute('getPageViews'), { path: 'api/writing-tips' })
            .then((res) => this.setState({
                data: res.data.nodes,
            }));
    }

    render() {
        const { classes } = this.props;
        const { data } = this.state;
        return (
            <>
                {data && (data.length > 0)
                    ?                           (
                        <Grid className={classes.sidebar} item xs={4}>
                            <h1> Writing Tips </h1>
                            { data.map((content, i) => (
                                <>
                                    <div className={classes.searchbodymainimage}>
                                        {content.node.field_image.src ?
                                        <img className={classes.dummyimage} src={content.node.field_image.src} alt="" onError={(e) => { e.target.src = fallback_url; }} />
                                    : null }
                                        </div>
                                    <div className={classes.searchactiontitle}> {content.node.title}</div>
                                </>

                            ))}
                        </Grid>
                    ) : (
                        <Grid className={classes.sidebarSkelton} item xs={4}>
                            <Skeleton height={710} width={390}  />
                        </Grid>
                    )}
                    
            </>
        );
    }
}
const Core = (state) => state.Core;

const mapStateToProps = (state) => ({
    Core: Core(state),
});
export default  connect(mapStateToProps)(withStyles(styles)(WritingTips));
