import * as actionTypes from './action.js';

const initialState = [{
    type: actionTypes.FOOTER_LOGO,
    value: '',
},
{
    type: actionTypes.FOOTER_MENU,
    value: [],
},
{
    type: actionTypes.FOOTER_SHARE_LINK,
    value: false,
},
{
    type: actionTypes.FOOTER_COPYRIGHT,
    value: false,
},
{
    type: actionTypes.FOOTER_SEARCH,
    value: false,
},
{
    type: actionTypes.FOOTER_NEWSLETTER,
    value: false,
},
];
const Footer = (state = initialState, action) => {
    let OtherStates = [];
    switch (action.type) {
        case actionTypes.FOOTER_LOGO:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.FOOTER_SEARCH:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.FOOTER_NEWSLETTER:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.FOOTER_MENU:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.FOOTER_SHARE_LINK:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.FOOTER_COPYRIGHT:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        default:
            return state;
    }
};

export default Footer;
