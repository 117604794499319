class SessionStorage {
    static set(_key, _val){
        window.sessionStorage.setItem(_key, _val);
    }
    static get(_key) {
        return window.sessionStorage.getItem(_key);
    }
    static remove(_key){
        window.sessionStorage.removeItem(_key);
    }
    static clearAll(){
        window.sessionStorage.clear();
    }
}
export default SessionStorage;
