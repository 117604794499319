import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Skeleton from 'react-loading-skeleton';
import Needtotalk from '../../../../common/needtotalkblock/needtotalk';

const styles = (theme) => ({
    widgetinfo: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.footerBrandColor,
        marginBottom: 20,
        borderRadius: 8,

        margin: '0 auto',
    },
    poemabout: {
        background: theme.palette.current.footerBrandColor,
        padding: 20,
        borderRadius: 10,
    },
    poemcode: {
        color: theme.palette.current.invertedBtn.fontColor,
        fontFamily: 'GT-Walsheim-Pro-Regular',
    },
    widgetsection: {
        display: 'inline-flex',
    },

    poemhome: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
        fontWeight: 600,
    },
});
class Poetwidget extends React.Component {
    render() {
        const { rigthIntro } = this.props;

        return (
            <>
                <Grid container>
                    <Grid item xs={12}>
                        {rigthIntro ? <Needtotalk data={rigthIntro} /> : <Skeleton height={450} />}
                    </Grid>
                </Grid>
            </>
        );
    }
}
export default withStyles(styles)(Poetwidget);
