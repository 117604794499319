/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Button } from '@material-ui/core';
import Alert from '../../../../common/alert/alert.js';
import * as poemActionTypes from '../../../../../reducers/poempage/action.js';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    titleField: {
        width: '100%',
    },
    poemBody: {
        borderRadius: '8px',
        margin: '10px 0px 50px 0px',
        '& .ql-container': {
            width: '100% !important',
            height: '350px',
            backgroundColor: 'white',
            borderRadius: '0 0 8px 8px',
        },
        '& .ql-toolbar': {
            borderRadius: '8px 8px 0 0',
            backgroundColor: '#F2F2F2',
        },
    },
    buttonUpload: {
        height: 40,
        width: 100,
        display: 'block',
        textTransform: 'capitalize',
        fontWeight: 600,
        borderRadius: 8,
        fontSize: 18,
    },
    buttonPrimary: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.invertedBtn.fontColor,
        float: 'right',
        width: 100,
        height: 40,
        // display: 'block',
        '& a': {
            color: theme.palette.current.invertedBtn.fontColor,
            textDecoration: 'none !important',
        },
        [theme.breakpoints.down('lg')]: {
            padding: '10px 13px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '10px 20px',
        },
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.invertedBtn.fontColor,
        },
        textTransform: 'capitalize',
        fontWeight: 600,
        borderRadius: 8,
    },
    poemContainer: {
        height: 1800,
    },
    input: {
        display: 'none',
    },
    imgUser: {
        height: 500,
        width: 500,
    },
});
export class EditPoem extends PureComponent {
    constructor() {
        super();
        this.state = {
            data: [],
            poem: '',
            title: '',
            image: '',
        };
        if (document) {
            this.ReactQuill = require('react-quill');
          }
    }

    componentDidMount() {
        const { Core, getPoemByurl } = this.props;
        const path = Core.getCurrentPath();
        let url = path.split('/');
        url = `${url[2]}/${url[3]}`;
        getPoemByurl(url, 1);
    }

    componentDidUpdate() {
        const { title, poem } = this.state;
        const { poemPageTitle, poemPagePoems } = this.props;
        if (!title || title.length === 0) {
            this.setState({ title: poemPageTitle });
        }
        if (!poem) {
            this.setState({ poem: poemPagePoems.und ? poemPagePoems.und[0].value : '' });
        }
    }

    onChange(name, value) {
        this.setState({ [name]: value });
    }

    handleInputPhoto = (event) => {
        const uid = sessionStorage.getItem('userId');
        const { Core, dispatch } = this.props;
        const ApiRoutes = new Core.ApiRoutes();
        const formdata = new FormData();
        formdata.append('uid', uid);
        formdata.append('mediafile', event.target.files[0]);
        fetch(ApiRoutes.getRoute('uploadProfilePicture'), {
            method: 'POST',
            body: formdata,
        })
            .then((res) => {
                dispatch({
                    type: 'POEMPAGE_IMAGE',
                    value: res.data.uri,
                });
            });
    };

    handleUpdatePoem = () => {
        const uid = sessionStorage.getItem('userId');
        const { title, poem } = this.state;
        const { poemNid, editPoem } = this.props;
        const data = {
            nid: poemNid,
            title,
            type: 'poem',
            body: poem,
            uid,
            country: 'au',
            causes: '105',
            guides: '24384',
            poetry_terms: '60265',
            poem_scopes: [72535, 72538],
            groups: 'Gaming Boom (344940)',
            access: 1,
            tags: '#addiction, #bird, #boston',
            poetry_slam: '16096',
            field_poetryvideo: 'i7ygKQunfmE',
            image: 'PATH HERE',
        };
        editPoem(data);
    };

    render() {
        const { classes } = this.props;
        const { title, poem } = this.state;
        return (
            <Grid className={classes.container}>
                <Alert />
                <div className={classes.poemContainer}>
                    <h1> Edit Poem </h1>
                    <TextField
                        variant="outlined"
                        value={title}
                        type="text"
                        onChange={(event) => this.onChange('title', event.target.value)}
                        placeholder="Title"
                        className={classes.titleField}
                    />
                    {
                       this.ReactQuill
                       ?                            (
<this.ReactQuill
    theme="snow"
    className={classes.poemBody}
    value={poem}
    onChange={(event) => this.onChange('poem',  event)}
/>
)
                        : null
                    }

                    <h4>Image</h4>
                    <label htmlFor="contained-button-file">
                        {/* <img
                            className={classes.imgUser}
                            src={image}
                            alt="user Profile"
                        /> */}
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={this.handleInputPhoto}
                        />
                        <Button variant="contained" component="span" className={classes.buttonUpload}>
                            Upload
                        </Button>
                    </label>
                    <Button
                        variant="contained"
                        className={classes.buttonPrimary}
                        onClick={this.handleUpdatePoem}
                    >
                        Submit
                    </Button>
                </div>
            </Grid>
        );
    }
}
EditPoem.propTypes = {
    classes: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
    poemPageTitle: PropTypes.string.isRequired,
    getPoemByurl: PropTypes.func.isRequired,
    editPoem: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    Poempage: state.Poempage,
    poemPageTitle: state.Poempage.find((st) => st.type === poemActionTypes.POEMPAGE_TITLE).value,
    poemPagePoems: state.Poempage.find((st) => st.type === poemActionTypes.POEMPAGE_POEMS).value,
    poemPageImage: state.Poempage.find((st) => st.type === poemActionTypes.POEMPAGE_IMAGE).value,
    poemNid: state.Poempage.find((st) => st.type === poemActionTypes.POEMPAGE_NID).value,
    Core: state.Core,
});
const mapDispatchToProps = (dispatch) => ({
        getPoemByurl: (url, page) => dispatch({ type: poemActionTypes.LOAD_POEM_BY_URL, url, page }),
        editPoem: (data) => dispatch({ type: poemActionTypes.UPDATE_POEMPAGE, data }),
        dispatch,
    });
export default connect(mapStateToProps, mapDispatchToProps)((withStyles(styles)(EditPoem)));
