/* eslint-disable import/prefer-default-export */
import {
    takeEvery, put,
} from 'redux-saga/effects';
import Http from '../services/http.js';
import ApiRoutes from '../api/Routes.js';
import * as actionTypes from '../reducers/aboutuspage/action.js';

function* aboutPageContent() {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    const data = yield http.post(apiRoutes.getRoute('getPageViews'), { path: 'about-us-2' });
    yield put({
        type: actionTypes.ABOUTUSPAGE_CONTENT,
        value: data.data.nodes[0].node,
    });
}

export function* watcheraboutPageContent() {
    yield takeEvery(actionTypes.LOAD_ABOUTUSPAGE_CONTENT, aboutPageContent);
}
