/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { Popuplogin } from '../../common/login/index';
import { Registerpopup } from '../../common/registration/index';
import ResetPassword from '../../common/requestnewpassword/index';
import * as actionTypes from '../../../reducers/auth/action.js';

const styles = (theme) => ({
    appbarr: {
        background: theme.palette.current.footerBrandColor,
        borderColor: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.footerBrandColor,
            color: theme.palette.current.btn.fontColor,
        },
        '& div>span:last-of-type': {
            background: theme.palette.current.btn.fontColor,
        },
    },
    InvertedButton: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        textTransform: 'initial',
        fontSize: '15px',
        width: '33.33%',
    },
    mainclass: {
        boxShadow: 'none !important',
        position: 'static',
        backgroundColor: theme.palette.current.footerBrandColor,
    },
    spaceclassnew: {
        paddingBottom: 20,
    },
    closeIcon: {
        float: 'right',
    },
});
const  Loginpopup = ({ classes, hideLoginDrawer }) =>  {
    function TabPanel(props) {
        const {
            children, value, index, ...other
        } = props;
        return (
            <Typography
                component="span"
                variant="body2"
                role="tabpanel"
                hidden={value !== index}
                id={`wrapped-tabpanel-${index}`}
                aria-labelledby={`wrapped-tab-${index}`}
                {...other}
            >
                <Box p={2}>{children}</Box>
            </Typography>
        );
    }
    function a11yProps(index) {
        return {
            id: `wrapped-tab-${index}`,
            'aria-controls': `wrapped-tabpanel-${index}`,
        };
    }
    const [value, setValue] = React.useState('one');

    function handleChange(event, newValue) {
        setValue(newValue);
    }

   
    return (
        <div className={classes.mainclassnew}>
            <IconButton color="inherit" aria-label="close" className={classes.closeIcon} onClick={hideLoginDrawer}>
                <CloseIcon />
            </IconButton>
            <AppBar className={classes.mainclass}>
                <Tabs className={classes.appbarr} value={value} onChange={handleChange} aria-label="wrapped label tabs example">
                    <Tab
                        className={classes.InvertedButton}
                        value="one"
                        label="Log in"
                        {...a11yProps('two')}
                    />

                    <Tab
                        className={classes.InvertedButton}
                        value="two"
                        label="Create new account"
                        wrapped
                        {...a11yProps('one')}
                    />
                    <Tab
                        className={classes.InvertedButton}
                        value="three"
                        label="Request new password"
                        {...a11yProps('two')}
                    />
                </Tabs>

            </AppBar>

            <div className={classes.spaceclassnew}>
                <TabPanel value={value} index="one">
                    <Popuplogin />
                </TabPanel>
                <TabPanel value={value} index="two">
                    <Registerpopup  /> 
                </TabPanel>
                <TabPanel value={value} index="three">
                    <ResetPassword />
                </TabPanel>

            </div>
        </div>
    );
};
Loginpopup.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
    index: PropTypes.string.isRequired,
    handleDrawerToggle: PropTypes.object.isRequired,
};
const mapDispatchToProps = (dispatch) => ({
    hideLoginDrawer: () => dispatch({ type: actionTypes.LOGIN_DRAWER_TOGGLE, value: false }),
});
export default connect(null, mapDispatchToProps)(withStyles(styles)(Loginpopup));
