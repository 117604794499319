import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'react-html-parser';
import Pagination from 'material-ui-flat-pagination';
import PropTypes from 'prop-types';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import GridList from '@material-ui/core/GridList';
import { Link } from 'react-router-dom';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Skeleton from 'react-loading-skeleton';
import Searchaction from '../../../common/takeactions/index';
import PoetryIdea from '../../../common/poetryidea/PoetryIdea';
import FeaturedPoems from '../../../common/featuredPoems/FeaturedPoems';
import Map from './MapLocal';
import { test } from './PageContent';
import placeholderImage from '../../../../images/poet-image.png';
import fallback_url from '../../../../images/newPP-placeholder-min.jpg';
import PoemList from './PoemList';
import PoemItem from '../../../common/poem/item';
import CommentSupporter from '../../../common/topPoetrySupporter/CommentSupporter';
import Search from './Search';
import BrowseGroup from './BrowseGroup.js';
import WritingTips from '../../../common/writingTips/WritingTips';
import Topics from '../../../common/topics/topics';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    content:
    {
        marginTop: -15,
    },
    headTitleView: {
        fontSize: 12,
    },
    message: {
        textAlign: 'center',
    },
    gridListTiles: {
        width: '80% !important',
        height: '200px !important',
    },
    headTitle: {
        '& .MuiGridListTileBar-titleWrap > .MuiGridListTileBar-title': {
            fontSize: 18,
            fontFamily: 'GT-Walsheim-Pro-Bold',
        },
        '& .MuiGridListTileBar-titleWrap > .MuiGridListTileBar-subtitle': {
            fontSize: 15,
        },
    },
    imgSrcLocal: {
        height: '100%',
        width: '100%',
    },
    paginationbutton: {
        paddingTop: 35,
        marginBottom: 35,
        '& button': {
            background: '#b4b4b4',
            margin: 5,
        },
        '& .MuiButton-textPrimary': {
            color: 'black',
        },
        '& .MuiButton-textSecondary': {
            color: '#fecb45',
            background: 'black',
            borderTopLeftRadius: '30%',
            borderBottomRightRadius: '30%',
        },
        '& .MuiButton-root.Mui-disabled': {
            '& :hover': {
                cursor: 'no-drop',
            },
        },
    },
    poemabout: {
        '& h1': {
            textTransform: 'uppercase',
        },
    },
    poemcolor: {
        '& img': {
            height: 80,
            width: 80,
            borderRadius: '50%',
        },
    },
    photoEdit: {
        '& img': {
            height: 80,
            width: 80,
            borderRadius: '50%',
        },
    },
    contnetimg: {
        '& span> &img:': {
            height: 20,
            width: 80,
        },
    },

    poetcolor: {
        '& a': {
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
    pathlink: {
        color: theme.palette.current.invertedBtn.primaryColor,
        '& a': {
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
    poemlinked: {
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.invertedBtn.primaryColor,
        padding: 10,
        borderRadius: 10,
    },
    poemlinkedabout: {
        marginTop: 20,
        marginBottom: 20,
    },
    mainbodypoem: {},
    heading: {
        padding: 10,
    },

    subrow: {
        padding: '0px 20px 20px 20px',
        width: '80%',
        textAlign: 'justify',
        '&:nth-child(even)': {
            padding: '0.1px 20px 20px 20px',
            width: '80%',
            textAlign: 'justify',
            background: theme.palette.current.topics.background,
            borderRadius: '5px',
        },
    },
    namePoet: {
        fontSize: 25,
    },
    subjectPoet: {
        fontSize: 20,

    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: '33.3%',
        float: 'left',
        padding: '5px 0px 20px 0px',
    },

    button: {
        width: '40%',

        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: 16,
        fontWeight: 600,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    pageTitle: {
        marginBottom: 25,
    },
    Searchbar: {
        width: '100%',
        '&::placeholder': {
            fontSize: 15,
        },
    },
    menuLink: {
        color: theme.palette.current.invertedColor,
        textDecoration: 'none !important',
    },


    poemDiv: {
        '&>div': {
            [theme.breakpoints.down('md')]: {
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
    },
    leftAlign: {
        marginBottom: 25,
    },
    rightAlign: {
        marginLeft: 0,
        marginBottom: 25,
    },
    loadmoreButton: {
        textAlign: 'center',
        width: '100%',
        textTransform: 'capitalize',
    },
    browseIdeas: {
        clear: 'both',
        margin: '75px 0px',
    },
});

class AllNodesDesktop extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            tags: '',
            offset: 0,
            value: '',
            selectedValue: '',
            actions: [],
        };
    }

    componentDidMount() {
        const { Core } = this.props;
        const ApiRoutes = new Core.ApiRoutes();
        const Http = new Core.Http();

        Http.post(ApiRoutes.getRoute('getsearchactions'), {})
            .then((res) => {
                this.setState({ actions: res.data.data });
            })
            .catch((error) => Promise.reject(error));
    }


    handleClickPagination(offset) {
        this.setState({ offset });
        const Core = this.props;
        test(Core, offset);
    }

    // eslint-disable-next-line class-methods-use-this
    createPoemData(data) {
        const poemdata = [];
        poemdata.nid = data.node.Nid;
        poemdata.url =  data.node.Path ? (data.node.Path) : data.node.path ? (data.node.path)
            : ReactHtmlParser(data.node.path_1)[0].props.href;
        poemdata.image = data.node.field_image ? data.node.field_image.src : data.node.field_media;
        poemdata.body_value = data.node.body;
        poemdata.title = data.node.title.replace(/<[^>]+>/g, '');
        return poemdata;
    }


    renderPoets(htmlContent) {
        const { classes, Core, width } = this.props;
        const page_type = htmlContent[0].node.static_page_type;
        if (page_type === 'card') {
            return htmlContent.map((data, index) => {
                if (isWidthUp('md', width)) {
                    return (
                        <Grid key={`poem_ ${index}`} item xs={4}>
                            <Grid container className={classes.poemDiv}>
                                <Grid
                                    className={index % 2 ? classes.rightAlign : classes.leftAlign}
                                    item
                                    xs={11}
                                >
                                    {data ? (
                                        <PoemItem poem={this.createPoemData(data)} />
                                    ) : ''}
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                }
                return (

                    <Grid key={`poem_ ${index}`} item xs={12}>
                        <Grid container className={classes.poemDiv}>
                            <Grid className={index % 2 ? classes.rightAlign : classes.leftAlign} item xs={11}>
                                {data ? (
                                    <PoemItem poem={this.createPoemData(data)} corenew={Core} />
                                ) : ''}
                            </Grid>
                        </Grid>
                    </Grid>
                );
            });
        }

        if (page_type === 'list' || page_type === 'browse-poems') {
            if (htmlContent !== null) {
                return htmlContent.map((data, index) => {
                    if (isWidthUp('md', width)) {
                        return (

                            <Grid key={`poem_ ${index}`} item xs={12}>

                                <Grid container className={classes.poemDiv}>
                                    <Grid
                                        className={index % 2 ? classes.rightAlign : classes.leftAlign}
                                        item
                                        xs={11}
                                    >
                                        <PoemList poem={this.createPoemData(data)} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    }
                    return (
                        <Grid key={`poem_ ${index}`} item xs={12}>
                            <Grid container className={classes.poemDiv}>
                                <Grid className={index % 2 ? classes.rightAlign : classes.leftAlign} item xs={11}>
                                    <PoemList poem={this.createPoemData(data)} corenew={Core} />
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                });
            }
        }
        if (page_type === 'mini-card') {
            const { contentLink } = this.props;
            return htmlContent.map((data, index) => (
                <>
                    <GridList cellHeight={200} className={classes.gridList}>
                        <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }} />
                        <GridListTile className={classes.gridListTiles}>
                            {data.node.Picture ?  ReactHtmlParser(data.node.Picture) : data.node.field_image ? (
                                <img
                                    src={data.node.field_image.src}
                                    alt="Poetimg"
                                    onError={(e) => { e.target.src = fallback_url; }}
                                    className={classes.imgSrcLocal}
                                />
                            ) : (
                                <img
                                    src={placeholderImage}
                                    alt="CommentsImg"
                                    onError={(e) => { e.target.src = fallback_url; }}
                                />
                            )  }

                            <GridListTileBar
                                className={classes.headTitle}
                                title={(
                                    <div>
                                        <Link to={`/userpoems/${data.node.name}`} className={classes.menuLink}>{ data.node.name}
                                        </Link>
                                    </div>
                                )}
                                subtitle={<span> {data.node.subject}</span>}
                            />
                            {contentLink === 'local-poetry-view' ? (
                                <>
                                    <GridListTileBar
                                        className={classes.headTitleView}
                                        title={(
                                            <div>
                                                <Link to={data.node.Path} className={classes.menuLink}>{data.node.title}
                                                </Link>
                                            </div>
                                        )}
                                        subtitle={<span> {data.node.City} , {data.node.Province}</span>}
                                    />
                                </>
                            ) : ''}
                        </GridListTile>
                    </GridList>
                </>
            ));
        }
        if (page_type === 'terms') {
            return htmlContent.map((data, i) => (
                <>
                    <div key={i} className={classes.subrow}>
                        {data.node.title ? (

                            <h1 className={classes.poetcolor}>{data.node.title}</h1>

                        ) : (
                            ''
                        )}

                        {data.node.field_subtitle ? (
                            <div>{data.node.field_subtitle}
                                <Link className={classes.pathlink} to={data.node.Path}>   show more </Link>

                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </>
            ));
        }
        if (page_type === 'resources')  {
            return (
                <div className={classes.poetsbody}>
                    {htmlContent.map((data, i) => (
                        <div key={i} className={classes.subrow}>
                            {data.node.title ? (
                                <h1 className={classes.poetcolor}>{ReactHtmlParser(data.node.title)}</h1>

                            ) : (
                                ''
                            )}
                            {data.node.Image ? (
                                <div className={classes.poemcolor}>
                                <img
                                    src={data.node.Image.src}
                                    alt={data.node.Image.alt}
                                />
                            </div>
                            ): (
                                <div className={classes.poemcolor}>

                                    <img
                                        src={placeholderImage}
                                        alt="PoetContentimg"
                                    />
                                </div>
                            )}
                            {data.node.body ? (
                                <div className={classes.poemcolor}>
                                    {ReactHtmlParser(data.node.body)}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    ))}
                </div>
            );
        }
        if (htmlContent !== null)  {
            return (
                <div className={classes.poetsbody}>
                    {htmlContent.map((data, i) => (
                        <div key={i} className={classes.subrow}>
                            {data.node.title ? (
                                <h1 className={classes.poetcolor}>{data.node.title}</h1>

                            ) : (
                                ''
                            )}
                            {data.node.Picture ? (
                                <div className={classes.contnetimg}>
                                    <Grid className={classes.photoEdit}>
                                        {ReactHtmlParser(data.node.Picture)}
                                    </Grid>
                                </div>
                            ) :  (
                                <div className={classes.poemcolor}>

                                    <img
                                        src={placeholderImage}
                                        alt="PoetContentimg"
                                    />

                                </div>
                            )}
                            {data.node.field_subtitle ? (
                                <div>{data.node.field_subtitle}</div>
                            ) : (
                                ''
                            )}
                            {data.node.name ? (
                                <div className={`${classes.poemcolor} ${classes.namePoet}`}>
                                    <span>{data.node.name}</span>
                                </div>
                            ) : (
                                ''
                            )}

                            {data.node.City ? (
                                <div className={classes.poemcolor}>
                                    <span>City:{data.node.City}</span>
                                </div>
                            ) : (
                                ''
                            )}
                            {data.node.Province ? (
                                <div className={classes.poemcolor}>
                                    <span>Province:{data.node.Province}</span>

                                </div>
                            ) : (
                                ''
                            )}

                            {data.node.subject ? (
                                <div className={`${classes.poemcolor} ${classes.subjectPoet}`}>
                                    <span>{data.node.subject}</span>

                                </div>
                            ) : (
                                ''
                            )}
                            {data.node.created ? (
                                <div className={classes.poemcolor}>
                                    <span>
                                        {ReactHtmlParser(data.node.created)} -{' '}
                                        {data.node.Authorfirstname}

                                    </span>
                                </div>
                            ) : (
                                ''
                            )}
                            {data.node.body ? (
                                <div className={classes.poemcolor}>
                                    {ReactHtmlParser(data.node.body)}
                                </div>
                            ) : (
                                ''
                            )}

                            {data.node.path_1 ? (
                                <div className={classes.poemlinkedabout}>
                                    <span className={classes.poemlinked}>
                                        <a href="/">{data.node.path_1}</a>
                                    </span>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    ))}
                </div>
            );
        }
       
    }

    renderPages(pager) {
        const { classes } = this.props;

        if (pager !== null) {
            return (
                <div className={classes.poetsbody}>
                    {pager.map((data, i) => (
                        <div key={i} className={classes.subrow}>
                            {data.pages}
                        </div>
                    ))}
                </div>
            );
        }
        return null;
    }

    render() {
        const {
            classes, htmlContent, pager, contentLink, Core, topics,
        } = this.props;
        const corenew = Core;
        const  { actions } = this.state;
        const pages = pager !== null ? pager : '';
        const page_title = htmlContent ? htmlContent[0].node.static_page_title : '';
        const content = contentLink.replace(/api/g, '');
        const replaceContent = content;
        const {
            offset: PaginationOffset,
        } = this.state;
        const page_type = htmlContent[0].node.static_page_type;

        if (page_type === 'list' || page_type === 'card') {
            return (
                <>

                    <Grid className={`${classes.container}`} container>
                        <h1 className={classes.pageTitle}> {page_title || replaceContent} </h1>
                        <Grid className={classes.contentArea} key="content-area" item xs={8} />
                        {contentLink === 'browse-poems' ? (
                            <Search Core={Core} pager={pager} />
                        ) : (
                            ''
                        )}
                        <Grid container>
                            {this.renderPoets(htmlContent)}
                        </Grid>
                        {pages ? (
                            <div>
                                <Pagination
                                    limit={pages.limit}
                                    total={pages.count}
                                    offset={PaginationOffset}
                                    className={classes.paginationbutton}
                                    onClick={(e, offset) => this.handleClickPagination(offset)}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                    </Grid>
                </>
            );
        }
        if (page_type === 'browse-poems') {
            return (
                <>
                    <Grid className={`${classes.container}`} container>
                        <h1 className={classes.pageTitle}> {page_title || replaceContent} </h1>
                        <Grid className={classes.contentArea} key="content-area" item xs={8} />
                        {contentLink === 'browse-poems' ? (
                            <Search Core={Core} pager={pager} />
                        ) : (
                            ''
                        )}
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={8}>
                                    {this.renderPoets(htmlContent)}
                                </Grid>
                                <Grid item xs={4}>
                                    {topics === null ? <Skeleton height={650} /> :  <Topics data={topics} />}
                                    <Grid className={classes.browseIdeas}><PoetryIdea  /></Grid>
                                    <Grid className={classes.browseIdeas}><FeaturedPoems  /></Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        {pages ? (
                            <div>
                                <Pagination
                                    limit={pages.limit}
                                    total={pages.count}
                                    offset={PaginationOffset}
                                    className={classes.paginationbutton}
                                    onClick={(e, offset) => this.handleClickPagination(offset)}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                    </Grid>
                </>
            );
        }
        if (page_type === 'browse-group') {
            return (<BrowseGroup Core={Core} pager={pager} data={htmlContent} />);
        }
        if (page_type === 'mini-card') {
            return (
                <>
                    <Grid
                        className={`${classes.container} ${classes.aboutuspage}`}
                        container
                    >
                        <Grid className={classes.contentArea} key="content-area" item xs={12}>
                            {contentLink === 'local-poetry-view' ? (
                                <Grid item xs={8}>
                                    <h1 className={classes.heading}>
                                        Regional Resource Map
                                    </h1>
                                    <Map Core={corenew} />
                                </Grid>
                            ) : ''}

                            <Grid item xs={12}>
                                <Grid className={classes.poemabout}>
                                    <h1 className={classes.heading}>
                                        { page_title || replaceContent}
                                    </h1>

                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid className={classes.contentArea} key="content-area" item xs={8}>
                                    {this.renderPoets(htmlContent)}
                                </Grid>
                                {contentLink === 'leaderboard/top_comments' ? (<CommentSupporter  />) : contentLink === 'poetry-terms' ? (
                                    <Grid item xs={4}><PoetryIdea /></Grid>) : (
                                        <Grid className={`${classes.content} ${classes.actionSideBar}`} key="content-area" item xs={4}>
                                        <Searchaction searchaction={actions} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {pages ? (
                            <div>
                                <Pagination
                                    limit={pages.limit}
                                    total={pages.count}
                                    offset={PaginationOffset}
                                    className={classes.paginationbutton}
                                    onClick={(e, offset) => this.handleClickPagination(offset)}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                    </Grid>
                </>
            );
        }
        if (page_type === 'terms') {
            return (
                <>
                    <Grid
                        className={`${classes.container} ${classes.aboutuspage}`}
                        container
                    >
                        <Grid className={classes.contentArea} key="content-area" item xs={12}>


                            <Grid item xs={12}>
                                <Grid className={classes.poemabout}>
                                    <h1 className={classes.heading}>
                                        { page_title || replaceContent}
                                    </h1>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid className={classes.contentArea} key="content-area" item xs={8}>
                                    {this.renderPoets(htmlContent)}
                                </Grid>
                                {contentLink === 'leaderboard/top_comments' ? (<CommentSupporter  />) : contentLink === 'poetry-terms' ? (<Grid item xs={4}><PoetryIdea /></Grid>) : (
                                    <Grid className={classes.content} key="content-area" item xs={4}>
                                        <Searchaction searchaction={actions} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        {pages ? (
                            <div>
                                <Pagination
                                    limit={pages.limit}
                                    total={pages.count}
                                    offset={PaginationOffset}
                                    className={classes.paginationbutton}
                                    onClick={(e, offset) => this.handleClickPagination(offset)}
                                />

                            </div>
                        ) : (
                            ''
                        )}
                    </Grid>
                </>
            );
        }
        if (page_type === 'resources') {
            return (
                <>
                    <Grid
                        className={`${classes.container} ${classes.aboutuspage}`}
                        container
                    >
                        <Grid className={classes.contentArea} key="content-area" item xs={12}>
                            <Grid item xs={12}>
                                <Grid className={classes.poemabout}>
                                    <h1 className={classes.heading}>
                                        { page_title || replaceContent}
                                    </h1>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid className={classes.contentArea} key="content-area" item xs={8}>
                                    {this.renderPoets(htmlContent)}
                                </Grid>
                                <WritingTips />
                            </Grid>
                        </Grid>
                        {pages ? (
                            <div>
                                <Pagination
                                    limit={pages.limit}
                                    total={pages.count}
                                    offset={PaginationOffset}
                                    className={classes.paginationbutton}
                                    onClick={(e, offset) => this.handleClickPagination(offset)}
                                />

                            </div>
                        ) : (
                            ''
                        )}

                    </Grid>
                </>
            );
        }
        return (
            <>
                <Grid
                    className={`${classes.container} ${classes.aboutuspage}`}
                    container
                >
                    <Grid className={classes.contentArea} key="content-area" item xs={12}>


                        <Grid item xs={12}>
                            <Grid className={classes.poemabout}>
                                <h1 className={classes.heading}>
                                    { page_title || replaceContent}
                                </h1>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid className={classes.contentArea} key="content-area" item xs={8}>
                                {this.renderPoets(htmlContent)}
                            </Grid>
                            {contentLink === 'leaderboard/top_comments' ? (<CommentSupporter  />) : contentLink === 'poetry-terms' ? (<Grid item xs={4}><PoetryIdea /></Grid>) : (
                                <Grid className={classes.content} key="content-area" item xs={4}>
                                    <Searchaction searchaction={actions} />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    {pages ? (
                        <div>
                            <Pagination
                                limit={pages.limit}
                                total={pages.count}
                                offset={PaginationOffset}
                                className={classes.paginationbutton}
                                onClick={(e, offset) => this.handleClickPagination(offset)}
                            />

                        </div>
                    ) : (
                        ''
                    )}

                </Grid>
            </>
        );
    }
}

const Core = (state) => state.Core;

const mapStateToProps = (state) => ({
    Core: Core(state),
});
AllNodesDesktop.propTypes = {
    classes: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
    htmlContent: PropTypes.object.isRequired,
    contentLink: PropTypes.object.isRequired,
};
AllNodesDesktop.defaultProps = {

};
const allNodes = withWidth()(AllNodesDesktop);
export default connect(mapStateToProps)(withStyles(styles)(allNodes));
