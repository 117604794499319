/* eslint-disable import/prefer-default-export */
export const LOAD_FOOTER_CONFIG = 'LOAD_FOOTER_CONFIG';
export const LOAD_FOOTER_MENU = 'LOAD_FOOTER_MENU';

export const FOOTER_LOGO = 'FOOTER_LOGO';
export const FOOTER_SEARCH = 'FOOTER_SEARCH';
export const FOOTER_NEWSLETTER = 'FOOTER_NEWSLETTER';
export const FOOTER_MENU  = 'FOOTER_MENU';
export const FOOTER_SHARE_LINK = 'FOOTER_SHARE_LINK';
export const FOOTER_COPYRIGHT = 'FOOTER_COPYRIGHT';
