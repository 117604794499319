import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomSelect from './Select';
import useStyles from '../useStyles';
import Section from '../Section';
import TagPoem from './TagPoem';
import APP_CONFIG from '../../../../../config';

const Options = ({
    apiData, setPoemField, poemData, setMediaData, setPoemData,
}) => {
    const classes = useStyles();
    const [selected, setSelected] = useState('Tagging');
    const clickHandler = (option) => {
        setSelected(option);
    };
    const [settingValue, setSettingValue] = React.useState('1');
    const [mediaValue, setMediaValue] = React.useState('0');

    const handleVideoChange = (event) => {
        setMediaValue((event.target).value);
    };

    const checkedValueChange = (a) => {
        if (poemData.tid.includes(a.tid)) {
            const filteredTid = poemData.tid.filter((tid) => tid !== a.tid);
            return setPoemField('tid', filteredTid);
        }
        setPoemField('tid', [...poemData.tid, a.tid]);
    };
    const handleInputPhoto = (event) => {
        const URL = `${APP_CONFIG.API_BASE_URL}${APP_CONFIG.API_ENDPOINT}${'/destm/uploadMedia'}`;
        const formdata = new FormData();
        formdata.append('mediafile', event.target.files[0]);
        fetch(URL, {
            method: 'POST',
            body: formdata,
        })
            .then((res) => res.json())
            .then((response) => {
                setMediaData({
                    fid: response.data.fid,
                    filename: response.data.filename,
                });
            });
    };
    const OptionsList = [
        'Tagging',
        // 'Author',
        'About Poem',
        'Media',
        'Settings',
    ];

    const getOption = (title) => {
        switch (title) {
            case 'Tagging':
                return (
                    <Section title="Tagging">

                        <CustomSelect
                            placeholder="Select a Slam"
                            apiData={apiData ? apiData.slams : ''}
                            title="Poetry Slam"
                            poemData={poemData.poetry_slam}
                            name="poetry_slam"
                            setPoemField={setPoemField}
                            classes={classes}
                        />
                        <FormControl className={classes.selectFormControl}>
                            <FormHelperText>Select a Cause</FormHelperText>
                            <Select
                                value={poemData ? poemData.causes : ''}
                                className={`${classes.txtSelect} ${classes.selectStyle}`}
                                onClick={(e) => setPoemField('causes', e.target.value)}
                                name="causes"
                            >
                                <MenuItem value="default" disabled>
                                    Select a Cause
                                </MenuItem>
                                {apiData.causes.map((cause) => (apiData.causes.filter((parent) => parent.parents.includes(cause.tid)).length > 1 ? (
                                    <MenuItem
                                        className={classes.bold}
                                        key={cause.uuid}
                                        value={cause}
                                        disabled
                                    >
                                        {cause.name}
                                    </MenuItem>
                                ) : (
                                    <MenuItem
                                        key={cause.uuid}
                                        value={cause}
                                    >
                                        {cause.name}
                                    </MenuItem>
                                )))}

                            </Select>
                        </FormControl>
                        <CustomSelect
                            placeholder="Select Guide"
                            apiData={apiData ? apiData.guides : ''}
                            title="Poetry Guide"
                            poemData={poemData.guides}
                            name="guides"
                            setPoemField={setPoemField}
                            classes={classes}
                        />
                        <TagPoem poemData={poemData} setPoemData={setPoemData} setPoemField={setPoemField} />
                    </Section>
                );


            case 'About Poem':
                return (
                    <Section title="About Poem">
                        <CustomSelect
                            titleToName
                            placeholder="Select a Poem Term"
                            apiData={apiData ? apiData.terms : ''}
                            title="Poetry Term"
                            poemData={poemData.poetry_terms}
                            name="poetry_terms"
                            setPoemField={setPoemField}
                            classes={classes}
                        />
                        <div id="poems-about" name="abtPoem" className={classes.abtPoem}>
                            {apiData ? apiData.about.map((a) => (
                                <div key={a.tid}>
                                    <Checkbox
                                        name={a.name}
                                        checked={poemData.tid.includes(a.tid)}
                                        onClick={() => checkedValueChange(a)}
                                        id={a.tid}
                                        classes={{ root: classes.radio, checked: classes.checked }}
                                    />
                                    <label name={a.name} htmlFor="name">{a.name}</label>
                                </div>
                            )) : ''}
                        </div>
                        <CustomSelect
                            placeholder="Select a Group"
                            apiData={apiData ? apiData.groups : ''}
                            title="Poetry Group"
                            poemData={poemData.groups}
                            name="groups"
                            setPoemField={setPoemField}
                            classes={classes}
                        />
                    </Section>
                );

            case 'Media':
                return (

                    <FormControl component="fieldset" className={classes.formControl}>

                        <h4>Choose Photo:</h4>
                        <label htmlFor="contained-button-file">
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={handleInputPhoto}
                            />
                            <Button variant="contained" component="span" className={classes.buttonUpload}>
                                Upload
                            </Button>
                        </label>

                        <h4>Choose Video:</h4>
                        {/* <FormLabel component="legend">Choose Source:</FormLabel> */}
                        <FormControl component="fieldset" className={classes.formControlbuttons}>

                            <RadioGroup
                                aria-label=" "
                                className={classes.RadioGroupMenu}
                                value={mediaValue || '0'}
                                onClick={(e) => setPoemField('video_type', e.target.value)}
                                onChange={handleVideoChange}
                            >
                                <FormControlLabel
                                    value="0"
                                    control={(
                                        <Radio
                                            disableRipple
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                        />
                                    )}
                                    label="Youtube"
                                />
                                <FormControlLabel
                                    value="1"
                                    control={(
                                        <Radio
                                            disableRipple
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                        />
                                    )}
                                    label="Vimeo"
                                />
                            </RadioGroup>
                        </FormControl>
                        <br />
                        <TextField
                            label="URL"
                            onChange={(e) => setPoemField('video_code', e.target.value)}
                            value={poemData.video_code || ''}
                            className={classes.textField}
                        />
                    </FormControl>
                );
            case 'Settings':

                return (
                    <Section title="Poem Visibility">
                        <FormControl component="fieldset" className={classes.formControlbuttons}>

                            <RadioGroup
                                aria-label=""
                                className={classes.RadioGroupMenu}
                                value={settingValue}
                                onClick={(e) => setPoemField('content_access', e.target.value)}
                                onChange={(event) => setSettingValue(event.target.value)}
                            >
                                <FormControlLabel
                                    value="0"
                                    control={(
                                        <Radio
                                            disableRipple
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                        />
                                    )}
                                    label="Group Defaults"
                                />
                                <FormControlLabel
                                    value="1"
                                    control={(
                                        <Radio
                                            disableRipple
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                        />
                                    )}
                                    label="Public Accessible"
                                />
                                <FormControlLabel
                                    value="2"
                                    control={(
                                        <Radio
                                            disableRipple
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                        />
                                    )}
                                    label="Private"
                                />
                                <br />

                            </RadioGroup>

                        </FormControl>


                    </Section>
                );
                // case 'Author':

                //     return (
                //         <Section title="Author Details">
                //             <FormControl className={classes.selectFormControl}>
                //                 <TextField
                //                     label="First Name"
                //                     id="margin-none"
                //                     className={classes.textField}
                //                     onChange={(e) => setPoemField('first_name', e.target.value)}
                //                     value={poemData.first_name}
                //                 />   <br />
                //                 <TextField
                //                     label="Last Name"
                //                     id="margin-none"
                //                     className={classes.textFieldLast}
                //                     onChange={(e) => setPoemField('last_name', e.target.value)}
                //                     value={poemData.last_name}
                //                 />  &nbsp; &nbsp;  &nbsp;  &nbsp;
                //                 <TextField
                //                     label="Postal Code"
                //                     id="margin-none"
                //                     className={classes.textFieldLast}
                //                     onChange={(e) => setPoemField('postal_code', e.target.value)}
                //                     value={poemData.postal_code}
                //                 />

                //                 <FormHelperText>Country</FormHelperText>
                //                 <Select
                //                     name="Country"
                //                     value={poemData.country}
                //                     onChange={(e) => setPoemField('country', e.target.value)}
                //                     className={classes.selectStyle}
                //                 >
                //                     <MenuItem value="" disabled>
                //                     Select one option
                //                     </MenuItem>

                //                     {Object.keys(apiData.countries).map((countryCode, index) => {
                //                         const countryValue = Object.values(apiData.countries)[index];
                //                         return (
                //                             <MenuItem key={countryCode} value={countryCode}>
                //                                 {countryValue}
                //                             </MenuItem>
                //                         );
                //                     })}
                //                 </Select>  <br />

                //             </FormControl>
                //         </Section>
                //     );

            default:
                return null;
        }
    };

    return (
        <div className={classes.optionContainer}>
            <div className={classes.optionsList}>
                {OptionsList.map((option) => (
                    <ListItem
                        key={option}
                        button
                        name={option}
                        onClick={() => clickHandler(option)}
                        selected={selected === option}
                        classes={{
                            root: classes.listItemRoot,
                            selected: classes.listItemSelected,
                        }}
                    >
                        {option}
                    </ListItem>
                ))}

            </div>
            <div className={classes.optionContent}>{getOption(selected)}</div>
        </div>

    );
};
Options.propTypes = {
    apiData: PropTypes.object.isRequired,
    setPoemField: PropTypes.func.isRequired,
    poemData: PropTypes.object.isRequired,
    setMediaData: PropTypes.func.isRequired,
    setPoemData: PropTypes.func.isRequired,
};

export default Options;
