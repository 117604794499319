import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    Map, GoogleApiWrapper, Marker, InfoWindow,
} from 'google-maps-react';


export class MapContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            activeMarker: {},
        };
    }


    componentDidMount() {
        const {  Core } = this.props;
        const Http = new Core.Http();
        const ApiRoutes = new Core.ApiRoutes();
        Http.post(ApiRoutes.getRoute('getPageViews'), { path: 'api/local-poetry-view' })
            .then((res) => this.setState({
                data: res.data.nodes,
            }));
    }


     onMarkerClick = (props, marker, e, content) => this.setState({
         selectedTitle: content.node.title,
         selectedBody: content.node.Body,
         selectedLink: content.node.Path,
         activeMarker: marker,
         showingInfoWindow: true,
     });


    displayMarkers = () => this.state.data.map((content, index) => (
        <Marker
            onClick={(props, marker, e) => this.onMarkerClick(props, marker, e, content)}
            key={index}
            id={index}
            position={{
                lat: content.node.Latitude,
                lng: content.node.Longitude,
            }}
        />
    ))


    render() {
        const mapStyles = {
            maxHeight: '60vh', width: '80%', marginBottom: 50,
        };
        const {
            activeMarker, selectedBody, selectedTitle, showingInfoWindow, selectedLink, data,
        } = this.state;
        console.log(data);
        return (
            <div style={{
                height: '60vh', width: '80%',  marginBottom: 50,
            }}
            >
                <Map
                    google={this.props.google}
                    zoom={3}
                    style={mapStyles}
                    initialCenter={{ lat: 47.444, lng: -122.176 }}
                >
                    {this.displayMarkers()}
                    <InfoWindow
                        marker={activeMarker}
                        visible={showingInfoWindow}
                    >
                        <div>
                            <h1>{selectedTitle}</h1>
                            <span> {selectedBody}<a href={selectedLink} style={{ textDecoration: 'none' }}>Learn More</a>
                            </span>
                        </div>
                    </InfoWindow>
                </Map>
            </div>

        );
    }
}
MapContainer.propTypes = {
    Core: PropTypes.object.isRequired,
    google: PropTypes.object.isRequired,
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDAnmJO4OKMofUzKq6B9yV7H-9VfIbP8s4',
})(MapContainer);
