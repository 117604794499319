class LocalStorage {
    static set(_key, _val){
        window.localStorage.setItem(_key, _val);
    }
    static get(_key) {
        return window.localStorage.getItem(_key);
    }
    static remove(_key){
        window.localStorage.removeItem(_key);
    }
    static clearAll(){
        window.localStorage.clear();
    }
}
export default LocalStorage;
