import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as poemActionTypes from '../../../../../../reducers/poempage/action';

const styles = (theme) => ({
    heading: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
        display: 'inline',
        fontWeight: 500,
    },
    poetsectionn: {
        textAlign: 'center',
    },
    headingshortinfo: {
        background: theme.palette.current.topics.background,
        marginBottom: 20,
        borderRadius: 8,
        padding: 20,
        fontFamily: 'GT-Walsheim-Pro-Regular',
        margin: '0 auto',
    },
    poemabout: {
        background: theme.palette.current.footerBrandColor,
        padding: 20,
        borderRadius: 10,
    },
    dummyimage: {
        width: '50%',
        borderRadius: '50%',
        display: 'block',
        marginLeft: ' auto',
        marginRight: 'auto',
    },
    otherpoem: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        paddingTop: 20,

    },
    headingshort: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
        textAlign: 'justify',
    },
    menuLink: {
        color: theme.palette.current.invertedBtn.primaryColor,
        textDecoration: 'none !important',
    },
    poetInfo: {
        overflow: 'hidden',
      }
});

class Poetinfo extends React.PureComponent {
    render() {
        const {
            classes, poetInfo, poetName, poetImage,
        } = this.props;
        return (
            <>

                <Grid container>
                    { poetName
                        ? (
                            <Grid className={classes.headingshortinfo} item xs={11}>
                                <Grid className={classes.poetsectionn} item xs={12}>
                                    <Grid item xs={12}>
                                        {poetImage ? <img className={classes.dummyimage} src={poetImage} alt="Poet" /> : ''}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h1 className={classes.heading}> {poetName}</h1>
                                        <Grid className={`${classes.headingshort}${classes.poetInfo}`}>{poetInfo.und ? poetInfo.und[0].value : ''}</Grid>
                                    </Grid>
                                    <Grid className={classes.otherpoem}>
                                        <Link to={`/userpoems/${poetName}`} className={classes.menuLink}>
                                            Other Poem By This Poet
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : ' '}
                </Grid>
            </>
        );
    }
}
Poetinfo.propTypes = {
    classes: PropTypes.object.isRequired,
    poetInfo: PropTypes.string.isRequired,
    poetName: PropTypes.string.isRequired,
    poetImage: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
    poetImage: state.Poempage.find((st) => st.type === poemActionTypes.POEMPAGE_POETIMAGE).value,
    poetName: state.Poempage.find((st) => st.type === poemActionTypes.POEMPAGE_POETNAME).value,
    poetInfo: state.Poempage.find((st) => st.type === poemActionTypes.POEMPAGE_POETDETAIL).value,

});

export default connect(mapStateToProps, null)(withStyles(styles)(Poetinfo));
