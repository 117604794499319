import React from 'react';
import { withStyles } from '@material-ui/core';


const styles = (theme) => ({
    sectionContainer: {
        margin: 'auto auto 10px auto',
    },
    sectionContent: {
        margin: '10px',
        display: 'block',
    },
    inlineElement: {
        display: 'inline-block',
        verticalAlign: 'middle',
    },
});

const Section = ({ title, children }) => (
    <div>
        <h4>{title}</h4>
        <div>{children}</div>
    </div>
);

export default withStyles(styles)(Section);
