import React from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actionTypes from '../../../reducers/homepage/action.js';
import Mobilehomepage from './mobile/index';
import Homepage from './desktop/index';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    message: {
        textAlign: 'center',
    },
});


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            page: 1,
            recieved: true,
        };
    }

    componentDidMount() {
        const { Core, Homepage } = this.props;
        const data = Core.getStateValueByType(Homepage, actionTypes.HOMEPAGE_POEMS);
        const { loadHomePagePoem, loadhomepageWidgetRight, loadHomePageTopics } = this.props;
        if (data.length === 0) {
            loadHomePagePoem();
        }
        loadhomepageWidgetRight();
        loadHomePageTopics();
    }

    componentWillUnmount() {
        const { unmountComponentState } = this.props;
        unmountComponentState();
    }

    loadPoem = () => {
        const { page } = this.state;
        const { loadHomePagePoemMore } = this.props;
        loadHomePagePoemMore(page);
    }

    loadMore = () => {
        const { page } = this.state;
        let newPage = page;
        if (page < 10) {
            newPage = page + 1;
            this.setState({
                page: newPage,
                recieved: false,
            }, () => this.loadPoem());
        }
    }

    render() {
        const { classes, ...others } = this.props;
        return (
            <Media query={{ maxWidth: this.props.Core.breakpoints.values.md }}>
                {(matches) => (matches ? <Mobilehomepage {...others} loadMore={this.loadMore} {...this.state} /> : <Homepage {...others} loadMore={this.loadMore} {...this.state} />)}
            </Media>
        );
    }
}
Home.propTypes = {
    classes: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    loadHomePagePoem: PropTypes.func.isRequired,
    loadHomePagePoemMore: PropTypes.func.isRequired,
    loadhomepageWidgetRight: PropTypes.func.isRequired,
    loadHomePageTopics: PropTypes.func.isRequired,
    unmountComponentState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    recieved: state.Homepage,
});
const mapDispatchToProps = (dispatch) => ({
    loadHomePagePoem: () => dispatch({ type: actionTypes.LOAD_HOMEPAGE_POEMS }),
    loadHomePagePoemMore: (val) => dispatch({ type: actionTypes.LOAD_HOMEPAGE_POEMS_MORE, i: val }),
    loadhomepageWidgetRight: () => dispatch({ type: actionTypes.LOAD_HOMEPAGE_WIDGET_RIGHT }),
    loadHomePageTopics: () => dispatch({ type: actionTypes.LOAD_HOMEPAGE_TOPICS }),
    unmountComponentState: () => dispatch({ type: actionTypes.HOMEPAGE_UNMOUNT, value: [] }),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home));
