import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import * as actionTypes from '../../../reducers/footer/action.js';
import FooterMenuItems from './FooterMenuItems';
import Logo from '../../common/logo/logo';
import Search from '../../common/search/search';
import Copyright from './copyright';
import ShareLinks from './shareLinks';
import NewsLetter from '../../newsletter/mobile/NewsLetter.js';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    containerBorderBottom: {
        borderBottom: `${'1px solid'} ${theme.palette.current.footerMenuItemColor}`,
    },
    footer: {
        borderColor: theme.palette.current.primaryColor,
        borderTop: '5px solid',
        paddingTop: 100,
        paddingBottom: 15,
        borderBottom: '1px solid',
        background: theme.palette.current.footerBackgroundColor,
    },
    mobileFooter: {
        paddingTop: '50px',
    },
    mobilelink: {
        width: '100%',
        paddingLeft: '25px',
        paddingRight: '25px',
        marginTop: '50px',
        marginBottom: '30px',
    },
    minHeightmobile: {
        paddingBottom: 25,
        textAlign: 'center',
        width: '100%',
    },
    right: {
        textAlign: 'right',
    },
    marginLeftAuto: {
        marginLeft: 'auto',
    },
    menu: {
        width: '100%',
    },
    minHeight: {
        minHeight: 250,
        paddingBottom: 70,
    },
    alignCenter: {
        textAlign: 'center',
    },
});
class Mobilefooter extends React.Component {
    componentDidMount() {
        const { getConfig, getMenu } = this.props;
        getConfig();
        getMenu();
    }

    render() {
        const { classes, Footer, ...others } = this.props;
        const { Core } = this.props;
        return (
            <footer className={classes.footer}>
                <Grid
                    className={`${classes.container} ${classes.containerBorderBottom}`}
                    container
                    justify="center"
                >
                    <Grid className={classes.minHeightmobile} item sm={12}>
                        {Core.getStateValueByType(Footer, actionTypes.FOOTER_LOGO) ? (
                            <Logo src={Core.getStateValueByType(Footer, actionTypes.FOOTER_LOGO)} />
                        ) : (
                            <SkeletonTheme color="#333" highlightColor="rgba(31,31,31,.7)">
                                <Skeleton height={150} />
                            </SkeletonTheme>
                        )}
                    </Grid>
                    <Grid className={classes.mobilelink} item sm={12}>

                        {Core.getStateValueByType(Footer,  actionTypes.FOOTER_NEWSLETTER) ? (
                            <NewsLetter Core={Core} />)
                            : (
                                <SkeletonTheme color="#333" highlightColor="rgba(31,31,31,.7)">
                                    <Skeleton height={50} />
                                </SkeletonTheme>
                            )}
                    </Grid>


                    <Grid className={classes.menu} item sm={12}>
                        {Core.getStateValueByType(Footer, actionTypes.FOOTER_MENU).length > 0 ? (
                            <FooterMenuItems />
                        ) : (
                            <SkeletonTheme color="#333" highlightColor="rgba(31,31,31,.7)">
                                <Skeleton height={200} />
                            </SkeletonTheme>
                        )}
                    </Grid>

                    <Grid className={classes.mobilelink} item sm={12}>
                        <div>
                            {Core.getStateValueByType(Footer, actionTypes.FOOTER_SEARCH) ? (
                                <Search {...others} />
                            ) : (
                                <SkeletonTheme color="#333" highlightColor="rgba(31,31,31,.7)">
                                    <Skeleton height={50} />
                                </SkeletonTheme>
                            )}
                        </div>
                        <div>
                            {Core.getStateValueByType(Footer, actionTypes.FOOTER_SHARE_LINK) ? (
                                <ShareLinks
                                    links={Core.getStateValueByType(Footer, actionTypes.FOOTER_SHARE_LINK)}
                                />
                            ) : (
                                <SkeletonTheme color="#333" highlightColor="rgba(31,31,31,.7)">
                                    <Skeleton height={50} />
                                </SkeletonTheme>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Grid className={classes.container} container justify="center">
                    <div>
                        {Core.getStateValueByType(Footer, actionTypes.FOOTER_COPYRIGHT) ? (
                            <Copyright
                                className={classes.alignCenter}
                                message={Core.getStateValueByType(Footer, actionTypes.FOOTER_COPYRIGHT)}
                            />
                        ) : (
                            <SkeletonTheme color="#333" highlightColor="rgba(31,31,31,.7)">
                                <Skeleton height={50} />
                            </SkeletonTheme>
                        )}
                    </div>
                </Grid>
            </footer>
        );
    }
}
Mobilefooter.propTypes = {
    classes: PropTypes.object.isRequired,
    getConfig: PropTypes.func.isRequired,
    getMenu: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch) => ({
    getConfig: () => dispatch({ type: actionTypes.LOAD_FOOTER_CONFIG }),
    getMenu: () => dispatch({ type: actionTypes.LOAD_FOOTER_MENU }),
});
export default connect(null, mapDispatchToProps)(withStyles(styles)(Mobilefooter));
