import { createMuiTheme } from '@material-ui/core/styles';

const current_theme = 'main';
const THEME = {


    main: {
        primaryColor: 'rgb(254,203,69)',
        borderBottomColor: 'rgb(211,211,211)',
        mainMenuItemColor: 'rgb(101,101,101)',
        footerBackgroundColor: 'rgb(31,31,31)',
        footerMenuItemColor: 'rgb(152,152,152)',
        footerBrandColor: '#fff',
        invertedColor: '#fff',
        placeholderColor: '#C6C6C6',
        labelColor: 'rgba(0, 0, 0, 0.54)',
        tablecolor: '#ddd',
        darkgreycolor: '#4c4c4c',
        tablebackground: '#100f0f',
        borderrighttable: '#C0C0C0',
        signUp: {
            background: 'rgb(230,179,44)',
            logo: 'rgb(211,163,36)',
            shadow: 'rgb(245,193,54)',
        },
        topics: {
            background: '#eee',
        },
        introBlock: {
            background: 'rgba(0,0,0,.9)',
        },
        user: {
            backgrounduserform: '#F8F8F8',
            invalidColor: '#FF0000',
            validColor: '#00FF00',
            fieldBorder: '#d3d3d3',
            tabColor: '#696969',
        },
        slams: {
            slamList: '#e2dcdc3b',
        },
        poem: {
            loadmore: {
                background: '#eee',
                paginationButton: '#b4b4b4',
                textColor: '#fecb45',
            },
            block: {
                borderColor: '#303f9f',
                fontColor: '#000',
                normalBackground: '#eee',
                hoverBackground: 'rgb(254,203,69)',
                hoverButtonBackground: 'rgb(218,163,20)',
                qlToolBackground: '#f2f2f2',
                borderBottomColor: 'rgba(0, 0, 0, 0.54)',
            },
        },
        myAccount: {

            userProfile: 'linear-gradient(to right, #272727 4%, #656161 25%, #cfd0a8 36%)',
        },
        slider: {
            widgetBackground: 'rgba(0,0,0,.6)',
            widgetFontColor: '#fff',
            dotInactive: 'rgb(115,115,115)',
            dotActive: '#fff',
        },
        btn: {
            primaryColor: 'rgb(254,203,69)',
            fontColor: '#000',
            tablecolor: '#ddd',
        },
        commentSupporter: {
            listBackground: 'rgba(254,203,69,0.6)',
        },
        invertedBtn: {
            primaryColor: '#000',
            fontColor: 'rgb(254,203,69)',
        },
        editProfilePage: {
            background: 'rgba(241, 241, 241, 0.4)',
            listColor: '#dcdcda82',
            listPrimaryColor: '#e2dcdc3b',
        },
        groupRequest:{
            color: '#006400',
        },
        subMenuBackgroundColor: '#f9f9f9',
        container: {
            backgroundColor: 'transparent',
            maxWidth: {
                downSmall: '100%',
                downMedium: '100%',
                upMedium: '970px',
                upLarge: '1170px',
            },
        },
    },
};
const getTheme = () => THEME[current_theme];
const theme = createMuiTheme({
    zIndex: {
        modal: '1111111',
        drawer: '111111',
    },
    palette: {
        current: getTheme(),
    },
});
export default theme;
