import * as actionTypes from './action.js';
import Core  from './core';

const initialState = [
    {
        type: actionTypes.SITE_CURRENT_URL,
        value: Core().getCurrentPath(),
    },
];
const Url = (state = initialState, action) => {
    let OtherStates = [];
    switch (action.type) {
        case actionTypes.SITE_CURRENT_URL:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        default:
            return state;
    }
};

export default Url;
