import ApiRoutes from '../../../api/Routes';
import Http from '../../../services/http';

const Registeraction = (Cores, username, email, password, confirm_password, dob) => {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    return http.post(apiRoutes.getRoute('userRegister'), {
        name: username,
        mail: email,
        pass: password,
        dob,
        confirm_pass: confirm_password,
        field_birthdate: { und: [{ value: dob }] },
    })
        .then((response) => response)
        .catch((error) => error);
};
export default Registeraction;
