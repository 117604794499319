import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ReactRouterLink from  '../../../helpers/ReactRouterLink';

const styles = (theme) => ({
    menu: {
        maxWidth: '100%',
        listStyle: 'none',
        padding: 0,
        position: 'relative',

        '& li': {
            '& ul': {
                '&  li': {
                    marginRight: '0%',
                    paddingRight: 20,

                    '& ul': {
                        position: 'absolute',
                        top: '0%',
                        left: '100%',
                        '& li': {
                            lineHeight: 1.5,
                        },
                    },
                },
            },
        },
    },
    horizontaly: {
        display: 'block!important',
    },
    submenu: {
        display: 'none',
        position: 'absolute',
        padding: 15,
        lineHeight: 1.9,
        borderTop: `${'3px solid'} ${theme.palette.current.primaryColor}`,
        background: theme.palette.current.subMenuBackgroundColor,
        zIndex: 30,
    },
    item: {
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
        [theme.breakpoints.down('lg')]: {
            marginRight: 15,
            fontSize: 14,
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: 20,
            fontSize: 17,
        },
        display: 'inline-block',
        color: theme.palette.current.mainMenuItemColor,
        cursor: 'pointer',
        '&:hover > ul': {
            display: 'block!important',
            zIndex: '1111',
        },
    },
    menulink: {
        color: theme.palette.current.mainMenuItemColor,
        textDecoration: 'none !important',
    },
});

class Menu extends React.Component {
    getMenuItem(item, horizontaly) {
        const { classes } = this.props;
        const urlString = item.href;
        return (
            <li
                className={`${classes.item} ${
                    !horizontaly ? classes.horizontaly : null
                }`}
                key={item.href}
            >
                {urlString.indexOf('http://') === 0
                || urlString.indexOf('https://') === 0 ? (
                        <a
                            className={classes.menulink}
                            rel="noopener noreferrer"
                            href={item.href}
                            target="_blank">
                            {item.title}
                        </a>
                    ) : (
                        <ReactRouterLink
                            css={classes.menulink}
                            to={`${'/'}${item.href}`}
                            label={item.title}
                        />
                    )}
                {item.below ? this.getBaseMenu(item.below, false) : null}
            </li>
        );
    }

    getBaseMenu(menu = false, horizontaly = true) {
        const { classes } = this.props;
        if (!menu) {
            menu = this.props.menu;
        } else {
            menu = Object.values(menu);
        }
        return (
            <ul className={`${classes.menu} ${!horizontaly ? classes.submenu : null}`}>
                {menu.map((item) => {
                    if (!horizontaly) {
                        return this.getMenuItem(item, horizontaly);
                    }
                    const key = Object.keys(item);
                    return this.getMenuItem(item[key[0]], horizontaly);
                })}
            </ul>
        );
    }

    render() {
        return <> {this.getBaseMenu()} </>;
    }
}
Menu.propTypes = {
    classes: PropTypes.object.isRequired,
    menu: PropTypes.object.isRequired,
};
export default withStyles(styles)(Menu);
