import React, { PureComponent } from 'react';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { Button, CircularProgress } from '@material-ui/core';
import {  withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Skeleton from 'react-loading-skeleton';
import ReactHtmlParser from 'react-html-parser';


const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    mainContainer: {

        maxWidth: '100%',
        position: 'relative',
        marginBottom: 100,
        minHeight: 1000,
    },
    parentContent: {
        minHeight: 170,
        width: '100%',
        display: 'flex',
    },
    coverPhoto: {
        minHeight: 300,
        width: '100%',
        background: theme.palette.current.myAccount.userProfile,

    },
    poemBody: {
        fontSize: 15,
        overflow:'hidden',
    },
    textTitle: {
        width: '85%',
        marginLeft: '5%',
    },
    imgApi: {
        minHeight: '100%',
        width: '100%',
        borderRadius: '4%',
    },
    imgPoetParent: {
        width: '15%',
        maxHeight: 90,
        margin: '30px 0px 0px 20px ',
    },

    userInfoProfile: {
        minHeight: '300px',
        width: '18%',
        borderRadius: 2,
        textAlign: 'center',
        position: 'absolute',
        top: '50px',
        left: '20%',
        fontFamily: 'GT-Walsheim-Pro-Medium',

    },

    imgFont: {
        fontSize: '120px',
        textAlign: 'center',
    },
    backUserInfo: {
        background: theme.palette.current.btn.primaryColor,
    },
    circularLoader: {
        maxHeight: 100,
        width: 100,
        color: theme.palette.current.primaryColor,
        marginLeft: '50%',
        marginTop: 50,
    },
    imgUser: {
        maxWidth: '200px',
        maxHeight: '200px',
        marginTop: '5px',
        borderRadius: '50%',
        overflow: 'hidden',
    },
    userNameBlock: {
        marginTop: 0,
        fontSize: '40px',
        textAlign: 'center',
    },
    locationUser: {
        marginTop: '2%',
        textAlign: 'center',
    },
    userShortBio: {
        marginTop: 10,
        marginBottom: '10px',
        fontSize: '15px',
        padding: '0 10px 10px 10px ',
        textAlign: 'justify',
    },
    buttonEditProfile: {
        maxWidth: '40%',
        marginTop: 'auto',
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.invertedBtn.fontColor,
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.invertedBtn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '14px 18px',
        fontWeight: 600,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
        marginBottom: '15px',
        textDecoration: 'none!important',
    },
    linkEdit: {
        textDecoration: 'none!important',
        color: theme.palette.current.primaryColor,
    },
    linkEditPoem: {
        textDecoration: 'none!important',
        color: theme.palette.current.invertedBtn.fontColor,
        fontFamily: 'GT-Walsheim-Pro-Bold',
        display:'block',
    },
    headingPoemPoet: {
        textAlign: 'center',
        fontFamily: 'GT-Walsheim-Pro-Bold',
    },
    containerProfilePage: {
        minHeight: '650px',
        width: 780,
        color: theme.palette.current.invertedBtn.primaryColor,
        borderRadius: 2,
        background: theme.palette.current.invertedColor,
        position: 'absolute',
        top: '50px',
        left: '40%',
        fontFamily: 'GT-Walsheim-Pro-Light',
    },
    dividerList: {
        listStyle: 'none',
    },
    subrow: {
        padding: '0px 10px 0px 10px ',
        textAlign: 'justify',
        justifyContent: 'center',
        '&:nth-child(odd)': {
            borderRadius: 3,
            background: theme.palette.current.editProfilePage.listColor,
            '&:nth-child(even)': {
                textAlign: 'justify',
                background: theme.palette.current.invertedColor,
                borderRadius: '5px',
                backgroundColor: theme.palette.current.editProfilePage.listPrimaryColor,
            },
        },
    },
    mainBlockBadge: {
        minHeight: 200,
        maxWidth: '15%',
        position: 'absolute',
        top: '10%',
        borderRadius: 2,
        left: '65%',
        background: theme.palette.current.user.fieldBorder,
    },
    badgeHeading: {
        fontSize: '25px',
    },
    imgUserBadges: {
        marginTop: '10px',
        marginLeft: '10px',
        overflow: 'hidden',
    },
    badgeName: {
        fontSize: '15px',
        marginLeft: '10px',
    },

});
class MyAccount extends PureComponent {
    constructor() {
        super();
        this.state = {
            data: [],
            uid: '',
        };
    }

    componentDidMount() {
        const  Core  = this.props.core;
        const core = Core;
        const ApiRoutes = new core.ApiRoutes();
        const Http = new core.Http();
        const name =  localStorage.getItem('loggein_username');
        Http.post(ApiRoutes.getRoute('fetchUser'), {
            username: name,
        })
            .then((response) => response.data)
            .then((response) => {
                this.setState({
                    data: response.data,
                    uid: response.data.uid,
                });
                Http.post(ApiRoutes.getRoute('getUserPoems'), {
                    uid: this.state.uid,
                    post_per_page: 5,
                })
                    .then((poem) => poem.data)
                    .then((poem) => {
                        this.setState({
                            poemData: poem.data,
                        });
                    });
            });
    }

    render() {
        const { classes } = this.props;
        const {
            data, poemData,
        } = this.state;
        const { name } = data;
        const { field_first_name } = data;
        const { field_last_name } = data;
        const { badges_all } = data;
        const { field_user_location } = data;
        const data_length = Object.keys(data).length;
        return (
            <>
                <div className={classes.mainContainer}>
                    <div className={classes.coverPhoto}> </div>
                    <div className={classes.userInfoProfile}>
                        {(data_length > 0) ? (
                            <div className={classes.backUserInfo}>
                                <div className={classes.usrPic}>
                                    <span className={classes.spanImg}>
                                        { data.picture
                                            ?  (
                                                <img
                                                    className={classes.imgUser}
                                                    src={data.picture.uri}
                                                    alt="user Profile"
                                                />
                                            )
                                            :                                                                                                             <AccountCircleRoundedIcon className={classes.imgFont} />}
                                    </span>
                                </div>
                                <div className={classes.userNameBlock}>
                                    {' '}
                                    {field_first_name && field_last_name
                                        ? `${field_first_name} ${field_last_name}`
                                        :  name }
                                </div>
                                <Button variant="contained" className={classes.buttonEditProfile}>
                                    <Link to="/userEditProfile" className={classes.linkEdit}>
                            Edit Profile
                                    </Link>
                                </Button>
                                <div className={classes.locationUser}>
                                    {field_user_location  && (field_user_location.country_name)  }
                                </div>
                                <div className={classes.userShortBio}>  {data.field_bio} </div>
                                <div className={classes.badgeHeading}>  Badges Earned By You:</div>


                                { badges_all && Object.keys(badges_all).map((key, i) => (
                                    <div key={i}>
                                        <img
                                            className={classes.imgUserBadges}
                                            src={badges_all[key].image}
                                            alt="userBadges"
                                        />

                                        <div className={classes.badgeName}> {badges_all[key].name} </div>
                                    </div>
                                ))}
                            </div>

                        ) : (

                            <Skeleton height={600} width={330} />

                        )}
                    </div>

                    {/* POEMS BY POET LIST */}

                    <div className={classes.containerProfilePage}>
                        <h1 className={classes.headingPoemPoet}>  Recent Poem Added By You:</h1>
                        { poemData ? (

                            <>
                                <div className={classes.mainContainerList}>
                                    {poemData && Object.keys(poemData).map((content, i) => (

                                        <div key={i} className={classes.subrow}>
                                            <div className={classes.parentContent}>
                                                <div className={classes.imgPoetParent}>
                                                    <img
                                                        src={poemData[content].image}
                                                        alt="PoetImage"
                                                        className={classes.imgApi}
                                                    />
                                                </div>
                                                <div className={classes.textTitle}><h3 className={classes.titlePoem}> {poemData[content].title}</h3>
                                                    <p className={classes.poemBody}>
                                                        {ReactHtmlParser(poemData[content].body_value)}   <Link to={poemData[content].url} className={classes.linkEditPoem}> show more </Link>

                                                    </p>
                                                </div>


                                            </div>
                                            <Divider variant="inset" component="li" className={classes.dividerList} />

                                        </div>

                                    ))}

                                </div>
                            </>
                        ) : (<CircularProgress classes={{ colorPrimary: classes.circularLoader }} />)}
                    </div>
                    {/* end of list poem */}

                </div>
            </>
        );
    }
}
MyAccount.propTypes = {
    classes: PropTypes.object.isRequired,
    core: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyAccount);
