import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
    heading: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        textAlign: 'left',
    },
    chip: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
        display: 'inline-block',
        borderRadius: '10px !important',
        background: theme.palette.current.tablecolor,
        padding: '10px 20px 10px 20px',
        marginRight: 10,
        marginBottom: 10,
        fontSize: 20,
    },
    geomeheader: {
        textAlign: 'left',
    },
});
class Poemtags extends React.Component {
    renderPoets(poemtags) {
        const { classes } = this.props;
        if (poemtags !== null) {
            return (
                <div className={classes.chipmain}>
                    {poemtags.map((data) => (
                        <div key={data.name} className={classes.chip}>
                            {data.name}
                        </div>
                    ))}
                </div>
            );
        }
        return null;
    }

    render() {
        const { classes, poemtags } = this.props;
        return (
            <>
                <Grid container>
                    <Grid className={classes.geomeheader} item xs={12}>
                        <h1 className={classes.heading}>Poem Tags</h1>
                        {this.renderPoets(poemtags)}
                    </Grid>
                </Grid>
            </>
        );
    }
}
Poemtags.propTypes = {
    poemtags: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Poemtags);
