/* eslint-disable no-param-reassign */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
 import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import ReactRouterLink from  '../../../helpers/ReactRouterLink';

const styles = (theme) => ({
    menu: {
        maxWidth: '100%',
        listStyle: 'none',
        padding: 0,
        position: 'relative',
    },
    horizontaly: {
        display: 'block!important',
    },
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        margin: '0px 0px !important',
    },
    nestedsub: {
        listStyle: 'none',
        paddingLeft: '0',
    },
    item: {
        [theme.breakpoints.down('md')]: {
            display: 'block',
            margin: '0px 0px',
        },
        display: 'inline-block',
        fontSize: 20,
        color: theme.palette.current.mainMenuItemColor,
        cursor: 'pointer',
    },
    menulink: {
        color: theme.palette.current.mainMenuItemColor,
        textDecoration: 'none !important',
        display: 'inline-block',
    },
});
class Mobilemenu extends React.Component {
    getMenuItem(item, horizontaly) {
        const { classes } = this.props;
        const datbelow = item.below ? item.below : null;
        const submen = item.below ? Object.values(datbelow) : null;
        // console.log(datbelow,'submen')
        const urlString = item.href;
        return (
            <ExpansionPanel
                className={`${classes.item} ${classes.nested} ${!horizontaly ? classes.horizontaly : null}`}
                title={item.href}
                key={item.href}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>
                        {urlString.indexOf('http://') === 0
              || urlString.indexOf('https://') === 0
                            ? (
                                <a
                                    className={classes.menulink}
                                    rel="noopener noreferrer"
                                    href={item.href}
                                    target="_blank"
                                >
                                    {item.title}
                                </a>
                            )
                            : (
                                <ReactRouterLink  
                                css={classes.menulink}
                                to={`${'/'}${item.href}`}
                                label={item.title}
                            />
                           
                            )}
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <ul className={classes.nestedsub}>
                        {item.below
                            ? submen.map((submens) => (
                                <li key={submens.href}>
                                    {urlString.indexOf('http://') === 0
                        || urlString.indexOf('https://') === 0
                                        ? (
                                            <Link
                                                className={classes.menulink}
                                                to={submens.href}
                                                target="_blank"
                                            >
                                                {submens.title}
                                            </Link>
                                        )
                                        : (
                                            <ReactRouterLink 
                                             css={classes.menulink}
                                             to={ `${'/'}${submens.href}` } 
                                             label={submens.title}
                                            />
                                        )}
                                </li>
                            ))
                            : null}
                    </ul>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    getBaseMenu(menu = false, horizontaly = true) {
        const { classes } = this.props;
        if (!menu) {
            menu = this.props.menu;
        } else {
            menu = Object.values(menu);
        }
        return (
            <ul className={`${classes.menu} ${!horizontaly ? classes.submenu : null}`}>
                {menu.map((item) => {
                    if (!horizontaly) {
                        return this.getMenuItem(item, horizontaly);
                    }
                    const key = Object.keys(item);
                    return this.getMenuItem(item[key[0]], horizontaly);
                })}
            </ul>
        );
    }

    render() {
        return <> {this.getBaseMenu()} </>;
    }
}
Mobilemenu.propTypes = {
    classes: PropTypes.object.isRequired,
    menu: PropTypes.object.isRequired,
};
export default withStyles(styles)(Mobilemenu);
