
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import BasicPageSkeleton from './skeleton/BasicPage.skeleton';
import SlamList from './Slams';
import GroupRequestForm from '../../../common/groupRequestForm/GroupRequestForm';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',

        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',

        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',

        },
    },
    space: {
        minHeight: 'calc(100vh - (370px + 10px))',
        padding: '32px 0px',
    },
    message: {
        textAlign: 'center',
        fontSize: 34,
        margin: 40,
    },
    aboutUsPage: {
        '& p': { textAlign: 'justify' },
        '& p > strong': {
            display: 'block',
            float: 'none',
            width: '100%',
            clear: 'both',
        },
        '& div > strong': {
            display: 'block',
            float: 'none',
            width: '100%',
            clear: 'both',
        },
    },
    contentArea: {
        '&internalContainer': {
        },
        '& h3 a': {
            fontSize: 25,
            color: theme.palette.current.invertedBtn.primaryColor,
            textDecoration: 'none',
        },
        '&  a': {
            color: theme.palette.current.mainMenuItemColor,
        },

    },
});

class BasicPageDesktop extends React.PureComponent {
    render() {
        const {
            classes,
            loading,
            htmlContent,
            Core,
        } = this.props;
        const coredata = Core;
        const cleanText = (htmlContent) ?  (htmlContent.Body).replace(/\xA0/g, ' ') : '';
        const msg = <h1 className={classes.message}>No Data Found!</h1>;
        if (htmlContent === null && loading === false) {
            return msg;
        }
        if (loading) {
            return <BasicPageSkeleton />;
        }
        if (htmlContent.static_page_type === 'introducing-groups') {
            return (
                <>
                    <Grid item xs={12}>
                        <Grid className={`${classes.container} ${classes.aboutUsPage} ${classes.space}`} container>
                            <Grid className={classes.contentArea} key="content-area" item xs={8}>
                                <h1> {ReactHtmlParser(htmlContent.title)} </h1>
                                <div>{ReactHtmlParser(cleanText)} </div>
                            </Grid>
                            <Grid item xs={4}>
                                <GroupRequestForm Core={Core}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            );
        }
        return (
            <>
                <Grid className={`${classes.container} ${classes.aboutUsPage} ${classes.space}`} container>
                    <Grid className={classes.contentArea} key="content-area" item xs={12}>
                        <h1> {ReactHtmlParser(htmlContent.title)} </h1>
                        <div>{ReactHtmlParser(cleanText)} </div>
                        {htmlContent.static_page_type === 'slams' ? <SlamList Core={coredata} /> : ''}
                    </Grid>
                </Grid>
            </>
        );
    }
}

BasicPageDesktop.propTypes = {
    classes: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    htmlContent: PropTypes.object,
};

BasicPageDesktop.defaultProps = {
    htmlContent: '',
};
const Core = (state) => state.Core;

const mapStateToProps = (state) => ({
    Core: Core(state),
});

export default connect(mapStateToProps)(withStyles(styles)(BasicPageDesktop));
