import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Searchdesktopview from './search/search';
// right - sidebar
// import queryString from 'query-string';
// import Searchaction from '../../../common/takeactions/index';


const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    contentArea: {
        marginBottom: 25,
        display: 'inline-flex',
    },
    sidebar: {
        marginLeft: 'auto',
        [theme.breakpoints.down('lg')]: {
            marginRight: 'auto',
        },
    },
    zIndex: {
        zIndex: 9999,
    },
});
class Searchdesktop extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            results: {},
            // sidebar-action
            // actions: [],
        };
    }

    componentDidMount() {
        this.fetchData();
        window.scrollTo(0, 0);
    }

    componentDidUpdate(previousProps) {
        const { param } = this.props;
        if (previousProps.param !== param) {
            this.fetchData();
        }
    }

    fetchData() {
        const { param, Core } = this.props;
        const ApiRoutes = new Core.ApiRoutes();
        const Http = new Core.Http();
        const test = window.location.search;
        const val = test.split('=');
        const searchvalue = param || decodeURI(val[1]);
        this.setState({ results: {} });
        return ((searchParam) => {
            Http.get(`${ApiRoutes.getRoute('search')}retrieve.json?keys=${encodeURI(searchParam)}`, {})
                .then((response) => {
                    if (searchParam === decodeURI(window.location.search.split('=')[1])) {
                        this.setState({ results: response.data.result });
                    }
                })
                .catch((error) => this.setState({ results: error }));
            // right-sidebar-call
            // Http.post(ApiRoutes.getRoute('getsearchactions'), {})
            //     .then((res) => {
            //         if (searchParam === queryString.parse(window.location.search).keys) {
            //          this.setState({ actions: res.data.data });
            //         }
            //     })
            //     .catch((error) => Promise.reject(searchvalue, param, error));
        })(searchvalue);
    }

    render() {
        const { classes } = this.props;
        const { results } = this.state;
        // sidebar-action
        //  const { results, actions } = this.state;
        return  (
            <>
                <Grid className={`${classes.container}`} container>
                    <Grid className={classes.contentArea} key="content-area" item xs={12}>
                        <Searchdesktopview
                            data={results}
                        />
                        {/* right-sidebar-view */}
                        {/* <Grid className={classes.content} item xs={8}> */}
                        {/* </Grid> */}
                        {/* <Grid className={classes.content} key="content-area" item xs={4}>
                            <Searchaction searchaction={actions} />
                        </Grid> */}
                    </Grid>
                </Grid>
            </>
        );
    }
}

Searchdesktop.propTypes = {
    classes: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
    param: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    param: state.Searchpage.find((st) => st.type === 'SEARCH.PARAM').value,
    Core: state.Core,
});

export default connect(mapStateToProps)(withStyles(styles)(Searchdesktop));
