import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Pagination from 'material-ui-flat-pagination';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import fallback_url from '../../../../images/newPP-placeholder-min.jpg';
import { test } from './PageContent';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    pageTitle: {
        marginBottom: 25,
    },
    subrow: {
        width: '80%',
        textAlign: 'justify',
        padding: 10,
        '&:nth-child(even)': {
            width: '80%',
            padding: 10,
            textAlign: 'justify',
            background: theme.palette.current.topics.background,
            borderRadius: '5px',
        },
    },
    InvertedButton: {
        color: theme.palette.current.invertedBtn.primaryColor,
        paddingRight: 15,
        textDecoration: 'none',
        float: 'right',
    },
    parentContent: {
        minHeight: 100,
        width: '100%',
        display: 'flex',
    },
    imgPoetParent: {
        width: '15%',
        maxHeight: 150,
        margin: 10,
        overflow: 'hidden',
    },
    textTitle: {
        width: '85%',
        marginLeft: '2%',
        '& h2': {
            color: theme.palette.current.mainMenuItemColor,
        },
    },
    dividerList: {
        listStyle: 'none',
    },
    poemBody: {
        fontSize: 15,
        overflow: 'hidden',
        marginLeft: 8,
    },
    imgApi: {
        minHeight: '100%',
        width: '100%',
    },
    headingGroup: {
        display: 'inline-block',
    },
    paginationbutton: {
        paddingTop: 35,
        marginBottom: 35,
        '& button': {
            background: theme.palette.current.poem.loadmore.paginationButton,
            margin: 5,
        },
        '& .MuiButton-textPrimary': {
            color: theme.palette.current.invertedBtn.primaryColor,
        },
        '& .MuiButton-textSecondary': {
            color:  theme.palette.current.poem.loadmore.textColor,
            background: theme.palette.current.invertedBtn.primaryColor,
            borderTopLeftRadius: '30%',
            borderBottomRightRadius: '30%',
        },
        '& .MuiButton-root.Mui-disabled': {
            '& :hover': {
                cursor: 'no-drop',
            },
        },
    },
});
class BrowseGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
        };
    }

    handleClickPagination(offset) {
        this.setState({ offset });
        const Core = this.props;
        test(Core, offset);
    }

    renderPoets(htmlContent) {
        const { classes } = this.props;
        if (htmlContent !== []) {
            return htmlContent.map((data, index) => (
                <Grid key={`poem_ ${index}`} item xs={12} className={classes.subrow}>
                    <div>
                        <div className={classes.parentContent}>
                            <div className={classes.imgPoetParent}>
                                {data.node.field_media !== null
                                    ? (
                                        <img
                                            src={data.node.field_media}
                                            alt="PoetImage"
                                            className={classes.imgApi}
                                            onError={(e) => { e.target.src = fallback_url; }}
                                        />
                                    ) : (
                                        <img
                                            src={fallback_url}
                                            alt="PoetImage"
                                            className={classes.imgApi}
                                        />
                                    )}
                            </div>
                            <div className={classes.textTitle}>
                                <h2 className={classes.headingGroup}>{data.node.title}</h2>
                                <br />
                                <Link
                                    className={classes.InvertedButton}
                                    color="primary"
                                    to={data.node.Path}
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                </Grid>
            ));
        }
        return htmlContent;
    }

    render() {
        const { classes, data, pager } = this.props;
        const page_title = data ? data[0].node.static_page_title : '';
        const pages = pager !== null ? pager : null;
        const {
            offset: PaginationOffset,
        } = this.state;
        return (
            <Grid className={`${classes.container}`} container>
                <h1 className={classes.pageTitle}>   {page_title}</h1>
                <Grid className={classes.contentArea} key="content-area" item xs={8} />
                <Grid container>
                    {this.renderPoets(data)}
                </Grid>
                {pages ? (
                    <div>
                        <Pagination
                            limit={pages.limit}
                            total={pages.count}
                            offset={PaginationOffset}
                            className={classes.paginationbutton}
                            onClick={(e, offset) => this.handleClickPagination(offset)}
                        />
                    </div>
                ) : (
                    null
                )}
            </Grid>
        );
    }
}
BrowseGroup.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    pager: PropTypes.object.isRequired,
};
export default connect(null, null)(withStyles(styles)(BrowseGroup));
