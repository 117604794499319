const initialState = [
    {
        type: 'SEARCH.RESULT',
        value: [],
    },
    {
        type: 'SEARCH.ACTION',
        value: [],
    },
    {
        type: 'SEARCH.PARAM',
        value: '',
    },
    {
        type: 'SEARCH.QUERY',
        value: '',
    },
];
const Searchpage = (state = initialState, action) => {
    let OtherStates = [];
    switch (action.type) {
        case 'SEARCH.RESULT':
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case 'SEARCH.ACTION':
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case 'SEARCH.PARAM':
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case 'SEARCH.QUERY':
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        default:
            return state;
    }
};
export default Searchpage;
