/* eslint-disable import/prefer-default-export */
import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../reducers/poempage/action';
import * as reducerAction from '../reducers/action.js';
import   Http from '../services/http.js';
import ApiRoutes from '../api/Routes.js';


function* getPoemByUrl(action) {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    const data = yield call([http, http.post], apiRoutes.getRoute('getPoemByUrl'), { path: action.url });
    const widgetBottom  = yield call([http, http.post], apiRoutes.getRoute('getBlock'), { block_name: 'poempage need to talk' });
    if (widgetBottom) {
        yield put({
            type: actionTypes.POEMPAGE_WIDGET_BOTTOM,
            value: widgetBottom.data.data,
        });
    }
    if (data.data.data) {
        yield put({
            type: actionTypes.POEMPAGE_NID,
            value: data.data.data.nid,
        });
        yield put({
            type: actionTypes.POEMPAGE_TITLE,
            value: data.data.data.title,
        });
        yield put({
            type: actionTypes.POEMPAGE_IMAGE,
            value: data.data.data.field_image,
        });
        yield put({
            type: actionTypes.POEMPAGE_POETIMAGE,
            value: data.data.data.userDetails.poet_picture,
        });
        yield put({
            type: actionTypes.POEMPAGE_DATE,
            value: data.data.data.created,
        });
        yield put({
            type: actionTypes.POEMPAGE_POEMS,
            value: data.data.data.body,
        });
        yield put({
            type: actionTypes.POEMPAGE_POETS,
            value: data.data.data.poetPrediction,
        });
        yield put({
            type: actionTypes.POEMPAGE_RAPPERS,
            value: data.data.data.rapPrediction,
        });
        yield put({
            type: actionTypes.POEMPAGE_POETDETAIL,
            value: data.data.data.userDetails.field_bio,
        });
        yield put({
            type: actionTypes.POEMPAGE_POETDETAIL_BADGES,
            value: data.data.data.userDetails.badges,
        });
        yield put({
            type: actionTypes.POEMPAGE_POETNAME,
            value: data.data.data.userDetails.username,
        });
        yield put({
            type: actionTypes.POEMPAGE_TAGS,
            value: data.data.data.taxonomyResult,
        });
        yield put({
            type: actionTypes.POEMPAGE_GEOME,
            value: data.data.data,
        });
        yield put({
            type: actionTypes.POEMPAGE_SCOPE,
            value: data.data.data,
        });
        const comments = yield call([http, http.post], apiRoutes.getRoute('getAllComments'), {
            nid: data.data.data.nid,
            uid: data.data.data.uid,
            page: action.page,
        });
        if (comments) {
            yield put({
                type: actionTypes.POEMPAGE_COMMENTS,
                value: Object.values(comments.data.data),
            });
            yield put({
                type: reducerAction.LOAD_COMMENTS,
                value: false,
            });
        }
    }
}

function* unmountPoemPage() {
    yield put({
        type: actionTypes.POEMPAGE_TITLE,
        value: [],
    });
    yield put({
        type: actionTypes.POEMPAGE_IMAGE,
        value: [],
    });
    yield put({
        type: actionTypes.POEMPAGE_DATE,
        value: [],
    });
    yield put({
        type: actionTypes.POEMPAGE_POEMS,
        value: [],
    });
    yield put({
        type: actionTypes.POEMPAGE_POETS,
        value: [],
    });
    yield put({
        type: actionTypes.POEMPAGE_RAPPERS,
        value: [],
    });
    yield put({
        type: actionTypes.POEMPAGE_POETDETAIL,
        value: [],
    });
    yield put({
        type: actionTypes.POEMPAGE_POETNAME,
        value: [],
    });
    yield put({
        type: actionTypes.POEMPAGE_TAGS,
        value: [],
    });
    yield put({
        type: actionTypes.POEMPAGE_GEOME,
        value: [],
    });
    yield put({
        type: actionTypes.POEMPAGE_COMMENTS,
        value: [],
    });
    yield put({
        type: actionTypes.POEMPAGE_SCOPE,
        value: [],
    });
    yield put({
        type: actionTypes.POEMPAGE_POETDETAIL_BADGES,
        value: [],
    });
}
function* createComment(action) {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    yield put({
        type: reducerAction.LOAD_COMMENTS,
        value: true,
    });
    const data = yield call([http, http.post], apiRoutes.getRoute('createComment'), { comment: action.data });
    yield put({
        type: actionTypes.CREATE_COMMENTS,
        value: data.data.data.data,
    });
    yield put({
        type: reducerAction.LOAD_COMMENTS,
        value: false,
    });
    yield put({
        type: reducerAction.SHOW_ALERT,
        value: true,
        message: 'Comment Created',
    });
}

function* updateComment(action) {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    yield put({
        type: reducerAction.LOAD_COMMENTS,
        value: true,
    });
    const data = yield call([http, http.post], apiRoutes.getRoute('updateComment'), { comment: action.payloads });
    yield put({
        type: actionTypes.UPDATE_COMMENTS,
        value: data.data.data.data,
    });
    yield put({
        type: reducerAction.EDIT_COMMENTS,
    });
    yield put({
        type: reducerAction.LOAD_COMMENTS,
        value: false,
    });
    yield put({
        type: reducerAction.SHOW_ALERT,
        value: true,
        message: 'Comment Updated!',
    });
}
function* deleteComment(action) {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    yield put({
        type: reducerAction.LOAD_COMMENTS,
        value: true,
    });
    const data = yield call([http, http.post], apiRoutes.getRoute('deleteComment'), { cid: action.id });
    yield put({
        type: actionTypes.DELETE_COMMENTS,
        value: data.data.data.cid,
    });
    yield put({
        type: reducerAction.LOAD_COMMENTS,
        value: false,
    });
    yield put({
        type: reducerAction.SHOW_ALERT,
        value: true,
        message: 'Comment Deleted',
    });
}

export function* watcherPoemPage() {
    yield takeEvery(actionTypes.LOAD_POEM_BY_URL, getPoemByUrl);
    yield takeEvery(actionTypes.POEMPAGE_DELETE_COMMENTS, deleteComment);
    yield takeEvery(actionTypes.POEMPAGE_UPDATE_COMMENTS, updateComment);
    yield takeEvery(actionTypes.POEMPAGE_CREATE_COMMENTS, createComment);
    yield takeEvery(actionTypes.UNMOUNT_POEM_PAGE, unmountPoemPage);
}
