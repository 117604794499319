import ApiRoutes from '../../../api/Routes';
import Http from '../../../services/http';

const Requestaction = (userId,groupname, groupdesc) => {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    const authToken = window.localStorage.getItem('authentication_token');
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Accept-Language':'*',
    };
    if(authToken){
        headers['X-CSRF-TOKEN'] = authToken;
        headers['withCredentials'] = true;
    }
    return http.post(apiRoutes.getRoute('groupRequestForm'), { uid:userId,group_name: groupname,group_description: groupdesc},true,headers)
        .then((response) => response)
        .catch((error) => error);
};

 const Registeraction = (username, email, school,zipcode,password, confirm_password) => {
    const http = new Http();
    const apiRoutes = new ApiRoutes();
    const headers = {
        'Content-Type': 'application/json',
    };
    return http.post(apiRoutes.getRoute('registerTeacherForm'), { name:username,email: email,school: school,zipcode:zipcode,password:password,confirm_password:confirm_password},true,headers)
        .then((response) => response)
        .catch((error) => error);
}

export default Requestaction;
export {Registeraction};