import SessionStorage from './storages/sessionStorages';
import LocalStorage from './storages/localStorages';

class Storage {
    constructor(_type = 'local') {
        if (_type === 'local') {
            this._cls = new LocalStorage();
        } else if (_type === 'session') {
            this._cls = new SessionStorage();
        }
    }

    static set(_key, _val) {
        this._cls.set(_key, _val);
    }

    static get(_key) {
        return this._cls.get(_key);
    }

    static remove(_key) {
        this._cls.remove(_key);
    }

    static clearAll() {
        this._cls.clearAll();
    }
}
export default Storage;
