import initialState from './initialState';
import * as ADDPOEM from './constants';

const Addpoem = (state = initialState, action) => {
    let OtherStates = [];
    let previousData = {};
    switch (action.type) {
        case ADDPOEM.POEMDATA:
            OtherStates = state.filter((st) => st.type !== action.type);
            previousData = state.filter((st) => st.type === action.type).value;
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: {
                        ...previousData,
                        ...action.value,
                    },
                },
            ];
        case ADDPOEM.API_DATA.COUNTRIES:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case ADDPOEM.API_DATA.SLAMS:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case ADDPOEM.API_DATA.CAUSES:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case ADDPOEM.API_DATA.GUIDES:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case ADDPOEM.API_DATA.TERMS:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case ADDPOEM.API_DATA.GROUPS:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case ADDPOEM.API_DATA.ABOUT:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];


        default:
            return state;
    }
};


export default Addpoem;
