import React from "react";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  brandMessage: {
    marginTop: 15,
    alignItems: "center"
  },
  copyrightYear: {
    alignItems: "center"
  },
  brandName: {
    textTransform: "uppercase",
    color: theme.palette.current.footerBrandColor
  },
  title: {
    color: theme.palette.current.footerMenuItemColor,
    paddingTop: 25,
    paddingBottom: 15
  }
});
class Copyright extends React.Component {
  render() {
    const { classes, message } = this.props;
    return (
      <div className={classes.title}>
        <span className={classes.copyrightYear}>&copy; {message.year} </span>
        <b className={classes.brandName}> {message.brand} </b>
        <span className={classes.brandMessage}> {message.message} </span>
      </div>
    );
  }
}
export default withStyles(styles)(Copyright);
