import React, { PureComponent } from 'react';
import {  withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { Button, CircularProgress } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import Skeleton from 'react-loading-skeleton';


const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',

        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',

        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    coverPhoto: {
        minHeight: 100,
        background: theme.palette.current.myAccount.userProfile,
    },
    userDetailsImg: {
        display: 'flex',
        justifyContent: 'center',
    },
    userDetails: {
        // background:'#d3d3d3',
        fontFamily: 'GT-Walsheim-Pro-Medium',
        minHeight: 100,
        marginLeft: '10%',
        marginTop: -50,
        flexDirection: 'column',
        display: 'flex',
        fontSize: '0.8em',
    },
    imgUser: {
        width: '50%',
        borderRadius: '50%',
    },
    userName: {
        fontFamily: 'GT-Walsheim-Pro-Medium',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 30,
    },
    userShortBio: {
        overflow: 'hidden',
        textAlign: 'justify',
        padding: 10,
    },
    linkEditPoem: {
        textDecoration: 'none!important',
        color: theme.palette.current.invertedBtn.fontColor,
        fontFamily: 'GT-Walsheim-Pro-Bold',
        display: 'block',
    },
    linkEdit: {
        textDecoration: 'none!important',
        color: theme.palette.current.primaryColor,
    },
    subrow: {
        padding: '0px 10px 0px 10px ',
        textAlign: 'justify',
        justifyContent: 'center',
        marginBottom: 50,
        listStyle: 'none',
        '&:nth-child(odd)': {
            borderRadius: 3,
            background: theme.palette.current.editProfilePage.listColor,
            '&:nth-child(even)': {
                textAlign: 'justify',
                background: theme.palette.current.invertedColor,
                borderRadius: 5,
                backgroundColor: theme.palette.current.editProfilePage.listPrimaryColor,
            },
        },
    },
    circularLoader: {
        maxHeight: 100,
        width: 100,
        color: theme.palette.current.primaryColor,
        marginLeft: '50%',
        marginTop: 50,
        marginBottom: 50,
    },
    buttonEditProfile: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.invertedBtn.fontColor,
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.invertedBtn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '5px 5px',
        fontWeight: 600,
        display: 'flex',
        margin: 'auto',
        justifyContent: 'center',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    poemBody: {
        overflow: 'hidden',
    },
});
class MyAccount extends PureComponent {
    constructor() {
        super();
        this.state = {
            data: [],
            uid: '',
        };
    }

    componentDidMount() {
        const  Core  = this.props.core;
        const core = Core;
        const ApiRoutes = new core.ApiRoutes();
        const Http = new core.Http();
        const name =  localStorage.getItem('loggein_username');
        Http.post(ApiRoutes.getRoute('fetchUser'), {
            username: name,
        })
            .then((response) => response.data)
            .then((response) => {
                this.setState({
                    data: response.data,
                    uid: response.data.uid,
                });
                Http.post(ApiRoutes.getRoute('getUserPoems'), {
                    uid: this.state.uid,
                    post_per_page: 3,
                })
                    .then((poem) => poem.data)
                    .then((poem) => {
                        this.setState({
                            poemData: poem.data,
                        });
                    });
            });
    }

    render() {
        const { classes } = this.props;
        const {
            data, poemData,
        } = this.state;
        // const { name } = data;
        const { field_first_name } = data;
        const { field_last_name } = data;
        // const { badges_all } = data;
        // const { field_user_location } = data;
        const data_length = Object.keys(data).length;
        return (
            <>
                <Grid container>
                    {(data_length > 0)
                        ? (
                            <>
                                <Grid className={classes.coverPhoto} item xs={12} />
                                <Grid className={classes.userPicture} item xs={12}>
                                    <Grid className={classes.userDetails} xs={10}>
                                        <Grid className={classes.userDetailsImg} xs={12}>
                                            { data.picture
                                                ?  (
                                                    <img
                                                        className={classes.imgUser}
                                                        src={data.picture.uri}
                                                        alt="user Profile"
                                                    />
                                                )
                                                :    ''}
                                        </Grid>
                                        <Grid className={classes.userDetails3} xs={12}>
                                            <h3 className={classes.userName}>  {field_first_name}  {field_last_name} </h3>
                                            <Button variant="contained" className={classes.buttonEditProfile}>
                                                <Link to="/userEditProfile" className={classes.linkEdit}>
                                                    Edit Profile
                                                </Link>
                                            </Button>
                                            {poemData
                                                ? (
                                                    <div className={classes.mainContainerList}>
                                                        {poemData && Object.keys(poemData).map((content, i) => (

                                                            <div key={i} className={classes.subrow}>
                                                                <div className={classes.parentContent}>
                                                                    <div className={classes.imgPoetParent}>
                                                                        {/* <img
                                                        src={poemData[content].image}
                                                        alt="PoetImage"
                                                        className={classes.imgApi}
                                                    /> */}
                                                                    </div>
                                                                    <div className={classes.textTitle}><h3 className={classes.titlePoem}> {poemData[content].title}</h3>
                                                                        <p className={classes.poemBody}>
                                                                            {ReactHtmlParser(poemData[content].body_value)}
                                                                            <Link to={poemData[content].url} className={classes.linkEditPoem}> show more </Link>
                                                                        </p>
                                                                    </div>


                                                                </div>
                                                                <Divider variant="inset" component="li" className={classes.dividerList} />

                                                            </div>

                                                        ))}
                                                    </div>
                                                ) : <Skeleton height={500} />}
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </>
                        ) : <CircularProgress classes={{ colorPrimary: classes.circularLoader }} />}
                </Grid>
            </>
        );
    }
}
MyAccount.propTypes = {
    classes: PropTypes.object.isRequired,
    core: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyAccount);
