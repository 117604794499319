import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Map, GoogleApiWrapper, Marker, InfoWindow,
} from 'google-maps-react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import poetImage from '../../../../images/newPP-placeholder-min.jpg';


const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    mainContainer: {
        minHeight: 1000,
        marginBottom: 50,
        fontFamily: 'GT-Walsheim-Pro-Regular',
    },
    mainDescriptionCon: {
        maxWidth: '1140px !important',
        boxShadow: '2px 2px 20px 1.5px rgba(0,0,0,0.19)',
    },
    descriptionContent: {
        minHeight: 100,
        background: theme.palette.current.user.fieldBorder,
        borderRadius: 5,
        display: 'flex',
    },
    imgContainer: {
        maxHeight: 100,
    },
    imgEdits: {
        maxHeight: 100,
        width: '100%',
        display: 'inline-block',
    },
    descriptionLocation: {
        marginLeft: 50,
        minHeight: 100,
        maxWidth: 600,
    },
    otherLink: {
        color: theme.palette.current.mainMenuItemColor,
        textDecoration: 'none !important',
    },
});
class LocalPoetry extends PureComponent {
    constructor() {
        super();
        this.state = {
            data: [],
            activeMarker: {},
        };
    }

    componentDidMount() {
        const {  coredata } = this.props;
        const url = coredata.getCurrentPath();
        const  urlNew = url.substr(1);
        const  core  = coredata;
        const ApiRoutes = new core.ApiRoutes();
        const Http = new core.Http();
        Http.post(ApiRoutes.getRoute('getPoemByUrl'), {
            path: urlNew,
        })
            .then((res) => this.setState({
                data: res.data.data,
            }));
    }

    onMarkerClick = (props, marker) => this.setState({
        activeMarker: marker,
        showingInfoWindow: true,
    });


    render() {
        const { classes, google } = this.props;
        const mapStyles = {
            maxHeight: '60vh', width: '60%', marginBottom: 50, overflow: 'hidden',
        };
        const {
            data, showingInfoWindow, activeMarker,
        } = this.state;
        const { locations } = data;
        const { field_website } = data;
        const webUrl = data.field_website ? field_website.und[0].url : '';
        const titleWeb = data.field_website ? field_website.und[0].title : '';
        return (
            <>
                <div className={classes.container}>
                    <div className={classes.mainContainer}>
                        { data.length === 0
                            ? <Skeleton height={50} width={400}  />
                            : <h1> {data.title}</h1>}
                        {data.length === 0
                            ? <Skeleton height={100} width={1150} marginTop={50}  />
                            :     (
                                <Grid
                                    className={classes.mainDescriptionCon}
                                    item
                                    xs={12}
                                >

                                    <div className={classes.descriptionContent}>
                                        <Grid className={classes.widgetss} item xs={4}>
                                            <div className={classes.imgContainer}>

                                                <img
                                                    src={data.field_image ? data.field_image.und[0].uri : ''}
                                                    alt="groups-poetry"
                                                    className={classes.imgEdits}
                                                    onError={poetImage}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid className={classes.widgetss} item xs={8}>
                                            <div className={classes.descriptionLocation}> Location
                                                <span>
                                                    { locations ? locations.map((content, i) => (
                                                        <div key={i}>
                                                            <div> {content.street}<br />{content.city}, {content.province}</div>
                                                            <div className={classes.descriptionWebsite}>
                                                                visit us:  <span> <a href={webUrl} className={classes.otherLink}> {titleWeb} </a> </span>
                                                            </div>
                                                        </div>
                                                    )) : ''}
                                                </span>
                                            </div>
                                        </Grid>

                                    </div>
                                </Grid>
                            )}
                        <h1> View Map</h1>
                        <Map
                            google={google}
                            zoom={3}
                            style={mapStyles}
                            initialCenter={{ lat: 47.444, lng: -122.176 }}
                        >
                            <Marker
                                onClick={(props, marker, e) => this.onMarkerClick(props, marker, e, data)}
                                position={{
                                    lat: data.location ? data.location.latitude : '',
                                    lng: data.location ? data.location.longitude : '',
                                }}
                            />
                            <InfoWindow
                                marker={activeMarker}
                                visible={showingInfoWindow}
                            >
                                <div>
                                    <h4> {data.title}</h4>
                                    <span> {data.location ? data.location.city : ''} , {data.location ? data.location.country_name : ''}</span>
                                </div>
                            </InfoWindow>
                        </Map>
                    </div>
                </div>

            </>
        );
    }
}
LocalPoetry.propTypes = {
    classes: PropTypes.object.isRequired,
    coredata: PropTypes.object.isRequired,
    google: PropTypes.object.isRequired,
};
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDAnmJO4OKMofUzKq6B9yV7H-9VfIbP8s4',
})(withStyles(styles)(LocalPoetry));
