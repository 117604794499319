import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Poembadges from './badges/badges';
import Poetbio from './bio/bio';
import Poetinfo from './shortInformation/shortInformation';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    geomeheader: {
        background: theme.palette.current.topics.background,
        marginBottom: 20,

        borderRadius: 8,
        padding: 20,
        margin: '0 auto',
    },
    contentArea: {
        marginBottom: 25,
    },
    sidebar: {
        marginLeft: 'auto',
    },
});
class Poempoet extends React.PureComponent {
    render() {
        const {
            classes, poembadges, poetinfo, poetname, poetscope,
        } = this.props;

        return (
            <>
                <Grid className={`${classes.container}`} container>
                    <Grid item xs={12}>
                        <Grid container justify="center" className={classes.geomeheader} item xs={9}>
                            <Poetbio poetscope={poetscope} />
                            <Poembadges poembadges={poembadges} />
                        </Grid>
                        <Poetinfo poetinfo={poetinfo} poetname={poetname} />
                    </Grid>
                </Grid>
            </>
        );
    }
}
export default withStyles(styles)(Poempoet);
