import React from 'react';
import { Button } from '@material-ui/core';
import {  withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as actionTypes from '../../../reducers/auth/action.js';
import AddPoem from '../../pages/addPoem/desktop/index';
import Loginpopup from '../../pages/loginpopup/index';
import DrawerForm from '../../pages/addPoem/desktop/Form/DrawerForm';

const styles = (theme) => ({
    button: {
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '& a': {
            color: theme.palette.current.btn.fontColor,
            textDecoration: 'none !important',
        },
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        formHheader: {
            color: '#000',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        },
        [theme.breakpoints.down('lg')]: {
            padding: '10px 12px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '10px 27px',
        },
        textTransform: 'capitalize',

        fontWeight: 600,
        borderRadius: 8,
    },
    addpoembutton: {
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '& a': {
            color: theme.palette.current.btn.fontColor,
            textDecoration: 'none !important',
        },
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        [theme.breakpoints.down('lg')]: {
            padding: '10px 9px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '10px 21px',
        },
        textTransform: 'capitalize',

        fontWeight: 600,
        borderRadius: 8,
    },
    InvertedButton: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.invertedBtn.fontColor,
        '& a': {
            color: theme.palette.current.invertedBtn.fontColor,
            textDecoration: 'none !important',
        },
        [theme.breakpoints.down('lg')]: {
            padding: '10px 26px',
        },
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.invertedBtn.fontColor,
        },
        marginLeft: 20,
        textTransform: 'capitalize',
        padding: '10px 30px',
        fontWeight: 600,
        borderRadius: 8,
    },
    InvertedlogoutButton: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.invertedBtn.fontColor,
        '& a': {
            color: theme.palette.current.invertedBtn.fontColor,
            textDecoration: 'none !important',
        },
        [theme.breakpoints.down('lg')]: {
            padding: '10px 13px',
            marginLeft: '5px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '10px 20px',
            marginLeft: 10,
        },
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.invertedBtn.fontColor,
        },
        textTransform: 'capitalize',
        fontWeight: 600,
        borderRadius: 8,
    },
    alertboxx: {
        zIndex: '11111 !important',
    },
    usertitle: {
        textAlign: 'center',
        marginTop: 0,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        marginBottom: 0,
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 30,
        fontFamily: 'GT-Walsheim-Pro-Bold',
    },
    innerheight: {
        padding: 0,
        width: 600,
    },
    drawerPaper: {
        overflowX: 'auto',
        width: '45%',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center !important',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        // width:'500px'

    },
    [theme.breakpoints.down('lg')]: {
        padding: '10px 12px',
    },
    [theme.breakpoints.up('lg')]: {
        padding: '10px 27px',
    },
    textTransform: 'capitalize',
    fontWeight: 600,
    borderRadius: 8,
});
class UserMenu extends React.Component  {
    constructor() {
        super();
        this.state = {
            drawerOpen: false,
        };
    }

     handleDrawerClose = () => {
         this.setState({ drawerOpen: false });
     };

     handleDrawerToggleAdd = () => {
         this.props.showLoginDrawer();
         this.setState((state) => ({ drawerOpen: !state.drawerOpen }));
     }


handleAddPoemDrawerToggle = () => {
    this.setState((state) => ({ drawerOpen: !state.drawerOpen }));
}

render() {
    const {
        classes, core, userLogout, loginPopup, showLoginDrawer, hideLoginDrawer,
    } = this.props;
    const { drawerOpen } = this.state;
    const corelogin = core;
    return (
        <>
            {(corelogin.auth_token()) ? (
                <>
                    <Button className={classes.addpoembutton} onClick={this.handleAddPoemDrawerToggle}>
                        Add Poem
                    </Button>

                    <Drawer
                        className={classes.drawer}
                        variant="temporary"
                        anchor="left"
                        open={drawerOpen}
                        onClose={this.handleDrawerClose}

                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <DrawerForm />
                        <div className={classes.drawerHeader}>
                            <AddPoem Core={core} />
                        </div>
                    </Drawer>
                    <Button className={classes.InvertedlogoutButton}>
                        <Link to="/user">
                            My Account
                        </Link>
                    </Button>
                    <Button className={classes.InvertedlogoutButton} onClick={() => userLogout()}>
                        Log Out
                    </Button>
                </>
            )
                : (
                    <>
                        <Button
                            className={classes.button}
                            onClick={this.handleDrawerToggleAdd}
                        >
                            Add Poem
                        </Button>
                        <Button className={classes.InvertedButton} onClick={showLoginDrawer}>
                            Log In
                        </Button>
                        <Dialog
                            open={loginPopup.value}
                            onClose={hideLoginDrawer}
                            className={classes.alertboxx}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="lg"
                        >
                            <span className={classes.popupheight}>
                                <DialogContent className={classes.innerheight}>
                                    <Loginpopup handleDrawerToggle={this.handleDrawerToggle} />
                                </DialogContent>
                            </span>
                        </Dialog>
                    </>
                )}
        </>
    );
}
}
UserMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    core: PropTypes.object.isRequired,
    userLogout: PropTypes.func.isRequired,
    loginPopup: PropTypes.bool.isRequired,
    showLoginDrawer: PropTypes.func.isRequired,
    hideLoginDrawer: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    Core: state.Core,
    Header: state.Header,
    loginPopup: state.LoginPopup,
});

const mapDispatchToProps = (dispatch) => ({
    userLogout: () => dispatch({ type: actionTypes.USER_LOGOUT }),
    showLoginDrawer: () => dispatch({ type: actionTypes.LOGIN_DRAWER_TOGGLE, value: true }),
    hideLoginDrawer: () => dispatch({ type: actionTypes.LOGIN_DRAWER_TOGGLE, value: false }),
    dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserMenu));
