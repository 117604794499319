import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';


const styles = (theme) => ({
    introBlock: {
        marginTop: 20,
        padding: 0,
        borderRadius: 8,
        textAlign: 'left',
    },
    label: {
        fontSize: 30,
        textDecoration: 'underline',
        fontFamily: 'GT-Walsheim-Pro-Bold',
    },
    containRight: {
        background: theme.palette.current.topics.background,
        marginBottom: 20,
        marginLeft: 10,
        textAlign: 'center',
        borderRadius: 8,
        padding: '30px 0px',
    },
    containLeft: {
        background: theme.palette.current.topics.background,
        textAlign: 'center',
        padding: '30px 0px',
        borderRadius: 8,
        marginBottom: 20,
        marginRight: 10,
        fontFamily: 'GT-Walsheim-Pro-Light',
    },
    topiclink: {
        color: theme.palette.current.poem.block.fontColor,
        textDecoration: 'none !important',
    },
});
class Topics extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    getBlockContainder() {
        const { data } = this.props;
        const widgetData = data.content ? JSON.parse(data.content) : [];
        const topics = widgetData ?  this.getChunk(widgetData, 2) : '';
        return (
            <>
                {
                    topics
                        ? (
                            <div>
                                {topics.map((topicRow) => (
                                    <Grid
                                        key={JSON.stringify(topicRow)}
                                        container
                                        direction="row"
                                        alignItems="center"
                                    >
                                        {this.getTopicRow(topicRow)}
                                    </Grid>
                                ))}
                            </div>
                        ) : <Skeleton height={550} />
                }
            </>
        );
    }

  getChunk = (arr, chunkSize) => {
      const R = [];
      for (let i = 0, len = arr.length; i < len; i += chunkSize) {
          R.push(arr.slice(i, i + chunkSize));
      }
      return R;
  };

  getTopicRow(topicRow) {
      const { classes } = this.props;
      let index = 1;
      return topicRow.map((topic) => {
          index += 1;

          return (
              <Grid key={topic.link} item xs={6}>
                  <div
                      className={`${index % 2 ? classes.containRight : classes.containLeft}`}
                  >
                      <Link className={classes.topiclink} to={topic.link}>
                          <div>
                              <img src={topic.img} alt={topic.label} />
                          </div>
                          <div>{topic.label}</div>
                      </Link>
                  </div>
              </Grid>
          );
      });
  }

  render() {
      const { classes } = this.props;
      return (
          <>
              <div className={classes.introBlock}>
                  <h2 className={classes.label}>By Topic</h2>
                  {this.getBlockContainder()}
              </div>
          </>
      );
  }
}
export default withStyles(styles)(Topics);
