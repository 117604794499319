import React from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actionTypes from '../reducers/header/action.js';
import Desktopheader from './header/desktop';
import Mobileheader from './header/mobile';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        minHeight: {
            minHeight: 120,
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
        alignItems: 'center',
    },
    header: {
        borderColor: theme.palette.current.primaryColor,
        borderTop: '5px solid',
        paddingTop: 15,
        paddingBottom: 15,
    },
    minHeight: {
        minHeight: 120,
    },
    right: {
        textAlign: 'right',
    },
    marginLeftAuto: {
        marginLeft: 'auto',
    },
});

class Header extends React.PureComponent {
    componentDidMount() {
        const { loadHeader, loadHeaderMenu } = this.props;
        loadHeader();
        loadHeaderMenu();
    }

    render() {
        const { Core } = this.props;
        return (
            <Media query={{ minWidth: Core.breakpoints.values.md }}>
                {(matches) => (matches ? <Desktopheader /> : <Mobileheader {...this.props} />)}
            </Media>
        );
    }
}
Header.propTypes = {
    Core: PropTypes.object.isRequired,
    loadHeader: PropTypes.func.isRequired,
    loadHeaderMenu: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    Core: state.Core,
});
const mapDispatchToProps = (dispatch) => ({
    loadHeader: () => dispatch({ type: actionTypes.LOAD_HEADER }),
    loadHeaderMenu: () => dispatch({ type: actionTypes.LOAD_HEADER_MENU }),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));
