import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Poembody from './body/body';
import * as poemActionTypes from '../../../../reducers/poempage/action.js';
import * as userActionTypes from '../../../../reducers/user/action.js';
import Comments from './comments/index';
import Geome from './geome/index';
import Poempoet from './poet/index';
import Poetwidget from './widget/widget';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    contentArea: {
        marginBottom: 25,
    },
    sidebar: {
        marginLeft: 'auto',
    },
});
class Poemmobile extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            poemComment: [],
            currentID: [],
            page: 1,
            currentUser: [],
            currentUserName: '',
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const {
            url, getPoemByurl,
        } = this.props;
        const { page } = this.state;
        const currenturl = url;
        const newurl = currenturl.replace('/', '');
        getPoemByurl(newurl, page);
    }

    componentWillUnmount() {
        const { unmountPoemPage } = this.props;
        unmountPoemPage();
    }

    loadMore = () => {
        const { page } = this.state;
        let newPage = page;
        if (page < 5)  {
            newPage = page + 1;
            this.setState({
                page: newPage,
            }, () => {
                this.loadAllComments();
            });
        }
    }

    updateComment = (id, newCommnt) => {
        const { poemComment, currentID } = this.state;
        const nid = poemComment;
        const uid = currentID;
        const { updateComment } = this.props;
        const data = {
            cid: id,
            nid,
            uid,
            body: newCommnt,
        };
        updateComment(data);
    };

    deleteComment = (id) => {
        const {  deletePoemComment } = this.props;
        deletePoemComment(id);
    };

  loadAllComments = () => {
      const { Core, dispatch } = this.props;
      const ApiRoutes = new Core.ApiRoutes();
      const Http = new Core.Http();
      const { poemComment, page } = this.state;
      const { storeComments } = this.props;
      return  Http.post(ApiRoutes.getRoute('getAllComments'), {
          nid: poemComment,
          page,
      }).then((resp) => {
          dispatch({
              type: poemActionTypes.POEMPAGE_COMMENTS,
              value: [...Object.values(resp.data.data), ...storeComments],
          });
      });
  };

  render() {
      const { classes, Core, Poempage } = this.props;
      return (
          <>
              <Grid className={`${classes.container}`} container>
                  <Grid item xs={12}>
                      <Poembody
                          dataTitle={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_TITLE)}
                          dataCreated={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_DATE)}
                          dataImage={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_IMAGE)}
                          data={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_POEMS)}
                          poetName={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_POETNAME)}
                          poemid={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_NID)}
                          Core={Core}
                      />
                      <Geome
                          poetdata={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_POETS)}
                          rappersdata={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_RAPPERS)}
                          poemtags={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_TAGS)}
                          poetname={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_POETNAME)}
                          poetscope={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_SCOPE)}
                      />
                      <Poempoet
                          poembadges={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_POETDETAIL_BADGES)}
                          poembio={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_POETDETAIL)}
                          poetinfo={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_POETDETAIL)}
                          poetname={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_POETNAME)}
                          poetscope={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_SCOPE)}
                          poetimage={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_POETIMAGE)}
                      />
                      <Comments
                          comments={Core.getStateValueByType(Poempage, poemActionTypes.POEMPAGE_COMMENTS)}
                          createComment={this.createComment}
                          deleteComment={this.deleteComment}
                          updateComment={this.updateComment}
                          Core={Core}
                      />
                      <Poetwidget />
                  </Grid>
              </Grid>
          </>
      );
  }
}
Poemmobile.propTypes = {
    classes: PropTypes.object.isRequired,
    deletePoemComment: PropTypes.func.isRequired,
    updateComment: PropTypes.func.isRequired,
    Core: PropTypes.object.isRequired,
    storeComments: PropTypes.array,
    Poempage: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    getPoemByurl: PropTypes.func.isRequired,
};
Poemmobile.defaultProps = {
    storeComments: [],
};
const mapStateToProps = (state) => ({
    storeComments: state.Poempage.find((st) => st.type === poemActionTypes.POEMPAGE_COMMENTS).value,
    userName: state.User.find((st) => st.type === userActionTypes.USER_USERNAME).value,
});
const mapDispatchToProps = (dispatch) => ({
    getPoemByurl: (url, page) => dispatch({ type: poemActionTypes.LOAD_POEM_BY_URL, url, page }),
    unmountPoemPage: () => dispatch({ type: poemActionTypes.UNMOUNT_POEM_PAGE }),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Poemmobile));
