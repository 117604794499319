import * as actionTypes from './action.js';

const initialState = [
    {
        type: actionTypes.POEMPAGE_NID,
        value: null,
    },
    {
        type: actionTypes.POEMPAGE_TITLE,
        value: null,
    },
    {
        type: actionTypes.POEMPAGE_POETDETAIL_BADGES,
        value: [],
    },
    {
        type: actionTypes.POEMPAGE_IMAGE,
        value: null,
    },
    {
        type: actionTypes.POEMPAGE_POETIMAGE,
        value: null,
    },
    {
        type: actionTypes.POEMPAGE_DATE,
        value: [],
    },
    {
        type: actionTypes.POEMPAGE_POEMS,
        value: [],
    },
    {
        type: actionTypes.POEMPAGE_POETS,
        value: [],
    },
    {
        type: actionTypes.POEMPAGE_GEOME,
        value: null,
    },
    {
        type: actionTypes.POEMPAGE_TOPICS,
        value: null,
    },
    {
        type: actionTypes.POEMPAGE_RAPPERS,
        value: null,
    },
    {
        type: actionTypes.POEMPAGE_TAGS,
        value: null,
    },
    {
        type: actionTypes.POEMPAGE_POETDETAIL,
        value: null,
    },
    {
        type: actionTypes.POEMPAGE_POETNAME,
        value: null,
    },
    {
        type: actionTypes.POEMPAGE_COMMENTS,
        value: null,
    },
    {
        type: actionTypes.POEMPAGE_SCOPE,
        value: null,
    },
    {
        type: actionTypes.POEMPAGE_WIDGET_BOTTOM,
        value: null,
    },
];
const Poempage = (state = initialState, action) => {
    let OtherStates = [];
    let comments = [];
    switch (action.type) {
        case actionTypes.POEMPAGE_NID:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_TITLE:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_IMAGE:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_POETIMAGE:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_POETDETAIL_BADGES:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_DATE:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_POEMS:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_GEOME:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_POETS:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_RAPPERS:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_TAGS:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_POETDETAIL:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_POETNAME:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_COMMENTS:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_SCOPE:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.POEMPAGE_WIDGET_BOTTOM:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.DELETE_COMMENTS:
            OtherStates = state.filter((st) => st.type !== actionTypes.POEMPAGE_COMMENTS);
            comments = state.filter((st) => st.type === actionTypes.POEMPAGE_COMMENTS);
            comments = [{ ...comments[0], value: comments[0].value.filter((item) => item.cid !== action.value) }];
            return [
                ...OtherStates,
                ...comments,
            ];
        case actionTypes.UPDATE_COMMENTS:
            OtherStates = state.filter((st) => st.type !== actionTypes.POEMPAGE_COMMENTS);
            comments = state.filter((st) => st.type === actionTypes.POEMPAGE_COMMENTS);
            comments = [{
                ...comments[0],
                value: comments[0].value.map((item) => {
                    if (item.cid === action.value.cid) {
                        const items =  action.value;
                        return items;
                    }
                    return item;
                }),
            }];
            return [
                ...OtherStates,
                ...comments,
            ];
        case actionTypes.CREATE_COMMENTS:
            OtherStates = state.filter((st) => st.type !== actionTypes.POEMPAGE_COMMENTS);
            comments = state.filter((st) => st.type === actionTypes.POEMPAGE_COMMENTS);
            comments = { ...comments[0], value: [...comments[0].value, { ...action.value }] };
            return [
                comments, ...OtherStates,
            ];
        default:
            return state;
    }
};
export default Poempage;
