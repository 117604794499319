import React from 'react';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import ApiRoutes from '../../../api/Routes';
import Http from '../../../services/http';

const styles = (theme) => ({
    profileBlock: {
        background: theme.palette.current.primaryColor,
        marginTop: -100,
        textAlign: 'center',
        borderRadius: 8,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    signDesign: {
        [theme.breakpoints.up('lg')]: {
            borderLeft: '170px solid transparent',
            borderRight: '170px solid transparent',
        },
        [theme.breakpoints.down('md')]: {
            borderLeft: '147px solid transparent',
            borderRight: '147px solid transparent',
        },
        [theme.breakpoints.down('sm')]: {
            width: 0,
            height: 0,
            borderTop: '100px solid rgb(230,179,44)',
            borderLeft: '45vw solid transparent',
            borderRight: '46vw solid transparent',
            display: 'inline-block',
        },
        borderTop: `${'40px solid'} ${theme.palette.current.signUp.background}`,
        marginBottom: 45,
        position: 'relative',
    },
    spanImg: {
        position: 'absolute',
        top: -35,
        background: theme.palette.current.signUp.logo,
        borderRadius: '50%',
        overflow: 'hidden',
        width: 65,
        height: 60,
        textAlign: 'center',
        left: -25,
    },
    imgUser: {
        width: '65px',
        height: '60px',
        overflow: 'hidden',
    },
    imgFont: {
        fontSize: '65px',
    },
    shortBioUser: {
        fontSize: '15px',
        padding: '0 10px 10px 10px ',
        textAlign: 'justify',
    },
    locationUser: {
        paddingBottom: '10px',
    },
    sectionSignup: {
        textAlign: 'center',
        marginTop: 0,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        marginBottom: 0,
        paddingTop: 35,
        paddingBottom: 10,
        fontSize: 30,
        background: theme.palette.current.signUp.background,
        fontFamily: 'GT-Walsheim-Pro-Bold',
    },
});

class ProfileInfo extends  React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
        };
    }


    componentDidMount() {
        const http = new Http();
        const apiRoutes = new ApiRoutes();

        const name =  localStorage.getItem('loggein_username');
        if (name) {
            http.post(apiRoutes.getRoute('fetchUser'), {
                username: name,
            })
                .then((response) => response.data)
                .then((response) => {
                    this.setState({
                        data: response.data,
                    });
                });
        }
    }


    render() {
        const { classes } = this.props;
        const {
            data,
        } = this.state;
        const { name } = data;
        const { field_first_name } = data;
        const { field_last_name } = data;
        const data_length = Object.keys(data).length;
        return (
            <>
                {(data_length > 0) ? (
                    <div className={classes.profileBlock}>
                        <div>
                            <h5 className={classes.sectionSignup}>Hello {' '}
                                {field_first_name && field_last_name
                                    ? `${field_first_name} ${field_last_name}`
                                    :  name }
                            </h5>
                            <div className={classes.signDesign}>

                                <span className={classes.spanImg}>
                                    { data.picture

                                        ?  (
                                            <img
                                                className={classes.imgUser}
                                                src={data.picture.uri}
                                                alt="user Profile"
                                            />
                                        )
                                        : <AccountCircleRoundedIcon className={classes.imgFont} />}
                                </span>

                            </div>
                            <div className={classes.userInfo}>

                                <div className={classes.shortBioUser}> {data.field_bio} </div>
                            </div>

                        </div>
                    </div>
                ) : (

                    <Skeleton height={250} />

                )}
            </>
        );
    }
}
ProfileInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
};
export default (withStyles(styles)(ProfileInfo));
