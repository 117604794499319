/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Route,  Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Poemspage from './pages/poem/index.js';
import PoemsActionPage from './pages/poemaction/index.js';
import Home from './pages/home/index.js';
import Searchpage from './pages/search/index.js';
import PageContent from './pages/content/index.js';
import MyAccount from './common/myaccount/Index.js';
import EditProfile from './common/myaccount/IndexEdit.js';
import Map from './Map/Maps.js';
import UserPoem from './pages/content/indexUser.js';
import LocalPoetry from './pages/content/desktop/LocalPoetry.js';
import EditPoems from './pages/poem/indexEditPoem';
import AddPoem from './pages/addPoem/mobile/AddPoem.js';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    message: {
        textAlign: 'center',
    },
});
class Body extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        const { classes, ...others } = this.props;
        const { Core } = this.props;
        const cores = Core;
        const coredata = Core;
        return (
            <>
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => (
                            <Home {...others} />
                        )}
                    />
                    <Route
                        path={['/poems/*', '/content/*', '/famous-poems/*', '/poetry-terms/*', '/poetry-slams/*', '/groups/*']}
                        render={() => <Poemspage core={coredata} url={Core.getCurrentPath()} {...others} />}
                    />
                    <Route
                        exact
                        path={['/actions/*', '/resources/*', '/resource/*']}
                        render={() => <PoemsActionPage core={coredata} url={Core.getCurrentPath()} {...others} />}
                    />
                    <Route exact path="/editpoem/poems/:url" render={() => <EditPoems coredata={cores} {...others} />} />
                    <Route exact path="/map_new" render={() => <Map coredata={cores} {...others} />} />
                    <Route exact path="/userpoems/:name" render={(props) => <UserPoem coredata={cores} {...others} {...props}  />} />
                    <Route exact path="/local-poetry/*" render={(props) => <LocalPoetry coredata={cores} {...others} {...props}  />} />
                    <Route exact path="/search" render={() => <Searchpage coredata={cores} {...others} />} />
                    <Route exact path="/user" render={() => <MyAccount core={coredata} {...others} />} />
                    <Route exact path="/addpoem" render={() => <AddPoem core={coredata} {...others} />} />
                    <Route exact path="/userEditProfile" render={() => <EditProfile core={coredata} {...others} />} />
                    <Route exact path={['/*', '/theme/*']} render={() => <PageContent core={coredata} {...others} />} />
                </Switch>
            </>
        );
    }
}

Body.propTypes = {
    classes: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
};

export default withStyles(styles)(Body);
