/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import APP_CONFIG from '../../../../../config';

const styles = (theme) => ({
    listSearch: {
        '& div ul ': {
            listStyle: 'none !important',
            fontSize: '1rem',
            maxHeight: '75px',
            overflow: 'scroll',
            overflowX: 'hidden',
            color: theme.palette.current.poem.block.fontColor,
            paddingLeft: '0',
            position: 'absolute',
            background: theme.palette.current.invertedColor,
            width: '38%',
            fontFamily: 'GT-Walsheim-Pro-Regular !important',
        },
        '& .MuiFormControl-fullWidth': {
            width: '70%',
        },
        '&>div>input[type=text]': {
            width: '10%',
            border: '0',
            height: '1.1875em',
            padding: '6px 0 7px',
            minWidth: '0',
            outline: 'none',
            borderBottom: '1px solid ',
            borderBottomColor: theme.palette.current.poem.block.borderUnderColor,
            fontSize: '1rem',
            minHeight: '1em',
            '&:hover': {
                borderBottom: '2px solid ',
                borderColor: theme.palette.current.poem.block.borderColor,

            },
            '&::placeholder': {
                fontFamily: 'GT-Walsheim-Pro-Regular !important',
                fontSize: '0.75rem',
                color: theme.palette.current.placeholderColor,
            },

        },
        '& label': {
            color: theme.palette.current.labelColor,
            fontSize: '0.75rem',
            minHeight: '1em',
        },


    },
});


class TagPoem extends React.Component {
    constructor() {
        super();
        this.state = {
            value: '',
            suggestions: [],
        };
    }


    alltags = (event, value) => {
        this.setState({
            value,
        });
        const tags = {
            tag: value,
        };
        let datanew = null;
        const URL = `${APP_CONFIG.API_BASE_URL}${APP_CONFIG.API_ENDPOINT}${'/destm/getAllPoemTags'}`;
        fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tags),
        })
            .then((res) => res.json())
            .then((res) => {
                datanew = Object.values(res.data);
                this.setState({
                    suggestions: datanew,
                });
            });
    };

    handleChange = (event, value) => {
        const {
            poemData, setPoemData,
        } = this.props;
        let data = [];
        data = value.map((row) => (row.name));
        setPoemData({
            ...poemData,
            tags: data.join(','),
            display_data: value,
        });
    };


    render() {
        const { suggestions } = this.state;
        const { classes, poemData } = this.props;

        return (

            <div className={classes.listSearch}>
                <label htmlFor="tag" className={classes.labels}> Select a Tag </label>
                <div style={{ width: 70 }} />
                <Autocomplete
                    multiple
                    id="tags-standard"
                    onChange={(e, value) => { this.handleChange(e, value); }}
                    onInputChange={(e, value) => this.alltags(e, value)}
                    options={suggestions}
                    getOptionLabel={(option) => option.name}
                    loadingText="Loading"
                    value={poemData.display_data}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                        />
                    )}
                />
            </div>
        );
    }
}
TagPoem.propTypes = {
    classes: PropTypes.object.isRequired,
    setPoemField: PropTypes.func.isRequired,
    poemData: PropTypes.object.isRequired,
    setPoemData: PropTypes.func.isRequired,
};
export default withStyles(styles)(TagPoem);
