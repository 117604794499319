import React from 'react';
import './App.css';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import theme from './theme/main';
import Header from './components/header';
import Footer from './components/footer';
import Body from './components/body';

function App(props) {
    return (
        <MuiThemeProvider theme={theme}>
            <div className="constainer-main-app">
                <Router>
                    <Header />
                    <Body {...props} />
                    <Footer {...props} />
                </Router>
            </div>
        </MuiThemeProvider>
    );
}
const mapStateToProps = (state) => ({ ...state });
export default connect(mapStateToProps)(App);
