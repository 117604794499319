import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  container: {
    [theme.breakpoints.down ('sm')]: {
      maxWidth: theme.palette.current.container.maxWidth.downSmall,
      margin: '0 auto',
    },
    [theme.breakpoints.down ('md')]: {
      maxWidth: theme.palette.current.container.maxWidth.downMedium,
      margin: '0 auto',
      width: '90%',
    },
    [theme.breakpoints.up ('md')]: {
      maxWidth: theme.palette.current.container.maxWidth.upMedium,
      margin: '0 auto',
    },
    [theme.breakpoints.up ('lg')]: {
      maxWidth: theme.palette.current.container.maxWidth.upLarge,
      margin: '0 auto',
    },
    position: 'relative',
    minHeight: 500,
    [theme.breakpoints.down ('sm')]: {
      minHeight: 380,
    },
  },
  slider: {
    backgroundSize: 'cover!important',
    backgroundColor: '#000',
    transition: 'all 0.3s ease',
  },
  absoluteSlider: {
    position: 'absolute',
    width: 400,
    minHeight: 250,
    color: theme.palette.current.slider.widgetFontColor,
    padding: 20,
    bottom: 50,
    left: 0,
    background: theme.palette.current.slider.widgetBackground,
    [theme.breakpoints.down ('md')]: {
      left: 'unset',
      width: '100%',
      padding: 0,
      minHeight: '100%',
      display: 'inline-block',
      bottom: 0,
    },
  },
  sliderTitle: {
    fontSize: '3em',
    marginBottom: 10,
    fontFamily: 'GT-Walsheim-Pro-Light',
    [theme.breakpoints.down ('md')]: {
      paddingTop: '20%',
      textAlign: 'center',
      fontSize: '2.5em',
    },
  },

  sliderDescription: {
    marginBottom: 10,
    fontFamily: 'GT-Walsheim-Pro-Regular',
    [theme.breakpoints.down ('md')]: {
      textAlign: 'center',
    },
  },
  dot: {
    width: 20,
    marginRight: 5,
    height: 20,
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'inline-block',
    background: theme.palette.current.slider.dotInactive,
  },
  dotActive: {
    background: `${theme.palette.current.slider.dotActive}!important`,
  },
  navigation: {
    [theme.breakpoints.down ('md')]: {
      textAlign: 'center',
    },
  },
  img: {
    display: 'none',
  },
});
class Slider extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      curentSlide: {},
      imgLoading: true,
    };
  }
  componentDidMount () {
    const {data} = this.props;
    this.setState ({
      curentSlide: data[0],
    });
    this.autoNavigate ();
  }
  handleImageLoaded () {
    setTimeout (() => {
      this.setState ({imgLoading: false});
    }, 100);
  }
  navigate (selectedSlide) {
    this.setState ({curentSlide: selectedSlide, imgLoading: true});
  }
  autoNavigate (selectedSlide) {
    const {data} = this.props;
    const {curentSlide} = this.state;
    data.map (nav => {
      let count = 0;
      if (data.indexOf (curentSlide) < data.length - 1) {
        count++;
      } else {
        count = 0;
      }
      return setTimeout (() => {
        this.setState ({curentSlide: data[count], imgLoading: true});
      }, 4000);
    });
    return setTimeout (() => {
      this.autoNavigate ();
    }, 4000);
  }
  getNavigate () {
    const {data, classes} = this.props;
    const {curentSlide} = this.state;
    return (
      <React.Fragment>
        {data.map (nav => {
          const isActiveClass = JSON.stringify (nav) ===
            JSON.stringify (curentSlide)
            ? classes.dotActive
            : null;
          return (
            <span
              key={JSON.stringify (nav)}
              title={nav.title}
              onClick={() => {
                this.navigate (nav);
              }}
              className={`${classes.dot} ${isActiveClass}`}
            />
          );
        })}
      </React.Fragment>
    );
  }
  getSliderBlock = curentSlide => {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <div
          className={classes.sliderTitle}
          dangerouslySetInnerHTML={{__html: curentSlide.content.title}}
        />
        <div className={classes.sliderDescription}>
          {curentSlide.content.content}
        </div>
        <div className={classes.navigation}>{this.getNavigate ()}</div>
      </React.Fragment>
    );
  };
  getCurrentSlide = () => {
    const {classes} = this.props;
    const {curentSlide, imgLoading} = this.state;
    const linearGradient =
      'linear-gradient(to right, #272727 4%, #656161 25%, #cfd0a8 36%)';
    return (
      <React.Fragment>
        <div
          title={curentSlide.content.title.replace (/(<([^>]+)>)/gi, '')}
          style={{
            opacity: imgLoading ? 0.7 : 1,
            background: imgLoading ? linearGradient : `url(${curentSlide.url})`,
          }}
          className={classes.slider}
        >
          <img
            className={classes.img}
            src={curentSlide.url}
            onLoad={() => {
              this.handleImageLoaded ();
            }}
            alt={curentSlide.content.title.replace (/(<([^>]+)>)/gi, '')}
          />
          <Grid className={`${classes.container}`} container justify="center">
            <div
              style={{opacity: imgLoading ? 0.5 : 1}}
              className={classes.absoluteSlider}
            >
              {this.getSliderBlock (curentSlide)}
            </div>
          </Grid>
        </div>
      </React.Fragment>
    );
  };
  render () {
    const {curentSlide} = this.state;
    return (
      <React.Fragment>
        {Object.keys (curentSlide).length > 0 ? this.getCurrentSlide () : null}
      </React.Fragment>
    );
  }
}
export default withStyles (styles) (Slider);
