import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
	heading: {
		fontFamily: 'GT-Walsheim-Pro-Bold',
		fontSize: 23
	},
	poemabout: {
		background: theme.palette.current.footerBrandColor,
		padding: 20,
		borderRadius: 10,
		display: 'flex'
	},
	chip: {
		margin: 10,
		padding: 20,
		borderRadius: '10px !important'
	},
	poembadgess: {
		textAlign: 'left',
		paddingLeft: 20,
		'& h1': {
			marginBottom: 0,
			marginTop: 0
		}
	}
});
class Poembadges extends React.Component {
	render() {
		const { classes , poembadges } = this.props;
		console.log(poembadges)
		// console.log(poembadges, 'badges');
		return (
			<React.Fragment>
				<Grid container>
					<Grid className={classes.poemabout} item xs={12}>
						<Grid className={classes.poembadgess}>
							<h1 className={classes.heading}> This Poet's Badges</h1>
							{ poembadges && Object.keys(poembadges).map((key, i) => (
                                    (
                                        <img
                                            className={classes.imgUserBadges}
                                            src={poembadges[key].image}
                                            alt="user badges"
                                        />
                                    )
                                ))}
						</Grid>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}

export default (withStyles(styles)(Poembadges));

