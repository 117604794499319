import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../../alert/alert';
import * as actionTypes from '../../../../reducers/user/action';
import bag from '../../../../images/icon.svg';
import PasswordFunction from './PasswordFunction';


const styles = (theme) => ({
    mainContainer: {
        maxWidth: '60%',
        position: 'relative',
        marginBottom: 100,
        left: '20%',
        borderRadius: '2%',
        minHeight: 'auto',
    },
    txtFieldHandle: {
        height: '275px',
        width: '75%',
        position: 'absolute',
        top: 0,
        left: '25%',
    },
    circularLoader: {
        color: theme.palette.current.primaryColor,
        marginLeft: 600,
        marginTop: 50,
    },
    updateProfile: {
        height: '35px',
        textAlign: 'center',
        color: theme.palette.current.btn.fontColor,
        background: theme.palette.current.btn.primaryColor,
        fontWeight: '600',
        borderRadius: '8px',
        margin: '10px 0px 0px 50px ',
        textTransform: 'capitalize',
        width: '100px',
        textDecoration: 'none',
        fontSize: '0.625rem',
        fontFamily: 'GT-Walsheim-Pro-Light',
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
    },
    delProfile: {
        height: '35px',
        textAlign: 'center',
        color: theme.palette.current.invertedBtn.fontColor,
        background: theme.palette.current.invertedBtn.primaryColor,
        fontWeight: '600',
        borderRadius: '8px',
        textTransform: 'capitalize',
        margin: '10px 0px 0px 20px ',
        width: '100px',
        textDecoration: 'none',
        fontSize: '0.625rem',
        fontFamily: 'GT-Walsheim-Pro-Light',
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.invertedBtn.fontColor,
        },
    },
    formField: {
        '& input[type="date"]::-webkit-calendar-picker-indicator': {
            color: 'transparent',
            opacity: '1',
            background: `url(${bag}) no-repeat`,
            backgroundSize: '100% 100%',
            height: '20px',
            borderWidth: 'thin',
        },
    },
    textField: {
        marginLeft: 20,
        minWidth: '45%',
        margin: '35px  0 0px 0',
    },
    imageEdit: {
        minHeight: '270px',
        maxWidth: '25%',
    },
    imgFont: {
        minHeight: '250px',
        fontSize: '125px',
        marginLeft: '25%',
        width: '150px',
    },
    imgUser: {
        maxHeight: '300px',
        marginTop: 20,
        marginLeft: '15%',
        maxWidth: '200px',
        overflow: 'hidden',
        borderRadius: '100%',
    },
    rootSelect: {
        width: '50%',
    },

    txtField: {
        marginTop: '40px',
        marginLeft: 50,
    },
    txtFieldCode: {
        left: 20,
        width: '45%',
        marginTop: '40px',
    },
    txtFieldBirthday: {
        width: '45%',
        marginTop: '30px',
        left: 50,
        '&:helping:': {
            color: theme.palette.current.user.invalidColor,
        },
    },
    labelRoot: {
        fontSize: '20px',
    },
    input: {
        display: 'none',
    },
    input2: {
        height: 100,
    },
    bioTxt: {
        minWidth: '92%',
        left: 50,
        marginTop: 40,
    },
    saveEdits: {
        height: '50px',
        maxWidth: '150px',
        margin: '30px 0px 10px 45%',
        color: theme.palette.current.btn.fontColor,
        background: theme.palette.current.btn.primaryColor,
        fontWeight: '600',
        borderRadius: '8px',
        textTransform: 'capitalize',
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
    },
    alert: {
        minWidth: 80,
        padding: 15,
        marginBottom: 20,
        border: '1px solid transparent',
        borderRadius: 3,
        '&  p': {
            padding: 0,
            margin: 0,
        },
        ' & button': {
            position: ' relative',
            float: 'right',
            padding: '0',
            border: 0,
            cursor: 'pointer',
            background: 0,
            fontSize: 21,
            lineHeight: 1,
            fontWeight: 'bold',
            opacity: '0.4',
        },
    },
    alertSuccess: {
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
    },
    alertFail: {
        color: theme.palette.current.btn.primaryColor,
        background: theme.palette.current.btn.fontColor,
    },
});
class EditProfile extends PureComponent {
    constructor() {
        super();
        this.state = {
            data: [],
            password: '',
            confirm_password: '',
            media_file: '',
            open: false,
            successSave: false,
            failSave: false,
            countryData: [],
            first_name: '',
            last_name: '',
            user_name: '',
            user_mail: '',
            field_bio: '',
            country: '',
            postal_code: '',
            dob: '',
            uid: '',
            emailError: '',
            nameError: '',
            birthdayError: '',
            successDelete: false,
        };
    }

    componentDidMount() {
        const { getUserData } = this.props;
        const name = localStorage.getItem('loggein_username');
        getUserData(name);
    }

    componentDidUpdate() {
        const {
            userName, lastName, firstName, fieldBio, postalCode, userId, userCountryData, userMail, birthDay,
        } = this.props;
        const {
            first_name, last_name, user_name, field_bio,  postal_code, countryData, user_mail, dob,
        } = this.state;
        this.setState({ uid: userId });
        if (!first_name) {
            this.setState({ first_name: firstName });
        }
        if (!last_name) {
            this.setState({ last_name: lastName });
        }
        if (!user_name) {
            this.setState({ user_name: userName });
        }
        if (!user_mail) {
            this.setState({ user_mail: userMail });
        }
        if (!dob) {
            this.setState({ dob: birthDay });
        }
        if (!field_bio) {
            this.setState({ field_bio: fieldBio });
        }
        if (!postal_code) {
            this.setState({ postal_code: postalCode });
        }
        if (countryData.length === 0) {
            this.setState({ countryData: userCountryData });
        }
    }

  handleInputPhoto = (event) => {
      const { Core, dispatch } = this.props;
      const { uid } = this.state;
      const ApiRoutes = new Core.ApiRoutes();
      const formdata = new FormData();
      formdata.append('mediafile', event.target.files[0]);
      formdata.append('uid', uid);
      fetch(ApiRoutes.getRoute('uploadProfilePicture'), {
          method: 'POST',
          body: formdata,
      })
          .then((res) => res.json())
          .then((res) => {
              dispatch({
                  type: 'USER_PICTURE',
                  value: res.data.uri,
              });
              if (res.status === true) {
                  this.setState({
                      open: true,
                      successSave: true,
                  });
              } else if (res.status === false) {
                  this.setState({
                      fail: true,
                  });
              } else {
                  //   hi
              }
          });
  };

  handleDeletePhoto = () => {
      const { Core } = this.props;
      const ApiRoutes = new Core.ApiRoutes();
      const Http = new Core.Http();
      const { uid } = this.state;
      Http.post(ApiRoutes.getRoute('removeUserProfilePicture'), {
          uid,
      }).then((response) => {
          if (response.status === 1) {
              this.setState({
                  open: true,
                  successDelete: true,
              });
          } else {
              this.setState({
                  fail: true,
              });
          }
      });
  };

  handleConfirmPassword = (e) => {
      const { password, confirm_password } = this.state;
      const { name, value } = e.target;
      this.setState({ [name]: value });
      if (password === confirm_password) {
          console.log('password not matched');
      } else {
          console.log('password matched');
      }
  };

  validate = () => {
      const { user_name, user_mail, dob } = this.state;
      let nameError = '';
      let birthdayError = '';
      let emailError = '';


      if (!dob) {
          birthdayError = 'Provide Birth Date';
      }
      if (!user_name) {
          nameError = 'Name cannot be blank';
      }
      if (!/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/i.test(user_mail)) {
          emailError = 'Please Provide E-mail Address';
      }

      if (birthdayError || nameError || emailError) {
          this.setState({ birthdayError, nameError, emailError });
          return false;
      }
      this.setState({ success: 'Profile successfully updated' });
      return true;
  };

  handleClose=(event, reason) => {
      if (reason === 'clickaway') {
          return;
      }

      this.setState({
          open: false,
          successSave: false,
          successDelete: false,
      });
  }

  handleSubmitInput = () => {
      const {
          first_name, last_name, user_name, user_mail, field_bio, country, dob, postal_code, uid,  pass, confirm_pass,
      } = this.state;
      const isValid = this.validate();
      if (isValid) {
          this.setState({

              emailError: '',
              nameError: '',
              birthdayError: '',

          });
      }
      if ((typeof confirm_pass === 'undefined' && confirm_pass === '') || (pass !== confirm_pass)) {
          const errormsg1 = 'Password did not match';
          return console.log(errormsg1);
      }
      const { updateUserData } = this.props;
      const data = {
          uid,
          account: {
              name: user_name,
              mail: user_mail,
              pass,
              dob,
              confirm_pass,
              first_name,
              postal_code,
              last_name,
              field_bio,
              country,
          },
      };
      updateUserData(data);
  };


  handleChange = (event) => {
      const { name, value } = event.target;
      this.setState({ [name]: value });
  };


  render() {
      const {
          countryData, first_name, last_name, user_name, user_mail, field_bio, country, postal_code,
      } = this.state;
      const {
          classes, User, Core, userPicture,
      } = this.props;
      const {
          password, confirm_password,   dob, failSave, successSave, nameError, emailError, birthdayError, successDelete,
      } = this.state;
      return (
          <div className={classes.mainContainer}>
              <Alert />
              <Snackbar open={successSave} autoHideDuration={3000} onClose={this.handleClose}>
                  <span variant="outlined" className={`${classes.alert} ${classes.alertSuccess}`}>
                      Profile Picture Uploaded Successfully
                      <button type="button" onClick={this.handleClose} className={classes.closeAlert}>  &nbsp; x  </button>
                  </span>
              </Snackbar>
              <div className={classes.imageEdit}>
                  {userPicture ? (
                      <img
                          className={classes.imgUser}
                          src={Core.getStateValueByType(User, 'USER_PICTURE')}
                          alt="user Profile"
                      />
                  ) : (
                      <AccountCircleRoundedIcon className={classes.imgFont} />
                  )}
                  <label htmlFor="contained-button-file">
                      <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={this.handleInputPhoto}
                      />
                      <Button
                          variant="contained"
                          component="span"
                          className={classes.updateProfile}
                      >
                          Edit Picture
                      </Button>
                  </label>

                  <Button
                      variant="contained"
                      className={classes.delProfile}
                      onClick={this.handleDeletePhoto}
                  >
                      Delete Picture
                  </Button>
                  <Snackbar open={successDelete} autoHideDuration={2000} onClose={this.handleClose}>
                      <span variant="outlined" className={`${classes.alert} ${classes.alertFail}`}>
                          Profile Picture Deleted Successfully
                          <button type="button" onClick={this.handleClose} className={classes.closeAlert}>  &nbsp; x  </button>
                      </span>
                  </Snackbar>
              </div>

              <div className={classes.txtFieldHandle}>
                  <TextField
                      // label="FirstName"
                      variant="outlined"
                      name="first_name"
                      required
                      value={first_name}
                      onChange={this.handleChange}
                      className={classes.textField}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                          classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                          },
                      }}
                  />
                  <TextField
                      name="last_name"
                      value={last_name}
                      onChange={this.handleChange}
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                          classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                          },
                      }}
                  />

                  <br />
                  <TextField
                      label="Username"
                      name="user_name"
                      value={user_name}
                      required
                      onChange={this.handleChange}
                      helperText={nameError}
                      FormHelperTextProps={{ classes: { root: classes.helping } }}
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                          classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                          },
                      }}
                  />
                  <TextField
                      label="E-mail"
                      name="user_mail"
                      value={user_mail}
                      required
                      onChange={this.handleChange}
                      variant="outlined"
                      helperText={emailError}
                      FormHelperTextProps={{ classes: { root: classes.helperText } }}
                      className={classes.textField}
                      margin="normal"
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                          classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                          },
                      }}
                  />
                  <br />
                  <PasswordFunction
                      label="Password"
                      name="password"
                      variant="outlined"
                      value={password}
                      onChange={this.handleChange}
                      className={classes.textField}
                  />
                  <PasswordFunction
                      label="Confirm Password"
                      name="confirm_password"
                      variant="outlined"
                      value={confirm_password}
                      onChange={this.handleConfirmPassword}
                      className={classes.textField}
                  />
              </div>

              <TextField
                  label="Bio"
                  name="field_bio"
                  value={field_bio}
                  onChange={this.handleChange}
                  variant="outlined"
                  className={classes.bioTxt}
                  InputProps={{ classes: { input: classes.input2 } }}
                  InputLabelProps={{
                      classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                      },
                  }}
              />
              <FormControl variant="outlined" className={classes.rootSelect}>
                  <Select
                      onChange={this.handleChange}
                      name="country"
                      input={(
                          <TextField
                              variant="outlined"
                              label="Country"
                              select
                              value={country}
                              name="country"
                              id="outlined-age-always-notched"
                              className={classes.txtField}
                              InputLabelProps={{
                                  classes: {
                                      root: classes.labelRoot,
                                      focused: 'none',
                                  },
                              }}
                          />
                      )}
                  >
                      <MenuItem value="default" disable="true">
                          Select Country
                      </MenuItem>
                      {Object.keys(countryData).map((key, index) => (
                          <MenuItem key={index} value={key}>
                              {countryData[key]}
                          </MenuItem>
                      ))}
                  </Select>
              </FormControl>


              <TextField
                  label="Postal Code"
                  name="postal_code"
                  value={postal_code}
                  onChange={this.handleChange}
                  variant="outlined"
                  className={classes.txtFieldCode}
                  margin="normal"
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                      classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                      },
                  }}
              />

              <div className={classes.formField}>
                  <TextField
                      type="date"
                      name="dob"
                      variant="outlined"
                      FormHelperTextProps={{ classes: { root: classes.helperText } }}
                      helperText={birthdayError}
                      className={classes.txtFieldBirthday}
                      onChange={this.handleChange}
                      value={dob}
                      InputLabelProps={{
                          classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                          },
                      }}
                  />
              </div>

              <Button
                  variant="contained"
                  className={classes.saveEdits}
                  onClick={this.handleSubmitInput}
              >
                  Save Changes
                  <Snackbar successSave={successSave} autoHideDuration={3000} onClose={this.handleClose}>
                      <span variant="outlined" className={`${classes.alert} ${classes.alertSuccess}`}>
                          Information saved Successfully
                          <button type="button" onClick={this.handleClose} className={classes.closeAlert}>  &nbsp; x  </button>
                      </span>
                  </Snackbar>
                  <Snackbar failSave={failSave} autoHideDuration={6000}>
                      <span variant="outlined" severity="success">
                          Error While Saving Updation
                      </span>
                  </Snackbar>
              </Button>
          </div>
      );
  }
}
EditProfile.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    Core: PropTypes.object.isRequired,
    User: PropTypes.array.isRequired,
    userPicture: PropTypes.string,
    getUserData: PropTypes.func.isRequired,
    updateUserData: PropTypes.func.isRequired,
};
EditProfile.defaultProps = {
    userPicture: [],
};

const mapStateToProps = (state) => ({
    userPicture: state.User.find((st) => st.type === actionTypes.USER_PICTURE).value,
    userName: state.User.find((st) => st.type === actionTypes.USER_USERNAME).value,
    lastName: state.User.find((st) => st.type === actionTypes.USER_LAST_NAME).value,
    firstName: state.User.find((st) => st.type === actionTypes.USER_FIRST_NAME).value,
    userMail: state.User.find((st) => st.type === actionTypes.USER_MAIL).value,
    fieldBio: state.User.find((st) => st.type === actionTypes.USER_BIO).value,
    UserCountry: state.User.find((st) => st.type === actionTypes.USER_COUNTRY).value,
    postalCode: state.User.find((st) => st.type === actionTypes.USER_POSTAL_CODE).value,
    birthDay: state.User.find((st) => st.type === actionTypes.USER_BIRTHDAY).value,
    userId: state.User.find((st) => st.type === actionTypes.USER_UID).value,
    userCountryData: state.User.find((st) => st.type === actionTypes.USER_COUNTRY_DATA).value,
});

const mapDispatchToProps = (dispatch) => ({
    getUserData: (name) => dispatch({ type: actionTypes.LOAD_USER_DATA, userName: name }),
    updateUserData: (data) => dispatch({ type: actionTypes.UPDATE_USER_DATA, payload: data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditProfile));