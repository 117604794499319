export const LOAD_USER_DATA = 'LOAD_USER_DATA';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_IMAGE = 'UPDATE_USER_IMAGE';

export const USER_FIRST_NAME = 'USER_FIRST_NAME';
export const USER_LAST_NAME = 'USER_LAST_NAME';
export const USER_USERNAME = 'USER_USERNAME';
export const USER_MAIL = 'USER_MAIL';
export const USER_BIO = 'USER_BIO';
export const USER_POSTAL_CODE = 'USER_POSTAL_CODE';
export const USER_COUNTRY = 'USER_COUNTRY';
export const USER_BIRTHDAY = 'USER_BIRTHDAY';
export const USER_UID = 'USER_UID';
export const USER_PICTURE = 'USER_PICTURE';
export const USER_COUNTRY_DATA = 'USER_COUNTRY_DATA';
