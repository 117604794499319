import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Footericon from '../../../images/footerhome.png';

const styles = (theme) => ({
    introBlock: {
        background: theme.palette.current.introBlock.background,
        marginTop: 20,

        color: theme.palette.current.invertedColor,
        borderRadius: 8,

        fontFamily: 'GT-Walsheim-Pro-Regular',
    },
    button: {
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '10px 30px',
        fontWeight: 600,
        borderRadius: 8,
        fontFamily: 'GT-Walsheim-Pro-Medium',
    },
    text: {
        fontSize: 25,
    },
    butnlink: {
        textDecoration: 'none',
    },
    widgetinfo: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.footerBrandColor,
        marginBottom: 20,
        borderRadius: 8,

        margin: '0 auto',
    },
    poemabout: {
        background: theme.palette.current.footerBrandColor,
        padding: 20,
        borderRadius: 10,
    },
    span: {
        color: theme.palette.current.invertedBtn.fontColor,
        fontFamily: 'GT-Walsheim-Pro-Regular',
    },
    widgetsection: {
        display: 'inline-flex',
    },

    poemhome: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
        fontWeight: 600,
    },
    heading: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        textAlign: 'left',
        display: 'inline',
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.current.footerBrandColor,
        color: theme.palette.current.footerBrandColor,
    },
});
class Needtotalk extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        const { classes, data } = this.props;
        const widgetData = data ? JSON.parse(data.content) : '';
        return (
            <>

                <Grid className={classes.widgetinfo} item xs={9}>
                    <Grid className={classes.widgetsection} item xs={12}>
                        <Grid item xs={4}>
                            <img className={classes.dummyimage} src={Footericon} alt="" />
                        </Grid>
                        <Grid className={classes.widgetss} item xs={8}>
                            <div className={classes.introBlock}>
                                {
                                    widgetData
                                        ?                                                                              (
                                            <>
                                                <Link className={classes.butnlink} to={widgetData.btnLink}><h1 className={classes.heading}>{widgetData.heading} </h1></Link>
                                                <p className={classes.text}>{widgetData.content}<span className={classes.span}>{widgetData.span}</span></p>
                                            </>
                                        )
                                        : null
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Grid>


            </>
        );
    }
}
Needtotalk.propTypes = {
    data: PropTypes.shape({
        content: PropTypes.string,
    }).isRequired,
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Needtotalk);
