import React,  { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Http_ from '../../../../../services/http';
import ApiRoute from '../../../../../api/Routes';
import Input from '../View/Input';
import Options from './Options';
import useStyles from '../useStyles';
import * as ADDPOEM from '../../../../../reducers/addpoem/constants';

const Form = (props) => {
    const classes = useStyles();
    const { Core } = props;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [myUid, setMyUid] = useState('');
    const {
        isLoaded, getPoemData, apiData,
    } = props;
    const [message, setMessage] = useState('');
    const [poemData, setPoemData] = useState(getPoemData());
    const [mediaData, setMediaData] = useState({
        fid: '',
        filename: '',
    });

    useEffect(() => {
        const ApiRoutes = new ApiRoute();
        const Http = new Http_();
        const name =  localStorage.getItem('loggein_username');
        if (name) {
            Http.post(ApiRoutes.getRoute('fetchUser'), {
                username: name,
            })
                .then((response) => response.data)
                .then((response) => {
                    setMyUid(response.data.uid);
                });
        }
    }, []);
    const setPoemField = (field, data) => {
        setPoemData({
            ...poemData,
            [field]: data,
        });
    };

    const clearInitialState = () => {
        setPoemData(getPoemData());
        setMediaData({
            fid: '',
            filename: '',
        });
    };
    const  handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const submitInput = () => {
        const terms_tid = poemData.poetry_terms.tid || {};
        const calls_tid = poemData.causes.tid;
        const slam_nid = poemData.poetry_slam.nid;
        const guides_nid = poemData.guides.nid;
        const group_title =  poemData.groups.title;
        const ApiRoutes = new Core.ApiRoutes();
        const Http = new Core.Http();
        let tags = poemData.display_data.map((row) => (row.name));
        tags = tags.join(',');
        setLoading(true);
        const poem_scope = poemData.tid;
        const bodytext = poemData.body;
        Http.post(ApiRoutes.getRoute('addPoem'), {
            title: poemData.title,
            type: 'poem',
            body: bodytext,
            uid: myUid,
            tags,
            causes: calls_tid,
            guides: guides_nid,
            poetry_terms: terms_tid,
            poem_scope,
            groups: group_title,
            content_access: poemData.content_access,
            poetry_slam: slam_nid,
            image_type: 'upload',
            image: {
                fid: mediaData.fid,
                title: mediaData.filename,
            },
            video_type: poemData.video_type,
            field_poetryvideo: poemData.video_code,
        })
            .then((response) => {
                setLoading(false);
                if (response ? response.data.nid : response.uri) {
                    clearInitialState();
                    setOpen(true);
                    setMessage('Poem Created Successfully');
                } else if (response.data.data) {
                    setOpen(true);
                    setMessage(response.data.data);
                } else {
                    setOpen(true);
                    setMessage('Error Occured while creating poem');
                }
            }).catch((error) => Promise.reject(error));
    };
    return isLoaded ? (
        <div className={classes.poemContent}>
            <Input
                type="text"
                placeholder="Title"
                value={poemData.title}
                onChange={(e) => setPoemField('title', e.target.value)}
            />
            <ReactQuill
                defaultValue={poemData.body}
                onChange={(e) => setPoemField('body', e)}
                theme="snow"
                placeholder="Enter poem"
                className={classes.poemBody}
            />
            <Options
                isLoaded={isLoaded}
                setPoemData={setPoemData}
                poemData={poemData}
                setPoemField={setPoemField}
                setMediaData={setMediaData}
                apiData={apiData}
            />
            {loading === false
                ? (
                    <Button
                        variant="contained"
                        className={classes.buttonPrimary}
                        onClick={submitInput}
                        disabled={poemData.title.replace(/\s*/g, '').length === 0}
                    >
                        Submit
                    </Button>
                )
                :  <CircularProgress classes={{ colorPrimary: classes.loaderButton }} />}
            <Snackbar
                autoHideDuration={3000}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <span variant="outlined" className={`${classes.alert} ${classes.alertSuccess}`}>
                    {message}
                    <button type="button" className={classes.closeAlert} onClick={handleClose}>  &nbsp; x  </button>
                </span>
            </Snackbar>
        </div>
    ) : (
        <CircularProgress classes={{ colorPrimary: classes.circularLoader }} />

    );
};

Form.propTypes = {
    isLoaded: PropTypes.array.isRequired,
    Core: PropTypes.object.isRequired,
    getPoemData: PropTypes.func.isRequired,
    apiData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    Core: state.Core,
    getPoemData: () => state.Addpoem.find((st) => st.type === ADDPOEM.POEMDATA).value,
});
export default connect(mapStateToProps)(Form);
