import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    loadingCircle: {
        color: theme.palette.current.primaryColor,
        marginBottom: 200,
        marginLeft: 500,
        marginTop: 200,

    },
});

class BasicPageDesktop extends React.PureComponent {
    render() {
        const {
            classes,
        } = this.props;
        return (
            <>
                <Grid
                    className={`${classes.container} ${classes.aboutuspage}`}
                    container
                >
                    <Grid
                        className={classes.contentArea}
                        key="content-area"
                        item
                        xs={12}
                    >

                        <CircularProgress size={100} thickness={2.5} className={classes.loadingCircle} />
                    </Grid>
                </Grid>
            </>
        );
    }
}
BasicPageDesktop.propTypes = {
    classes: PropTypes.object.isRequired,
};
const Core = (state) => state.Core;

const mapStateToProps = (state) => ({
    Core: Core(state),
});

export default connect(mapStateToProps)(withStyles(styles)(BasicPageDesktop));
