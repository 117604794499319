import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import fallback_url from '../../../images/newPP-placeholder-min.jpg';


const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    card: {
        maxWidth: '100%',
        background: theme.palette.current.poem.block.normalBackground,
        width: '100%',
        '&:hover': {
            background: theme.palette.current.poem.block.hoverBackground,
        },
    },
    media: {
        height: 200,
        filter: 'grayscale(100%)',
    },
    bodyHeight: {
        height: 100,
        lineHeight: 1.7,
        overflow: 'hidden',
        color: theme.palette.current.poem.block.fontColor,
        fontFamily: ' GT-Walsheim-Pro-Light',
    },
    titleHeight: {
        height: 60,
        overflow: 'hidden',
        fontFamily: 'GT-Walsheim-Pro-Bold',
    },
    PoemItem: {
        fontFamily: 'GT-Walsheim-Pro-Light',
    },
    InvertedButton: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.invertedBtn.fontColor,
        '&:hover': {
            color: theme.palette.current.invertedBtn.primaryColor,
            background: theme.palette.current.poem.block.hoverButtonBackground,
        },
        marginLeft: 10,
        textTransform: 'capitalize',
        padding: '10px 25px',
        fontWeight: 600,
        fontSize: '0.8125rem',
        lineHeight: 1.75,
        letterSpacing: '0.02857em',
        textDecoration: 'none',
        borderRadius: 8,
        fontFamily: 'GT-Walsheim-Pro-Light',
    },
    butnlink: {
        color: theme.palette.current.invertedBtn.fontColor,
        textDecoration: 'none !important',
        '&:hover': {
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
});

class PoemItem extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    poems = () => {
        const { classes, poem } = this.props;
        const title = poem ? poem.title : null;

        return (
            <Card className={classes.card}>
                <CardMedia className={classes.media} component="img" src={poem.image} onError={(e) => { e.target.src = fallback_url; }} />
                <CardContent>
                    <Typography className={classes.titleHeight} variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography className={classes.bodyHeight} variant="body2" color="textSecondary" component="p">
                        {poem.body_value}
                    </Typography>
                </CardContent>{' '}
                <CardActions>
                    <Link
                        className={classes.InvertedButton}
                        color="primary"
                        id={poem.nid}
                        to={poem.url}
                    >
                        Learn More
                    </Link>
                </CardActions>
            </Card>
        );
    };


    render() {
        const { poem } = this.props;

        return (
            <>
                <Grid container> {poem ? this.poems() : null} </Grid>{' '}
            </>
        );
    }
}
export default withStyles(styles)(PoemItem);
