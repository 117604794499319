import * as actionTypes from './action.js';

const initialState = [
    {
        type: actionTypes.MULTINODEPAGE_TITLE,
        value: null,
    },
    {
        type: actionTypes.MULTINODEPAGE_LOADING,
        value: true,
    },
    {
        type: actionTypes.MULTINODEPAGE_BODY,
        value: null,
    },
    {
        type: actionTypes.MULTINODEPAGE_ALL_CONTENT,
        value: null,
    },
    {
        type: actionTypes.MULTINODEPAGE_CURRENT_LINK,
        value: null,
    },
    {
        type: actionTypes.MULTINODEPAGE_CURRENT_PAGE,
        value: null,
    },
];
const MultipleNodePage = (state = initialState, action) => {
    let OtherStates = [];
    switch (action.type) {
        case actionTypes.MULTINODEPAGE_TITLE:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.MULTINODEPAGE_ALL_CONTENT:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.MULTINODEPAGE_BODY:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.MULTINODEPAGE_CURRENT_LINK:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.MULTINODEPAGE_LOADING:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        case actionTypes.MULTINODEPAGE_CURRENT_PAGE:
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        default:
            return state;
    }
};

export default MultipleNodePage;
