import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Poem from './desktop/index';
import Poemmobile from './mobile/index';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    contentArea: {
        marginBottom: 25,
    },
    sidebar: {
        marginLeft: 'auto',
    },
});
function Poemspage(props) {
    const { width } = props;
    if (isWidthUp('md', width)) {
        return <Poem {...props} />;
    }
    return <Poemmobile {...props} />;
}
Poemspage.propTypes = {
    width: PropTypes.any.isRequired,
};
const widthFooter = withWidth()(Poemspage);
export default withStyles(styles)(widthFooter);
