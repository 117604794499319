import * as ADDPOEM from './constants';

const initialState = [
    {
        type: ADDPOEM.POEMDATA,
        value: {
            title: '',
            type: 'poem',
            body: '',
            uid: '',
            country: 'au',
            causes: '',
            guides: '',
            poetry_terms: '',
            groups: 'Gaming Boom (344940)',
            content_access: '1',
            tags: '',
            display_data: [],
            poetry_slam: '',
            video_code: '',
            poem_scopes: [],
            video_type: '0',
            tid: [],
            default_value: 'Youtube',
        },
    },
    {
        type: ADDPOEM.API_DATA.COUNTRIES,
        value: {
            data: [],
            recieved: false,
        },
    },
    {
        type: ADDPOEM.API_DATA.SLAMS,
        value: {
            data: [],
            recieved: false,
        },
    },
    {
        type: ADDPOEM.API_DATA.CAUSES,
        value: {
            data: [],
            recieved: false,
        },
    },
    {
        type: ADDPOEM.API_DATA.GUIDES,
        value: {
            data: [],
            recieved: false,
        },
    },
    {
        type: ADDPOEM.API_DATA.TERMS,
        value: {
            data: [],
            recieved: false,
        },
    },
    {
        type: ADDPOEM.API_DATA.GROUPS,
        value: {
            data: [],
            recieved: false,
        },
    },
    {
        type: ADDPOEM.API_DATA.ABOUT,
        value: {
            data: [],
            recieved: false,
        },
    },
];

export default initialState;
