export const NAME_CHANGE_HANDLER = 'NAME_CHANGE_HANDLER';
export const LOADING = 'LOADING';
export const LOGIN_DRAWER_TOGGLE = 'LOGIN_DRAWER_TOGGLE';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOGIN_FAILED = 'LOGIN_FAILED';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';
