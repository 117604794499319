/* eslint-disable no-tabs */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'react-html-parser';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

const styles = (theme) => ({
    poemactionheader: {
        margin: 'auto',
        paddingBottom: 25,
        '& p': {
            fontFamily: 'GT-Walsheim-Pro-Regular',
            fontSize: '18px',
            lineHeight: 2,
        },
        '& span': {
            fontFamily: 'GT-Walsheim-Pro-Regular !important',
            fontSize: '18px !important',
            lineHeight: '2px !important',
            fontStyle: 'unset !important',
        },
    },
    date: {
        fontFamily: 'GT-Walsheim-Pro-Light',
        paddingBottom: 20,
        color: theme.palette.current.darkgreycolor,
        display: 'inline-block',
        paddingRight: 20,
        verticalAlign: 'middle',
    },
    heading: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.current.invertedBtn.primaryColor,
        display: 'block',
        maxWidth: '80vw',

    },

    poemactionbody: {
        fontFamily: 'GT-Walsheim-Pro-Regular !important',
        textAlign: 'justify',
        '& a': {
            color: theme.palette.current.mainMenuItemColor,
        },
        '& u': {
            fontSize: '18px !important',
            fontWeight: 'bold',
        },
        '& iframe': {
            width: '-webkit-fill-available',
            height: '50vh',
        },
    },

    mainimage: {
        width: '100vw',
        display: 'block',
    },
    poemactionthumbnail: {
        width: 150,
    },

});

const datapoem = (data) => {
    if (data) {
        if (data.und) {
            let val =  data.und[0].value;
            val = val.replace(/(^\\)|(\\$)/g, '');
            return val;
        }
        return ('');
    }
    return ('');
};
const poemactionimage = (dataimage) => {
    if (dataimage) {
        if (dataimage.und) {
            return dataimage.und[0].uri;
        }
        return ('');
    }
    return ('');
};
const poemactionimagealt = (dataimage) => {
    if (dataimage) {
        if (dataimage.und) {
            return dataimage.und[0].alt;
        }
        return ('');
    }
    return ('');
};
class PoemActionBody extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    loadSkeleton(size = 1, type) {
        const { classes } = this.props;
        const skeletonSize = Array(size).fill('');
        let index = 1;
        return skeletonSize.map(() => {
            index += 1;
            return (
                <Grid key={`skeleton_ ${index}`} item xs={12}>
                    <Grid container>
                        <Grid className={index % 2 ? classes.rightAlign : classes.leftAlign} item xs={12}>
                            {type === 'title' ? <Skeleton height={50}  /> : <Skeleton height={350}  /> }

                        </Grid>
                    </Grid>
                </Grid>
            );
        });
    }

    render() {
        const {
            classes, data, datatitle, dataimage,
        } = this.props;
        const reqdata = JSON.stringify(datapoem(data));
        let poemactiondata_html = '';
        let skeletonTitle = 0;
        let skeletonImage = 0;
        if (datatitle === null) {
            skeletonTitle = 1;
        } else if (typeof datatitle === 'object' && datatitle.length <= 0) {
            skeletonTitle = 1;
        }

        if (dataimage === null) {
            skeletonImage = 1;
        } else if (typeof dataimage === 'object' && dataimage.length <= 0) {
            skeletonImage = 1;
        }

        if (typeof reqdata !== 'undefined') {
            const stringData = reqdata.replace(new RegExp('\\\\r', 'g'), '<br />');
            const poemactiondata = stringData.replace(new RegExp('\\\\n', 'g'), '<br />');
            poemactiondata_html = poemactiondata;
            poemactiondata_html = poemactiondata_html.toString().replace(/"/g, '');
            poemactiondata_html = poemactiondata_html.replace(/&nbsp;/gi, '');
        }
        return (
            <>
                <Grid className={`${classes.container}`} container>
                    <Grid className={classes.contentArea} key="content-area" item xs={12}>
                        {skeletonTitle === 0 ? <h1 className={classes.heading}>{datatitle}</h1> : this.loadSkeleton(1, 'title') }

                        <div className={classes.mainimage}>
                            {skeletonImage === 0 ? <img className={classes.poemactionthumbnail} src={poemactionimage(dataimage)} alt={poemactionimagealt(dataimage)} /> : ''                            }
                        </div>

                        { poemactiondata_html ? <div className={classes.poemactionbody}>{ReactHtmlParser(poemactiondata_html)}</div> : this.loadSkeleton(1, 'body') }
                    </Grid>
                </Grid>
            </>
        );
    }
}
PoemActionBody.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object,
    datatitle: PropTypes.string,
    dataimage: PropTypes.object,

};

PoemActionBody.defaultProps = {
    data: 'body',
    datatitle: 'title',
    dataimage: 'image',
};

export default withStyles(styles)(PoemActionBody);
