import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Badges from '../../../../../../images/badges.png';

const styles = (theme) => ({
    heading: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        display: 'table',
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.current.invertedBtn.primaryColor,
        textAlign: 'left',
    },
    poemAbout: {
        background: theme.palette.current.footerBrandColor,
        padding: 8,
        borderRadius: 10,
        textAlign: 'left',
        display: 'flex',
        '&:h1': {
            textAlign: 'left',
        },
    },
    rankPoet: {
        width: '100%',
        fontFamily: 'GT-Walsheim-Pro-Regular',
    },
    rank: {
        display: 'inline-block',
        width: '100%',
    },
    poemTable: {
        '& td': {
            width: '30%',
            background: theme.palette.current.invertedBtn.primaryColor,
            '&:hover': {
                background: theme.palette.current.invertedBtn.primaryColor,
                color: theme.palette.current.invertedBtn.fontColor,
            },
            marginLeft: 20,
            textTransform: 'capitalize',
            border: '1px solid',
            borderColor: theme.palette.current.mainMenuItemColor,
            fontWeight: 600,
            paddingTop: 10,
            paddingBottom: 10,
        },
        '& tr': {
            borderRadius: 8,
        },
    },
    poetColor: {
        color: theme.palette.current.invertedBtn.fontColor,
        textAlign: 'left',
        borderRightColor: theme.palette.current.darkgreycolor,
        borderBottomColor: theme.palette.current.darkgreycolor,
        borderRight: '1px solid',
        borderBottom: '1px solid',
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 12,
        width: '50%',
        fontFamily: 'GT-Walsheim-Pro-Regular',
    },
    poetHeading: {
        paddingTop: 10,
        paddingBottom: 10,
        fontFamily: 'GT-Walsheim-Pro-Regular',
        textAlign: 'left',
        paddingLeft: 12,
    },
    poetryInfo: {
        paddingBottom: 20,
    },
    mainContainer: {
        display: 'inline-block',
        paddingLeft: 5,
        paddingRight: 5,
        width: '100%',
    },
    poetsLink: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
        textAlign: 'left',
        '& span': {
            textDecoration: 'underline',
            color: theme.palette.current.invertedBtn.primaryColor,
            fontFamily: 'GT-Walsheim-Pro-Bold',
        },
    },
    poetsTable: {
        width: '100%',
        borderSpacing: 1,
        borderCollapse: 'collapse',
        '& thead': {
            background: theme.palette.current.tablecolor,
        },
        '& th:first-child': {
            borderTopLeftRadius: '12px !important',
            borderRightColor: theme.palette.current.borderrighttable,
            borderRight: '1px solid',
            width: '50%',
        },
        '& th:last-child': {
            borderTopRightRadius: '12px !important',
        },
        '& tr:nth-child(even)': {
            background: theme.palette.current.invertedBtn.primaryColor,
        },
        '& tbody': {
            background: theme.palette.current.tablebackground,
            borderColor: theme.palette.current.tablecolor,
            borderRadius: 5,
            '& tr:last-child': {
                '& td:first-child': {
                    borderBottomLeftRadius: '12px !important',
                    borderBottom: 'none !important',
                },
                '& td:last-child': {
                    borderBottomRightRadius: '12px !important',
                    borderBottom: 'none !important',
                    borderRight: 'none !important',
                },
            },
        },
    },
    linkPoets: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
        color: theme.palette.current.invertedBtn.primaryColor,
    },
    poemabt: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
    },
    aboutpoem: {
        fontFamily: 'GT-Walsheim-Pro-Light',
    },
    poemabott: {
        textAlign: 'left',
        paddingLeft: 20,
        '& h1': {
            marginBottom: 0,
            fontSize: 23,
        },
    },
});
const data = (poetScope) => {
    const value = [];
    if (poetScope) {
        const poetScopeData = poetScope.field_poem_scope ? poetScope.field_poem_scope.und : [];
        const taxonomyData = poetScope.taxonomyResult;
        const poetsname = (scopeId) => {
            taxonomyData.map((taxoid) => {
                if (taxoid.tid === scopeId.tid) {
                    value.push(taxoid.name);
                }
                return ('');
            });
        };
        if (poetScopeData) {
            poetScopeData.map((scopeId) => {
                poetsname(scopeId);
                return ('');
            });
        }
        return value.join(', ');
    }
    return null;
};
class Slam extends React.Component {
    loadSkeleton(size = 1) {
        const { classes } = this.props;
        const skeletonSize = Array(size).fill('');
        let index = 1;
        return skeletonSize.map(() => {
            index += 1;
            return (
                <Grid key={`skeleton_ ${index}`} item xs={12}>
                    <Grid container>
                        <Grid className={index % 2 ? classes.rightAlign : classes.leftAlign} item xs={12}>
                            <Skeleton height={200} />
                        </Grid>
                    </Grid>
                </Grid>
            );
        });
    }

    renderPoets(poetData) {
        const { classes } = this.props;
        if (poetData !== null) {
            return (
                <TableBody>
                    {poetData.map((item) => (
                        <TableRow key={item.score}>
                            <TableCell className={classes.poetColor}>{item.poet}</TableCell>
                            <TableCell className={classes.poetColor}>{item.score}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            );
        }
        return poetData;
    }

    renderrappers(rappersData) {
        const { classes } = this.props;
        if (rappersData !== null) {
            return (
                <TableBody>
                    {rappersData.map((item) => (
                        <TableRow key={item.score}>
                            <TableCell className={classes.poetColor}>{item.rapper}</TableCell>
                            <TableCell className={classes.poetColor}>{item.score}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            );
        }
        return rappersData;
    }

    render() {
        const {
            classes, poetData, rappersData, poetScope,
        } = this.props;

        return (
            <>
                { poetData || rappersData
                    ? (
                        <>
                            <Grid container>
                                <Grid className={classes.poetryInfo} item xs={12}>
                                    <h1 className={classes.heading}>Poetry Geome</h1>
                                    <Grid className={classes.mainContainer} item xs={6}>

                                        <Table className={classes.poetsTable}>
                                            <TableHead>
                                                <TableRow className={classes.rankPoet}>
                                                    <TableCell className={classes.poetHeading}>American Poet</TableCell>
                                                    <TableCell className={classes.poetHeading}>Similarity Rank</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {this.renderPoets(poetData)}

                                        </Table>
                                    </Grid>
                                    <Grid className={classes.mainContainer} item xs={6}>
                                        <Table className={classes.poetsTable}>
                                            <TableHead>
                                                <TableRow className={classes.rankPoet}>
                                                    <TableCell className={classes.poetHeading}>Rapper</TableCell>
                                                    <TableCell className={classes.poetHeading}>Similarity Rank</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {this.renderrappers(rappersData)}

                                        </Table>
                                    </Grid>
                                    <Grid className={classes.poetsLink}>
                                        <p>
                                            Supported by &nbsp;
                                            <a
                                                className={classes.linkPoets}
                                                rel="noopener noreferrer"
                                                href="https://poets.org/"
                                                target="_blank"
                                            >
                                                Poets.org
                                            </a>
                                        </p>
                                    </Grid>
                                </Grid>
                                {poetScope
                                    ? (
                                        <Grid className={classes.poemAbout} item xs={12}>

                                            <Grid>
                                                <img className={classes.dummyimage} src={Badges} alt="" />
                                            </Grid>
                                            <Grid className={classes.poemabott}>
                                                <h1>This Poem is about</h1>
                                                <Grid className={classes.poemabt}>
                                                    {data(poetScope) }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ) : this.loadSkeleton() }
                            </Grid>
                        </>
                    ) :  this.loadSkeleton()}
            </>
        );
    }
}

Slam.propTypes = {
    classes: PropTypes.object.isRequired,
    poetData: PropTypes.array.isRequired,
    rappersData: PropTypes.array.isRequired,
    poetScope: PropTypes.shape({
        field_poem_scope: PropTypes.array.isRequired,
        taxonomyResult: PropTypes.array.isRequired,
    }).isRequired,
};
export default withStyles(styles)(Slam);
