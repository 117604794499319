import { InputAdornment, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { RemoveRedEye } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = (theme) => ({
    eyeIcon: {
        cursor: 'pointer',
    },
});

export default class PasswordFunction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            passwordIsMasked: true,
        };
    }

  togglePasswordMask = () => {
      this.setState((prevState) => ({
          passwordIsMasked: !prevState.passwordIsMasked,
      }));
  };

  render() {
      const { classes } = this.props;
      const { passwordIsMasked } = this.state;

      return (
          <TextField
              type={passwordIsMasked ? 'password' : 'text'}
              {...this.props}
              InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                          <RemoveRedEye
                              className={classes.eyeIcon}
                              onClick={this.togglePasswordMask}
                          />
                      </InputAdornment>
                  ),
              }}
          />
      );
  }
}

PasswordFunction.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.func.isRequired,
};

PasswordFunction = withStyles(styles)(PasswordFunction);
