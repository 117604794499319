import ApiRoutes from '../api/Routes';
import Storage from '../services/storage';
import Http from '../services/http';
import theme from '../theme/main';


const getStateValueByType = (allStates, type) => {
    const filteredState = allStates.filter((states) => states.type === type);
    if (filteredState.length > 0) {
        return filteredState[0].value;
    }
    return null;
};

export const isSingleNode = (res) => {
    if (res.length === 1) {
        return true;
    }
    return false;
};
export const isAllNode = (res) => {
    if (res.length > 1) {
        return true;
    }
    return false;
};
const stripTags = (html) => html.replace(/<\/?[^>]+(>|$)/g, '');

export const getSingleNode = (res) => {
    if (res.length === 1) {
        return res[0].node;
    }
    return null;
};
export const getAllNode = (res) => {
    if (res.length > 1) {
        return res;
    }

    return null;
};

const getCurrentPath = (segment = null) => {
    if (segment) {
        let pSeg = segment;
        if (pSeg === 0) {
            pSeg = 1;
        }
        const path = window.location.pathname.split('/');
        return path[pSeg];
    }
    return window.location.pathname;
};

const auth_token = () => {
    const token = localStorage.getItem('authentication_token');
    if (token) {
        return true;
    }

    return false;
};

const core = {
    Http,
    Storage,
    ApiRoutes,
    getStateValueByType,
    breakpoints: theme.breakpoints,
    getCurrentPath,
    auth_token,
    getSingleNode,
    isSingleNode,
    isAllNode,
    getAllNode,
    stripTags,
};

const Core = (state = core, action) => state;

export default Core;
