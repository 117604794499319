import React, { PureComponent } from 'react';
import { Button } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import * as actionTypes from '../../../../reducers/action.js';


const styles = (theme) => ({
    autoText: {
        width: '80%',

    },
    button: {
        width: '40%',

        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: 16,
        fontWeight: 600,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    listSearch: {
        width: 500,
        display: 'flex',
        marginBottom: 30,


    },

    Searchbar: {
        width: '100%',
        '&::placeholder': {
            fontSize: 15,
        },
    },

});

class Search extends PureComponent {
    constructor() {
        super();
        this.state = {
            suggestions: [],
            poemData: '',
        };
    }

    handleChange = (event, value) => {
        const {
            suggestions,
        } = this.state;
        const data  = suggestions.map((row) => (row.name));
        const selectedValue = value.map((id) => (id.tid));
        const newSelect = selectedValue.toString();
        this.setState({
            tags: data.join(','),
            newSelect,
        });
    };

    handleSubmitData =() => {
        const { pager, dispatch } = this.props;
        const { Core } = this.props;
        const ApiRoutes = new Core.ApiRoutes();
        const Http = new Core.Http();
        const { newSelect } = this.state;
        Http.post(ApiRoutes.getRoute('poemsByTagSearch'), {
            tags: newSelect,
            page: pager.page,
            post_per_page: pager.limit,
        }).then((response) => {
            dispatch({
                type: actionTypes.MULTINODEPAGE_ALL_CONTENT,
                value: response.data.nodes,
            });
            dispatch({
                type: actionTypes.MULTINODEPAGE_CURRENT_PAGE,
                value: pager,
            });
        });
    }

    alltags = (event, value) => {
        this.setState({
            value,
        });
        const tags = {
            tag: value,
        };
        let datanew = null;
        const { Core } = this.props;
        const ApiRoutes = new Core.ApiRoutes();
        const Http = new Core.Http();
        Http.post(ApiRoutes.getRoute('getAllTags'),
            tags)
            .then((res) => {
                datanew = Object.values(res.data);
                this.setState({
                    suggestions: datanew[0],
                });
            });
    };

    render() {
        const { classes } = this.props;
        const { suggestions, poemData } = this.state;
        return (
            <div>
                <div className={classes.listSearch}>
                    <Autocomplete
                        multiple
                        className={classes.autoText}
                        id="tags-standard"
                        onChange={this.handleChange}
                        onInputChange={this.alltags}
                        options={suggestions}
                        getOptionLabel={(option) => option.name}
                        value={poemData.display_data}
                        renderInput={(suggestions) => (
                            <TextField
                                key="searchBox"
                                className={classes.Searchbar}
                                {...suggestions}
                                placeholder="search for tag"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                    <Grid key="searchButton" item xs={2}>
                        <Button className={classes.button} onClick={() => this.handleSubmitData()}> <SearchIcon /></Button>
                    </Grid>

                </div>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    dispatch,
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(Search));
