import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import * as actionTypes from '../../../reducers/footer/action.js';
import FooterMenuItems from './FooterMenuItems';
import Logo from '../../common/logo/logo';
import NewsLetter from '../../newsletter/desktop/NewsLetter.js';
import Search from '../../common/search/search';
import ShareLinks from './shareLinks';
import Copyright from './copyright';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    containerBorderBottom: {
        borderBottom: `${'1px solid'} ${theme.palette.current.footerMenuItemColor}`,
    },
    footer: {
        borderColor: theme.palette.current.primaryColor,
        borderTop: '5px solid',
        paddingTop: 100,
        paddingBottom: 15,
        borderBottom: '1px solid',
        background: theme.palette.current.footerBackgroundColor,
    },
    minHeight: {
        minHeight: 250,
        paddingBottom: 70,
    },
    right: {
        textAlign: 'right',
    },
    marginLeftAuto: {
        marginLeft: 'auto',
    },
    alignCenter: {
        textAlign: 'center',
    },
    innerlogoDiv: {
        [theme.breakpoints.down('lg')]: {
            margin: '0 100',
        },
    },
});
class Desktopfooter extends React.Component {
    constructor() {
        super();
        this.state = {
            loadSkeleton: false,
        };
    }

    componentDidMount() {
        const { getConfig, getMenu } = this.props;
        getConfig();
        getMenu();
    }

  clicksearch = () => {
      this.setState({ loadSkeleton: true });
      setTimeout(
          () => {
              this.setState({ loadSkeleton: false });
          },
          2000,
      );
  }

  render() {
      const { classes, Footer, ...others } = this.props;
      const { Core } = this.props;
      return (
          <footer className={classes.footer}>
              <Grid
                  className={`${classes.container} ${classes.containerBorderBottom}`}
                  container
                  justify="center"
              >
                  <Grid className={classes.minHeight} item xs={4}>
                      <Grid item className={classes.innerlogoDiv} xs={4}>
                          {Core.getStateValueByType(Footer, actionTypes.FOOTER_LOGO)
                              ? (

                                  <Logo
                                      src={Core.getStateValueByType(Footer, actionTypes.FOOTER_LOGO)}
                                  />


                              )
                              : (
                                  <SkeletonTheme
                                      color="#333"
                                      highlightColor="rgba(31,31,31,.7)"
                                  >
                                      <Skeleton height={150}  />
                                  </SkeletonTheme>
                              )}

                      </Grid>
                      <Grid className={classes} item xs={11}>
                          {Core.getStateValueByType(Footer, actionTypes.FOOTER_NEWSLETTER)
                              ? <NewsLetter Core={Core} />
                              : (
                                  <SkeletonTheme
                                      color="#333"
                                      highlightColor="rgba(31,31,31,.7)"
                                  >
                                      <Skeleton height={50} />
                                  </SkeletonTheme>
                              )}
                      </Grid>
                  </Grid>
                  <Grid className={classes.menu} item xs={4}>
                      {Core.getStateValueByType(Footer, actionTypes.FOOTER_MENU).length > 0
                          ? <FooterMenuItems />
                          : (
                              <SkeletonTheme color="#333" highlightColor="rgba(31,31,31,.7)">
                                  <Skeleton height={200} />
                              </SkeletonTheme>
                          )}
                  </Grid>


                  <Grid item xs={4}>
                      <Grid className={classes.marginLeftAuto} item xs={11}>
                          <div>
                              {Core.getStateValueByType(Footer, actionTypes.FOOTER_SEARCH)
                                  ? <Search {...others} clicksearch={this.clicksearch} />
                                  : (
                                      <SkeletonTheme
                                          color="#333"
                                          highlightColor="rgba(31,31,31,.7)"
                                      >
                                          <Skeleton height={50} />
                                      </SkeletonTheme>
                                  )}
                          </div>
                          <div>
                              {Core.getStateValueByType(Footer, actionTypes.FOOTER_SHARE_LINK)
                                  ? (
                                      <ShareLinks
                                          links={Core.getStateValueByType(
                                              Footer,
                                              actionTypes.FOOTER_SHARE_LINK,
                                          )}
                                      />
                                  )
                                  : (
                                      <SkeletonTheme
                                          color="#333"
                                          highlightColor="rgba(31,31,31,.7)"
                                      >
                                          <Skeleton height={50} />
                                      </SkeletonTheme>
                                  )}
                          </div>
                      </Grid>
                  </Grid>
              </Grid>
              <Grid className={classes.container} container justify="center">
                  <div>
                      {Core.getStateValueByType(Footer, actionTypes.FOOTER_COPYRIGHT)
                          ? (
                              <Copyright
                                  className={classes.alignCenter}
                                  message={Core.getStateValueByType(
                                      Footer,
                                      actionTypes.FOOTER_COPYRIGHT,
                                  )}
                              />
                          )

                          : (
                              <SkeletonTheme color="#333" highlightColor="rgba(31,31,31,.7)">
                                  <Skeleton height={50} />
                              </SkeletonTheme>
                          )}
                  </div>
              </Grid>
          </footer>
      );
  }
}
Desktopfooter.propTypes = {
    classes: PropTypes.object.isRequired,
    getConfig: PropTypes.func.isRequired,
    getMenu: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch) => ({
    getConfig: () => dispatch({ type: actionTypes.LOAD_FOOTER_CONFIG }),
    getMenu: () => dispatch({ type: actionTypes.LOAD_FOOTER_MENU }),
});
export default connect(null, mapDispatchToProps)(withStyles(styles)(Desktopfooter));
