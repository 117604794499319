import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'react-html-parser';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import { Link, withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';


const styles = (theme) => ({
    poemHeader: {
        margin: '0 auto',
        paddingBottom: 25,
        '& p': {
            fontFamily: 'GT-Walsheim-Pro-Regular',
            fontSize: '18px',
            lineHeight: 2,
        },
        '& span': {
            fontFamily: 'GT-Walsheim-Pro-Regular !important',
            fontSize: '18px !important',
            lineHeight: '2px !important',
            fontStyle: 'unset !important',
        },
    },
    date: {
        fontFamily: 'GT-Walsheim-Pro-Light',
        paddingBottom: 20,
        color: theme.palette.current.darkgreycolor,
        paddingRight: 20,
        verticalAlign: 'middle',
    },
    heading: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        textDecoration: 'underline',
        display: 'inline-block',
    },
    poemThumbNail: {
        height: 200,
        width: 200,
    },
    poetNameNew: {
        fontFamily: 'GT-Walsheim-Pro-Regular !important',
        paddingLeft: '10px !important',
        display: 'inline',
    },
    headerdata: {},
    InvertedButton: {
        height: 40,
        '& a': {
            color: theme.palette.current.invertedBtn.fontColor,
            textDecoration: 'none !important',
        },
        [theme.breakpoints.down('lg')]: {
            padding: '10px 13px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '10px 20px',
        },
        textTransform: 'capitalize',
        fontWeight: 600,
        borderRadius: 8,
        marginLeft: 20,
    },
    editButton: {
        background: theme.palette.current.invertedBtn.fontColor,
        color: theme.palette.current.invertedBtn.primaryColor,
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.invertedBtn.fontColor,
        },
    },
    deleteButton: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.invertedBtn.fontColor,
        '&:hover': {
            background: theme.palette.current.invertedBtn.fontColor,
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
});
const dataPoem = (data) => {
    if (data) {
        if (data.und) {
            return data.und[0].value;
        }
        return ('');
    }
};
const poemdataimage = (dataimage) => {
    if (dataimage) {
        if (dataimage.und) {
            return dataimage.und[0].uri;
        }
        return ('');
    }
};
const poemdataimagealt = (dataimage) => {
    if (dataimage) {
        if (dataimage.und) {
            return dataimage.und[0].alt;
        }
        return ('');
    }
};
class Poembody extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            open: false,
        };
    }

    handleClickOpen=() => {
        this.setState({
            open: true,
        });
    }

    handleClose=() => {
        this.setState({
            open: false,
        });
    }

    handleUnpublishPoem = () => {
        const { Core, poemid, history } = this.props;
        const ApiRoutes = new Core.ApiRoutes();
        const Http = new Core.Http();
        Http.post(ApiRoutes.getRoute('unpublishPoem'), {
            nid: poemid,
        })
            .then((response) => {
                this.setState({
                    open: false,
                });
                if (response.status === 1) {
                    this.setState({
                        open: false,
                    });
                    history.push('/user');
                }
            });
    }

    loadSkeleton(size = 1) {
        const { classes } = this.props;
        const skeletonSize = Array(size).fill('');
        let index = 1;
        return skeletonSize.map(() => {
            index += 1;
            return (
                <Grid key={`skeleton_ ${index}`} item xs={12}>
                    <Grid container>
                        <Grid className={index % 2 ? classes.rightAlign : classes.leftAlign} item xs={12}>
                            <Skeleton height={200} width={200} />
                        </Grid>
                    </Grid>
                </Grid>
            );
        });
    }


    render() {
        const {
            classes, data, dataTitle, dataCreated, dataImage, poetName, Core,
        } = this.props;
        const { open } = this.state;
        const reqData = JSON.stringify(dataPoem(data));
        const html = reqData;
        const newData = html.toString().replace(/"/g, '');
        const neww = newData.replace(/&nbsp;/gi, '');
        const newDate = dataCreated.toString().replace(/,/g, '/');
        const nameUser =  localStorage.getItem('loggein_username');
        const path = Core.getCurrentPath();
        const newPath = path.substr(1);
        return (
            <>
                <Grid container>
                    <Grid justify="center" className={classes.poemHeader} item xs={11}>
                        <Grid xs={11}>
                            <h1 className={classes.heading}>{dataTitle || null}</h1>
                            {nameUser === poetName
                                ?        (
                                    <>
                                        <Link to={`/editpoem/${newPath}`}>
                                            <Button
                                                className={`${classes.InvertedButton} ${classes.editButton}`}
                                                size="small"
                                                color="primary"
                                            >
                                                <CreateIcon />
                                            </Button>
                                        </Link>
                                        <Button
                                            className={`${classes.InvertedButton} ${classes.deleteButton}`}
                                            size="small"
                                            color="primary"
                                            onClick={this.handleClickOpen}
                                        >
                                            <DeleteOutlineIcon />
                                        </Button>
                                        <Dialog
                                            open={open}
                                            onClose={this.handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">Unpublish poem </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    Are you sure you want to unpublish this poem?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={this.handleUnpublishPoem}
                                                    color="primary"
                                                >
                                                    yes
                                                </Button>
                                                <Button
                                                    onClick={this.handleClose}
                                                    color="primary"
                                                    autoFocus
                                                >
                                                    No
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                )
                                : ' '}
                        </Grid>
                        { poetName ?  (
                            <Grid className={classes.date} item xs={11}>
                                {newDate}<span className={classes.poetNameNew}>{poetName}</span>
                            </Grid>
                        ) : ''}
                        <div>
                            {dataImage
                                ? (
                                    <img
                                        className={classes.poemThumbNail}
                                        src={poemdataimage(dataImage)}
                                        alt={poemdataimagealt(dataImage)}
                                    />
                                )
                                : this.loadSkeleton()}
                        </div>
                        <div>{ReactHtmlParser(neww)}</div>
                    </Grid>
                </Grid>
            </>
        );
    }
}
Poembody.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
    dataCreated: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
};
export default (withRouter(withStyles(styles)(Poembody)));
