import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Badges from '../../../../../../images/badges.png';

const styles = (theme) => ({
    heading: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        display: 'table',
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.current.invertedBtn.primaryColor,
        textAlign: 'left',
    },
    poemabout: {
        background: theme.palette.current.footerBrandColor,
        padding: 8,
        borderRadius: 10,
        textAlign: 'left',
        display: 'flex',
        '&:h1': {
            textAlign: 'left',
        },
    },
    rankpoet: {
        width: '100%',
        fontFamily: 'GT-Walsheim-Pro-Regular',
    },
    rank: {
        display: 'inline-block',
        width: '100%',
    },
    poemtable: {
        '& td': {
            width: '30%',
            background: theme.palette.current.invertedBtn.primaryColor,
            '&:hover': {
                background: theme.palette.current.invertedBtn.primaryColor,
                color: theme.palette.current.invertedBtn.fontColor,
            },
            marginLeft: 20,
            textTransform: 'capitalize',
            border: '1px solid',
            borderColor: theme.palette.current.mainMenuItemColor,
            fontWeight: 600,
            paddingTop: 10,
            paddingBottom: 10,
        },
        '& tr': {
            borderRadius: 8,
        },
    },
    poemcolor: {
        color: theme.palette.current.footerBrandColor,
        textAlign: 'left',

        borderBottomColor: theme.palette.current.darkgreycolor,
        borderRight: '1px solid',
        borderBottom: '1px solid',
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 12,
    },
    poetcolor: {
        color: theme.palette.current.invertedBtn.fontColor,
        textAlign: 'left',
        borderRightColor: theme.palette.current.darkgreycolor,
        borderBottomColor: theme.palette.current.darkgreycolor,
        borderRight: '1px solid',
        borderBottom: '1px solid',
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 12,
        width: '50%',
        fontFamily: 'GT-Walsheim-Pro-Regular',
    },
    poetheading: {
        paddingTop: 10,
        paddingBottom: 10,
        fontFamily: 'GT-Walsheim-Pro-Regular',
        textAlign: 'left',
        paddingLeft: 12,
    },
    poetryinfo: {
        paddingBottom: 20,
    },
    mainclass: {
        display: 'inline-block',
        paddingLeft: 5,
        paddingRight: 5,
        width: '100%',
        marginTop: 20,
    },
    poetslink: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
        textAlign: 'left',
        '& span': {
            textDecoration: 'underline',
            color: theme.palette.current.invertedBtn.primaryColor,
            fontFamily: 'GT-Walsheim-Pro-Bold',
        },
    },
    poetstable: {
        width: '100%',
        borderSpacing: 1,
        borderCollapse: 'collapse',
        '& thead': {
            background: theme.palette.current.tablecolor,
        },
        '& th:first-child': {
            borderTopLeftRadius: '12px !important',
            borderRightColor: theme.palette.current.borderrighttable,
            borderRight: '1px solid',
            width: '50%',
        },
        '& th:last-child': {
            borderTopRightRadius: '12px !important',
        },
        '& tr:nth-child(even)': {
            background: theme.palette.current.invertedBtn.primaryColor,
        },
        '& tbody': {
            background: theme.palette.current.tablebackground,
            borderColor: theme.palette.current.tablecolor,
            borderRadius: 5,
            '& tr:last-child': {
                '& td:first-child': {
                    borderBottomLeftRadius: '12px !important',
                    borderBottom: 'none !important',
                },
                '& td:last-child': {
                    borderBottomRightRadius: '12px !important',
                    borderBottom: 'none !important',
                    borderRight: 'none !important',
                },
            },
        },
    },
    poemabt: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
    },
    aboutpoem: {
        fontFamily: 'GT-Walsheim-Pro-Light',
    },
    poemabott: {
        textAlign: 'left',
        paddingLeft: 20,
        '& h1': {
            marginBottom: 0,
            fontSize: 23,
        },
    },
});
const data = (poetscope) => {
    const value = [];
    if (poetscope) {
        const poetscopedata = poetscope.field_poem_scope.und;
        const taxonomydata = poetscope.taxonomyResult;
        const poetsname = (scopeid) => {
            taxonomydata.map((taxoid) => {
                if (taxoid.tid === scopeid.tid) {
                    value.push(taxoid.name);
                }
                return ('');
            });
        };
        // let text = '';
        if (poetscopedata) {
            poetscopedata.map((scopeid) => {
                poetsname(scopeid);
                return ('');
            });
        }
        return value.join(', ');
    }
    return null;
};

class Slam extends React.Component {
    renderPoets(poetdata) {
        const { classes } = this.props;
        if (poetdata !== null) {
            return (
                <tbody className={classes.poetsbody}>
                    {poetdata.map((item) => (
                        <tr key={item.score} className={classes.subrow}>
                            <td className={classes.poetcolor}>{item.poet}</td>
                            <td className={classes.poemcolor}>{item.score}</td>
                        </tr>
                    ))}
                </tbody>
            );
        }
        return null;
    }

    renderrappers(rappersdata) {
        const { classes } = this.props;
        if (rappersdata !== null) {
            return (
                <tbody className={classes.poetsbody}>
                    {rappersdata.map((item) => (
                        <tr key={item.score} className={classes.subrow}>
                            <td className={classes.poetcolor}>{item.rapper}</td>
                            <td className={classes.poemcolor}>{item.score}</td>
                        </tr>
                    ))}
                </tbody>
            );
        }
        return null;
    }

    render() {
        const {
            classes, poetdata, rappersdata, poetscope,
        } = this.props;

        return (
            <>
                <Grid container>
                    <Grid className={classes.poetryinfo} item xs={12}>
                        <h1 className={classes.heading}>Poetry Geome</h1>
                        <Grid className={classes.mainclass} item xs={12}>
                            <table className={classes.poetstable}>
                                <thead>
                                    <tr className={classes.rankpoet}>
                                        <th className={classes.poetheading}>American Poet</th>
                                        <th className={classes.poetheading}>Similarity Rank</th>
                                    </tr>
                                </thead>
                                {this.renderPoets(poetdata)}
                            </table>
                        </Grid>
                        <Grid className={classes.mainclass} item xs={12}>
                            <table className={classes.poetstable}>
                                <thead>
                                    <tr className={classes.rankpoet}>
                                        <th className={classes.poetheading}>Rapper</th>
                                        <th className={classes.poetheading}>Similarity Rank</th>
                                    </tr>
                                </thead>
                                {this.renderrappers(rappersdata)}
                            </table>
                        </Grid>
                        <Grid className={classes.poetslink}>
                            <p>
                                Supported by &nbsp;
                                <a
                                    className={classes.linkPoets}
                                    rel="noopener noreferrer"
                                    href="https://poets.org/"
                                    target="_blank"
                                >
                                    Poets.org
                                </a>
                            </p>
                        </Grid>
                    </Grid>
                    <Grid className={classes.poemabout} item xs={12}>
                        <Grid>
                            {' '}
                            <img className={classes.dummyimage} src={Badges} alt="" />{' '}
                        </Grid>
                        <Grid className={classes.poemabott}>
                            <h1>This Poem is about</h1>

                            <Grid className={classes.poemabt}>{data(poetscope)}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}
Slam.propTypes = {
    classes: PropTypes.object.isRequired,
    poetdata: PropTypes.object.isRequired,
    rappersdata: PropTypes.object.isRequired,
    poetscope: PropTypes.object.isRequired,
};
export default withStyles(styles)(Slam);
