import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
    logo: {
        maxWidth: '100%',
    },
    searchBox: {
        border: 0,
        padding: '15px 5px',
        width: '100%',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        outline: 'none !important',
    },
    button: {
        width: '100%',
        height: '100%',
        minWidth: '50%',
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '10px 20px',
        fontWeight: 600,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
});
const searchpage = (theme) => ({
    searchBox: {
        border: '1px solid',
        borderColor: theme.palette.current.borderrighttable,
        padding: '15px 5px',
        width: '100%',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        outline: 'none !important',
        height: 12,
        '&::placeholder': {
            fontFamily: 'GT-Walsheim-Pro-Light',
            fontSize: 20,
            paddingLeft: 12,
        },
    },
    button: {
        width: '100%',
        height: '100%',
        minWidth: '50%',
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '6px 20px',
        fontWeight: 600,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    searchhead: {
        display: 'flex',
        marginBottom: 20,
        '&:input': {
            border: '1px solid !important',
            borderColor: theme.palette.current.borderrighttable,
            padding: '15px 5px',
            width: '100%',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
        },
    },
});
const mobilevieww = (theme) => ({
    searchBox: {
        border: '1px solid',
        borderColor: theme.palette.current.borderrighttable,
        padding: '15px 5px',
        width: '100%',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        outline: 'none !important',
        height: 12,
        '&::placeholder': {
            fontFamily: 'GT-Walsheim-Pro-Light',
            fontSize: 20,
            paddingLeft: 12,
        },
    },
    button: {
        width: '100%',
        height: '100%',
        minWidth: '50%',
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '10px 20px',
        fontWeight: 600,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    maincontainer: {
        padding: 20,
    },
    searchhead: {
        display: 'flex',
        marginBottom: 20,
        '&:input': {
            border: '1px solid !important',
            borderColor: theme.palette.current.borderrighttable,
            padding: '15px 5px',
            width: '100%',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
        },
    },
});
class Search extends React.PureComponent {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        const { setQuery } = this.props;
        if (window.location.search !== '') {
            const param = decodeURI(window.location.search.split('=')[1]);
            setQuery(param);
        }
    }

    checkforspace=(e) => {
        if (e.which === 32 && !e.target.value.length) { e.preventDefault(); }
    }

    handleInputChange(event) {
        const { setQuery } = this.props;
        const target = event.target;
        const value = target.value;
        setQuery(value);
    }

    searchoption() {
        const { setParam, query, setQuery } = this.props;
        const newvalue = query.trim();
        setParam(newvalue);
        setQuery(newvalue);
    }

    render() {
        const { classes, query } = this.props;

        return (
            <>
                <Grid className={classes.maincontainer} container justify="center">
                    <Grid key="searchBox" item xs={10}>
                        <div>
                            <input
                                type="text"
                                className={classes.searchBox}
                                name="searchkeyword"
                                onChange={(event) => this.handleInputChange(event)}
                                onKeyPress={(e) => this.checkforspace(e)}
                                value={query || ''}
                                placeholder="Search"
                            />
                        </div>
                    </Grid>
                    <Grid key="searchButton" item xs={2}>
                        {(() => {
                            if (query) {
                                return (
                                    <Link to={{ pathname: '/search', search: `?keys=${query}` }}>
                                        <Button className={classes.button} type="submit" onClick={() => this.searchoption()}>
                                            <SearchIcon />
                                        </Button>
                                    </Link>
                                );
                            }
                            return (
                                <Button
                                    className={classes.button}
                                    disabled={!query}
                                    type="submit"
                                >
                                    <SearchIcon />
                                </Button>
                            );
                        })()}
                    </Grid>
                </Grid>
            </>
        );
    }
}
Search.propTypes = {
    classes: PropTypes.object.isRequired,
    setParam: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    param: state.Searchpage.find((st) => st.type === 'SEARCH.PARAM').value,
    query: state.Searchpage.find((st) => st.type === 'SEARCH.QUERY').value,
});
const mapDispatchToProps = (dispatch) => ({
    setQuery: (value) => dispatch({
        type: 'SEARCH.QUERY',
        value,
    }),
    setParam: (value) => dispatch({
        type: 'SEARCH.PARAM',
        value,
    }),
});
export const Searchstyle = connect(mapStateToProps, mapDispatchToProps)(withStyles(searchpage)(Search));
export const Searchstylemobile = connect(mapStateToProps, mapDispatchToProps)(withStyles(mobilevieww)(Search));
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Search));
