import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import ReactHtmlParser from 'react-html-parser';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import  Registeraction from './action';
import bag from '../../../images/icon.svg';
import APP_CONFIG from '../../../config';

const styles = (theme) => ({
    registerBlock: {
        background: theme.palette.current.primaryColor,
        marginTop: -100,
        borderRadius: 8,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    formField: {
        textAlign: 'center',
        '& > input': {
            border: 0,
            padding: 15,
            width: '70%',
            borderRadius: 8,
            marginBottom: 10,
            fontFamily: 'GT-Walsheim-Pro-Light',
            outline: 'none !important',
        },
        '& input[type="date"]::-webkit-calendar-picker-indicator': {
            color: 'rgba(0,   0, 0, 0)',
            opacity: '1',
            display: 'block',
            background: `url(${bag}) no-repeat`,
            backgroundSize: '100% 100%',
            width: '20px',
            height: '20px',
            borderWidth: 'thin',
        },
    },
    sectionSignup: {
        textAlign: 'center',
        marginTop: 0,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        marginBottom: 0,
        paddingTop: 35,
        paddingBottom: 10,
        fontSize: 30,
        background: theme.palette.current.signUp.background,
        fontFamily: 'GT-Walsheim-Pro-Bold',
    },
    valid: {
        textAlign: 'center',
        padding: '20px',
        fontWeight: 500,
        clear: 'both',
        color: theme.palette.current.user.validColor,
        '& a': {
            display: 'block !important',
            color: theme.palette.current.invertedBtn.primaryColor,
        },
        '& p': {
            margin: '10px 0px 0px 0px',
        },
    },

    errorStyle: {
        fontSize: 14,
        color: 'red',
        width: '75%',
        paddingBottom: 10,
        margin: 'auto',
        textAlign: 'left',
    },
    InvertedButton: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.footerBrandColor,
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.footerBrandColor,
        },
        marginLeft: 0,
        textTransform: 'capitalize',
        padding: '10px 25px',
        fontWeight: 600,
        borderRadius: 8,
        marginBottom: 25,
        width: '78%',
    },
    te: {
        borderBottom: `${'5px solid'} ${theme.palette.current.signUp.shadow}`,
    },
    signDesign: {
        [theme.breakpoints.up('lg')]: {
            borderLeft: '170px solid transparent',
            borderRight: '170px solid transparent',
        },
        [theme.breakpoints.down('md')]: {
            borderLeft: '147px solid transparent',
            borderRight: '147px solid transparent',
        },
        [theme.breakpoints.down('sm')]: {
            width: 0,
            height: 0,
            borderTop: '100px solid rgb(230,179,44)',
            borderLeft: '45vw solid transparent',
            borderRight: '46vw solid transparent',
            display: 'inline-block',
        },
        borderTop: `${'40px solid'} ${theme.palette.current.signUp.background}`,
        marginBottom: 45,
        position: 'relative',
    },
    spanImg: {
        position: 'absolute',
        top: -35,
        background: theme.palette.current.signUp.logo,
        borderRadius: '50%',
        overflow: 'hidden',
        width: 65,
        height: 55,
        textAlign: 'center',
        paddingTop: 10,
        left: -25,
        [theme.breakpoints.down('sm')]: {
            left: 'inherit',
            right: 'inherit',
        },
    },
    errormsgdiv: {
        color: theme.palette.current.invertedBtn.primaryColor,
        padding: '20px',
        textAlign: 'center',
        fontWeight: 500,
        clear: 'both',
        '& a': {
            color: theme.palette.current.invertedBtn.primaryColor,
        },
        '& p': {
            margin: '10px 0px 0px 0px',
        },
    },
    circularLoader: {
        color: theme.palette.current.invertedBtn.primaryColor,
        marginBottom: 25,
    },
    innerheight: {
        padding: '0px 5px',
        width: 600,
        border: '2px solid',
        borderColor: theme.palette.current.primaryColor,
        borderRadius: '4px',
        background: theme.palette.current.primaryColor,
    },
    alertboxes: {
        zIndex: '11111 !important',
    },
    closeicon: {
        textAlign: 'right',
        float: 'right',
        display: 'block',
        width: '100%',
        backgroundColor: 'none !important',
        padding: 0,
        lineHeight: 1,
        '& span': {
            cursor: 'pointer',
            outline: 'none !important',
            fontSize: '20px',
            color: theme.palette.current.user.invalidColor,
        },
    },
});
const userpage = (theme) => ({
    registerBlock: {
        background: 'none',
        marginTop: '30px',
    },
    sectionSignup: {
        display: 'none',
    },
    formField: {
        textAlign: 'center',
        '& > input': {
            border: '1px solid',
            borderColor: theme.palette.current.user.fieldBorder,
            padding: '15px 0px 15px 4px',
            width: '97%',
            borderRadius: 8,
            margin: '15px auto',
            fontFamily: 'GT-Walsheim-Pro-Light',
            outline: 'none !important',
        },
    },
    signDesign: {
        display: 'none',
    },
    InvertedButton: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.signUp.background,
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.signUp.background,
        },
        marginLeft: 0,
        textTransform: 'capitalize',
        padding: '10px 25px',
        fontWeight: 600,
        borderRadius: 8,
        margin: '25px auto',
        width: '78%',
    },
    errormsgdiv: {
        color: theme.palette.current.user.invalidColor,
        padding: '20px',
        textAlign: 'center',
        fontWeight: 500,
        '& >a': {
            display: 'block !important',
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
    valid: {
        textAlign: 'center',
        padding: '20px',
        fontWeight: 500,
        color: theme.palette.current.user.validColor,
        '& a': {
            display: 'block !important',
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
    circularLoader: {
        color: theme.palette.current.primaryColor,
        margin: '25px auto',
    },
    innerheight: {
        padding: '15px',
        width: 600,
        border: '2px solid',
        borderColor: theme.palette.current.primaryColor,
        borderRadius: '4px',
        background: theme.palette.current.primaryColor,
    },
    alertboxes: {
        zIndex: '11111 !important',
    },
    errorwithoutpopoup: {
        display: 'none',
    },
    closeicon: {
        textAlign: 'right',
        float: 'right',
        display: 'block',
        width: '100%',
        backgroundColor: 'none !important',
        padding: 0,
        '& span': {
            cursor: 'pointer',
            outline: 'none !important',
            fontSize: '20px',
            color: theme.palette.current.user.invalidColor,
        },
    },
});
const registerpopupstyle = (theme) => ({
    sectionSignup: {
        display: 'none',
    },
    signDesign: {
        display: 'none',
    },
    InvertedButton: {
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '10px 30px',
        fontWeight: 600,
        borderRadius: 8,
        width: '50%',
    },
    errorStyle: {
        fontSize: 14,
        color: 'red',
        marginLeft: 12,
        textAlign: 'left',
    },
    formField: {
        textAlign: 'center',
        marginTop: '20px',
        '& > input': {
            border: '1px solid ',
            borderColor: theme.palette.current.btn.tabcolor,
            padding: 15,
            width: '92%',
            borderRadius: 8,
            marginBottom: 10,
            fontFamily: 'GT-Walsheim-Pro-Light',
            outline: 'none !important',
        },

    },
    formFieldbtn: {
        textAlign: 'center',
    },
    errormsgdiv: {
        color: theme.palette.current.user.invalidColor,
        padding: '0px',
        textAlign: 'center',
        '& a': {
            display: 'block !important',
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
    valid: {
        textAlign: 'center',
        padding: '0px',
        color: theme.palette.current.user.validColor,
        '& a': {
            display: 'block !important',
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
    circularLoader: {
        color: theme.palette.current.primaryColor,
    },
    alertboxes: {
        display: 'none',
    },
});
class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            username: '',
            email: '',
            password: '',
            confirm_password: '',
            dob: '',
            emailError: '',
            nameError: '',
            passwordError: '',
            birthdayError: '',
            errors: [],
            mobileOpen: false,
            isLoading: false,
        };
    }


    validate = () => {
        const {
            email, username, dob, confirm_password, password,
        } = this.state;
        let nameError = '';
        let birthdayError = '';
        let emailError = '';
        let passwordError = '';
        let passwordUnmatchError = '';


        if (!dob) {
            birthdayError = 'Please Provide Birth Date';
        }
        if (!username) {
            nameError = 'Username cannot be blank';
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            emailError = 'Please Provide E-mail Address';
        }

        if (!password || !confirm_password) {
            passwordError = 'Password field cannot be empty';
        }
        if ((typeof confirm_password === 'undefined' && confirm_password === '') || (password !== confirm_password)) {
            passwordUnmatchError = 'Password did not match';
        }


        if (birthdayError || nameError || emailError || passwordError || passwordUnmatchError) {
            this.setState({
                birthdayError,
                nameError,
                emailError,
                passwordError,
                passwordUnmatchError,
                isLoading: false,
            });
            return false;
        }

        return true;
    };

    isValid=() => {
        const isValid = this.validate();
        const { coredata } = this.props;
        if (isValid) {
            this.register(coredata);
            // clear form
            this.setState({
                emailError: '',
                birthdayError: '',
                nameError: '',
                passwordError: '',
                passwordUnmatchError: '',
                isLoading: true,
            });
        }
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }

    register() {
        const {
            username, email, password, confirm_password, dob,
        } = this.state;
        const { Core, dispatch } = this.props;
        const { mobileOpen } = this.state;
        const Cores = Core;
        if ((typeof confirm_password === 'undefined' && confirm_password === '') || (password !== confirm_password)) {
            const errormsg1 = 'Password did not match';
            return this.setState({ errors: errormsg1, isLoading: false });
        }
        return this.setState({
            errors: '',
        }, () => Registeraction(Cores, username, email, password, confirm_password, dob).then((response) => {
            if (response.status === 1) {
                localStorage.setItem('loggein_username', response.data.user.name);
                const authtoken = response.data.token;
                localStorage.setItem('authentication_token', authtoken);
                const cookies = new Cookies();
                cookies.set('pp_token', response.data.session_name += `=${response.data.sessid}`, { path: '/' });
                this.setState({
                    errors: 'Registration Successful',
                    mobileOpen: !mobileOpen,
                });
                if (window.location.pathname !== '/') {
                    return setInterval(() => {
                        dispatch({
                            type: 'LOGIN.DRAWER.TOGGLE',
                            value: false,
                        });
                    }, 2000);
                }
                return window.location.reload();
            } if (response ? response.data.response.data.form_errors : null) {
                const errors = response.data.response.data.form_errors;
                const errorvalues = Object.values(errors);
                const errormsg =  errorvalues.toString().replace(/,/g, '</br>');
                return this.setState({
                    isLoading: false,
                    errors: errormsg,
                });
            }
            return null;
        }));
    }

    render() {
        const { classes } = this.props;
        const {
            username, email, password, confirm_password, dob, errors,  emailError, passwordError, passwordUnmatchError,
            birthdayError, nameError, isLoading,
        } = this.state;
        const errorRegister = ReactHtmlParser(errors);
        return (
            <>

                <div className={classes.registerBlock}>
                    <div>
                        <h2 className={classes.sectionSignup}>Sign Up</h2>
                        <div className={classes.signDesign}>
                            <span className={classes.spanImg}>
                                <img
                                    src={`${APP_CONFIG.API_BASE_URL}${'sites/default/files/signup-logo.PNG'}`}
                                    alt="sign up"
                                />
                            </span>
                        </div>
                    </div>
                    <form>
                        <div className={classes.formField}>
                            <input
                                type="text"
                                name="username"
                                className={`${username ? '' : classes.invalidinput}`}
                                onChange={(event) => this.handleInputChange(event)}
                                value={username}
                                placeholder="Username"
                            />
                            <div className={classes.errorStyle}>  {nameError}
                            </div>
                        </div>
                        <div className={classes.formField}>
                            <input
                                type="email"
                                name="email"
                                value={email}
                                className={`${email ? '' : classes.invalidinput}`}
                                onChange={(event) => this.handleInputChange(event)}
                                placeholder="Email"
                            />
                            <div className={classes.errorStyle}> {emailError}
                            </div>
                        </div>
                        <div className={classes.formField}>
                            <input
                                type="password"
                                name="password"
                                value={password}
                                className={`${password ? '' : classes.invalidinput}`}
                                onChange={(event) => this.handleInputChange(event)}
                                placeholder="Password"
                            />
                            <div className={classes.errorStyle}>  {passwordError || passwordUnmatchError}
                            </div>
                        </div>
                        <div className={classes.formField}>
                            <input
                                type="password"
                                name="confirm_password"
                                value={confirm_password}
                                className={`${confirm_password ? '' : classes.invalidinput}`}
                                onChange={(event) => this.handleInputChange(event)}
                                placeholder="Confirm Password"
                            />
                        </div>
                        <div className={classes.formField}>
                            <input
                                type="date"
                                name="dob"
                                className={`${dob ? '' : classes.invalidinput}`}
                                onChange={(event) => this.handleInputChange(event)}
                                value={dob}
                                placeholder="DD/MM/YYYY"
                            />
                            <div className={classes.errorStyle}>   {birthdayError}
                            </div>
                        </div>
                        <div className={classes.formField}>
                            { isLoading === true
                                ? <CircularProgress classes={{ colorPrimary: classes.circularLoader }} />
                                : (
                                    <Button
                                        onClick={() => this.isValid()}
                                        className={classes.InvertedButton}
                                        size="small"
                                        color="primary"
                                        onKeyPress={() => this.isValid()}
                                    >
                                        Submit
                                    </Button>
                                ) }
                            <div className={classes.errorwithoutpopoup}>
                                <div className={errors === 'Registration Successful' ? classes.valid : classes.errormsgdiv}>
                                    {errors
                                        ? (
                                            <p>
                                                {errorRegister}
                                            </p>
                                        )
                                        : ''}
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </>
        );
    }
}

Registration.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    Core: PropTypes.object.isRequired,
    coredata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    Core: state.Core,
});


const mapDispatchToProp = (dispatch) => ({
    dispatch,
});

export const Userregister = connect(mapStateToProps, mapDispatchToProp)(withStyles(userpage)(Registration));
export default connect(null, mapDispatchToProp)(withStyles(styles)(Registration));
export const Registerpopup = connect(null, mapDispatchToProp)(withStyles(registerpopupstyle)(Registration));
