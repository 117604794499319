import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import * as actionTypes from '../../../reducers/auth/action.js';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    contentArea: {
        marginBottom: 25,
    },
    sidebar: {
        marginLeft: 'auto',
    },
    zIndex: {
        zIndex: 9999,
    },
    formField: {
        textAlign: 'center',
        '& > input': {
            border: 0,
            padding: 15,
            width: '70%',
            borderRadius: 8,
            marginBottom: 10,
            fontFamily: 'GT-Walsheim-Pro-Light',
            outline: 'none !important',
        },
    },
    sectionSignup: {
        textAlign: 'center',
        marginTop: 0,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        marginBottom: 0,
        paddingTop: 35,
        paddingBottom: 10,
        fontSize: 30,
        background: theme.palette.current.signUp.background,
        fontFamily: 'GT-Walsheim-Pro-Bold',
    },
    InvertedButton: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.footerBrandColor,
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.footerBrandColor,
        },
        marginLeft: 0,
        textTransform: 'capitalize',
        padding: '10px 25px',
        fontWeight: 600,
        borderRadius: 8,
        marginBottom: 25,
        width: '78%',
    },
    te: {
        borderBottom: `${'5px solid'} ${theme.palette.current.signUp.shadow}`,
    },
    signDesign: {
        borderLeft: '170px solid transparent',
        borderRight: '170px solid transparent',
        borderTop: `${'40px solid'} ${theme.palette.current.signUp.background}`,
        marginBottom: 45,
        position: 'relative',
    },
    spanImg: {
        position: 'absolute',
        top: -35,
        background: theme.palette.current.signUp.logo,
        borderRadius: '50%',
        overflow: 'hidden',
        width: 65,
        height: 55,
        textAlign: 'center',
        paddingTop: 10,
        left: -25,
    },
    invalid: {
        textAlign: 'center',
        color: theme.palette.current.user.invalidColor,
        height: '30px',
    },
    loginHead: {
        textAlign: 'center',
    },
    circularLoader: {
        color: theme.palette.current.primaryColor,
    },
    valid: {
        textAlign: 'center',
        color: theme.palette.current.user.validColor,
        height: '30px',
    },
});
const userlogin = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    contentArea: {
        marginBottom: 25,
    },
    sidebar: {
        marginLeft: 'auto',
    },
    zIndex: {
        zIndex: 9999,
    },
    formField: {
        textAlign: 'center',
        '& > input': {
            border: '1px solid #d3d3d3',
            padding: '15px 0px 15px 4px',
            width: '97%',
            borderRadius: 8,
            margin: '15px auto',
            fontFamily: 'GT-Walsheim-Pro-Light',
            outline: 'none !important',
        },
    },
    sectionSignup: {
        textAlign: 'center',
        marginTop: 0,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        marginBottom: 0,
        paddingTop: 35,
        paddingBottom: 10,
        fontSize: 30,
        background: theme.palette.current.signUp.background,
        fontFamily: 'GT-Walsheim-Pro-Bold',
    },
    InvertedButton: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.signUp.background,
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.signUp.background,
        },
        marginLeft: 0,
        textTransform: 'capitalize',
        padding: '10px 25px',
        fontWeight: 600,
        borderRadius: 8,
        margin: '25px auto',
        width: '78%',
    },
    te: {
        borderBottom: `${'5px solid'} ${theme.palette.current.signUp.shadow}`,
    },
    signDesign: {
        borderLeft: '170px solid transparent',
        borderRight: '170px solid transparent',
        borderTop: `${'40px solid'} ${theme.palette.current.signUp.background}`,
        marginBottom: 45,
        position: 'relative',
    },
    spanImg: {
        position: 'absolute',
        top: -35,
        background: theme.palette.current.signUp.logo,
        borderRadius: '50%',
        overflow: 'hidden',
        width: 65,
        height: 55,
        textAlign: 'center',
        paddingTop: 10,
        left: -25,
    },
    invalid: {
        textAlign: 'center',
        color: '#FF0000',
    },
    valid: {
        textAlign: 'center',
        color: theme.palette.current.user.validColor,
    },
    loginHead: {
        display: 'none',
    },
    loginBlock: {
        marginTop: '30px',
    },
    circularLoader: {
        color: theme.palette.current.primaryColor,
    },
});
const loginPopup = (theme) => ({
    invalid: {
        textAlign: 'center',
        color: theme.palette.current.user.invalidColor,
    },
    valid: {
        textAlign: 'center',
        color: theme.palette.current.user.validColor,
    },
    circularLoader: {
        color: theme.palette.current.primaryColor,
    },
    formField: {
        textAlign: 'center',
        marginTop: '20px',
        '& > input': {
            border: '1px solid ',
            borderColor: theme.palette.current.btn.tabcolor,
            padding: 15,
            width: '92%',
            borderRadius: 8,
            marginBottom: 10,
            fontFamily: 'GT-Walsheim-Pro-Light',
            outline: 'none !important',
        },
    },
    formFieldbtn: {
        textAlign: 'center',
    },
    InvertedButton: {
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '10px 30px',
        fontWeight: 600,
        borderRadius: 8,
        width: '50%',
    },
    loginHead: {
        display: 'none',
    },
});
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            nameError: '',
            passwordError: '',
        };
    }

    validate = () => {
        const { Auth } = this.props;
        const data = {
            username: Auth.username,
            password: Auth.password,
        };
        let { nameError, passwordError } = this.state;
        if (data.username === '') {
            nameError = 'Username cannot be blank';
        }
        if (data.password === '') {
            passwordError = 'Password field cannot be empty';
        }
        if (nameError || passwordError) {
            this.setState({
                nameError,
                passwordError,
            });
            return false;
        }
        return true;
    };

    isValid=() => {
        const isValid = this.validate();
        if (isValid) {
            this.userLogin();
            this.setState({
                nameError: '',
                passwordError: '',
            });
        }
    }

    userLogin() {
        const { userLogin, Auth } = this.props;
        const data = {
            username: Auth.username,
            password: Auth.password,
        };
        userLogin(data);
    }

    render() {
        const {
            classes, nameChangeHandler, Auth,
        } = this.props;
        return (
            <>
                <div className={classes.loginBlock}>
                    <div className={classes.loginHead}>
                        <h1>Login</h1>
                    </div>
                    <form>
                        <div>
                            {Auth.error ? (
                                <p className={Auth.error === 'Login Success' ? classes.valid : classes.invalid}>
                                    {Auth.error}
                                </p>
                            ) : null}
                        </div>
                        <div className={classes.formField}>
                            <input
                                type="text"
                                name="username"
                                value={Auth.username}
                                onChange={(event) => nameChangeHandler(event)}
                                placeholder="Username"
                            />
                            <div className={classes.invalid}>  {this.state.nameError}
                            </div>
                        </div>
                        <div className={classes.formField}>
                            <input
                                type="password"
                                name="password"
                                value={Auth.password}
                                onChange={(event) => nameChangeHandler(event)}
                                placeholder="Password"
                            />
                            <div className={classes.invalid}>  {this.state.passwordError}
                            </div>
                        </div>
                        <div className={classes.formField}>
                            { Auth.isLoading
                                ? <CircularProgress classes={{ colorPrimary: classes.circularLoader }} />
                                : (
                                    <Button
                                        onClick={() => this.isValid()}
                                        className={classes.InvertedButton}
                                        size="small"
                                        color="primary"
                                    >
                                        Submit
                                    </Button>
                                ) }
                        </div>
                    </form>
                </div>
            </>
        );
    }
}
Login.propTypes = {
    classes: PropTypes.object.isRequired,
    nameChangeHandler: PropTypes.func.isRequired,
    Auth: PropTypes.object.isRequired,
    userLogin: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    Auth: state.Auth,
    Core: state.Core,
});
const mapDispatchToProps = (dispatch) => ({
    nameChangeHandler: (event) => dispatch({ type: actionTypes.NAME_CHANGE_HANDLER, payloads: event.target }),
    userLogin: (data) => dispatch({ type: actionTypes.USER_LOGIN, data }),
    dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
export const Userlogin = connect(mapStateToProps, mapDispatchToProps)(withStyles(userlogin)(Login));
export const Popuplogin = connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPopup)(Login));
