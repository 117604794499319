import { combineReducers } from 'redux';
import Header from './header/header';
import Footer from './footer/footer';
import Core from './core';
import Homepage from './homepage/homepage';
import Poempage from './poempage/poempage';
import Poemactionpage from './poemactionpage/poemactionpage';
import Searchpage from './searchpage/searchpage';
import Aboutuspage from './aboutuspage/aboutuspage';
import BasicPage from './BasicPage';
import MultipleNodePage from './MultiplenodePage';
import Url from './Url';
import LoginPopup from './LoginPopup';
import Addpoem from './addpoem/addpoem';
import User from './user/user';
import Auth from './auth/auth';

const rootReducer = combineReducers({
    Header,
    Footer,
    Core,
    Homepage,
    Poempage,
    Poemactionpage,
    Searchpage,
    Aboutuspage,
    BasicPage,
    MultipleNodePage,
    Url,
    LoginPopup,
    Addpoem,
    User,
    Auth,
});
export default rootReducer;
