import React, { useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './useStyles';
import Form from './Form/Form';
import * as ADDPOEM from '../../../../reducers/addpoem/constants';

const AddPoem = (props) => {
    const classes = useStyles();
    const { getApiState } = props;
    const isLoaded = useState(false);
    const { Core } = props;

    const InitialProps = useRef(props);
    useMemo(() => {
        (async () => {
            const Http = new Core.Http();
            const ApiRoutes = new Core.ApiRoutes();
            const {  isApiRecieved, setApiState } = InitialProps.current;
            Http.post(ApiRoutes.getRoute('getCountries'), {}).then((res) => setApiState({
                data: res.data.data,
                recieved: true,
            }, ADDPOEM.API_DATA.COUNTRIES));
            Http.post(ApiRoutes.getRoute('getAllPoetrySlam'), {}).then((res) => setApiState({
                data: res.data.data,
                recieved: true,
            }, ADDPOEM.API_DATA.SLAMS));
            Http.post(ApiRoutes.getRoute('getAllPoemActions'), {}).then((res) => setApiState({
                data: res.data.data,
                recieved: true,
            }, ADDPOEM.API_DATA.GUIDES));
            Http.post(ApiRoutes.getRoute('getAllGroups'), {}).then((res) => setApiState({
                data: res.data.data,
                recieved: true,
            }, ADDPOEM.API_DATA.GROUPS));
            Http.post(ApiRoutes.getRoute('getAllPoemScopes'), {}).then((res) => setApiState({
                data: res.data.data,
                recieved: true,
            }, ADDPOEM.API_DATA.ABOUT));
            Http.post(ApiRoutes.getRoute('getAllPoetryTerms'), {}).then((res) => setApiState({
                data: res.data.data,
                recieved: true,
            }, ADDPOEM.API_DATA.TERMS));
            Http.post(ApiRoutes.getRoute('getAllCauses'), {}).then((res) => {
                const filteredData = res.data.data.filter((cause, index) => (
                    index === res.data.data.findIndex(
                        (t) => t.uuid === cause.uuid && t.tid === cause.tid,
                    )
                ));
                setApiState({
                    data: filteredData,
                    recieved: true,
                }, ADDPOEM.API_DATA.CAUSES);
            });
            if (
                isApiRecieved(ADDPOEM.API_DATA.COUNTRIES)
                && isApiRecieved(ADDPOEM.API_DATA.SLAMS)
                && isApiRecieved(ADDPOEM.API_DATA.GUIDES)
                && isApiRecieved(ADDPOEM.API_DATA.GROUPS)
                && isApiRecieved(ADDPOEM.API_DATA.ABOUT)
                && isApiRecieved(ADDPOEM.API_DATA.TERMS)
                && isApiRecieved(ADDPOEM.API_DATA.CAUSES)
            ) {
                // setIsLoaded(true);
            }
        })();
    }, [Core, InitialProps]);

    // TODO: SIMPLIFIED to avoid large code change. --
    // We can change the components that are calling apiData variable to directly fetch the values
    const apiData = {
        countries: getApiState(ADDPOEM.API_DATA.COUNTRIES),
        slams: getApiState(ADDPOEM.API_DATA.SLAMS),
        causes: getApiState(ADDPOEM.API_DATA.CAUSES),
        guides: getApiState(ADDPOEM.API_DATA.GUIDES),
        terms: getApiState(ADDPOEM.API_DATA.TERMS),
        groups: getApiState(ADDPOEM.API_DATA.GROUPS),
        about: getApiState(ADDPOEM.API_DATA.ABOUT),
        scopes: '',
    };

    return isLoaded
        ? (
            <div className={classes.root}>
                <Form isLoaded={isLoaded} apiData={apiData} />
            </div>
        ) : <CircularProgress classes={{ colorPrimary: classes.circularLoader }} />;
};


AddPoem.propTypes = {
    getApiState: PropTypes.func.isRequired,
    // setApiState: PropTypes.func.isRequired,
    // isApiRecieved: PropTypes.func.isRequired,
    // setIsLoaded: PropTypes.func.isRequired,
    Core: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    getApiState: (type) => state.Addpoem.find((st) => st.type === type).value.data,
    isApiRecieved: (type) => state.Addpoem.find((st) => st.type === type).value.data,
    Core: state.Core,

});

const mapDispatchToProps = (dispatch) => ({
    setApiState: (data, type) => dispatch({
        type,
        value: data,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPoem);
