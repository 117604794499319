const initialState = [
    {
        type: 'ABOUTUSPAGE.CONTENT',
        value: [],
    },
];
const Aboutuspage = (state = initialState, action) => {
    let OtherStates = [];
    switch (action.type) {
        case 'ABOUTUSPAGE.CONTENT':
            OtherStates = state.filter((st) => st.type !== action.type);
            return [
                ...OtherStates,
                {
                    type: action.type,
                    value: action.value,
                },
            ];
        default:
            return state;
    }
};

export default Aboutuspage;
