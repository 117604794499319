const API_ROUTES_LIST  = {
    userLogin: '/user/login',
    guestToken: '/user/token',
    getSesionToken: '/session/token',
    userLogout: '/user/logout',
    getConfig: '/destm/getConfig',
    getAllPoems: '/destm/getAllPoems',
    getPoemByUrl: '/destm/getPoemByUrl',
    getAllComments: '/destm/getAllComments',
    createComment: '/destm/createComment',
    deleteComment: '/destm/deleteComment',
    updateComment: '/destm/updateComment',
    getBlock: '/destm/getblock',
    getMenu: '/destm/getMenu',
    getPoemsWithImageAndBody: '/destm/getPoemWithImage',
    userRegister: '/user/register',
    search: '/search_api/',
    getsearchactions: '/destm/getRandomPoemActions',
    getCountries: '/destm/getCountries',
    getAllPoetrySlam: '/destm/getAllPoetrySlam',
    getAllCauses: '/destm/getAllCauses',
    getAllPoemActions: '/destm/getAllPoemActions',
    getAllPoetryTerms: '/destm/getAllPoetryTerms',
    getAllGroups: '/destm/getAllGroups',
    getAllPoemScopes: '/destm/getAllPoemScopes',
    getAllTags: '/destm/getAllTags',
    getPageViews: '/destm/getPageView',
    checkSession: '/user/sessionname',
    addPoem: '/destm/create',
    fetchUser: '/destm/fetch_user',
    newsLetter: '/destm/newsletter',
    getUserPoems: '/destm/getUserPoems',
    uploadProfilePicture: '/destm/uploadMedia',
    removeUserProfilePicture: '/destm/removeUserProfilePicture',
    updateProfilePage: '/destm/update_user',
    poemsByTagSearch: '/destm/poemsByTagSearch',
    getUpdatePoem: '/destm/updatePoem',
    unpublishPoem: '/destm/unpublishPoem',
    forgetPassword: '/user/request_new_password',
    groupRequestForm: '/destm/createGroup',
    registerTeacherForm: '/destm/registerTeacher',
};
export default API_ROUTES_LIST;
