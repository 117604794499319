import React, { PureComponent } from 'react';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from 'react-loading-skeleton';
import fallback_url from '../../../../images/poet-image.png';


const styles = (theme) => ({
    mainContainer: {
        marginBottom: 50,
        marginTop: 20,
        ' & h3': {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    parentImg: {
        height: 100,
        width: '20%',
    },
    headingText: {
        paddingBottom: 15,
        marginTop: 15,
    },

    circularLoader: {
        marginTop: 200,
        color: theme.palette.current.primaryColor,
    },
    contentStyle: {
        fontFamily: 'GT-Walsheim-Pro-light',
        maxHeight: 150,
        display: 'flex',
    },
    imgSlams: {
        height: '100%',
        width: 70,
    },
    subrow: {
        textAlign: 'justify',
        justifyContent: 'center',
        borderRadius: 3,
        padding: 5,
        width: '80%',
        marginLeft: '10%',
        '&:nth-child(even)': {
            padding: 5,
            marginLeft: '10%',

            textAlign: 'justify',
            width: '80%',
            backgroundColor: theme.palette.current.slams.slamList,
            borderRadius: 3,
        },
        textList: {
            fontSize: 12,
        },

    },
    loadingBox: {
        paddingLeft: 50,
    },
});

export class Slams extends PureComponent {
    constructor() {
        super();
        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        const {  Core } = this.props;
        const  ApiRoutes  = new Core.ApiRoutes();
        const Http = new Core.Http();
        Http.post(ApiRoutes.getRoute('getPageViews'), { path: 'api/past-featured-slams' })
            .then((res) => this.setState({
                data: res.data.nodes,
            }));
    }

    render() {
        const { data } = this.state;
        const { classes } = this.props;
        return (
            <div className={classes.mainContainer}>
                <h1 className={classes.headingSlam}> Slams</h1>
                { data.length === 0
                    ? (
                        <div className={classes.loadingBox}>
                            <Skeleton height={600} />
                        </div>
                    )
                    : (
                        <div> {data.map((content, i) => (
                            <div key={i} className={classes.subrow}>
                                {content
                                    ? (
                                        <div className={classes.contentStyle}>
                                            <div>
                                                <span className={classes.parentImg}>
                                                    <img
                                                        src={content.node.field_image.src}
                                                        onError={(e) => { e.target.src = fallback_url; }}
                                                        alt="poetry slams"
                                                        className={classes.imgSlams}
                                                    />
                                                </span>
                                            </div>
                                            <ul className={classes.textList}>
                                                {content.node.title}
                                            </ul>
                                        </div>
                                    )
                                    : <CircularProgress />}
                            </div>
                        ))}
                        </div>
                    )}
            </div>
        );
    }
}

export default (withStyles(styles)(Slams));
