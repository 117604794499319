import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const CustomSelect = ({
    classes,
    poemData,
    setPoemField,
    apiData,
    title,
    placeholder,
    name,
    titleToName,
}) => (
    <FormControl className={classes.selectFormControl}>
        <FormHelperText>{placeholder}</FormHelperText>
        <Select
            value={poemData || ''}
            onChange={(e) => setPoemField(name, e.target.value)}
            name={name}
            className={classes.selectStyle}
        >
            <MenuItem value=" " disabled>
                {title}
            </MenuItem>
            {apiData ? apiData.map((data) => (
                <MenuItem
                    key={data.uuid}
                    value={data}
                >
                    {titleToName ? data.name : data.title}
                </MenuItem>
            )) : ''}
        </Select>
    </FormControl>
);
CustomSelect.defaultProps = {
    titleToName: false,
    poemData: '',
};

CustomSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    poemData: PropTypes.string,
    setPoemField: PropTypes.func.isRequired,
    apiData: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    titleToName: PropTypes.bool,
};

export default CustomSelect;
