import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import ReactHtmlParser from 'react-html-parser';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import Pagination from 'material-ui-flat-pagination';
import { Searchstyle } from '../../../../common/search/search';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    contentArea: {
        marginBottom: 25,
    },
    searchBox: {
        border: '1px solid',
        borderColor: theme.palette.current.borderrighttable,
        padding: '15px 5px',
        width: '100%',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        '&::placeholder': {
            fontFamily: 'GT-Walsheim-Pro-Light',
            fontSize: 20,
            paddingLeft: 12,
        },
    },
    button: {
        width: '100%',
        height: '100%',
        minWidth: '50%',
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '10px 20px',
        fontWeight: 600,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    searchhead: {
        display: 'flex',
        marginBottom: 20,
        '&:input': {
            border: '1px solid !important',
            borderColor: theme.palette.current.borderrighttable,
            padding: '15px 5px',
            width: '100%',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
        },
    },
    searchtitle: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        '& a': {
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
    searchbody: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
    },
    searchauthor: {
        fontFamily: 'GT-Walsheim-Pro-Regular',
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.current.invertedBtn.primaryColor,
        display: 'inline',
    },
    searchbodymain: {
        paddingBottom: 25,
        marginTop: 20,
    },
    searchbodymainimage: {
        textAlign: 'center',
        '& img': {
            width: '85%',
        },
    },
    sidebar: {
        backgroundColor: theme.palette.current.topics.background,
        marginLeft: 20,
        borderRadius: 8,
        textAlign: 'center',
        '& h1': {
            fontsize: 25,
            paddingTop: 20,
        },
    },
    searchactiontitle: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
        textAlign: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        '& a': {
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },
    searchbodymainaction: {
        paddingBottom: 30,
    },
    heading: {
        fontFamily: 'GT-Walsheim-Pro-Bold',
    },
    headingsearch: {
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.current.invertedBtn.primaryColor,
        display: 'inline',
    },
    comments: {
        paddingLeft: 10,
    },
    paginationbutton: {
        '& button': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.invertedBtn.primaryColor,
            margin: 5,
        },
    },
    noresult: {
        marginTop: 30,
        fontFamily: ' GT-Walsheim-Pro-Regular',
    },
    poemmainsection: {
        display: 'flex',
    },
    imagesection: {
        '& img': {
            width: '90%',
            maxHeight: 90,
        },
    },
});


class Searchdesktopview extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            offset: 0,
            poemsPerPage: 10,
            loadSkeleton: false,
        };
    }

    loadSkeleton = (size = 10) => {
        const skeletonSize = Array(size).fill('');
        return skeletonSize.map((object, i) => (
            <CardHeader
                /* eslint-disable */
                key={i}
                /* eslint-enable */
                avatar={<Skeleton variant="circle" width={80} height={80} />}
                title={<Skeleton height={10} width="100%" />}
                subheader={<Skeleton height={6} width="40%" />}
            />
        ));
    }

    clicksearch = () => {
        this.setState({ loadSkeleton: true });
        setTimeout(
            () => {
                this.setState({ loadSkeleton: false });
            },
            2000,
        );
    }

    getDataOrSkeleton = () => {
        const { data, classes } = this.props;
        const { loadSkeleton, offset, poemsPerPage } = this.state;
        if (data) {
            if (Object.values(data).length > 0 && !loadSkeleton) {
                const indexOfLastTodo = parseInt(offset, 10) + parseInt(poemsPerPage, 10);
                const indexOfFirstTodo = parseInt(offset, 10);
                const currentPoems = Object.values(data).slice(indexOfFirstTodo, indexOfLastTodo);
                return this.rendersearch(Object.values(currentPoems));
            }
            return this.loadSkeleton();
        }
        return <div className={classes.noresult}>YOUR SEARCH YIELDED NO RESULTS </div>;
    }

    handleClickPagination(offset) {
        this.setState({ loadSkeleton: true, offset });
        setTimeout(
            () => {
                this.setState({ loadSkeleton: false });
            },
            2000,
        );
    }

    rendersearch(data) {
        const { classes } = this.props;
        if (data) {
            const search = Object.values(data);
            return (
                <div>
                    {search.map((datanew) => {
                        const newcomment = typeof datanew.body.und !== 'undefined' && datanew.body.und[0].value;
                        const reqdata = JSON.parse(JSON.stringify(newcomment).replace('\\r', ' '));
                        const removebreak = reqdata ? reqdata.replace(/<[^>]+>/g, '') : '';
                        const body = `${removebreak.substring(1, 80)}...`;
                        const created = datanew.created;
                        return (
                            <div key={datanew.nid} className={classes.searchbodymain}>
                                <Grid className={classes.poemmainsection} item xs={12}>
                                    <Grid className={classes.imagesection} item xs={2}>
                                        <img className={classes.dummyimage} src={datanew.field_image} alt="" />
                                    </Grid>
                                    <Grid className={classes.contentsection} item xs={10}>
                                        <div className={classes.searchtitle}>
                                            <Link to={datanew.url}>{datanew.title}</Link>
                                        </div>
                                        <div className={classes.searchbody}>{ReactHtmlParser(body)}</div>
                                        <div>
                                            <span className={classes.searchauthor}>
                                                {typeof datanew.field_editorial_first_name !== 'undefined'
                                                    && datanew.field_editorial_first_name.und[0].value}{' '}
                                            </span>
                                            <span className={classes.comments}>
                                                {' '}
                                                - {created} - {datanew.comment} comments
                                            </span>{' '}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        );
                    })}
                </div>
            );
        }
        return <div>No Results Found!</div>;
    }

    render() {
        const { classes, data } = this.props;
        const { poemsPerPage, offset } = this.state;
        return (
            <>
                <Grid className={`${classes.container}`} container>
                    <Grid className={classes.contentArea} key="content-area" item xs={12}>
                        <h1 className={classes.heading}>Enter your keywords</h1>
                        <Searchstyle clicksearch={this.clicksearch} />
                        <Grid className={classes.searchdataa} item xs={12}>
                            <h1 className={classes.headingsearch}>SEARCH RESULTS</h1>
                            {this.getDataOrSkeleton()}
                        </Grid>
                        {data ? (
                            <div>
                                <Pagination
                                    limit={poemsPerPage}
                                    className={classes.paginationbutton}
                                    offset={offset}
                                    total={Object.values(data).length}
                                    onClick={(e, offsetValue) => this.handleClickPagination(offsetValue)}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                    </Grid>
                </Grid>
            </>
        );
    }
}

Searchdesktopview.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default withStyles(styles)(Searchdesktopview);
