import * as actionTypes from './auth/action.js';
import * as reducerAction from './action.js';

const initialState = {
    value: false,
    loadComments: true,
    editComments: false,
    showAlert: {
        value: false,
        msg: '',
    },
};

const LoginPopup = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_DRAWER_TOGGLE:
            return { ...state, value: action.value };
        case reducerAction.LOAD_COMMENTS:
            return { ...state, loadComments: action.value };
        case reducerAction.EDIT_COMMENTS:
            return { ...state, editComments: !state.editComments };
        case reducerAction.SHOW_ALERT:
            return { ...state, showAlert: { value: action.value, msg: action.message }  };
        default:
            return state;
    }
};

export default LoginPopup;
