/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-indent */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as reducerAction from '../../../reducers/action.js';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';

const styles = (theme) => ({
    alertboxx: {
        zIndex: '11111 !important',
    },
    alert: {
        minWidth: 80,
        padding: 15,
        marginBottom: 20,
        border: '1px solid transparent',
        borderRadius: 3,
        '&  p': {
            padding: 0,
            margin: 0,
        },
        ' & button': {
            position: ' relative',
            float: 'right',
            padding: '0',
            border: 0,
            cursor: 'pointer',
            background: 0,
            fontSize: 21,
            lineHeight: 1,
            fontWeight: 'bold',
            opacity: '0.4',
        },
    },
    alertSuccess: {
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
    },
    alertFail: {
        color: theme.palette.current.btn.primaryColor,
        background: theme.palette.current.btn.fontColor,
    },
    closeAlert:{
        color: theme.palette.current.btn.primaryColor,
    },
})

class Alert extends React.Component{
    render(){
        const { alertState, handleClose, classes } = this.props;
        return(
        <>
            <Snackbar open={alertState.value} autoHideDuration={2000} onClose={handleClose}>
                <span variant="outlined" className={`${classes.alert} ${classes.alertFail}`}>
                    {alertState.msg}
                    <button type="button" onClick={handleClose} className={classes.closeAlert}>  &nbsp; x  </button>
                </span>
             </Snackbar>
            </>
        )
    }
}

Alert.propTypes = {
    classes: PropTypes.object.isRequired,
    alertState: PropTypes.object.isRequired,
};
const mapStateToProps = (state) =>{
    return{
        alertState: state.LoginPopup.showAlert,
    }
}
const mapDispatchToProps = (dispatch) =>{
    return{
        handleClose: () => dispatch({ type: reducerAction.SHOW_ALERT, value: false})
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Alert))