import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import bag from '../../../images/icon.svg';
import  Requestaction from './action';
import  {Registeraction} from './action';
import { connect } from 'react-redux';
import * as userActionTypes from '../../../reducers/user/action';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as actionTypes from '../../../reducers/auth/action.js';
const styles = (theme) => ({
    sideBar: {
        backgroundColor: theme.palette.current.topics.background,
        borderRadius: 8,
        maxHeight: 900,
        textAlign: 'center',
        '& h1': {
            fontSize: 25,
            paddingTop: 20,
        },
    },
    groupDesc: {
        width: '75.9%',
        border: 'none',
        borderRadius: 8,
    },
    grpSubHead: {
        marginTop: 10,
        marginBottom: 20,
    },
    formField: {
        textAlign: 'center',
        '& > input': {
            border: 0,
            padding: 15,
            width: '70%',
            borderRadius: 8,
            marginBottom: 10,
            fontFamily: 'GT-Walsheim-Pro-Light',
            outline: 'none !important',
        },
        '& input[type="date"]::-webkit-calendar-picker-indicator': {
            color: 'rgba(0,   0, 0, 0)',
            opacity: '1',
            display: 'block',
            background: `url(${bag}) no-repeat`,
            backgroundSize: '100% 100%',
            width: '20px',
            height: '20px',
            borderWidth: 'thin',
        },
    },
    valid: {
        textAlign: 'center',
        padding: '20px',
        fontWeight: 500,
        clear: 'both',
        color: theme.palette.current.user.validColor,
        margin:'0 auto',
        '& a': {
            display: 'block !important',
            color: theme.palette.current.invertedBtn.primaryColor,
        },
    },

    errorStyle: {
        fontSize: 14,
        color: 'red',
        width: '75%',
        paddingBottom: 10,
        margin: 'auto',
        textAlign: 'left',
    },
    InvertedButton: {
        background: theme.palette.current.invertedBtn.primaryColor,
        color: theme.palette.current.footerBrandColor,
        '&:hover': {
            background: theme.palette.current.invertedBtn.primaryColor,
            color: theme.palette.current.footerBrandColor,
        },
        marginLeft: 0,
        textTransform: 'capitalize',
        padding: '10px 25px',
        fontWeight: 600,
        borderRadius: 8,
        marginBottom: 25,
        width: '78%',
    },
    errorMsgDiv: {
        color: theme.palette.current.user.invalidColor,
        padding: '20px',
        textAlign: 'center',
        fontWeight: 500,
        clear: 'both',
        '& a': {
            color: theme.palette.current.invertedBtn.primaryColor,
        },
        '& p': {
            margin: '10px 0px 0px 0px',
            display:'initial !important',
        },
    },
    circularLoader: {
        color: theme.palette.current.invertedBtn.primaryColor,
        marginBottom: 25,
    },
    successMsg:{
        textAlign:'center !important',
        color: theme.palette.current.groupRequest.color,
        border:'1px solid',
        borderColor:theme.palette.current.groupRequest.color,
        padding:'10px',
    },
    emailCheck:{
            textAlign: 'left',
            display: 'block',
            paddingLeft: '35px',
            margin: '10px 0px 15px 0px',
            '& .MuiCheckbox-colorSecondary.Mui-checked':{
                color: theme.palette.current.poem.block.hoverBackground,
            }
    },
    lognButton:{
        color: theme.palette.current.mainMenuItemColor,
        background: 'none !important',
        padding: '0px !important',
        width: 'auto !important',
        margin: '0px !important',
        textDecoration: 'underline !important',
        fontSize: 16,
        fontWeight: 700,
        textTransform:' initial !important',
    }
});

class GroupRequestForm extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            username: '',
            email: '',
            school: '',
            zipcode: '',
            password: '',
            confirm_password: '',
            groupname: '',
            groupdesc:'',
            uid: '',
            errors: [],
            groupError:'',
            isLoading: false,
        };
    }

    validate = () => {
        const {
            groupname, groupdesc,
        } = this.state;
        let groupNameError = '';
        let groupDescError = '';

        if (!groupname) {
            groupNameError = 'Group Name cannot be blank';
        }
        if (!groupdesc) {
            groupDescError = 'Please provide Group Description';
        }

        if (groupNameError || groupDescError) {
            this.setState({
                groupNameError,
                groupDescError,
                isLoading: false,
            });
            return false;
        }

        return true;
    };

    isValid=() => {
        const isValid = this.validate();
        if (isValid) {
         this.groupRequest();
            // clear form
            this.setState({
                groupNameError: '',
                groupDescError: '',
                uid:'',
                isLoading: true,
            });
        }
    }

    validateRegister = () => {
        const {
            username, email,school,zipcode,password,confirm_password
        } = this.state;
        let nameError = '';
        let emailError = '';
        let schoolError = '';
        let zipcodeError='';
        let passwordError = '';
        let passwordUnmatchError = '';

        if (!username) {
            nameError = 'Username cannot be blank';
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            emailError = 'Please Provide E-mail Address';
        }
        if (!school) {
            schoolError = 'School cannot be blank';
        }
        if (!zipcode) {
            zipcodeError = 'Zip code cannot be blank';
        }
        if (!password || !confirm_password) {
            passwordError = 'Password field cannot be empty';
        }
        if ((typeof confirm_password === 'undefined' && confirm_password === '') || (password !== confirm_password)) {
            passwordUnmatchError = 'Password did not match';
        }

        if (nameError || emailError || schoolError || zipcodeError || passwordError || passwordUnmatchError) {
            this.setState({
                nameError,
                emailError,
                schoolError,
                zipcodeError,
                passwordError,
                passwordUnmatchError,
                isLoading: false,
            });
            return false;
        }

        return true;
    };

    isValidRegister=() => {
        const isValid = this.validateRegister();
        const { coredata } = this.props;
        if (isValid) {
            this.registerTeacher(coredata);
            // clear form
            this.setState({
                nameError: '',
                emailError: '',
                schoolError:'',
                zipcodeError:'',
                passwordError: '',
                passwordUnmatchError: '',
                isLoading: true,
            });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }

    registerTeacher(){
        const {
            username, email, school,zipcode,password, confirm_password,
        } = this.state;
        return this.setState({
            errors: '',
        }, () => Registeraction(username, email, school,zipcode,password, confirm_password).then((response) => {
            if (response.status === 1) {
                this.setState({
                    errors: 'Teacher Registered Successfully',
                });
                setTimeout(function(){
                    return window.location.reload(1);
                 }, 3000);
            }
            if (response ? response.data.response.data.form_errors : null) {
                const errors = response.data.response.data.form_errors;
                const errorvalues = Object.values(errors);
                const errormsg =  errorvalues.toString().replace(/,/g, '</br>');
                return this.setState({
                    isLoading: false,
                    errors: errormsg,
                });
            }
            return null;
        }));
    }
    
    groupRequest() {
        const {
            groupname, groupdesc,
        } = this.state; 
        const {Core,userId} = this.props;
        const auth_token = Core.auth_token();
        if(auth_token){
            this.setState({ uid: userId });
        }
        return this.setState({
            groupError: '',
        }, () => Requestaction(userId,groupname, groupdesc).then((response) => {
            if (response.status === 1) {
            this.setState({
                groupError: 'Group Request Successfully Sent',
            });
            setTimeout(function(){
                return window.location.reload(1);
             }, 3000);
        }
        return null;
    }));
    }

    componentDidMount() {
        const { getUserData } = this.props;
        const name = localStorage.getItem('loggein_username');
        getUserData(name);
    }

    render() {
        const { classes,Core ,userId,showLoginDrawer} = this.props;
        const {
            groupname, groupdesc,groupNameError,errors,groupDescError,username,email,school,zipcode,password,confirm_password,
            nameError,emailError,schoolError,zipcodeError,passwordError,passwordUnmatchError,groupError,isLoading
        } = this.state; 
        
        const errorGroupRequest = ReactHtmlParser(groupError);
        const errorRegisterTeacher = ReactHtmlParser(errors);
        const auth_token = Core.auth_token();
        return (
            <>
            {auth_token ? (
                <div className={classes.sideBar} item xs={12}>
                    <h1>Group Request Form</h1>
                    <div className={classes.grpSubHead}>Tell us more about the group</div>
                    <form>
                        <div className={classes.formField}>
                            <input
                                type="text"
                                name="groupname"
                                className={`${groupname ? '' : classes.invalidinput}`}
                                onChange={(event) => this.handleInputChange(event)}
                                value={groupname}
                                placeholder="Group Name (You can change this later) "
                                label="Group Name (You can change this later) "
                            />
                            <div className={classes.errorStyle}>  {groupNameError}
                            </div>
                        </div>
                        <div className={classes.formField}>
                        <input className={classes.groupDesc} name="groupdesc"   onChange={(event) => this.handleInputChange(event)} value={groupdesc}  placeholder="Group Description "/>
                            <div className={classes.errorStyle}>  {groupDescError}
                            </div>
                        </div>
                        <div>                        <input
                                type="hidden"
                                value={userId}
                            /></div>
                        <div className={classes.formField}>
                            { isLoading === true
                                ? <CircularProgress classes={{ colorPrimary: classes.circularLoader }} />
                                : (
                                    <Button
                                        onClick={() => this.isValid()}
                                        className={classes.InvertedButton}
                                        size="small"
                                        color="primary"
                                        onKeyPress={() => this.isValid()}
                                    >
                                        Submit
                                    </Button>
                                ) }
                            <div className={classes.errorwithoutpopoup}>
                                <div className={groupError === 'Group Request Successfully Sent' ? classes.valid : classes.errorMsgDiv}>
                                    {errors
                                        ? (
                                            <p className ={groupError === 'Group Request Successfully Sent' ? classes.successMsg :''}>
                                                {errorGroupRequest}
                                            </p>
                                        )
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            ): (
                <div className={classes.sideBar} item xs={12}>
                    <h1>Teacher Registration Form</h1>
                    <div className={classes.grpSubHead}>Create an account on Power Poetry
                        Already have an account? <Button className={classes.lognButton} onClick={showLoginDrawer}>
                                    Login
                                </Button>  
                        
                    </div>
                    <form>
                        <div className={classes.formField}>
                            <input
                                type="text"
                                name="username"
                                className={`${username ? '' : classes.invalidinput}`}
                                onChange={(event) => this.handleInputChange(event)}
                                value={username}
                                placeholder="Username"
                            />
                            <div className={classes.errorStyle}>  {nameError}
                            </div>
                        </div>
                        <div className={classes.formField}>
                            <input
                                type="email"
                                name="email"
                                value={email}
                                className={`${email ? '' : classes.invalidinput}`}
                                onChange={(event) => this.handleInputChange(event)}
                                placeholder="Email"
                            />
                            <div className={classes.errorStyle}> {emailError}
                            </div>
                        </div>

                        <div className={classes.formField}>
                            <input
                                type="text"
                                name="school"
                                value={school}
                                className={`${school ? '' : classes.invalidinput}`}
                                onChange={(event) => this.handleInputChange(event)}
                                placeholder="Your School"
                            />
                            <div className={classes.errorStyle}> {schoolError}
                            </div>
                        </div>

                        <div className={classes.formField}>
                            <input
                                type="text"
                                name="zipcode"
                                value={zipcode}
                                className={`${zipcode ? '' : classes.invalidinput}`}
                                onChange={(event) => this.handleInputChange(event)}
                                placeholder="Zip Code"
                            />
                            <div className={classes.errorStyle}> {zipcodeError}
                            </div>
                        </div>
                        <div className={classes.formField}>
                            <input
                                type="password"
                                name="password"
                                value={password}
                                className={`${password ? '' : classes.invalidinput}`}
                                onChange={(event) => this.handleInputChange(event)}
                                placeholder="Password"
                            />
                            <div className={classes.errorStyle}>  {passwordError || passwordUnmatchError}
                            </div>
                        </div>
                        <div className={classes.formField}>
                            <input
                                type="password"
                                name="confirm_password"
                                value={confirm_password}
                                className={`${confirm_password ? '' : classes.invalidinput}`}
                                onChange={(event) => this.handleInputChange(event)}
                                placeholder="Confirm Password"
                            />
                        </div>
                        <FormControlLabel
                            className={classes.emailCheck}
                            control={<Checkbox name="email-updates" defaultChecked={true} onChange={(event) => this.handleInputChange(event)}/>}
                            label="Email Updates"
                        />
                        <Button
                            onClick={() => this.isValidRegister()}
                            className={classes.InvertedButton}
                            size="small"
                            color="primary"
                            onKeyPress={() => this.isValidRegister()}
                        >
                            Submit
                        </Button>
                        <div className={classes.errorwithoutpopoup}>
                                <div className={errors === 'Teacher Registered Successfully' ? classes.valid : classes.errorMsgDiv}>
                                    {errors
                                        ? (
                                            <p className ={errors === 'Teacher Registered Successfully' ? classes.successMsg :''}>
                                                {errorRegisterTeacher}
                                            </p>
                                        )
                                        : ''}
                                </div>
                            </div>
                    </form>
                </div>
            )}
            </>
        );
    }
}

GroupRequestForm.propTypes = {
    classes: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
    getUserData: PropTypes.func.isRequired,
    showLoginDrawer: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    userId: state.User.find((st) => st.type === userActionTypes.USER_UID).value,
});

const mapDispatchToProps = (dispatch) => ({
    getUserData: (name) => dispatch({ type: userActionTypes.LOAD_USER_DATA, userName: name }),
    showLoginDrawer: () => dispatch({ type: actionTypes.LOGIN_DRAWER_TOGGLE, value: true }),
});



export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(GroupRequestForm));
