export const POEMDATA = 'ADDPOEM_POEMDATA';
export const API_DATA = {
    COUNTRIES: 'ADDPOEM_API_DATA_COUNTRIES',
    SLAMS: 'ADDPOEM_API_DATA_SLAMS',
    CAUSES: 'ADDPOEM_API_DATA_CAUSES',
    GUIDES: 'ADDPOEM_API_DATA_GUIDES',
    TERMS: 'ADDPOEM_API_DATA_TERMS',
    GROUPS: 'ADDPOEM_API_DATA_GROUPS',
    ABOUT: 'ADDPOEM_API_DATA_ABOUT',
};
