import React, { Component } from 'react';
import {  withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send';
import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from 'prop-types';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    radio: {
        color: theme.palette.current.btn.primaryColor,
        '&$checked': {
            color: theme.palette.current.btn.primaryColor,
        },
    },
    searchBox: {
        border: 0,
        padding: '15px 5px',
        width: '100%',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        outline: 'none !important',
    },
    msg: {
        color: theme.palette.current.btn.primaryColor,
    },
    alert: {
        minWidth: 80,
        padding: 15,
        marginBottom: 20,
        border: '1px solid transparent',
        borderRadius: 3,
        '&  p': {
            padding: 0,
            margin: 0,
        },
        ' & button': {
            position: ' relative',
            float: 'right',
            padding: '0',
            border: 0,
            cursor: 'pointer',
            background: 0,
            fontSize: 21,
            lineHeight: 1,
            fontWeight: 'bold',
            opacity: '0.4',
        },
    },
    alertSuccess: {
        background: theme.palette.current.btn.primaryColor,
        color: 'black',
    },
    button: {
        width: '100%',
        height: '100%',
        minWidth: '50%',
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '10px 20px',
        fontWeight: 600,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    checked: {},
    rootClass: {
        flexDirection: 'row',
        color: theme.palette.current.mainMenuItemColor,
    },
    subHead: {
        color: theme.palette.current.mainMenuItemColor,
        fontSize: 18,
        paddingTop: 7,
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
            paddingTop: 11,
        },
    },
    submitNews: {
        width: '100%',
        height: '100%',
        minWidth: '50%',
        background: theme.palette.current.btn.primaryColor,
        color: theme.palette.current.btn.fontColor,
        '&:hover': {
            background: theme.palette.current.btn.primaryColor,
            color: theme.palette.current.btn.fontColor,
        },
        textTransform: 'capitalize',
        padding: '10px 20px',
        fontWeight: 600,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    radioButtons: {
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            display: 'flex',
            flexDirection: 'column',
        },
    },
});
class NewsLetter extends Component {
    constructor() {
        super();
        this.state = {
            mail: '',
            sub: 'student',
            mailError: ' ',
            success: false,
            open: false,
        };
    }

    handleMail = (e) => {
        this.setState({
            mail: e.target.value,
        });
    }

    handleSub =(e) => {
        this.setState({
            sub: e.target.value,
        });
    }

    checkforspace=(e) => {
        if (e.which === 32 && !e.target.value.length) { e.preventDefault(); }
    }

    handleClose=(event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            open: false,
        });
    }

    validate= () => {
        const { mail } = this.state;
        let mailError = '';
        let success = '';
        // eslint-disable-next-line no-useless-escape
        if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/i.test(mail)) {
            mailError = 'Please Provide Valid E-mail Address';
        } else if (!mailError) {
            success = 'You have successfully subscribed to our newsletter service';
        }
        if (mailError || success) {
            this.setState({ mailError, success });
            return false;
        }
        return true;
    }


    saveMail = () => {
        const { Core } = this.props;
        const ApiRoutes = new Core.ApiRoutes();
        const Http = new Core.Http();
        const { mail, sub } = this.state;
        const isValid = this.validate();
        if (isValid) {
            //   clearing msg
            this.setState({
                mailError: '',
            });
        }
        Http.post(ApiRoutes.getRoute('newsLetter'), {
            email: mail,
            subscribe_as: sub,
        })
            .then((response) => {
                if (response.data.status === true) {
                    this.setState({
                        open: true,
                        mail: '',
                    });
                }
            }).catch((error) => Promise.reject(error));
    }

    render() {
        const { classes } = this.props;
        const {
            sub,  mailError, success, open, mail,
        } = this.state;
        return (
            <Grid className={classes.container}>
                <Grid className={classes.mainContainer} container justify="center">
                    <FormControl component="fieldset" className={classes.radioButtons}>
                        <RadioGroup
                            aria-label=" "
                            required
                            className={classes.rootClass}
                            value={sub || ''}
                            onChange={(e) => this.handleSub(e)}
                        >
                            <span className={classes.subHead}> Subscribe As: &nbsp; </span>
                            <div className={classes.formButtons}>
                                <FormControlLabel
                                    value="student"
                                    control={(
                                        <Radio
                                            disableRipple
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                        />
                                    )}
                                    label="Student"
                                />
                                <FormControlLabel
                                    value="teacher"
                                    control={(
                                        <Radio
                                            disableRipple
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                        />
                                    )}
                                    label="Teacher"
                                />
                            </div>
                        </RadioGroup>
                    </FormControl>
                    <>
                        <Grid key="searchBox" item xs={10}>
                            <div>
                                <input
                                    type="text"
                                    label="E-mail"
                                    name="newsletter"
                                    onChange={(e) => this.handleMail(e)}
                                    required
                                    className={classes.searchBox}
                                    onKeyPress={(e) => this.checkforspace(e)}
                                    placeholder="Newsletter"
                                    value={mail || ''}
                                />
                            </div>
                        </Grid>
                        <Grid key="searchButton" item xs={2} className={classes.hide}>
                            { mail !== ''
                                ? (
                                    <Button className={classes.submitNews} type="submit" onClick={() => this.saveMail()}>
                                        <SendIcon />
                                    </Button>
                                ) : (
                                    <Button className={`${classes.submitNews} ${classes.block}`} type="submit" onClick={() => this.saveMail()} disabled>
                                        <SendIcon />
                                    </Button>
                                )}
                        </Grid>
                        { success
                            ? (
                                <Snackbar open={open} autoHideDuration={2000} onClose={this.handleClose}>
                                    <span variant="outlined" className={`${classes.alert} ${classes.alertSuccess}`}>
                                        You have successfully subscribed to our newsletter service
                                        <button type="button" onClick={this.handleClose} className={classes.closeAlert}>  &nbsp; x  </button>
                                    </span>
                                </Snackbar>
                            ) : mailError ? (
                                <Snackbar open={open} autoHideDuration={2000} onClose={this.handleClose}>
                                    <span variant="outlined" className={`${classes.alert} ${classes.alertSuccess}`}>
                                        Please provide valid e-mail Address.
                                        <button type="button" onClick={this.handleClose} className={classes.closeAlert}>  &nbsp; x  </button>
                                    </span>
                                </Snackbar>
                            ) : ('')}
                    </>
                </Grid>
            </Grid>
        );
    }
}
NewsLetter.propTypes = {
    classes: PropTypes.object.isRequired,
    Core: PropTypes.object.isRequired,
};
export default withStyles(styles)(NewsLetter);
