/* eslint-disable no-tabs */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actionTypes from '../../../../reducers/action.js';
import BasicPageDesktop from './BasicPage';
import getFromState from '../../../../helpers/state.helper.js';
import AllNodesDesktop from './AllNode.js';


const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.current.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.current.container.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.current.container.maxWidth.upLarge,
            margin: '0 auto',
        },
    },
    message: {
        textAlign: 'center',
    },
});

class PageContent extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {

        };
    }

    componentDidMount() {
        this.getData();
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
        const { loadHomePageTopics } = this.props;
        loadHomePageTopics();
    }

    UNSAFE_componentWillUpdate(nextProps) {
        const {
            contentLink,
        } = this.props;

        if (contentLink !== nextProps.contentLink) {
            this.clearing();
            this.getData();
        }
    }


    componentWillUnmount() {
        this.clearing();
    }


    getData() {
        const {
            Core: {
                getCurrentPath,
            },
            getPageViewContent,
        } = this.props;

        let url = getCurrentPath();
        if (url && url.includes('/theme/')) { url = url.substr(7); } else { url = url.substr(1); }
        getPageViewContent(url);
    }

    clearing() {
        const { dispatch } = this.props;
        dispatch({
            type: actionTypes.BASICPAGE_TITLE,
            value: null,
        });
        dispatch({
            type: actionTypes.BASICPAGE_LOADING,
            value: true,
        });
        dispatch({
            type: actionTypes.BASICPAGE_BODY,
            value: null,
        });
        dispatch({
            type: actionTypes.BASICPAGE_ALL_CONTENT,
            value: null,
        });
        dispatch({
            type: actionTypes.MULTINODEPAGE_ALL_CONTENT,
            value: null,
        });
        dispatch({
            type: actionTypes.MULTINODEPAGE_CURRENT_PAGE,
            value: null,
        });
    }

    render() {
        const {
            allContent,
            loading,
            allContentnew,
            pager,
            contentLink,
            Homepage,
            Core,
        } = this.props;
        return (
            <>
                {allContentnew ? (
                    <AllNodesDesktop
                        loading={loading}
                        htmlContent={allContentnew}
                        pager={pager}
                        contentLink={contentLink}
                        topics={Core.getStateValueByType(Homepage, actionTypes.HOMEPAGE_TOPICS)}
                    />
                ) : (
                    <BasicPageDesktop loading={loading} htmlContent={allContent} />
                )}
            </>
        );
    }
}

const Core = (state) => state.Core;

const mapStateToProps = (state) => ({
    Core: Core(state),
    title: getFromState(state.BasicPage, actionTypes.BASICPAGE_TITLE),
    loading: getFromState(state.BasicPage, actionTypes.BASICPAGE_LOADING),
    body: getFromState(state.BasicPage, actionTypes.BASICPAGE_BODY),
    allContent: getFromState(state.BasicPage, actionTypes.BASICPAGE_ALL_CONTENT),
    alltitle: getFromState(state.MultipleNodePage, actionTypes.MULTINODEPAGE_TITLE),
    allContentnew: getFromState(
        state.MultipleNodePage,
        actionTypes.MULTINODEPAGE_ALL_CONTENT,
    ),
    pager: getFromState(state.MultipleNodePage, actionTypes.MULTINODEPAGE_CURRENT_PAGE),
    contentLink: getFromState(state.Url, actionTypes.SITE_CURRENT_URL),

});
PageContent.propTypes = {
    Core: PropTypes.object.isRequired,
    allContent: PropTypes.object,
    loading: PropTypes.bool,
    allContentnew: PropTypes.array,
    pager: PropTypes.object,
    contentLink: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    getPageViewContent: PropTypes.func.isRequired,
    Homepage: PropTypes.array.isRequired,
    loadHomePageTopics: PropTypes.func.isRequired,
};
PageContent.defaultProps = {
    allContent: '',
    loading: '',
    allContentnew: '',
    pager: '',
    contentLink: '',

};

const mapDispatchToProps = (dispatch) => ({
    getPageViewContent: (url) => dispatch({ type: actionTypes.LOAD_PAGE_CONTENT_VIEW, url }),
    loadHomePageTopics: () => dispatch({ type: actionTypes.LOAD_HOMEPAGE_TOPICS }),
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PageContent));

export const test = (Cores, offset) => {
    const {
        Core: {
            getCurrentPath, Http, ApiRoutes, isAllNode, getAllNode,
        },
        dispatch,
    } = Cores;

    let url = getCurrentPath();
    const routes = new ApiRoutes();


    const request = new Http();
    const apiUrl =    offset === 0
        ? routes.getRoute('getPageViews')
        : `${routes.getRoute('getPageViews')}?page=${offset / 9}`;
    if (url && url.includes('/theme/')) { url = url.substr(7); } else { url = url.substr(1); }

    request.post(apiUrl, { path: `api/${url}` }).then((res) => {
        if (isAllNode(res.data.nodes)) {
            const data = getAllNode(res.data.nodes);
            const pager = res.data.pager;


            dispatch({
                type: actionTypes.MULTINODEPAGE_ALL_CONTENT,
                value: data,
            });
            dispatch({
                type: actionTypes.MULTINODEPAGE_CURRENT_PAGE,
                value: pager,
            });
        }
    });
};
